<div class="table-wrapper">
  <div class="flex gap-4 tools header-buttons-container">
    <ng-container *ngIf="hasWritePermission">
      <div class="flex align-items-center gap-2 table__action-container" (click)="openAddPopup()">
        <i class="pi pi-plus navigation-img"></i>
        <span class="vertical-align-middle table__action-button">Add</span>
      </div>
    </ng-container>
    <div class="flex align-items-center gap-2 table__action-container" (click)="refresh()">
      <i class="pi pi-refresh navigation-img"></i>
      <span class="vertical-align-middle table__action-button">Refresh</span>
    </div>
  </div>

  <div class="users-table">
    <app-table
      [value]="deposits"
      [lazy]="true"
      [fields]="fields"
      [hasMenuPermission]="hasWritePermission || hasDeletePermission"
      [totalRecords]="totalRecords"
      [menuItems]="items"
      [rowsLimit]="DEPOSITS_LIMIT"
      [loading]="loading"
      (onToggleMenu$)="initMenuItems($event)"
      (onLazyLoad$)="initDeposit($event)"
      [contextMenuItems]="items"
      [(selectedItem)]="selectedDeposit"
      (selectedItemChange)="initCmItems()"
    ></app-table>
  </div>
</div>

