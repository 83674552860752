import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-progressBar',
  template: `
        <div
            [ngStyle]="style"
            role="progressbar"
            aria-valuemin="0"
            [attr.aria-valuenow]="value"
            aria-valuemax="100"
            [ngClass]="{ 'p-progressbar p-component': true, 'p-progressbar-determinate': mode === 'determinate', 'p-progressbar-indeterminate': mode === 'indeterminate' }"
        >
            <div *ngIf="mode === 'determinate' && showValue" class="p-progressbar-label" [style.display]="value != null && value !== 0 ? 'flex' : 'none'">{{ value }}{{ unit }}</div>
            <div (transitionend)="transitionEndEvent.emit()" *ngIf="mode === 'determinate'" class="p-progressbar-value p-progressbar-value-animate" [style.width]="value + '%'" style="display:flex" [style.background]="color">
            </div>
            <div *ngIf="mode === 'indeterminate'" class="p-progressbar-indeterminate-container">
                <div (transitionend)="transitionEndEvent.emit()" class="p-progressbar-value p-progressbar-value-animate" [style.background]="color"></div>
            </div>
        </div>
    `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./progressbar.css'],
  host: {
    class: 'p-element'
  }
})
export class ProgressBar {
  @Input() value: number | undefined;
  @Input() showValue = true;
  @Input() styleClass: string | undefined;
  @Input() style: { [klass: string]: any } | null | undefined;
  @Input() unit = '%';
  @Input() mode = 'determinate';
  @Input() color: string | undefined;
  @Output() public transitionEndEvent: EventEmitter<void> = new EventEmitter<void>();
}
