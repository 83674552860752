import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit,} from '@angular/core';
import {MachineActivityLogModel} from '../../../../model/machine/machine.model';
import {HttpDataService} from '../../../../services/http/http-data.service';
import {
  DEFAULT_TABLE_ROWS_OPTIONS,
  LOCAL_STORAGE_MACHINE_ACTIVITY_LOGS_LIMIT,
  MACHINE_ACTIVITY_LOGS_LIMIT,
  RIGHT_BAR_LOGS
} from '../../../../constants';
import {TableLazyLoadEvent} from 'primeng/table';
import {TableFieldModel} from '../../../../model/table-field.model';
import {FieldTypesEnum} from '../../../../field-types.enum';
import {FieldSortTypesEnum} from '../../../../field-sort-types.enum';
import {NavigationService} from '../../../../services/navigation.service';
import {MenuItem} from 'primeng/api';
import {UtilService} from '../../../../services/util.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogsComponent implements OnInit {
  private _machineId: string;
  public menuItems: MenuItem[] = [];
  @Input() public selectedItem: any;

  get machineId(): string {
    return this._machineId;
  }

  @Input() set machineId(value: string) {
    this._machineId = this._machineId || value;
  }

  @Input() public machineName: string;

  private _visible: boolean;
  public machineActivityLogs: MachineActivityLogModel[];
  public totalRecords: number;
  public fields: TableFieldModel[] = [
    {
      name: 'gatewayMacAddress',
      displayName: 'Gateway Mac Address',
      isCopy: false,
      globalFilter: false,
      sortable: true,
      sortType: FieldSortTypesEnum.string,
      searchable: true,
    },
    {
      name: 'operatingSystem',
      displayName: 'Operating System',
      isCopy: false,
      globalFilter: false,
      sortable: true,
      sortType: FieldSortTypesEnum.string,
      searchable: true,
    },
    {
      name: 'is64BitOperatingSystem',
      displayName: 'Is 64 Bit OS',
      isCopy: false,
      globalFilter: false,
      sortable: true,
      sortType: FieldSortTypesEnum.string,
      searchable: false,
    },
    {
      name: 'hostVersion',
      displayName: 'Host Version',
      isCopy: false,
      globalFilter: false,
      sortable: true,
      sortType: FieldSortTypesEnum.string,
      searchable: false,
    },
    {
      name: 'countryCode',
      displayName: 'Country Code',
      isCopy: false,
      globalFilter: false,
      sortable: true,
      sortType: FieldSortTypesEnum.string,
      searchable: false,
    },
    {
      name: 'ipAddress',
      displayName: 'IP Address',
      isCopy: false,
      globalFilter: false,
      sortable: true,
      sortType: FieldSortTypesEnum.string,
      searchable: false,
    },
    {
      name: 'createdAt',
      displayName: 'Created At',
      isCopy: false,
      globalFilter: false,
      sortable: true,
      sortType: FieldSortTypesEnum.date,
      searchable: true,
      type: FieldTypesEnum.date,
    },
  ]
  public MACHINE_ACTIVITY_LOGS_LIMIT: number;

  @Input()
  set visible(value: boolean) {
    this.initMenuItems();
    this._visible = value;

    if (value) {
      const storedLimit = localStorage.getItem(LOCAL_STORAGE_MACHINE_ACTIVITY_LOGS_LIMIT);
      this.MACHINE_ACTIVITY_LOGS_LIMIT = storedLimit && DEFAULT_TABLE_ROWS_OPTIONS.includes(+storedLimit) ? +storedLimit : MACHINE_ACTIVITY_LOGS_LIMIT;
      this.loadActivityLogs();
    }
  }

  get visible(): boolean {
    return this._visible;
  }

  constructor(
    private httpDataService: HttpDataService,
    private cdr: ChangeDetectorRef,
    private navigationService: NavigationService,
    private utilService: UtilService,
  ) {
  }

  public initCmItems() {
    this.initMenuItems(this.selectedItem);
  }

  public ngOnInit(): void {
    this.initMenuItems();
  }

  public refresh(): void {
    this.loadActivityLogs();
  }

  public initMenuItems(log?: MachineActivityLogModel) {
    this.menuItems = [];
    this.menuItems.push({
      label: 'Copy',
      items: [
        {
          label: 'ID',
          command: () => {
            log && this.utilService.copyText(log.id as string, 'ID copied.');
          }
        }
      ]
    });
  }

  public loadActivityLogs(event?: TableLazyLoadEvent) {
    if (this.visible) {
      localStorage.setItem(LOCAL_STORAGE_MACHINE_ACTIVITY_LOGS_LIMIT, event?.rows?.toString() || MACHINE_ACTIVITY_LOGS_LIMIT.toString());
      this.httpDataService.getMachineActivityLogs(this.machineId, (event ? event.first! : 0), (event ? event.rows! : this.MACHINE_ACTIVITY_LOGS_LIMIT)).subscribe({
        next: (res) => {
          if (res.succeed) {
            if (!this.totalRecords && res.totalItems) {
              this.totalRecords = res.totalItems;
            }
            this.machineActivityLogs = res.value;
          }
          this.cdr.detectChanges();
        },
        error: err => console.log(err),
      });
    }
  }

  public onVisibleChange(event: boolean) {
    if (!event) {
      this.navigationService.closeRightBarPage(RIGHT_BAR_LOGS);
    }
  }

}
