import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {ICON_SIZES} from '../../constants';
import {IconSizeModel} from '../../model/icon-size.model';

@Component({
  selector: 'app-icon-size-dropdown',
  templateUrl: './icon-size-dropdown.component.html',
  styleUrls: ['./icon-size-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconSizeDropdownComponent {
  public iconSizes: IconSizeModel[] = ICON_SIZES;
  @Input() selectedSize = ICON_SIZES[0];
  @Output() public sizeChange$: EventEmitter<IconSizeModel> = new EventEmitter<IconSizeModel>();

  public onSizeChange(size: IconSizeModel): void {
    this.sizeChange$.emit(size);
  }
}
