import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {MAX_PAGING_LIMIT} from '../../constants';
import {
  OrganizationIdentityAggregatorService
} from '../../../../../organization/src/lib/organization-identity-aggregator.service';
import {UtilService} from '../../services/util.service';
import {TreeNode} from 'primeng/api';
import {FormGroup} from '@angular/forms';
import {NotifierService} from '../../services/notifier.service';
import {TreeNodeSelectEvent} from 'primeng/tree';

@Component({
  selector: 'app-organizations-tree-dropdown',
  templateUrl: './organizations-tree-dropdown.component.html',
  styleUrls: ['./organizations-tree-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationsTreeDropdownComponent implements OnInit {
  private readonly currentOrgId: string;
  public organizationNodes: TreeNode[] = [];
  public options: TreeNode[] = [];
  public loading = true;

  @Input() public id: string;
  @Input() public placeholder: string;
  @Input() public label: string;
  @Input() public fcn: string;
  @Input() public fg: FormGroup;
  @Output() public parentTreeChange: EventEmitter<{ tree: any, selectedOrgId: any }> = new EventEmitter<{ tree: any, selectedOrgId: any }>();

  public constructor(
    private orgIdentityAggregatorService: OrganizationIdentityAggregatorService,
    private utilService: UtilService,
    private notifierService: NotifierService,
    private cdr: ChangeDetectorRef,
  ) {
    this.currentOrgId = this.utilService.getCurrentOrganizationId();
  }

  public ngOnInit(): void {
    this.initOrganization();
  }

  private initOrganization(): void {
    this.organizationNodes = [];
    this.loading = true;
    const observer = this.orgIdentityAggregatorService.getChildOrganizations(this.currentOrgId, 1, MAX_PAGING_LIMIT);
    observer.subscribe((res: any) => {
      if (res && res.value) {
        this.organizationNodes = this.utilService.buildOrganizationTree(res.value.entities);
        const organizations = res.value.entities;
        organizations.forEach((org: any, index: number) => {
          this.orgIdentityAggregatorService.getChildOrganizations(org.organizationId, 1, MAX_PAGING_LIMIT).subscribe({
            next: childRes => {
              const childNodes = this.utilService.buildOrganizationTree(childRes.value.entities, org.organizationId);
              this.organizationNodes[index].children = childNodes.length ? childNodes : undefined;
              this.organizationNodes[index].leaf = childNodes.length === 0;
              this.cdr.detectChanges();
            }
          });
        });
        if (!res.value.length) {
          this.loading = false;
          this.cdr.detectChanges();
        }
      }
    });
  }

  public nodeExpand(event: any): void {
    const node = event.node;

    if (!node) {
      return;
    }

    const orgId = node.id;
    if (node.children && node.children.length) {
      node.children.forEach((chNode: TreeNode) => {
        this.orgIdentityAggregatorService.getChildOrganizations(chNode.data, 1, MAX_PAGING_LIMIT).subscribe({
          next: childRes => {
            if (childRes.succeed) {
              const childNodes = this.utilService.buildOrganizationTree(childRes.value.entities, orgId);
              chNode.children = childNodes.length ? childNodes : undefined;
              chNode.leaf = childNodes.length === 0;
              this.cdr.detectChanges();  // todo Arayik
            } else {
              this.notifierService.handleErrors(childRes.errors);
            }
          },
          error: err => {
            this.notifierService.handleRequestError(err);
          }
        });
      });
    }
  }

  public nodeSelect(event: TreeNodeSelectEvent) {
    const selectedOrgNode: TreeNode | undefined = event.node;
    let node = selectedOrgNode;
    const parentToChildIds = [];
    while (node?.parent) {
      parentToChildIds.unshift(node.data);
      node = node.parent;
    }
    this.parentTreeChange.emit({
      tree: node,
      selectedOrgId: selectedOrgNode.data,
    });
  }
}
