<app-drawer *ngIf="!inNewWindow"
            (visibleChange)="onVisibleChange($event)"
            [visible]="visible"
            [header]="'Information: '  + terminalName">

  <div>
    <ng-container [ngTemplateOutlet]="backgroundImageTemplate">
      <div class="card" *ngIf="visible" class="info" [ngClass]="{'isMobileView': isMobileView }">
        <p-tabView >
          <p-tabPanel header="System Information">
            <pre *ngIf="!showProgressSpinner">{{ systemInfo }}</pre>
          </p-tabPanel>
          <p-tabPanel header="Address Resolution Protocol (ARP) table">
            <ng-template pTemplate="content">
              <pre *ngIf="!showProgressSpinner">{{ arpInfo }}</pre>
            </ng-template>
          </p-tabPanel>
        </p-tabView>
      </div>
    </ng-container>
  </div>

</app-drawer>

<ng-template #backgroundImageTemplate>
  <div class="container" style=" display: flex;justify-content: center;">
    <div *ngIf="showProgressSpinner"
         style="margin: 5em 0 30% 0"
         class="remote-desktop">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>
</ng-template>
