import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit,} from '@angular/core';
import {AuthService} from '../../../../services/auth.service';
import {PermissionsEnum} from '../../../../permissions.enum';
import {DEFAULT_TABLE_ROWS_OPTIONS, LOCAL_STORAGE_NOTES_LIMIT, NOTES_LIMIT} from '../../../../constants';
import {MenuItem} from 'primeng/api';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TableFieldModel} from '../../../../model/table-field.model';
import {DataConversionService} from '../../../../services/data-conversion.service';
import {TableLazyLoadEvent} from 'primeng/table';
import {DeletePopup} from '../../../delete-popup/delete-popup.component';
import {NOTE_DELETED} from '../../../../../../locale/multilingual-strings-constants';
import {FieldTypesEnum} from '../../../../field-types.enum';
import {NoteComponent} from '../../../note-popup/note.component';
import {NotifierService} from '../../../../services/notifier.service';
import {PersonalDataService} from '../../../../../../../personal-data/src/lib/personal-data.service';
import {MachineUserNoteModel} from '../../../../../../../personal-data/src/lib/models/machine.model';
import {UtilService} from '../../../../services/util.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesComponent implements OnInit {
  public hasDeletePermission = false;
  public hasWritePermission = false;
  public notes: MachineUserNoteModel[];
  public items: MenuItem[];
  public ref: DynamicDialogRef | undefined;
  public totalRecords: number;
  public loading = true;
  public NOTES_LIMIT: number;
  public selectedNote: MachineUserNoteModel;
  public fields: TableFieldModel[] = [
    {
      name: 'subject',
      displayName: 'Subject',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    },
    {
      name: 'value',
      displayName: 'Message',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    }
    ,
    {
      name: 'createdAt',
      displayName: 'Created At',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
      type: FieldTypesEnum.date
    }
  ];

  @Input() public userId: string;

  constructor(
    private authService: AuthService,
    private notifierService: NotifierService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private dataConversionService: DataConversionService,
    private personalDataService: PersonalDataService,
    private utilService: UtilService
  ) {
  }

  public ngOnInit(): void {
    const storedLimit = localStorage.getItem(LOCAL_STORAGE_NOTES_LIMIT);
    this.NOTES_LIMIT = storedLimit && DEFAULT_TABLE_ROWS_OPTIONS.includes(+storedLimit) ? +storedLimit : NOTES_LIMIT;
    this.hasDeletePermission = this.authService.hasPermission(PermissionsEnum.deleteNote);
    this.hasWritePermission = this.authService.hasPermission(PermissionsEnum.writeNote, [PermissionsEnum.deleteNote]);
    this.initNotes();
    this.initMenuItems();
  }

  public openAddPopup() {
    this.openPopup('Add a new note', {personalDataId: this.userId});
  }

  private openPopup(header: string, data: { personalDataId?: string, note?: MachineUserNoteModel }) {
    this.ref = this.dialogService.open(NoteComponent, {
      header,
      width: this.utilService.getPopupResponsiveWidth(),
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data,
      maximizable: true,
    });
    this.ref.onClose.subscribe((result: { edited: boolean; added: boolean; note: MachineUserNoteModel }) => {
      this.handlePopupResult(result);
    });
  }

  public handlePopupResult(result: { edited: boolean; added: boolean; note: MachineUserNoteModel }) {
    if (result) {
      if (result.edited) {
        const note = this.notes.find((note: MachineUserNoteModel) => note.id === result.note!.id)!;
        note.value = result.note.value;
        note.subject = result.note.subject;
        note.createdAt = result.note.createdAt;
        this.cdr.detectChanges();
      }
      if (result.added) {
        this.notes.push(result.note);
        this.totalRecords++;
        this.cdr.detectChanges();
      }
    }
  }

  public refresh() {
    this.initNotes();
  }

  public initNotes(event?: TableLazyLoadEvent) {
    this.loading = true;
    this.NOTES_LIMIT = event?.rows || NOTES_LIMIT;
    localStorage.setItem(LOCAL_STORAGE_NOTES_LIMIT, this.NOTES_LIMIT.toString());
    this.personalDataService.getMachineUserNotes(this.userId,
      (event ? event.first! / (event ? event.rows! : this.NOTES_LIMIT) + 1 : 1), (event ? event.rows! : this.NOTES_LIMIT)).subscribe({
      next: notesResponse => {
        this.loading = false;
        if (notesResponse.succeed) {
          if (!this.totalRecords && notesResponse.value.totalCount) {
            this.totalRecords = notesResponse.value.totalCount;
          }
          // if (notesResponse.value.entities.length) {
          this.notes = notesResponse.value.entities.map(a => ({
            ...a,
          }));
          this.loading = false;
          this.cdr.detectChanges();
          // }
          this.cdr.detectChanges();
        } else {
          this.notifierService.handleErrors(notesResponse.errors);
        }
      },
      error: err => this.notifierService.handleRequestError(err)
    });
  }

  public initMenuItems(note?: MachineUserNoteModel) {
    this.items = [];
    if (this.hasWritePermission) {
      this.items.push(
        {
          label: 'Edit',
          command: () => {
            note && this.openPopup(note.subject + ': User note', {note});
          }
        });
    }

    this.items.push({
      label: 'Copy',
      items: [
        {
          label: 'ID',
          command: () => {
            note && this.utilService.copyText(note.id as string, 'Note ID copied.');
          }
        }
      ]

    })

    if (this.hasDeletePermission) {
      this.items.push(
        {
          label: 'Delete',
          command: () => {
            note && this.openDeleteNotePopup(note);
          }
        });
    }
  }

  public initCmItems() {
    this.initMenuItems(this.selectedNote);
  }

  private openDeleteNotePopup(note: MachineUserNoteModel) {
    this.ref = this.dialogService.open(DeletePopup, {
      header: 'Are you sure you want to delete this note?',
      width: 'fit-content',
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data: {
        id: note.id,
        data: this.dataConversionService.convertData(
          [note],
          {
            subject: 'Subject',
            value: 'Value',
          }
        ),
      },
    });
    this.ref.onClose.subscribe((result?: {
      id: string,
      delete: boolean,
    }) => {
      if (result?.delete) {
        this.deleteNote(note, result.id);
      }
    });
  }

  private deleteNote(note: MachineUserNoteModel, noteId: string) {
    this.personalDataService.deleteNote(noteId).subscribe({
      next: r => {
        if (r.succeed) {
          this.notifierService.handleSuccessRequest(NOTE_DELETED);
          this.notes.splice(this.notes.indexOf(note), 1);
          this.cdr.detectChanges();
        } else {
          this.notifierService.handleErrors(r.errors);
        }
      },
      error: err => {
        this.notifierService.handleRequestError(err);
      },
    });
  }
}
