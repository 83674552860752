import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {ICON_SIZES} from '../../../shared/constants';
import {TerminalService} from '../../../../../organization-url-redirection-aggregator/src/lib/terminal.service';
import {NavigationService} from '../../../shared/services/navigation.service';
import {WebSocketService} from '../../../shared/services/web-socket.service';
import {RegistryEditorService,} from '../file-services/registry-editor.service';
import {TreeNode} from 'primeng/api';
import {IconSizeModel} from '../../../shared/model/icon-size.model';
import {TableWithTreeComponent} from '../file-services/table-with-tree/table-with-tree.component';
import {ShellWebSocketService} from '../../../shared/services/shell-web-socket.service';
import {FileModel} from '../../../../../file-service/src/lib/models/file.model';

@Component({
  selector: 'app-registry-editor',
  templateUrl: './registry-editor.component.html',
  styleUrls: ['./registry-editor.component.scss'],
  providers: [ WebSocketService, RegistryEditorService, TableWithTreeComponent, ShellWebSocketService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegistryEditorComponent implements OnInit {
  @Input() public terminalId: string;
  @Input() public terminalName: string;
  @Input() public visible: boolean;
  @Output() public onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isRegisterEditor = true;
  public selectedDirectory: any;
  public currentNode: TreeNode | null;
  public files: FileModel[] = [];
  public directories: TreeNode[] = [];
  public selectedIconSize: IconSizeModel = ICON_SIZES[0];
  public loading = true;
  public showProgressSpinner = true;
  public inNewWindow = false;
  private loadedNodes = new Map<string | undefined, boolean>();

  public constructor(
    private cdr: ChangeDetectorRef,
    private terminalService: TerminalService,
    private navigationService: NavigationService,
    private registryEditorService: RegistryEditorService,
  ) {
  }

  public ngOnInit(): void {
    this.terminalService.getTerminalShell(this.terminalId).subscribe(res => {
      this.registryEditorService.connectToMachine(res.value);
      this.cdr.detectChanges();
    });

    this.registryEditorService.rootDirectories$.subscribe((directories: TreeNode[]) => {
      this.directories = directories;
      this.loading = false;
      this.cdr.detectChanges();
    });
    this.registryEditorService.directories$.subscribe((childDirectories: any) => {
      console.log('Received child directories:', childDirectories);
      if (this.currentNode) {
        this.directories = [...this.directories];
        const loadingTime = childDirectories > 100 ? 3000 : 100
        setTimeout(() => {
          if (this.loading) {
            this.loading = false;
            this.cdr.detectChanges();
          }
        }, loadingTime);
        this.currentNode.children = childDirectories.directories;
        this.loadedNodes.set(this.currentNode.label, true);
      } else {
        this.loading = false;
      }
      this.cdr.detectChanges();
    });
    this.registryEditorService.files$.subscribe((files: any) => {
      this.files = files.files;
      this.loading = false;
      this.cdr.detectChanges();
    });

    this.registryEditorService.emptyData.subscribe(() => {
      this.loading = false;
      this.cdr.detectChanges();
    });
  }

  public onVisibleChange(event: boolean) {
    if (!event) {
      this.onClose.emit(true);
    }
  }

  public ngOnDestroy(): void {
    this.registryEditorService.closeSocket();
  }

  public onNodeExpand(event: any): void {
    this.currentNode = event;
    this.registryEditorService.loadChildDirectories(event.key);
    this.files = [];
    this.loading = true;
    this.cdr.detectChanges();
  }

  public nodeSelect(event: any) {
    this.onNodeExpand(event);
    this.selectedDirectory = event
  }

  public refreshCurrentNode(): void {
    if (this.currentNode) {
      this.loading = true;
      this.cdr.detectChanges();
      this.registryEditorService.loadChildDirectories(this.currentNode.key as string);
    }
  }

  public getDeletedRegistryEntry(event: any) {
    this.nodeSelect(event)
  }

}
