<app-drawer *ngIf="!inNewWindow"
            (visibleChange)="onVisibleChange($event)"
            [visible]="visible"
            [header]="'Keylogger: ' + machineName">
  <div HeaderButtonExtras>
    <div style="display: flex; align-items: center; gap: 30px">
      <i (click)="openNewWindows()" class="fa fa-external-link new-window-btn shell-header-button"></i>
    </div>
  </div>
  <div style="padding-bottom: 16px" class="keylogger">
    <ng-container [ngTemplateOutlet]="keylogger"></ng-container>
  </div>
</app-drawer>

<ng-container *ngIf="inNewWindow">
  <div class="keylogger-full">
    <ng-container [ngTemplateOutlet]="keylogger"></ng-container>
  </div>
</ng-container>

<ng-template #keylogger>
  <div class="container">


    <div *ngIf="showProgressSpinner"
         style="position: absolute ;display: flex; flex-direction: column; align-items: center; justify-content: center"
         class="keylogger">
      <p-progressSpinner></p-progressSpinner>
    </div>

    <div *ngIf="!showProgressSpinner">
      {{text}}
    </div>
  </div>
</ng-template>
