<ng-container *ngIf="!separateWindow">
  <p-dialog *ngIf="!previewInSplitter"
    [(visible)]="preview"
    (visibleChange)="onHideDialog()"
    [maximizable]="true"
    [style]="{ width: '60vw', height: '600px' }">
    <ng-template pTemplate="header">
      <div style="display: flex; justify-content: space-between; width: 100%">
        <span class="text-xl font-bold" style="padding: 12px;">{{previewHeader}}</span>
        <div style="display: flex; gap: 12px; padding: 16px;">
          <i (click)="openSeparateWindow()" style="cursor: pointer; align-self: center" class="fa fa-external-link-square navigation-img"></i>
          <i (click)="openPreviewInSplitter()" style="cursor: pointer; align-self: center" class="fa fa-columns navigation-img"></i>
        </div>
      </div>

    </ng-template>
    <ng-container *ngTemplateOutlet="fileView"></ng-container>
  </p-dialog>
  <div *ngIf="previewInSplitter">
    <div style="display: flex; justify-content: space-between; width: 100%">
      <span class="text-xl font-bold" style="padding: 12px;">{{previewHeader}}</span>
      <div style="display: flex; gap: 15px; padding: 16px;">
        <i (click)="openSeparateWindow()" style="cursor: pointer; align-self: center" class="fa fa-external-link-square navigation-img"></i>
        <i (click)="openPreviewInPopup()" style="cursor: pointer; align-self: center" class="fa fa-window-restore navigation-img"></i>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="fileView"></ng-container>
  </div>
</ng-container>
<ng-container *ngIf="separateWindow">
  <div>
    <span class="text-xl font-bold">{{previewHeader}}</span>
    <ng-container *ngTemplateOutlet="fileView"></ng-container>
  </div>
</ng-container>
<ng-template #fileView>
  <div id='view'>
  </div>
</ng-template>
