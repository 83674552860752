import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input,} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TableFieldModel} from '../../../../model/table-field.model';
import {FieldTypesEnum} from '../../../../field-types.enum';
import {NotifierService} from '../../../../services/notifier.service';
import {DataConversionService} from '../../../../services/data-conversion.service';
import {
  DEFAULT_TABLE_ROWS_OPTIONS,
  LOCAL_STORAGE_PSYCHOLOGICAL_PORTRAITS_LIMIT,
  PSYCHOLOGICAL_PORTRAITS_LIMIT,
} from '../../../../constants';
import {TableLazyLoadEvent} from 'primeng/table';
import {DeletePopup} from '../../../delete-popup/delete-popup.component';
import {PSYCHOLOGICAL_PORTRAIT_DELETED} from '../../../../../../locale/multilingual-strings-constants';
import {PsychologicalPortraitModel} from '../../../../model/employee/psychological-portrait.model';
import {
  PsychologicalPortraitPopupComponent
} from '../../../psychological-portrait-popup/psychological-portrait-popup.component';
import {PersonalDataService} from '../../../../../../../personal-data/src/lib/personal-data.service';
import {MachineUserNoteModel} from '../../../../../../../personal-data/src/lib/models/machine.model';
import {UtilService} from '../../../../services/util.service';

@Component({
  selector: 'app-psychological-portraits',
  templateUrl: './psychological-portraits.component.html',
  styleUrls: ['./psychological-portraits.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PsychologicalPortraitsComponent {
  public psychologicalPortraits: PsychologicalPortraitModel[];
  public items: MenuItem[];
  public ref: DynamicDialogRef | undefined;
  public totalRecords: number;
  public loading = true;
  public PSYCHOLOGICAL_PORTRAITS_LIMIT: number;
  public selectedPsychologicalPortrait: PsychologicalPortraitModel;
  public fields: TableFieldModel[] = [
    {
      name: 'summary',
      displayName: 'Summary',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    },
    {
      name: 'description',
      displayName: 'Description',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    }
    ,
    {
      name: 'createdAt',
      displayName: 'Created At',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
      type: FieldTypesEnum.date
    }
  ];

  @Input() public userId: string;

  constructor(
    private notifierService: NotifierService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private dataConversionService: DataConversionService,
    private personalDataService: PersonalDataService,
    private utilService: UtilService
  ) {
  }

  public ngOnInit(): void {
    const storedLimit = localStorage.getItem(LOCAL_STORAGE_PSYCHOLOGICAL_PORTRAITS_LIMIT);
    this.PSYCHOLOGICAL_PORTRAITS_LIMIT = storedLimit && DEFAULT_TABLE_ROWS_OPTIONS
      .includes(+storedLimit) ? +storedLimit : PSYCHOLOGICAL_PORTRAITS_LIMIT;
    this.initPsychologicalPortraits();
    this.initMenuItems();
  }

  public openAddPopup() {
    this.openPopup('Add a new psychological portrait', {personalDataId: this.userId});
  }

  private openPopup(header: string, data: { personalDataId?: string, psychologicalPortrait?: PsychologicalPortraitModel }) {
    this.ref = this.dialogService.open(PsychologicalPortraitPopupComponent, {
      header,
      width: this.utilService.getPopupResponsiveWidth(),
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data,
      maximizable: true,
    });
    this.ref.onClose.subscribe((result: { edited: boolean; added: boolean; psychologicalPortrait: PsychologicalPortraitModel }) => {
      this.handlePopupResult(result);
    });
  }

  public handlePopupResult(result: { edited: boolean; added: boolean; psychologicalPortrait: PsychologicalPortraitModel }) {
    if (result) {
      if (result.edited) {
        const psychologicalPortrait = this.psychologicalPortraits.find((psychologicalPortrait: PsychologicalPortraitModel) => psychologicalPortrait.id === result.psychologicalPortrait!.id)!;
        psychologicalPortrait.summary = result.psychologicalPortrait.summary;
        psychologicalPortrait.description = result.psychologicalPortrait.description;
        psychologicalPortrait.createdAt = result.psychologicalPortrait.createdAt;
        this.cdr.detectChanges();
      }
      if (result.added) {
        this.psychologicalPortraits.push(result.psychologicalPortrait);
        this.totalRecords++;
        this.cdr.detectChanges();
      }
    }
  }

  public refresh() {
    this.initPsychologicalPortraits();
  }

  public initPsychologicalPortraits(event?: TableLazyLoadEvent) {
    this.loading = true;
    this.PSYCHOLOGICAL_PORTRAITS_LIMIT = event?.rows || PSYCHOLOGICAL_PORTRAITS_LIMIT;
    localStorage.setItem(LOCAL_STORAGE_PSYCHOLOGICAL_PORTRAITS_LIMIT, this.PSYCHOLOGICAL_PORTRAITS_LIMIT.toString());
    this.personalDataService.getPsychologicalPortraits(this.userId,
      (event ? event.first! / (event ? event.rows! : this.PSYCHOLOGICAL_PORTRAITS_LIMIT) + 1 : 1), (event ? event.rows! : this.PSYCHOLOGICAL_PORTRAITS_LIMIT)).subscribe({
      next: response => {
        this.loading = false;
        if (response.succeed) {
          if (!this.totalRecords && response.value.totalCount) {
            this.totalRecords = response.value.totalCount;
          }
          // if (notesResponse.value.entities.length) {
          this.psychologicalPortraits = response.value.entities.map(a => ({
            ...a,
          }));
          this.loading = false;
          this.cdr.detectChanges();
          // }
          this.cdr.detectChanges();
        } else {
          this.notifierService.handleErrors(response.errors);
        }
      },
      error: err => this.notifierService.handleRequestError(err)
    });
  }

  public initMenuItems(psychologicalPortrait?: PsychologicalPortraitModel) {
    this.items = [];
    this.items.push(
      {
        label: 'Edit',
        command: () => {
          psychologicalPortrait && this.openPopup(
            psychologicalPortrait.summary + ': User psychological portrait',
            {psychologicalPortrait}
          );
        }
      });


    this.items.push({
      label: 'Copy',
      items: [
        {
          label: 'ID',
          command: () => {
            psychologicalPortrait && this.utilService.copyText(psychologicalPortrait.id as string, 'Psychological Portrait ID copied.');
          }
        }
      ]
    })

    this.items.push(
      {
        label: 'Delete',
        command: () => {
          psychologicalPortrait && this.openDeletePsychologicalPortraitPopup(psychologicalPortrait);
        }
      });
  }

  public initCmItems() {
    this.initMenuItems(this.selectedPsychologicalPortrait);
  }

  private openDeletePsychologicalPortraitPopup(psychologicalPortrait: PsychologicalPortraitModel) {
    this.ref = this.dialogService.open(DeletePopup, {
      header: 'Are you sure you want to delete this psychological portrait?',
      width: 'fit-content',
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data: {
        id: psychologicalPortrait.id,
        data: this.dataConversionService.convertData(
          [psychologicalPortrait],
          {
            summary: 'Summary',
            description: 'Description',
          }
        ),
      },
    });
    this.ref.onClose.subscribe((result?: {
      id: string,
      delete: boolean,
    }) => {
      if (result?.delete) {
        this.deletePsychologicalPortrait(psychologicalPortrait, result.id);
      }
    });
  }

  private deletePsychologicalPortrait(psychologicalPortrait: PsychologicalPortraitModel, psychologicalPortraitId: string) {
    this.personalDataService.deletePsychologicalPortrait(psychologicalPortraitId).subscribe({
      next: r => {
        if (r.succeed) {
          this.notifierService.handleSuccessRequest(PSYCHOLOGICAL_PORTRAIT_DELETED);
          this.psychologicalPortraits.splice(this.psychologicalPortraits.indexOf(psychologicalPortrait), 1);
          this.cdr.detectChanges();
        } else {
          this.notifierService.handleErrors(r.errors);
        }
      },
      error: err => {
        this.notifierService.handleRequestError(err);
      },
    });
  }

}
