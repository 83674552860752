import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {MachineUserLoanModel,} from '../../../../model/machine/machine.model';
import {MenuItem} from 'primeng/api';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {AuthService} from '../../../../services/auth.service';
import {DEFAULT_TABLE_ROWS_OPTIONS, LOANS_LIMIT, LOCAL_STORAGE_LOANS_LIMIT} from '../../../../constants';
import {PermissionsEnum} from '../../../../permissions.enum';
import {TableLazyLoadEvent} from 'primeng/table';
import {DeletePopup} from '../../../delete-popup/delete-popup.component';
import {LOAN_DELETED} from '../../../../../../locale/multilingual-strings-constants';
import {DataConversionService} from '../../../../services/data-conversion.service';
import {LoanPopupComponent} from '../../../loan-popup/loan-popup.component';
import {TableFieldModel} from '../../../../model/table-field.model';
import {NotifierService} from '../../../../services/notifier.service';
import {PersonalDataService} from '../../../../../../../personal-data/src/lib/personal-data.service';
import {FieldTypesEnum} from '../../../../field-types.enum';
import {UtilService} from '../../../../services/util.service';

@Component({
  selector: 'app-loans',
  templateUrl: './loans.component.html',
  styleUrls: ['./loans.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoansComponent {
  public hasDeletePermission = false;
  public hasWritePermission = false;
  public loans: MachineUserLoanModel[];
  public items: MenuItem[];
  public ref: DynamicDialogRef | undefined;
  public totalRecords: number;
  public loading = true;
  public LOANS_LIMIT: number;
  public selectedLoan: MachineUserLoanModel;
  public fields: TableFieldModel[] = [
    {
      name: 'company',
      displayName: 'Company',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    },
    {
      name: 'amount',
      displayName: 'Amount',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    },
    {
      name: 'from',
      displayName: 'From',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
      type: FieldTypesEnum.date,
    },
    {
      name: 'to',
      displayName: 'To',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
      type: FieldTypesEnum.date,
    },
    {
      name: 'percent',
      displayName: 'Percent',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    },
  ];

  @Input() public userId: string;

  constructor(
    private authService: AuthService,
    private notifierService: NotifierService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private dataConversionService: DataConversionService,
    private personalDataService: PersonalDataService,
    private utilService: UtilService
  ) {
  }

  public ngOnInit(): void {
    const storedLimit = localStorage.getItem(LOCAL_STORAGE_LOANS_LIMIT);
    this.LOANS_LIMIT = storedLimit && DEFAULT_TABLE_ROWS_OPTIONS.includes(+storedLimit) ? +storedLimit : LOANS_LIMIT;
    this.hasDeletePermission = this.authService.hasPermission(PermissionsEnum.deleteLoan);
    this.hasWritePermission = this.authService.hasPermission(PermissionsEnum.writeLoan, [PermissionsEnum.deleteLoan]);
    this.initLoans();
    this.initMenuItems();
  }

  public openAddPopup() {
    this.openPopup('Add a new loan', {personalDataId: this.userId});
  }

  private openPopup(header: string, data: { personalDataId?: string, loan?: MachineUserLoanModel }) {
    this.ref = this.dialogService.open(LoanPopupComponent, {
      header,
      width: this.utilService.getPopupResponsiveWidth(),
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data,
      maximizable: true,
    });
    this.ref.onClose.subscribe((result: { edited: boolean; added: boolean; loan: MachineUserLoanModel }) => {
      this.handlePopupResult(result);
    });
  }

  public handlePopupResult(result: { edited: boolean; added: boolean; loan: MachineUserLoanModel }) {
    if (result) {
      if (result.edited) {
        const loan = this.loans.find((loan: MachineUserLoanModel) => loan.id === result.loan!.id)!;
        loan.company = result.loan.company;
        loan.from = result.loan.from;
        loan.to = result.loan.to;
        loan.amount = result.loan.amount;
        loan.percent = result.loan.percent;
        this.cdr.detectChanges();
      }
      if (result.added) {
        this.loans.push(result.loan);
        this.totalRecords++;
        this.cdr.detectChanges();
      }
    }
  }

  public refresh() {
    this.initLoans();
  }

  public initLoans(event?: TableLazyLoadEvent) {
    this.loading = true;
    this.LOANS_LIMIT = event?.rows || LOANS_LIMIT;
    localStorage.setItem(LOCAL_STORAGE_LOANS_LIMIT, this.LOANS_LIMIT.toString());
    this.personalDataService.getMachineUserLoans(this.userId,
      (event ? event.first! / (event ? event.rows! : this.LOANS_LIMIT) + 1 : 1), (event ? event.rows! : this.LOANS_LIMIT)).subscribe({
      next: loanResponse => {
        this.loading = false;
        if (loanResponse.succeed) {
          if (!this.totalRecords && loanResponse.value.totalCount) {
            this.totalRecords = loanResponse.value.totalCount;
          }
          // if (loanResponse.value.entities.length) {
          this.loans = loanResponse.value.entities.map(a => ({
            ...a,
          }));
          this.loading = false;
          this.cdr.detectChanges();
          // }
          this.cdr.detectChanges();
        } else {
          this.notifierService.handleErrors(loanResponse.errors);
        }
      },
      error: err => this.notifierService.handleRequestError(err)
    });
  }

  public initMenuItems(loan?: MachineUserLoanModel) {
    this.items = [];

    if (this.hasWritePermission) {
      this.items.push(
        {
          label: 'Edit',
          command: () => {
            loan && this.openPopup(loan.company + ': User loan', {loan});
          }
        });
    }

    this.items.push(  {
        label: 'Copy',
      items: [
        {
          label: 'ID',
          command: () => {
            loan && this.utilService.copyText(loan.id, 'Loan ID copied.');
          }
        }
      ]
    })

    if (this.hasDeletePermission) {
      this.items.push(
        {
          label: 'Delete',
          command: () => {
            loan && this.openDeleteLoanPopup(loan);
          }
        });
    }
  }

  public initCmItems() {
    this.initMenuItems(this.selectedLoan);
  }

  private openDeleteLoanPopup(loan: MachineUserLoanModel) {
    this.ref = this.dialogService.open(DeletePopup, {
      header: 'Are you sure you want to delete this loan?',
      width: 'fit-content',
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data: {
        id: loan.id,
        data: this.dataConversionService.convertData(
          [loan],
          {
            company: 'Company',
            amount: 'Amount',
            from: 'From',
            to: 'To',
            percent: 'Percent'
          }
        ),
      },
    });
    this.ref.onClose.subscribe((result?: {
      id: string,
      delete: boolean,
    }) => {
      if (result?.delete) {
        this.deleteLoan(loan, result.id);
        this.refresh();
      }
    });
  }

  private deleteLoan(loan: MachineUserLoanModel, loanId: string) {
    this.personalDataService.deleteLoan(loanId).subscribe({
      next: r => {
        if (r.succeed) {
          this.notifierService.handleSuccessRequest(LOAN_DELETED);
          this.loans.splice(this.loans.indexOf(loan), 1);
          this.cdr.detectChanges();
        } else {
          this.notifierService.handleErrors(r.errors);
        }
      },
      error: err => {
        this.notifierService.handleRequestError(err);
      },
    });
  }

}
