import {ChangeDetectionStrategy, Component,} from '@angular/core';

@Component({
  selector: 'app-employee-photos',
  templateUrl: './employee-photos.component.html',
  styleUrls: ['./employee-photos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeePhotosComponent {
}
