import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class KeyloggerService {
  private socket: WebSocket;
  public messageReceived$: Subject<ArrayBuffer> = new Subject();
  public connectionDied$: Subject<void> = new Subject();

  public connectWithWebsocket(url: string): WebSocket {
    this.socket = new WebSocket(url);
    this.socket.binaryType = 'arraybuffer';

    this.setupSocketMessageHandler();
    this.setupSocketCloseHandler();
    this.setupSocketErrorHandler();

    return this.socket;
  }

  public sendMessage(message: ArrayBuffer): void {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    } else {
      console.error('WebSocket is not open. Unable to send message.');
    }
  }

  public closeSocket(): void {
    this.socket?.close();
  }

  private setupSocketErrorHandler(): void {
    this.socket.onerror = (error: Event) => {
      console.error('[error]', error);
    };
  }

  private setupSocketCloseHandler(): void {
    this.socket.onclose = (event: CloseEvent): void => {
      if (event.wasClean) {
        console.log(
          `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
        );
      } else {
        console.log('[close] Connection died');
        this.connectionDied$.next();
      }
    };
  }

  private setupSocketMessageHandler(): void {
    this.socket.onmessage = async (event: MessageEvent) => {
      if (event.data) {
        this.messageReceived$.next(event.data);
      } else {
        // Handle text frame if needed
        // const json = JSON.parse(event.data);
      }
    };
  }
}
