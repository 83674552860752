import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {EMAIL_SAVED} from '../../../../locale/multilingual-strings-constants';
import {MachineUserEmailModel} from '../../model/machine/machine.model';
import {NotifierService} from '../../services/notifier.service';
import {Observable} from 'rxjs';
import {Response} from '../../model/response.model';
import {PersonalDataService} from '../../../../../personal-data/src/lib/personal-data.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailComponent {
  public emailForm: FormGroup;
  public personalDataId: FormGroup;

  constructor(
              private ref: DynamicDialogRef,
              private fb: FormBuilder,
              private notifierService: NotifierService,
              private config: DynamicDialogConfig,
              private cdr: ChangeDetectorRef,
              private personalDataService: PersonalDataService) {
    const email = config.data.email;
    this.personalDataId = config.data.personalDataId;
    this.emailForm = this.fb.group({
      id: [email?.id],
      personalDataId: [email?.personalDataId || this.personalDataId],
      address: [email?.address, [Validators.required]],
    });
  }

  public submit() {
    if (this.emailForm.valid) {
      this.emailForm.disable();
      const submitRequest$: Observable<Response<string | void>> = this.emailForm.value.id ?
        this.personalDataService.updateEmail(this.emailForm.value as MachineUserEmailModel) :
        this.personalDataService.addEmail(this.emailForm.value);

      submitRequest$.subscribe({
        next: response => {
          if (response.succeed) {
            this.notifierService.handleSuccessRequest(EMAIL_SAVED);
            if (!this.emailForm.value.id) {
              this.emailForm.controls['id'].setValue(response.value);
              this.closeDialog(true);
            } else this.closeDialog(false, true);
          } else {
            this.notifierService.handleErrors(response.errors);
            this.emailForm.enable();
          }
          this.cdr.detectChanges();
        },
        error: err => {
          this.notifierService.handleRequestError(err);
          this.emailForm.enable();
          this.cdr.detectChanges();
        }
      })
    }
  }

  public closeDialog(added: boolean, edited?: boolean) {
    this.ref.close({added, edited, email: this.emailForm.value});
  }

}
