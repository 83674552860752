import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrganizationEventService {
  private onOrgNodeSelect = new Subject<string>();
  public onOrgNodeSelect$ = this.onOrgNodeSelect.asObservable();

  public selectOrganizationNode(orgId: string) {
    this.onOrgNodeSelect.next(orgId);
  }
}
