import {Inject, Injectable, InjectionToken} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {URL_SHORTENER_API_URL} from '../../../src/app/shared/constants';
import {NewResponse, Response} from './models/response';
import {UrlShortenerModel} from './models/url-shortener.model';
import {Observable, of} from 'rxjs';
import {first} from 'rxjs/operators';
import {ObinStatisticModel} from '../../../src/app/modules/file-hosting/model/obin-statistic.model';
import {UrlRedirectionLogModel} from './models/url-redirection-log.model';
import {UrlShorterLogModel} from './models/url-shorter-log.model';

export const API_URL = new InjectionToken<string>('apiUrl');


@Injectable()
export class UrlShortenerService {
  private static readonly urlShortenerApiUrl = URL_SHORTENER_API_URL;
  private readonly urlShortener: string;
  private readonly urlShorterLogs: string;
  private readonly headersSkippingInterceptor: HttpHeaders;
  private readonly headers: HttpHeaders;
  private readonly headersSkippingToken: HttpHeaders;
  private readonly optionsSkippingToken: object;
  private readonly optionsSkippingInterceptor: object;
  private readonly options: object;

  public constructor(private http: HttpClient, @Inject(API_URL) private apiUrl: string) {
    this.headersSkippingToken = new HttpHeaders({
      'Content-Type': 'application/json',
      'skipToken': 'true',
      'skipInterceptor': 'true',
    });
    this.headersSkippingInterceptor = new HttpHeaders({
      'Content-Type': 'application/json',
      'skipInterceptor': 'true',
    });
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.optionsSkippingToken = {
      headers: this.headersSkippingToken,
      withCredentials: true
    };

    this.optionsSkippingInterceptor = {
      headers: this.headersSkippingInterceptor,
      withCredentials: true
    };

    this.options = {
      headers: this.headers,
      withCredentials: true
    };

    this.urlShortener = `${UrlShortenerService.urlShortenerApiUrl}/url-shorter`;
    this.urlShorterLogs = `${UrlShortenerService.urlShortenerApiUrl}/url-shorter/urlShorterLogs`;
  }


  public getShortedURLs(groupId: string, page: number, size: number): Observable<NewResponse<UrlShortenerModel[]>> {
    return this.http.get<NewResponse<UrlShortenerModel[]>>(`${this.urlShortener}/groups/${groupId}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteGroupById(groupId: string): Observable<NewResponse<void>> {
    return this.http.delete<NewResponse<void>>(`${this.urlShortener}/groups/${groupId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getUrlShorter(code: string): Observable<any> {
    return this.http.get<NewResponse<any>>(`${this.urlShortener.replace('/api/url-shorter', '')}/f/${code}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public createShortedUrl(groupId: string, longUrl: string, name: string): Observable<Response<UrlShortenerModel>> {
    return this.http.post<Response<UrlShortenerModel>>(`${this.urlShortener}/urlShorter/${groupId}`,
      {
        longUrl,
        name
      }, this.optionsSkippingInterceptor).pipe(first());
  }

  // Health
  public getHealthz(): Observable<Response<string>> {
    return this.http.get<Response<string>>(`${this.urlShortener}/healthz`, this.optionsSkippingInterceptor).pipe(first());
  }

  // Statistics
  public getShortedUrlStatistics(shortedUrlId: string): Observable<Response<any>> {
    return this.http.get<Response<any>>(`${this.urlShortener}/statistics/${shortedUrlId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  // URL Shorter Logs
  public getAllUrlShorterLogs(shortedUrlId: string, page: number, size: number): Observable<NewResponse<string[]>> {
    return this.http.get<NewResponse<string[]>>(`${this.urlShorterLogs}/${shortedUrlId}/logs?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getUrlShorterLog(logId: string): Observable<Response<UrlShorterLogModel>> {
    return this.http.get<Response<UrlShorterLogModel>>(`${this.urlShorterLogs}/${logId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteUrlShorterLog(logId: string): Observable<NewResponse<void>> {
    return this.http.delete<NewResponse<void>>(`${this.urlShorterLogs}/${logId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteUrlShorterLogs(shortedUrlId: string): Observable<NewResponse<void>> {
    return this.http.delete<NewResponse<void>>(`${this.urlShorterLogs}/${shortedUrlId}/logs/clear`, this.optionsSkippingInterceptor).pipe(first());
  }
}
