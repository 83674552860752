import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-fps-popup',
  templateUrl: './FPS-popup.component.html',
  styleUrls: ['./FPS-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FPSPopupComponent implements OnInit {
  public framesPerSecond$: Subject<number> = new Subject(); // todo
  public dataPoints: any = [];
  public FPSChart: any;

  constructor(
    private config: DynamicDialogConfig,
  ) {
    this.framesPerSecond$ = config.data.framesPerSecond$;
  }

  public FPSChartOptions = {
    theme: 'light2',
    zoomEnabled: false,
    exportEnabled: true,
    title: {
      text: 'Frames Per Second'
    },
    subtitles: [{
      text: 'Loading Data...',
      fontSize: 24,
      horizontalAlign: 'center',
      verticalAlign: 'center',
      dockInsidePlotArea: true
    }],
    axisY: {
      title: 'FPS',
      prefix: '' // 'FPS: '
    },
    data: [{
      type: 'line',
      name: 'Frames Per Second',
      yValueFormatString: '#',
      xValueType: 'dateTime',
      dataPoints: this.dataPoints
    }]
  }

  public ngOnInit(): void {
    this.subscribeFramesPerSecondChange();
  }

  public getChartInstance(chart: object) {
    this.FPSChart = chart;
  }

  private subscribeFramesPerSecondChange(): void {
    this.framesPerSecond$.subscribe({
      next: (seconds: number) => {
        this.dataPoints.push({x: new Date(), y: seconds});
        this.FPSChart.subtitles && this.FPSChart.subtitles[0] && this.FPSChart.subtitles[0].remove();
        this.FPSChart?.render();
      },
      error: (err: string) => console.error(err),
    });
  }
}
