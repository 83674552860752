import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpDataService} from '../../services/http/http-data.service';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {WORK_EXPERIENCE_CREATED_SUCCESSFULLY} from '../../../../locale/multilingual-strings-constants';
import {WorkExperienceModel} from '../../model/employee/work-experience.model';
import {ValidationService} from '../../services/validation.service';
import {NotifierService} from '../../services/notifier.service';
import {PersonalDataService} from '../../../../../personal-data/src/lib/personal-data.service';

@Component({
  selector: 'app-work-experience-popup',
  templateUrl: './work-experience-popup.component.html',
  styleUrls: ['./work-experience-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkExperiencePopupComponent {
  private readonly personalDataId: string;
  public workExperienceForm: FormGroup;

  constructor(private httpDataService: HttpDataService,
              private ref: DynamicDialogRef,
              private fb: FormBuilder,
              private personalDataService:PersonalDataService,
              private validationService: ValidationService,
              private notifierService: NotifierService,
              private config: DynamicDialogConfig,
              private cdr: ChangeDetectorRef,) {

    const workExperience = config.data.workExperience;
    this.personalDataId = config.data.personalDataId;
    this.workExperienceForm = this.fb.group({
      id: [workExperience?.id],
      personalDataId: [workExperience?.personalDataId || config.data.personalDataId],
      organization: [workExperience?.organization, [Validators.required,]],
      occupation: [workExperience?.occupation, [Validators.required,]],
      contacts: [workExperience?.contacts, [Validators.required,]],
      from: [workExperience?.from ? new Date(workExperience?.from) : null ],
      to: [workExperience?.to ? new Date(workExperience?.to) : null],
    }, {
      validators: this.validationService.validateDateRange('from', 'to'),
    });
  }

  public submit() {
    if (this.workExperienceForm.valid) {
      this.workExperienceForm.disable();
      const body = this.workExperienceForm.value as WorkExperienceModel;
      const submitRequest$ = this.workExperienceForm.value.id ? this.personalDataService.updateWork(body) :
        this.personalDataService.addWork(body);

      submitRequest$.subscribe({
        next: response => {
          if (response.succeed) {
            this.notifierService.handleSuccessRequest(WORK_EXPERIENCE_CREATED_SUCCESSFULLY);
            if (!this.workExperienceForm.value.id) {
              this.workExperienceForm.controls['id'].setValue(response.value);
              this.closeDialog(true);
            } else this.closeDialog(false, true);
          } else {
            this.notifierService.handleErrors(response.errors);
            this.workExperienceForm.enable();
          }
          this.cdr.detectChanges();
        },
        error: err => {
          this.notifierService.handleRequestError(err);
          this.workExperienceForm.enable();
          this.cdr.detectChanges();
        }
      })
    }
  }

  public closeDialog(added: boolean, edited?: boolean) {
    this.ref.close({added, edited, workExperience: this.workExperienceForm.value});
  }
}
