import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter, HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  LEFT_BAR_WIDTH,
  LEFT_BAR_WIDTH_ICONS_ONLY,
  LEFT_BAR_WIDTH_ICONS_ONLY_MOBILE,
  LEFT_BAR_WIDTH_MOBILE
} from '../../constants';
import {NavigationService} from '../../services/navigation.service';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawerComponent implements OnInit {
  @Input() public header: string;
  @Input() public visible = false;
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isWide = window.innerWidth > 769;
  public readonly fullWidth = '100%';
  public mostWidth = 'calc(100% - ' + (this.isWide ? LEFT_BAR_WIDTH_ICONS_ONLY : LEFT_BAR_WIDTH_ICONS_ONLY_MOBILE) + ')';
  public halfWidth = 'calc(100% - ' + (this.isWide ? LEFT_BAR_WIDTH : LEFT_BAR_WIDTH_MOBILE) + ')';
  public leftBarVisible: boolean;
  public onlyIcons: boolean;

  public constructor(
    private navigationService: NavigationService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  @HostListener('window:resize', ['$event'])
  onOrientationChange(event: any) {
    const rightBarRes = document.querySelector<HTMLDivElement>('.p-sidebar.p-sidebar-right');
    if (rightBarRes) {
      this.isWide = window.innerWidth > 769;
      this.mostWidth = 'calc(100% - ' + (this.isWide ? LEFT_BAR_WIDTH_ICONS_ONLY : LEFT_BAR_WIDTH_ICONS_ONLY_MOBILE) + ')';
      this.halfWidth = 'calc(100% - ' + (this.isWide ? LEFT_BAR_WIDTH : LEFT_BAR_WIDTH_MOBILE) + ')';
      rightBarRes.style.width = this.onlyIcons ? this.mostWidth : this.halfWidth;
    }
  }

  public onVisibleChange(event: boolean) {
    this.visible = event;
    this.visibleChange.emit(event);
  }

  public ngOnInit(): void {
    this.navigationService.toggleLeft.subscribe({
      next: leftBarVisible => {
        this.leftBarVisible = leftBarVisible.open;
        this.onlyIcons = leftBarVisible.onlyIcons;
        const rightBarRes = document.querySelector<HTMLDivElement>('.p-sidebar.p-sidebar-right');
        if (rightBarRes) {
          rightBarRes.style.width = this.leftBarVisible ? (this.onlyIcons ? this.mostWidth : this.halfWidth) : this.fullWidth;
        }
        this.cdr.detectChanges();
      },
      error: err => console.error(err),
    });
  }
}
