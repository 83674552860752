<div style="min-height: 254px" *ngIf="loanForm ">
  <form [formGroup]="loanForm" (ngSubmit)="submit()">
    <div class="form">
      <div class="input-box">
      <span class="p-float-label">
        <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': loanForm.get('company')?.touched}"
               id="company"
               type="text" pInputText formControlName="company"/>
        <label htmlFor="company">Company*</label>
      </span>
        <small
          id="company-help"
          class="p-error"
          *ngIf="loanForm.get('company')?.touched && loanForm.get('company')?.hasError('required')">
          The company is required.
        </small>
      </div>
      <div class="input-box">
      <span class="p-float-label">
        <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': loanForm.get('amount')?.touched}"
               id="amount"
               type="number" pInputText formControlName="amount"/>
        <label htmlFor="amount">Amount*</label>
      </span>
        <small
          id="amount-help"
          class="p-error"
          *ngIf="loanForm.get('amount')?.touched && loanForm.get('amount')?.hasError('required')">
          The amount is required.
        </small>
      </div>

      <div class="input-box">
      <span class="p-float-label">
                <div style="min-width: 100%;" [ngClass]="{'ng-dirty': loanForm.get('from')?.touched}">

                  <span [ngClass]="{'invalid-label': loanForm.get('from')?.touched && !loanForm.get('from')?.valid}" htmlFor="to" id="fromLabel">From*</span>

                        <p-calendar
                          [style]="{width: '100%'}"
                          id="from"
                          [showIcon]="true"
                          formControlName="from"
                          [appendTo]="'body'"
                          placeholder="From"
                          ariaLabelledBy="fromLabel"
                        ></p-calendar>
                </div>
      </span>
        <small
          id="from-help"
          class="p-error"
          *ngIf="loanForm.get('from')?.touched && loanForm.get('from')?.hasError('required')">
          The field is required.
        </small>
      </div>
      <div class="input-box">
      <span class="p-float-label">
       <div style="min-width: 100%;" [ngClass]="{'ng-dirty': loanForm.get('to')?.touched}">
         <span [ngClass]="{'invalid-label': loanForm.get('to')?.touched && !loanForm.get('to')?.valid}" htmlFor="to" id="toLabel">To*</span>
            <p-calendar
              [style]="{width: '100%'}"
              id="to"
              [showIcon]="true"
              [appendTo]="'body'"
              formControlName="to"
              placeholder="To"
              ariaLabelledBy="toLabel"
            ></p-calendar>
       </div>
      </span>
        <small
          id="to-help"
          class="p-error"
          *ngIf="loanForm.get('to')?.touched">
          <ng-container *ngIf="loanForm.get('to')?.hasError('required')">The field is required.</ng-container>
          <ng-container *ngIf="loanForm.get('to')?.hasError('dateRangeError')">"From" must be less than "To".
          </ng-container>
        </small>
      </div>

      <div class="input-box">
      <span class="p-float-label">
        <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': loanForm.get('percent')?.touched}"
               id="percent"
               type="number" pInputText formControlName="percent"/>
        <label htmlFor="percent">Percent </label>
      </span>
        <small
          id="percent-help"
          class="p-error"
          *ngIf="loanForm.get('percent')?.touched && loanForm.get('percent')?.hasError('required')">
          The percent is required.
        </small>
      </div>


      <div class="input-box buttons">
        <p-button [style]="{width: '120px'}" [disabled]="loanForm.invalid || loanForm.disabled" type="submit"
                  label="Save"></p-button>
      </div>
    </div>
  </form>
</div>

