import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-fps-popup',
  templateUrl: './bytes-length-chart.component.html',
  styleUrls: ['./bytes-length-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BytesLengthChartComponent implements OnInit {
  public bytesLength$: Subject<number> = new Subject();
  public dataPoints: any = [];
  public bytesLengthChart: any;

  constructor(
    private config: DynamicDialogConfig,
  ) {
    this.bytesLength$ = config.data.bytesLength$;
  }

  public bytesLengthChartOptions = {
    theme: 'light2',
    zoomEnabled: false,
    exportEnabled: true,
    title: {
      text: 'Bytes Size'
    },
    subtitles: [{
      text: 'Loading Data...',
      fontSize: 24,
      horizontalAlign: 'center',
      verticalAlign: 'center',
      dockInsidePlotArea: true
    }],
    axisY: {
      title: 'Size',
      prefix: '' // 'FPS: '
    },
    data: [{
      type: 'line',
      name: 'Size',
      yValueFormatString: '#',
      xValueType: 'dateTime',
      dataPoints: this.dataPoints
    }]
  }

  public ngOnInit(): void {
    this.subscribeFramesPerSecondChange();
  }

  public getChartInstance(chart: object) {
    this.bytesLengthChart = chart;
  }

  private subscribeFramesPerSecondChange(): void {
    this.bytesLength$.subscribe({
      next: (seconds: number) => {
        this.dataPoints.push({x: new Date(), y: seconds});
        this.bytesLengthChart.subtitles && this.bytesLengthChart.subtitles[0] && this.bytesLengthChart.subtitles[0].remove();
        this.bytesLengthChart?.render();
      },
      error: (err: string) => console.error(err),
    });
  }
}
