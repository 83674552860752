import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {NavigationService} from '../../services/navigation.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoadingComponent implements OnInit {
  public leftBarOpen = false;
  public onlyIcons = false;

  constructor(
    private navigationService: NavigationService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  public ngOnInit() {
    this.navigationService.toggleLeft.subscribe((value) => {
      this.leftBarOpen = value.open;
      this.onlyIcons = value.onlyIcons;
      this.cdr.detectChanges();
    });
  }
}
