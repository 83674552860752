import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit,} from '@angular/core';
import {TableLazyLoadEvent} from 'primeng/table';
import {AuthService} from '../../../../services/auth.service';
import {PermissionsEnum} from '../../../../permissions.enum';
import {EducationModel} from '../../../../model/employee/education.model';
import {MenuItem} from 'primeng/api';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {DEFAULT_TABLE_ROWS_OPTIONS, EDUCATION_LIMIT, LOCAL_STORAGE_EDUCATION_LIMIT,} from '../../../../constants';
import {EducationPopupComponent} from '../../../education/education-popup.component';
import {EDUCATION_DELETED} from '../../../../../../locale/multilingual-strings-constants';
import {DataConversionService} from '../../../../services/data-conversion.service';
import {DeletePopup} from '../../../delete-popup/delete-popup.component';
import {TableFieldModel} from '../../../../model/table-field.model';
import {FieldSortTypesEnum} from '../../../../field-sort-types.enum';
import {FieldTypesEnum} from '../../../../field-types.enum';
import {NotifierService} from '../../../../services/notifier.service';
import {PersonalDataService} from '../../../../../../../personal-data/src/lib/personal-data.service';
import {UtilService} from '../../../../services/util.service';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EducationComponent implements OnInit {
  public hasDeletePermission = false;
  public hasWritePermission = false;
  public educations: EducationModel[];
  public items: MenuItem[];
  public ref: DynamicDialogRef | undefined;
  public totalRecords: number;
  public loading = true;
  public EDUCATION_LIMIT: number;
  public selectedEducation: EducationModel;
  public fields: TableFieldModel[] = [

    {
      name: 'educationType',
      displayName: 'Education Type',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    },
    {
      name: 'institution',
      displayName: 'Institution',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    },
    {
      name: 'specialty',
      displayName: 'Specialty',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    },
    {
      name: 'degree',
      displayName: 'Degree',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    },
    {
      name: 'from',
      displayName: 'From',
      sortable: true,
      isCopy: false,
      globalFilter: false,
      searchable: false,
      type: FieldTypesEnum.date,
      sortType: FieldSortTypesEnum.date,
    },
    {
      name: 'to',
      displayName: 'To',
      sortable: true,
      isCopy: false,
      globalFilter: false,
      searchable: false,
      type: FieldTypesEnum.date,
      sortType: FieldSortTypesEnum.date,
    },
  ];

  @Input() public userId: string;

  constructor(
    private authService: AuthService,
    private notifierService: NotifierService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private dataConversionService: DataConversionService,
    private personalDataService: PersonalDataService,
    private utilService: UtilService
  ) {
  }

  public ngOnInit(): void {
    const storedLimit = localStorage.getItem(LOCAL_STORAGE_EDUCATION_LIMIT);
    this.EDUCATION_LIMIT = storedLimit && DEFAULT_TABLE_ROWS_OPTIONS.includes(+storedLimit) ? +storedLimit : EDUCATION_LIMIT;
    this.hasDeletePermission = this.authService.hasPermission(PermissionsEnum.deleteEducation);
    this.hasWritePermission = this.authService.hasPermission(PermissionsEnum.writeEducation, [PermissionsEnum.deleteEducation]);
    this.initEducations();
    this.initMenuItems();
  }

  public openAddPopup() {
    this.openPopup('Add a new education', {personalDataId: this.userId});
  }

  public refresh() {
    this.initEducations();
  }

  public initEducations(event?: TableLazyLoadEvent) {
    this.loading = true;
    localStorage.setItem(LOCAL_STORAGE_EDUCATION_LIMIT, event?.rows?.toString() || EDUCATION_LIMIT.toString());
    this.personalDataService.getAllEducations(this.userId,
      (event ? event.first! / (event ? event.rows! : this.EDUCATION_LIMIT) + 1 : 1), (event ? event.rows! : this.EDUCATION_LIMIT)).subscribe({
      next: educationResponse => {
        this.loading = false;
        if (educationResponse.succeed) {
          if (!this.totalRecords && educationResponse.value.totalCount) {
            this.totalRecords = educationResponse.value.totalCount;
          }
          // if (educationResponse.value.entities.length) {
          this.educations = educationResponse.value.entities.map(a => ({
            ...a,
          }));
          this.loading = false;
          this.cdr.detectChanges();
          // }
          this.cdr.detectChanges();
        } else {
          this.notifierService.handleErrors(educationResponse.errors);
        }
      },
      error: err => this.notifierService.handleRequestError(err)
    });
  }

  private openPopup(header: string, data: { personalDataId?: string, education?: EducationModel }) {
    this.ref = this.dialogService.open(EducationPopupComponent, {
      header,
      width: this.utilService.getPopupResponsiveWidth(),
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data,
      maximizable: true,
    });
    this.ref.onClose.subscribe((result: { edited: boolean; added: boolean; education: EducationModel }) => {
      this.handlePopupResult(result);
    });
  }

  public handlePopupResult(result: { edited: boolean; added: boolean; education: EducationModel }) {
    if (result) {
      if (result.edited) {
        const education = this.educations.find((education: EducationModel) => education.id === result.education!.id)!;
        education.educationType = result.education.educationType;
        education.institution = result.education.institution;
        education.specialty = result.education.specialty;
        education.degree = result.education.degree;
        education.from = result.education.from;
        education.to = result.education.to;
        this.cdr.detectChanges();
      }
      if (result.added) {
        this.educations.push(result.education);
        this.totalRecords++;
        this.cdr.detectChanges();
      }
    }
  }

  public initMenuItems(education?: EducationModel) {
    this.items = [];

    if (this.hasWritePermission) {
      this.items.push(
        {
          label: 'Edit',
          command: () => {
            education && this.openPopup(education.educationType + ': User education', {education});
          }
        });
    }

    this.items.push({
      label: 'Copy',
      items: [
        {
          label: 'ID',
          command: () => {
            education && this.utilService.copyText(education.id as string, 'Education ID copied.');
          }
        }
      ]
    })

    if (this.hasDeletePermission) {
      this.items.push(
        {
          label: 'Delete',
          command: () => {
            education && this.openDeleteEducationPopup(education);
          }
        });
    }
  }

  public initCmItems() {
    this.initMenuItems(this.selectedEducation);
  }

  private openDeleteEducationPopup(education: EducationModel) {
    this.ref = this.dialogService.open(DeletePopup, {
      header: 'Are you sure you want to delete this education information?',
      width: 'fit-content',
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data: {
        id: education.id,
        data: this.dataConversionService.convertData(
          [education],
          {
            educationType: 'Education Type',
            institution: 'Institution',
            specialty: 'Specialty',
            degree: 'Degree',
            from: 'From',
            to: 'To',
          })
      },
    });
    this.ref.onClose.subscribe((result?: {
      id: string,
      delete: boolean,
    }) => {
      if (result?.delete) {
        this.deleteEducation(education, result.id);
      }
    });
  }

  private deleteEducation(education: EducationModel, educationId: string) {
    this.personalDataService.deleteEducation(educationId).subscribe({
      next: r => {
        if (r.succeed) {
          this.notifierService.handleSuccessRequest(EDUCATION_DELETED);
          this.educations.splice(this.educations.indexOf(education), 1);
          this.cdr.detectChanges();
        } else {
          this.notifierService.handleErrors(r.errors);
        }
      },
      error: err => {
        this.notifierService.handleRequestError(err);
      },
    });
  }

}
