<div class="items-count-container" *ngIf="filesShownInExplorer.length">
  <div class="items-count">
    <i class="pi pi-folder items-count-icon"></i>
    <span class="items-count-txt">{{folderItemsCount}}</span>
  </div>
  <div class="items-count">
    <i class="pi pi-file items-count-icon"></i>
    <span class="items-count-txt">{{fileItemsCount}}</span>
  </div>
</div>

<div *ngIf="!loading; else loadingContainer" class="scroll-container"
     infiniteScroll
     [scrollWindow]="false"
     [infiniteScrollDistance]="1"
     [infiniteScrollThrottle]="30"
     (scrolled)="onScrollDown()">
  <div class="file-cards">
    <div *ngIf="!filesShownInExplorer.length">
      No Data Available
    </div>
    <div class="file-card"
         *ngFor="let file of filesShownInExplorer"
         (contextmenu)="onContextMenu($event, file)"
         (click)="onFileClick(file)"
         [ngStyle]="{'cursor': file.isDirectory ? 'pointer' : null}"
    >
      <i *ngIf="file.name?.value; else defaultIcon"
         [ngClass]="utilService.getFileIconClass(file)"
         [style]="getFileIconStyle(selectedIconSize.code)" class="file-icon"></i>
      <ng-template #defaultIcon>
        <i class="file-icon fa {{file.isDirectory ? 'fa-folder' : 'fa-file'}}" [style]="getFileIconStyle(selectedIconSize.code)"></i>
      </ng-template>
      <div *ngIf="file.name" class="file-title" [pTooltip]="file.name.value">{{ file.name.value }}</div>
      <div *ngIf="!file.name" class="file-title">
        <p-skeleton width="6em" styleClass="mb-2"></p-skeleton>
      </div>
    </div>
    <p-contextMenu #cm [model]="contextMenuItems" [appendTo]="'body'" (onHide)="onHide()"/>
  </div>
</div>

<ng-template #loadingContainer>
    <div class="file-cards skeleton-cards">
      <div class="file-card skeleton-card"
           *ngFor="let _ of [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29]"
      >
        <p-skeleton [styleClass]="getFileSkeletonStyle(selectedIconSize.code)" styleClass="mb-2"></p-skeleton>
        <p-skeleton [styleClass]="getFileSkeletonStyle(selectedIconSize.code)+'-title'" styleClass="mb-2"></p-skeleton>
      </div>
    </div>
</ng-template>
