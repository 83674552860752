<div class="report-wrapper">
  <div class="report-tools">
    <div class="report-buttons">
      <ng-container *ngFor="let option of employeeInfoOptions">
        <button
          class="toggle-button"
          (click)="toggleState(option)"
          [class.active]="option.value"> {{option.name}}
        </button>
      </ng-container>
    </div>

    <button
      class="report-print-btn"
      pButton
      type="button"
      icon="pi pi-print"
      (click)="print()"></button>
  </div>

  <div *ngIf="!user"
       style="height: 30vh; display: flex; flex-direction: column; align-items: center; justify-content: center">
    <p-progressSpinner></p-progressSpinner>
  </div>

  <div class="printable-content" #printableContent>
    <div class="personal-data-cards-container" *ngIf="user">
      <div *ngIf="showPI" class="personal-data-card">
        <ng-container *ngTemplateOutlet="divider; context: {$implicit: 'Personal information'}"></ng-container>

        <div class="report-personal-information">
          <div class="report-personal-info-wrapper">
            <div class="report-personal-info">
              <div>Name: {{user.firstName}}</div>
              <div>Surname: {{user.lastName}}</div>
              <div>Father's name: {{user.fatherName}}</div>
              <div>ID: {{user.identifier}}</div>
            </div>
            <div class="report-personal-info">
              <div>Date of Birth: {{user.dateOfBirth ? (user.dateOfBirth | date:'shortDate') : ''}}</div>
              <div>Place of Birth: {{user.placeOfBirth}}</div>
              <div>Sex: {{user.gender}} </div>
              <div>Status: {{user.status}}</div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="showAddresses" class="personal-data-card">
        <ng-container *ngTemplateOutlet="divider; context: {$implicit: 'Address'}"></ng-container>

        <div *ngIf="user.addresses?.length; else emptyCard" class="card-content">
          <div *ngFor="let address of user.addresses">
            {{address.description || 'Address'}}: {{address.country}}, {{address.city}} {{address.streetAddress}}
          </div>
        </div>
      </div>

      <div *ngIf="showContacts" class="personal-data-card">
        <ng-container *ngTemplateOutlet="divider; context: {$implicit: 'Contacts'}"></ng-container>

        <div *ngIf="user.phoneNumbers?.length || user.emails.length; else emptyCard"
             class="card-content">
          <div style="flex: 1" *ngIf="user.phoneNumbers?.length">
            <div *ngFor="let phoneNumber of user.phoneNumbers">
              {{phoneNumber.numberType || 'Tel.'}}: {{phoneNumber.number}}
            </div>
          </div>
          <div *ngIf="user.emails?.length">
            <div>E-mails:</div>
            <div>
              <div *ngFor="let email of user?.emails">
                {{email.address}}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="showSocialMedia" class="personal-data-card">
        <ng-container *ngTemplateOutlet="divider; context: {$implicit: 'Social Medias'}"></ng-container>

        <div *ngIf="user.socialMedias?.length; else emptyCard" class="card-content">
          <div *ngFor="let socialMedia of user.socialMedias">
            {{socialMedia.name}}: {{socialMedia.accountId}}
          </div>
        </div>
      </div>

      <div *ngIf="showEd" class="personal-data-card">
        <ng-container *ngTemplateOutlet="divider; context: {$implicit: 'Educations'}"></ng-container>

        <div *ngIf="user.educations?.length; else emptyCard" class="card-content">
          <div *ngFor="let education of user.educations">
            {{education.institution}} {{education.specialty}} {{education.from | date:'yyyy'}}
            - {{education.to | date:'yyyy'}}. {{education.degree}}
          </div>
        </div>
      </div>

      <div *ngIf="showWE" class="personal-data-card">
        <ng-container *ngTemplateOutlet="divider; context: {$implicit: 'Work Experiences'}"></ng-container>

        <div *ngIf="user.works?.length; else emptyCard" class="card-content">
          <div *ngFor="let work of user.works">
            {{work.organization}} {{work.from | date:'yyyy'}} - {{work.to | date:'yyyy'}}. Occupation
            - {{work.occupation}}.
          </div>
        </div>
      </div>

      <div *ngIf="showFI" class="personal-data-card">
        <ng-container *ngTemplateOutlet="divider; context: {$implicit: 'Financial Information'}"></ng-container>

        <div
          *ngIf="user.salaries?.length || user.deposits?.length || user.loans?.length; else emptyCard"
          class="card-content">
          <div class="report-personal-information">
            <div *ngIf="user.salaries?.length">
              <div>Salary:</div>
              <div *ngFor="let salary of user.salaries">
                <div>{{salary.company}}: {{salary.amount}}</div>
              </div>
            </div>
            <div *ngIf="user.deposits?.length">
              <div>Deposits:</div>
              <div *ngFor="let deposit of user.deposits">
                <div>{{deposit.company}} {{deposit.from | date:'shortDate'}}
                  - {{deposit.to | date:'shortDate'}} {{deposit.amount}} {{deposit.percent}}</div>
              </div>
            </div>
            <div *ngIf="user.loans?.length">
              <div>Loans:</div>
              <div *ngFor="let loan of user.loans">
                <div>{{loan.company}} {{loan.from | date:'shortDate'}}
                  - {{loan.to | date:'shortDate'}} {{loan.amount}} {{loan.percent}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="showRC" class="personal-data-card">
        <ng-container *ngTemplateOutlet="divider; context: {$implicit: 'Companies'}"></ng-container>

        <div *ngIf="user.registeredCompanies?.length; else emptyCard" class="card-content">
          <div *ngFor="let c of user.registeredCompanies">
            <div>{{c.name}}. Occupation {{c.occupation}}. Annual turnover {{c.annualTurnover}}</div>
            <div>{{c.description}}</div>
          </div>
        </div>
      </div>

      <div *ngIf="showCWP" class="personal-data-card">
        <ng-container *ngTemplateOutlet="divider; context: {$implicit: 'Relations'}"></ng-container>

        <div *ngIf="user.relations?.length; else emptyCard" class="card-content">
          <div *ngFor="let relation of user.relations">
            {{relation.relationType}}: {{relation.firstName}} {{relation.lastName}}.
          </div>
        </div>
      </div>

      <div *ngIf="showPsychP" class="personal-data-card">
        <ng-container *ngTemplateOutlet="divider; context: {$implicit: 'Psychological Portraits'}"></ng-container>

        <div *ngIf="user.psychoPortraits?.length; else emptyCard" class="card-content">
          <div *ngFor="let psychoPortrait of user.psychoPortraits">
            <div>Summary: {{psychoPortrait.summary}}</div>
            <div>Description: {{psychoPortrait.description}}</div>
          </div>
        </div>
      </div>

      <div *ngIf="showNotes" class="personal-data-card">
        <ng-container *ngTemplateOutlet="divider; context: {$implicit: 'Notes'}"></ng-container>

        <div *ngIf="user.notes?.length; else emptyCard" class="card-content">
          <div *ngFor="let note of user.notes">
            <div>Note type: {{note.subject}}</div>
            <div>Note: {{note.value}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #emptyCard>
  <div class="no-data"> No Data</div>
</ng-template>

<ng-template #divider let-title>
  <p-divider>
    <div class="inline-flex align-items-center">
      <b>{{ title }}</b>
    </div>
  </p-divider>
</ng-template>
