import {Inject, Injectable, InjectionToken} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Response} from './models/response.model';
import {first, Observable} from 'rxjs';
import {AddressModel} from './models/address.model';
import {RegisteredCompanyModel} from './models/registered-company.model';
import {
  MachineUserDepositModel,
  MachineUserEmailModel,
  MachineUserLoanModel,
  MachineUserModel,
  MachineUserNoteModel,
  MachineUserPhoneModel,
  MachineUserSalaryModel,
  MachineUserSocialMediaModel
} from './models/machine.model';
import {EducationModel} from './models/education.model';
import {UserRequestModel} from './models/user-request.model';
import {PersonalDataModel} from './models/personal-data.model';
import {PropertiesModel} from './models/properties.model';
import {PsychologicalPortraitModel} from './models/psychological-portrait.model';
import {RelationModel} from './models/relation.model';
import {WorkExperienceModel} from './models/work-experience.model';
import {NewResponse} from './models/response';

export const API_URL = new InjectionToken<string>('apiUrl');

@Injectable()
export class PersonalDataService {

  private readonly urlPersonalData: string;
  private readonly urlPersonalDataHealthz: string;
  private readonly urlEmails: string;
  private readonly urlPhoneNumbers: string;
  private readonly urlSocialMedia: string;
  private readonly urlEducations: string;
  private readonly urlAddresses: string;
  private readonly urlWorks: string;
  private readonly urlCompanies: string;
  private readonly urlNotes: string;
  private readonly urlPsychologicalPortraits: string;
  private readonly urlRelations: string;
  private readonly urlSalaries: string;
  private readonly urlDeposits: string;
  private readonly urlLoans: string;
  private readonly urlProperties: string;


  private readonly headersSkippingInterceptor: HttpHeaders;
  private readonly headers: HttpHeaders;
  private readonly optionsSkippingInterceptor: object;
  private readonly options: object;


  constructor(private http: HttpClient, @Inject(API_URL) private apiUrl: string) {
    this.headersSkippingInterceptor = new HttpHeaders({
      'Content-Type': 'application/json',
      'skipInterceptor': 'true',
    });
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.optionsSkippingInterceptor = {
      headers: this.headersSkippingInterceptor,
      withCredentials: true
    };
    this.options = {
      headers: this.headers,
      withCredentials: true
    };

    this.urlPersonalData = `${apiUrl}/personal-data`;
    this.urlPersonalDataHealthz = `${apiUrl}/personal-data/healthz`;
    this.urlEmails = `${apiUrl}/personal-data/emails`;
    this.urlPhoneNumbers = `${apiUrl}/personal-data/phoneNumbers`;
    this.urlSocialMedia = `${apiUrl}/personal-data/SocialMedia`;
    this.urlEducations = `${apiUrl}/personal-data/educations`;
    this.urlAddresses = `${apiUrl}/personal-data/addresses`;
    this.urlWorks = `${apiUrl}/personal-data/works`;
    this.urlCompanies = `${apiUrl}/personal-data/companies`;
    this.urlNotes = `${apiUrl}/personal-data/notes`;
    this.urlPsychologicalPortraits = `${apiUrl}/personal-data/PsychologicalPortraits`;
    this.urlRelations = `${apiUrl}/personal-data/Relations`;
    this.urlSalaries = `${apiUrl}/personal-data/Salaries`;
    this.urlDeposits = `${apiUrl}/personal-data/deposits`;
    this.urlLoans = `${apiUrl}/personal-data/loans`;
    this.urlProperties = `${apiUrl}/personal-data/properties`;

  }

  // Health
  public getHealthz(): Observable<Response<string>> {
    return this.http.get<Response<string>>(`${this.urlPersonalDataHealthz}`,this.optionsSkippingInterceptor).pipe(first());
  }

  // Addresses
  public getAllAddresses(personalDataId: string, page: number, size: number): Observable<NewResponse<AddressModel[]>> {
    return this.http.get<NewResponse<AddressModel[]>>(`${this.urlAddresses}/allByPersonalDataId/${personalDataId}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getAddressById(id: string): Observable<Response<AddressModel>> {
    return this.http.get<Response<AddressModel>>(`${this.urlAddresses}/${id}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteAddress(id: string): Observable<Response<boolean>> {
    return this.http.delete<Response<boolean>>(`${this.urlAddresses}/${id}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public addAddress(body: AddressModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlAddresses}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public updateAddress(body: AddressModel): Observable<Response<string>> {
    return this.http.put<Response<string>>(`${this.urlAddresses}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  // Companies
  public getAllRegisteredCompanies(personalDataId: string, page: number, size: number): Observable<NewResponse<RegisteredCompanyModel[]>> {
    return this.http.get<NewResponse<RegisteredCompanyModel[]>>(`${this.urlCompanies}/allByPersonalDataId/${personalDataId}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
    // return of({
    //   succeed: true,
    //   value: [],
    //   errors: [],
    //   totalItems: 0,
    // });
  }

  public getRegisteredCompany(id: string): Observable<Response<RegisteredCompanyModel>> {
    return this.http.get<Response<RegisteredCompanyModel>>(`${this.urlCompanies}/${id}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteRegisteredCompany(id: string): Observable<Response<boolean>> {
    return this.http.delete<Response<boolean>>(`${this.urlCompanies}/${id}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public addRegisteredCompany(body: RegisteredCompanyModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlCompanies}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public updateRegisteredCompany(body: RegisteredCompanyModel): Observable<Response<string>> {
    return this.http.put<Response<string>>(`${this.urlCompanies}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  // Deposits
  public getAllDeposits(personalDataId: string, page: number, size: number): Observable<NewResponse<MachineUserDepositModel[]>> {
    return this.http.get<NewResponse<MachineUserDepositModel[]>>(`${this.urlDeposits}/allByPersonalDataId/${personalDataId}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getDeposit(id: string): Observable<Response<MachineUserDepositModel>> {
    return this.http.get<Response<MachineUserDepositModel>>(`${this.urlDeposits}/${id}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteDeposit(id: string): Observable<Response<boolean>> {
    return this.http.delete<Response<boolean>>(`${this.urlDeposits}/${id}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public addDeposit(body: MachineUserDepositModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlDeposits}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public updateDeposit(body: MachineUserDepositModel): Observable<Response<string>> {
    return this.http.put<Response<string>>(`${this.urlDeposits}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  // Education
  public getAllEducations(personalDataId: string, page: number, size: number): Observable<NewResponse<EducationModel[]>> {
    return this.http.get<NewResponse<EducationModel[]>>(`${this.urlEducations}/allByPersonalDataId/${personalDataId}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getEducation(id: string): Observable<Response<EducationModel>> {
    return this.http.get<Response<EducationModel>>(`${this.urlEducations}/${id}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteEducation(id: string): Observable<Response<boolean>> {
    return this.http.delete<Response<boolean>>(`${this.urlEducations}/${id}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public addEducation(body: EducationModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlEducations}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public updateEducation(body: EducationModel): Observable<Response<string>> {
    return this.http.put<Response<string>>(`${this.urlEducations}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  // Emails
  public getAllEmails(personalDataId: string, page: number, size: number): Observable<NewResponse<MachineUserEmailModel[]>> {
    return this.http.get<NewResponse<MachineUserEmailModel[]>>(`${this.urlEmails}/allByPersonalDataId/${personalDataId}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getEmail(id: string): Observable<Response<MachineUserEmailModel>> {
    return this.http.get<Response<MachineUserEmailModel>>(`${this.urlEmails}/${id}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteEmail(id: string): Observable<Response<void>> {
    return this.http.delete<Response<void>>(`${this.urlEmails}/${id}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public addEmail(body: MachineUserEmailModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlEmails}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public updateEmail(body: MachineUserEmailModel): Observable<Response<void>> {
    return this.http.put<Response<void>>(`${this.urlEmails}`, body, this.optionsSkippingInterceptor).pipe(first());
  }


  // Loans
  public getMachineUserLoans(personalDataId: string, page: number, size: number): Observable<NewResponse<MachineUserLoanModel[]>> {
    return this.http.get<NewResponse<MachineUserLoanModel[]>>(`${this.urlLoans}/allByPersonalDataId/${personalDataId}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getLoanById(loanId: string): Observable<Response<MachineUserLoanModel>> {
    return this.http.get<Response<MachineUserLoanModel>>(`${this.urlNotes}/${loanId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteLoan(loanId: string): Observable<Response<boolean>> {
    return this.http.delete<Response<boolean>>(`${this.urlLoans}/${loanId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public addLoan(body: MachineUserLoanModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlLoans}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public updateLoan(body: MachineUserLoanModel): Observable<Response<string>> {
    return this.http.put<Response<string>>(`${this.urlLoans}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  // Notes
  public getMachineUserNotes(personalDataId: string, page: number, size: number): Observable<NewResponse<MachineUserNoteModel[]>> {
    return this.http.get<NewResponse<MachineUserNoteModel[]>>(`${this.urlNotes}/allByPersonalDataId/${personalDataId}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getNotesById(noteId: string): Observable<Response<MachineUserNoteModel>> {
    return this.http.get<Response<MachineUserNoteModel>>(`${this.urlNotes}/${noteId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteNote(noteId: string): Observable<Response<void>> {
    return this.http.delete<Response<void>>(`${this.urlNotes}/${noteId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public addNote(body: MachineUserNoteModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlNotes}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public updateNote(body: MachineUserNoteModel): Observable<Response<void>> {
    return this.http.put<Response<void>>(`${this.urlNotes}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  // Personal Data
  public getPersonalDataById(personalDataId: string) {
    return this.http.get<Response<PersonalDataModel>>(`${this.urlPersonalData}/${personalDataId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public updatePersonalData(body: PersonalDataModel): Observable<Response<void>> {
    return this.http.put<Response<void>>(`${this.urlPersonalData}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public getFilteredEmployees(body: UserRequestModel): Observable<Response<MachineUserModel[]>> {
    return this.http.post<Response<MachineUserModel[]>>(`${this.urlPersonalData}/search`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public getMachineUserById(personalDataId: string): Observable<Response<MachineUserModel>> {
    return this.http.get<Response<MachineUserModel>>(`${this.urlPersonalData}/${personalDataId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public updateMachineUser(body: MachineUserModel): Observable<Response<void>> {
    return this.http.put<Response<void>>(`${this.urlPersonalData}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  // public getAssignedData(personalDataId: string, skip: number, take: number): Observable<Response<MachineModel[]>> {
  //   return this.http.get<Response<MachineModel[]>>(`${this.urlPersonalData}${personalDataId}/Machines/${skip}/${take}`, this.optionsSkippingInterceptor).pipe(first());
  // }

  // Phone Numbers
  public getPersonalDataPhoneNumbers(personalDataId: string, page: number, size: number): Observable<NewResponse<MachineUserPhoneModel[]>> {
    return this.http.get<NewResponse<MachineUserPhoneModel[]>>(`${this.urlPhoneNumbers}/allByPersonalDataId/${personalDataId}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getPhoneNumberById(phoneNumberId: string): Observable<Response<MachineUserPhoneModel>> {
    return this.http.get<Response<MachineUserPhoneModel>>(`${this.urlPhoneNumbers}/${phoneNumberId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deletePhoneNumber(phoneNumberId: string): Observable<Response<void>> {
    return this.http.delete<Response<void>>(`${this.urlPhoneNumbers}/${phoneNumberId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public addPhoneNumber(body: MachineUserPhoneModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlPhoneNumbers}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public updatePhoneNumber(body: MachineUserPhoneModel): Observable<Response<void>> {
    return this.http.put<Response<void>>(`${this.urlPhoneNumbers}`, body, this.optionsSkippingInterceptor).pipe(first());
  }


  // Properties
  public getPropertyById(id: PropertiesModel): Observable<Response<MachineUserPhoneModel>> {
    return this.http.get<Response<MachineUserPhoneModel>>(`${this.urlProperties}/${id}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getPropertyByPersonalDataId(personalDataId: string): Observable<Response<MachineUserPhoneModel>> {
    return this.http.get<Response<MachineUserPhoneModel>>(`${this.urlProperties}/${personalDataId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteProperty(propertyId: string): Observable<Response<void>> {
    return this.http.delete<Response<void>>(`${this.urlProperties}/${propertyId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public addProperty(body: PropertiesModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlProperties}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public updateProperty(body: PropertiesModel): Observable<Response<void>> {
    return this.http.put<Response<void>>(`${this.urlProperties}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  // Psychological Portraits
  public getPsychologicalPortraits(personalDataId: string, page: number, size: number): Observable<NewResponse<PsychologicalPortraitModel[]>> {
    return this.http.get<NewResponse<PsychologicalPortraitModel[]>>(`${this.urlPsychologicalPortraits}/allByPersonalDataId/${personalDataId}/?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getPsychologicalPortraitById(psychologicalPortraitId: string): Observable<Response<PsychologicalPortraitModel>> {
    return this.http.get<Response<PsychologicalPortraitModel>>(`${this.urlPsychologicalPortraits}/${psychologicalPortraitId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deletePsychologicalPortrait(psychologicalPortraitId: string): Observable<Response<boolean>> {
    return this.http.delete<Response<boolean>>(`${this.urlPsychologicalPortraits}/${psychologicalPortraitId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public addPsychologicalPortrait(body: PsychologicalPortraitModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlPsychologicalPortraits}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public updatePsychologicalPortrait(body: PsychologicalPortraitModel): Observable<Response<void>> {
    return this.http.put<Response<void>>(`${this.urlPsychologicalPortraits}`, body, this.optionsSkippingInterceptor).pipe(first());
  }


  // Relations

  public getRelation(relationId: string): Observable<Response<RelationModel>> {
    return this.http.get<Response<RelationModel>>(`${this.urlRelations}/${relationId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteRelation(relationId: string): Observable<Response<boolean>> {
    return this.http.delete<Response<boolean>>(`${this.urlRelations}/${relationId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public addRelation(body: RelationModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlRelations}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public updateRelation(body: RelationModel): Observable<Response<void>> {
    return this.http.put<Response<void>>(`${this.urlRelations}`, body, this.optionsSkippingInterceptor).pipe(first());
  }
  public getPersonalDataRelations(relationId: string, page: number, size: number): Observable<Response<RelationModel[]>> {
    return this.http.get<Response<RelationModel[]>>(`${this.urlRelations}/allByPersonalDataId/${relationId}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }


  // Salaries
  public getAllSalaries(personalDataId: string, page: number, size: number): Observable<NewResponse<MachineUserSalaryModel[]>> {
    return this.http.get<NewResponse<MachineUserSalaryModel[]>>(`${this.urlSalaries}/allByPersonalDataId/${personalDataId}/?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteSalary(salaryId: string): Observable<Response<boolean>> {
    return this.http.delete<Response<boolean>>(`${this.urlSalaries}/${salaryId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public addSalary(body: MachineUserSalaryModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlSalaries}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public updateSalary(body: MachineUserSalaryModel): Observable<Response<string>> {
    return this.http.put<Response<string>>(`${this.urlSalaries}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public getSalary(salaryId: string): Observable<Response<MachineUserSalaryModel>> {
    return this.http.get<Response<MachineUserSalaryModel>>(`${this.urlSalaries}/${salaryId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  // Social Media
  public getPersonalDataSocialMedia(personalDataId: string, page: number, size: number): Observable<NewResponse<MachineUserSocialMediaModel[]>> {
    return this.http.get<NewResponse<MachineUserSocialMediaModel[]>>(`${this.urlSocialMedia}/allByPersonalDataId/${personalDataId}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getSocialMedia(socialMediaId: string): Observable<Response<MachineUserSocialMediaModel>> {
    return this.http.get<Response<MachineUserSocialMediaModel>>(`${this.urlSocialMedia}/${socialMediaId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteSocialMedia(socialMediaId: string): Observable<Response<void>> {
    return this.http.delete<Response<void>>(`${this.urlSocialMedia}/${socialMediaId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public addSocialMedia(body: MachineUserSocialMediaModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlSocialMedia}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public updateSocialMedia(body: MachineUserSocialMediaModel): Observable<Response<void>> {
    return this.http.put<Response<void>>(`${this.urlSocialMedia}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  // Works
  public getAllWorks(personalDataId: string, page: number, size: number): Observable<NewResponse<WorkExperienceModel[]>> {
    return this.http.get<NewResponse<WorkExperienceModel[]>>(`${this.urlWorks}/allByPersonalDataId/${personalDataId}/?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getWork(workId: string): Observable<Response<WorkExperienceModel>> {
    return this.http.get<Response<WorkExperienceModel>>(`${this.urlWorks}/${workId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteWork(workId: string): Observable<Response<boolean>> {
    return this.http.delete<Response<boolean>>(`${this.urlWorks}/${workId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public addWork(body: WorkExperienceModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlWorks}`, body, this.options).pipe(first());
  }

  public updateWork(body: WorkExperienceModel): Observable<Response<string>> {
    return this.http.put<Response<string>>(`${this.urlWorks}`, body, this.optionsSkippingInterceptor).pipe(first());
  }


}
