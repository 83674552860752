import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NOTE_SAVED_SUCCESSFULLY} from '../../../../locale/multilingual-strings-constants';
import {NotifierService} from '../../services/notifier.service';
import {Observable} from 'rxjs';
import {Response} from '../../model/response.model';
import {PersonalDataService} from '../../../../../personal-data/src/lib/personal-data.service';
import {MachineUserNoteModel} from '../../../../../personal-data/src/lib/models/machine.model';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteComponent {
  public personalDataId: string;
  public noteForm: FormGroup;

  constructor(
              private ref: DynamicDialogRef,
              private fb: FormBuilder,
              private notifierService: NotifierService,
              private config: DynamicDialogConfig,
              private cdr: ChangeDetectorRef,
              private personalDataService: PersonalDataService) {

    const note = config.data.note;
    this.personalDataId = config.data.personalDataId;
    this.noteForm = this.fb.group({
      id: [note?.id],
      personalDataId: [note?.personalDataId || this.personalDataId],
      subject: [note?.subject, [Validators.required,]],
      value: [note?.value, [Validators.required,]],
      createdAt: [new Date()]
    });
  }

  public closeDialog(added: boolean, edited?: boolean) {
    this.ref.close({added, edited, note: this.noteForm.value});
  }

  public submit() {
    if (this.noteForm.valid) {
      this.noteForm.disable();
      const body = this.noteForm.value as MachineUserNoteModel;
      const submitRequest$: Observable<Response<string | void>> = this.noteForm.value.id ? this.personalDataService.updateNote(body)
        : this.personalDataService.addNote(body);
      submitRequest$.subscribe({
        next: response => {
          if (response.succeed) {
            this.notifierService.handleSuccessRequest(NOTE_SAVED_SUCCESSFULLY);
            if (!this.noteForm.value.id) {
              this.noteForm.controls['id'].setValue(response.value);
              this.closeDialog(true);
            } else this.closeDialog(false, true);
          } else {
            this.notifierService.handleErrors(response.errors);
            this.noteForm.enable();
          }
          this.cdr.detectChanges();
        },
        error: err => {
          this.notifierService.handleRequestError(err);
          this.noteForm.enable();
          this.cdr.detectChanges();
        }
      })
    }
  }

}
