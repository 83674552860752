import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {LoadingService} from '../../../shared/services/loading.service';
import {ActivatedRoute} from '@angular/router';
import {VncWebSocketService} from '../../../shared/services/vnc-web-socket.service';
import {TerminalService} from '../../../../../organization-url-redirection-aggregator/src/lib/terminal.service';
import {DialogService} from 'primeng/dynamicdialog';
import {UtilService} from '../../../shared/services/util.service';
import {FPSPopupComponent} from './FPS-popup/FPS-popup.component';
import {BytesLengthChartComponent} from './bytes-length-popup/bytes-length-chart.component';

@Component({
  selector: 'app-remote-desktop',
  templateUrl: './remote-desktop.component.html',
  styleUrls: ['./remote-desktop.component.scss'],
  providers: [VncWebSocketService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemoteDesktopComponent implements OnDestroy, OnInit, AfterViewInit {
  public FPSdataPoints: { x: Date, y: number }[] = [];
  public bytesLengthDataPoints: { x: Date, y: number }[] = [];
  public FPSChart: any;
  public bytesLengthChart: any;
  private _machineId: string;
  private socket: WebSocket;
  public showProgressSpinner = true;
  public inNewWindow = false;
  public fps = 0;
  public disconnected = false;
  public selectedQuality: string;

  public options: { name: string, value: string }[] = [
    {name: '6', value: '6'},
    {name: '12', value: '12'},
    {name: '25', value: '25'},
    {name: '50', value: '50'},
    // {name: '75', value: '75'},
    // {name: '100', value: '100'},
  ];

  public FPSChartOptions = {
    theme: 'light2',
    zoomEnabled: false,
    exportEnabled: false,
    title: {
      text: 'Frames Per Second'
    },
    subtitles: [{
      text: 'Loading Data...',
      fontSize: 24,
      horizontalAlign: 'center',
      verticalAlign: 'center',
      dockInsidePlotArea: true
    }],
    axisY: {
      title: 'FPS',
      prefix: '', // 'FPS: ',
      interval: 1,
    },
    axisX: {
      labelFormatter: () => '',
      tickLength: 0,
    },
    data: [{
      type: 'line',
      name: 'Frames Per Second',
      yValueFormatString: '#',
      xValueType: 'dateTime',
      dataPoints: this.FPSdataPoints
    }]
  }

  public bytesLengthOptions = {
    theme: 'light2',
    zoomEnabled: false,
    exportEnabled: false,
    title: {
      text: 'Bytes Size'
    },
    subtitles: [{
      text: 'Loading Data...',
      fontSize: 24,
      horizontalAlign: 'center',
      verticalAlign: 'center',
      dockInsidePlotArea: true
    }],
    axisY: {
      title: 'Size',
      prefix: '' // 'BPS: '
    },
    axisX: {
      labelFormatter: () => '',
      tickLength: 0,
    },
    data: [{
      type: 'line',
      name: 'Bytes Size',
      yValueFormatString: '#',
      xValueType: 'dateTime',
      dataPoints: this.bytesLengthDataPoints
    }]
  }

  @Input() public machineName: string;
  @Input() public visible: boolean;

  get machineId(): string {
    return this._machineId;
  }

  @Input() set machineId(value: string) {
    this._machineId = this._machineId || value;
  }

  @Output() public onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('remoteDesktop') public remoteDesktop: ElementRef<HTMLCanvasElement>;

  public constructor(
    private terminalService: TerminalService,
    private webSocketService: VncWebSocketService,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    private utilService: UtilService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
  ) {
  }

  public ngOnInit(): void {
    this.selectedQuality = '12';

    this.route.params.subscribe(params => {
      if (params['machineId']) {
        this.machineId = params['machineId'] as string;
        this.inNewWindow = true;
      }
    });
  }

  public ngAfterViewInit(): void {
    this.setupRemoteDesktopStreaming();
  }

  public openNewWindows() {
    const popupOptions = 'width=600,height=400';
    window.open(`https://${document.domain}/remote-desktop/${this.machineId}`, '_blank', popupOptions);
    // window.open(`http://localhost:4201/remote-desktop/${this.machineId}`, '_blank', popupOptions);
  }

  public changeQuality(event: number) {
    this.webSocketService.updateQuality(event);
  }

  public ngOnDestroy(): void {
    if (this.socket) {
      this.socket.close();
    }
    this.loadingService.hideLoading();
  }

  public onVisibleChange(event: boolean) {
    if (!event) {
      this.onClose.emit(true);
    }
  }

  private setupRemoteDesktopStreaming() {
    const canvasRef: ElementRef<HTMLCanvasElement> = this.remoteDesktop;
    const canvasEl: HTMLCanvasElement = canvasRef.nativeElement;
    const parentElement: HTMLElement | null = canvasEl.parentElement;

    if (!canvasEl || !parentElement) {
      console.error('Canvas or parent element not found');
      return;
    }

    const ctx: CanvasRenderingContext2D | null = canvasEl.getContext('2d');
    if (!ctx) {
      console.error('2D context not supported');
      return;
    }

    canvasEl.width = 1920;
    canvasEl.height = 1080;

    this.connectWithWebsocket();
    this.handleReceivedFrame(ctx);
    this.subscribeFramesPerSecondChange();
    this.subscribeFramesSize();
    this.handleConnectionDied();
  }

  private connectWithWebsocket() {
    this.terminalService.getVNCStreamId(this.machineId).subscribe(res => {
      this.socket = this.webSocketService.connectWithWebsocket(res.value);
      if (this.socket) {
        this.disconnected = false;
      }
      this.cdr.detectChanges();
    });
  }

  private handleConnectionDied(): void {
    this.webSocketService.connectionDied$.subscribe(() => {
      this.disconnected = true;
    });
  }

  private handleReceivedFrame(ctx: CanvasRenderingContext2D): void {
    this.webSocketService.wsRemoteDesktopFrameReceived.subscribe({
      next: (base64Data: string) => {
        const topImage = new Image();
        if (this.showProgressSpinner) {
          this.showProgressSpinner = false;
          this.cdr.detectChanges();
        }
        topImage.src = base64Data;
        topImage.onload = () => {
          ctx.drawImage(topImage, 0, 0, topImage.width, topImage.height, 0, 0, ctx.canvas.width, ctx.canvas.height);
        };
      },
      error: (err: string) => console.error(err),
    });
  }

  private subscribeFramesPerSecondChange(): void {
    this.webSocketService.framesPerSecond$.subscribe({
      next: (seconds: number) => {
        this.fps = seconds;
        this.FPSdataPoints.push({x: new Date(), y: this.fps});
        this.FPSChart.subtitles && this.FPSChart.subtitles[0] && this.FPSChart.subtitles[0].remove();
        this.FPSChart?.render();
        this.cdr.detectChanges()
      },
      error: (err: string) => console.error(err),
    });
  }


  private subscribeFramesSize(): void {
    this.webSocketService.lastFrameSize$.subscribe({
      next: (size: number) => {
        this.bytesLengthDataPoints.push({x: new Date(), y: size});
        this.bytesLengthChart.subtitles && this.bytesLengthChart.subtitles[0] && this.bytesLengthChart.subtitles[0].remove();
        this.bytesLengthChart?.render();
        this.cdr.detectChanges()
      },
      error: (err: string) => console.error(err),
    });
  }

  public setFpsChart(chart: object) {
    this.FPSChart = chart;
  }

  public setBytesSizeChart(chart: object) {
    this.bytesLengthChart = chart;
  }

  public openFPSChartPopup() {
    this.dialogService.open(FPSPopupComponent, {
      header: 'Frames Per Second',
      height: '500px',
      width: this.utilService.getPopupResponsiveWidth(),
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data: {framesPerSecond$: this.webSocketService.framesPerSecond$},
      maximizable: true,
    });
  }

  public openBytesLengthChartPopup() {
    this.dialogService.open(BytesLengthChartComponent, {
      header: 'Bytes Length',
      width: this.utilService.getPopupResponsiveWidth(),
      height: '500px',
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data: {bytesLength$: this.webSocketService.lastFrameSize$},
      maximizable: true,
    });
  }

}
