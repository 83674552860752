import {inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LoadingService} from '../services/loading.service';
import {SKIP_LOADING} from '../constants';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private activeRequests = 0;
  private loadingService = inject(LoadingService);

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers.get(SKIP_LOADING) == 'true') {
      return next.handle(request);
    }

    this.activeRequests++;
    if (this.activeRequests === 1) {
      this.loadingService.showLoading();
    }
    return next.handle(request).pipe(
      finalize(() => {
        this.activeRequests--;
        if (this.activeRequests === 0) {
          this.loadingService.hideLoading();
        }
      })
    );
  }
}
