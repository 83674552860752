<div style="min-height: 254px">
  <div>
    <canvasjs-chart
      [styles]="{width: '100%', height: '100%',}"
      [options]="FPSChartOptions"
      (chartInstance)="getChartInstance($event)">
    </canvasjs-chart>
  </div>
</div>

