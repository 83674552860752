<div class="container">
  <div *ngIf="!loading">
    <form [formGroup]="fg">
      <span class="p-float-label">
        <p-treeSelect
          class="md:w-20rem w-full"
          containerStyleClass="w-full"
          [formControlName]="fcn"
          [placeholder]="placeholder"
          [id]="id"
          [disabled]="loading"
          [style]="{width: '100%'}"
          [options]="organizationNodes"
          (onNodeExpand)="nodeExpand($event)"
          (onNodeSelect)="nodeSelect($event)"
          [appendTo]="'body'"
        >
        </p-treeSelect>
        <label>{{ label }}</label>
      </span>
    </form>
  </div>

  <div *ngIf="loading" class="input-container">
    <input [style]="{width: '100%'}" [disabled]="true" [placeholder]="placeholder" pInputText/>

    <div class="spinner-container">
      <p-progressSpinner
        styleClass="w-1rem h-1rem"
        strokeWidth="5"
        fill="var(--surface-ground)"
        animationDuration=".5s"/>
    </div>
  </div>
</div>
