import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  PSYCHOLOGICAL_PORTRAIT_SAVED_SUCCESSFULLY,
} from '../../../../locale/multilingual-strings-constants';
import {NotifierService} from '../../services/notifier.service';
import {PsychologicalPortraitModel} from '../../model/employee/psychological-portrait.model';
import {Observable} from 'rxjs';
import {Response} from '../../model/response.model';
import {PersonalDataService} from '../../../../../personal-data/src/lib/personal-data.service';

@Component({
  selector: 'app-psychological-portrait-popup',
  templateUrl: './psychological-portrait-popup.component.html',
  styleUrls: ['./psychological-portrait-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PsychologicalPortraitPopupComponent {
  public personalDataId: string;
  public psychologicalPortraitForm: FormGroup;

  constructor(
              private ref: DynamicDialogRef,
              private fb: FormBuilder,
              private notifierService: NotifierService,
              private config: DynamicDialogConfig,
              private cdr: ChangeDetectorRef,
              private personalDataService: PersonalDataService) {

    const psychologicalPortrait = config.data.psychologicalPortrait;
    this.personalDataId = config.data.personalDataId;
    this.psychologicalPortraitForm = this.fb.group({
      id: [psychologicalPortrait?.id],
      personalDataId: [psychologicalPortrait?.personalDataId || this.personalDataId],
      summary: [psychologicalPortrait?.summary, [Validators.required,]],
      description: [psychologicalPortrait?.description, [Validators.required,]],
      createdAt: [new Date()]
    });
  }

  public closeDialog(added: boolean, edited?: boolean) {
    this.ref.close({added, edited, psychologicalPortrait: this.psychologicalPortraitForm.value});
  }

  public submit() {
    if (this.psychologicalPortraitForm.valid) {
      this.psychologicalPortraitForm.disable();
      const body = this.psychologicalPortraitForm.value as PsychologicalPortraitModel;
      const submitRequest$: Observable<Response<string | void>> = this.psychologicalPortraitForm.value.id ? this.personalDataService.updatePsychologicalPortrait(body) :
        this.personalDataService.addPsychologicalPortrait(body);

      submitRequest$.subscribe({
        next: response => {
          if (response.succeed) {
            this.notifierService.handleSuccessRequest(PSYCHOLOGICAL_PORTRAIT_SAVED_SUCCESSFULLY);
            if (!this.psychologicalPortraitForm.value.id) {
              this.psychologicalPortraitForm.controls['id'].setValue(response.value);
              this.closeDialog(true);
            } else this.closeDialog(false, true);
          } else {
            this.notifierService.handleErrors(response.errors);
            this.psychologicalPortraitForm.enable();
          }
          this.cdr.detectChanges();
        },
        error: err => {
          this.notifierService.handleRequestError(err);
          this.psychologicalPortraitForm.enable();
          this.cdr.detectChanges();
        }
      })
    }
  }

}
