import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit,} from '@angular/core';
import {TableLazyLoadEvent} from 'primeng/table';
import {PermissionsEnum} from '../../../../permissions.enum';
import {AuthService} from '../../../../services/auth.service';
import {AddressModel} from '../../../../model/employee/address.model';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {MenuItem} from 'primeng/api';
import {ADDRESSES_LIMIT, DEFAULT_TABLE_ROWS_OPTIONS, LOCAL_STORAGE_ADDRESSES_LIMIT,} from '../../../../constants';
import {AddressPopupComponent} from '../../../address-popup/address-popup.component';
import {DeletePopup} from '../../../delete-popup/delete-popup.component';
import {ADDRESS_DELETED} from '../../../../../../locale/multilingual-strings-constants';
import {DataConversionService} from '../../../../services/data-conversion.service';
import {TableFieldModel} from '../../../../model/table-field.model';
import {NotifierService} from '../../../../services/notifier.service';
import {PersonalDataService} from '../../../../../../../personal-data/src/lib/personal-data.service';
import {UtilService} from '../../../../services/util.service';
import {RoleModel} from '../../../../../../../identity/src/lib/models/role.model';

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressesComponent implements OnInit {
  public hasDeletePermission = false;
  public hasWritePermission = false;
  public roles: RoleModel[];
  public addresses: AddressModel[];
  public items: MenuItem[];
  public ref: DynamicDialogRef | undefined;
  public totalRecords: number;
  public loading = true;
  public ADDRESSES_LIMIT: number;
  public selectedAddress: AddressModel;
  public fields: TableFieldModel[] = [
    {
      name: 'description',
      displayName: 'Description',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    },
    {
      name: 'country',
      displayName: 'Country',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    },
    {
      name: 'state',
      displayName: 'State',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    },
    {
      name: 'city',
      displayName: 'City',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    },
    {
      name: 'streetAddress',
      displayName: 'Street Address',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    },
    {
      name: 'postalCode',
      displayName: 'Postal Code',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    },
  ];

  @Input() public userId: string;

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private notifierService: NotifierService,
    private dialogService: DialogService,
    private dataConversionService: DataConversionService,
    private personalDataService: PersonalDataService,
    private utilService: UtilService
  ) {
  }

  public ngOnInit(): void {
    const storedLimit = localStorage.getItem(LOCAL_STORAGE_ADDRESSES_LIMIT);
    this.ADDRESSES_LIMIT = storedLimit && DEFAULT_TABLE_ROWS_OPTIONS.includes(+storedLimit) ? +storedLimit : ADDRESSES_LIMIT;
    this.hasDeletePermission = this.authService.hasPermission(PermissionsEnum.deleteAddress);
    this.hasWritePermission = this.authService.hasPermission(PermissionsEnum.writeAddress, [PermissionsEnum.deleteAddress]);
    this.initAddresses();
    this.initMenuItems();
  }

  public openAddPopup() {
    this.openPopup('Add a new address', {personalDataId: this.userId});
  }

  private openPopup(header: string, data: { personalDataId?: string, address?: AddressModel }) {
    this.ref = this.dialogService.open(AddressPopupComponent, {
      header,
      width: this.utilService.getPopupResponsiveWidth(),
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data,
      maximizable: true,
    });
    this.ref.onClose.subscribe((result: { edited: boolean; added: boolean; address: AddressModel }) => {
      this.handlePopupResult(result);
    });
  }

  public handlePopupResult(result: { edited: boolean; added: boolean; address: AddressModel }) {
    if (result) {
      if (result.edited) {
        const address = this.addresses.find((address: AddressModel) => address.id === result.address!.id)!;
        address.country = result.address.country;
        address.state = result.address.state;
        address.city = result.address.city;
        address.streetAddress = result.address.streetAddress;
        address.postalCode = result.address.postalCode;
        address.description = result.address.description;
        this.cdr.detectChanges();
      }
      if (result.added) {
        this.addresses.push(result.address);
        this.totalRecords++;
        this.cdr.detectChanges();
      }
    }
  }

  public refresh() {
    this.initAddresses();
  }

  public initAddresses(event?: TableLazyLoadEvent) {
    this.loading = true;
    localStorage.setItem(LOCAL_STORAGE_ADDRESSES_LIMIT, event?.rows?.toString() || ADDRESSES_LIMIT.toString());
    this.personalDataService.getAllAddresses(this.userId,
      (event ? event.first! / (event ? event.rows! : this.ADDRESSES_LIMIT) + 1 : 1), (event ? event.rows! : this.ADDRESSES_LIMIT)).subscribe({
      next: addressResponse => {
        this.loading = false;
        if (addressResponse.succeed) {
          if (!this.totalRecords && addressResponse.value.totalCount) {
            this.totalRecords = addressResponse.value.totalCount;
          }
          // if (addressResponse.value.entities.length) {
          this.addresses = addressResponse.value.entities.map(a => ({
            ...a,
          }));
          this.loading = false;
          this.cdr.detectChanges();
          // }
          this.cdr.detectChanges();
        } else {
          this.notifierService.handleErrors(addressResponse.errors);
        }
      },
      error: err => this.notifierService.handleRequestError(err)
    });
  }

  public initMenuItems(address?: AddressModel) {
    this.items = [];

    if (this.hasWritePermission) {
      this.items.push(
        {
          label: 'Edit',
          command: () => {
            address && this.openPopup(address?.description + ': User Address', {address});
          }
        });
    }

    this.items.push({
      label: 'Copy',
      items: [
        {
          label: 'ID',
          command: () => {
            address && this.utilService.copyText(address.id as string, 'Address ID copied.');
          }
        }
      ]
    })

    if (this.hasDeletePermission) {
      this.items.push(
        {
          label: 'Delete',
          command: () => {
            address && this.openDeleteAddressPopup(address);
          }
        });
    }
  }

  public initCmItems() {
    this.initMenuItems(this.selectedAddress);
  }

  private openDeleteAddressPopup(address: AddressModel) {
    this.ref = this.dialogService.open(DeletePopup, {
      header: 'Are you sure you want to delete this address?',
      width: 'fit-content',
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data: {
        id: address.id,
        data: this.dataConversionService.convertData(
          [address],
          {
            description: 'Description',
            country: 'Country',
            state: 'State',
            city: 'City',
            streetAddress: 'Street Address',
            postalCode: 'Postal Code',
          }
        ),
      },
    });
    this.ref.onClose.subscribe((result?: {
      id: string,
      delete: boolean,
    }) => {
      if (result?.delete) {
        this.deleteAddress(address, result.id);
      }
    });
  }

  private deleteAddress(address: AddressModel, addressId: string) {
    this.personalDataService.deleteAddress(addressId).subscribe({
      next: r => {
        if (r.succeed) {
          this.notifierService.handleSuccessRequest(ADDRESS_DELETED);
          this.addresses.splice(this.addresses.indexOf(address), 1);
          this.cdr.detectChanges();
        } else {
          this.notifierService.handleErrors(r.errors);
        }
      },
      error: err => {
        this.notifierService.handleRequestError(err);
      },
    });
  }
}
