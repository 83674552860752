export enum PermissionsEnum {
  readSocialMedia = 'PersonalData.SocialMedia.Read',
  deleteSocialMedia = 'PersonalData.SocialMedia.Delete',
  deleteEducation = 'PersonalData.Education.Delete',
  writeWork = 'PersonalData.Work.Write',
  writeMachine = 'Machine.Write', // todo no use
  deletePhoneNumber = 'PersonalData.PhoneNumber.Delete',
  writePhoneNumber = 'PersonalData.PhoneNumber.Write',
  readMachineActivityLogs = 'Machine.ActivityLogs.Read',
  deleteIdentitySession = 'Permissions.Sessions.DeleteCurrent',
  readEmail = 'PersonalData.Email.Read',
  writeRole = 'Permissions.Roles.Update',
  readIdentitySession = 'Permissions.Sessions.ReadCurrent',
  deleteEmail = 'PersonalData.Email.Delete',
  deleteOwnNotification = 'User.Notification.Delete',
  manageHostedService = 'HostedService.Manage',
  deleteAddress = 'PersonalData.Address.Delete',
  readUser = 'Permissions.Users.Read',
  manageMachineFileSystem = 'Machine.FileSystem.Read',
  writeMachineUser = 'PersonalData.Write',
  readPermission = 'Permission.Read', // todo delete?
  writeEmail = 'PersonalData.Email.Write',
  writeAddress = 'PersonalData.Address.Write',
  deleteMachineUser = 'PersonalData.Delete',
  deleteOwnIdentitySession = 'User.Delete', // todo no ui
  deleteWork = 'PersonalData.Work.Delete',
  readEducation = 'PersonalData.Education.Read',
  deleteRole = 'Permissions.Roles.Delete',
  deleteUser = 'Users.Delete',
  readWork = 'PersonalData.Work.Read',
  readRole = 'Permissions.Roles.Read',
  writeUser = 'User.Write',
  manageUrlAdministration = 'URLAdministration.Manage',
  manageMachineDesktop = 'Machine.Desktop.Manage',
  writeProfile = 'User.Profile.Write',
  manageOwnAccount = 'Permissions.Account.Manage',
  readMachine = 'Machine.Read',
  manageSoftRestartMachine = 'Machine.SoftRestart',
  writeSocialMedia = 'PersonalData.SocialMedia.Write',
  readProfile = 'User.Profile.Read',
  readMachineAccessPermission = 'Machine.AccessPermission.Read',
  writeMachineAccessPermission = 'Machine.AccessPermission.Write',
  readPhoneNumber = 'PersonalData.PhoneNumber.Read',
  manageOnlineMachine = 'Machine.Online.Read',
  writeSyncConfiguration = 'Machine.SyncConfiguration.Write',
  writeEducation = 'PersonalData.Education.Write',
  readSyncConfiguration = 'Machine.SyncConfiguration.Read',
  readAddress = 'PersonalData.Address.Read',
  readMachineUser = 'PersonalData.Read',
  readOwnNotification = 'User.Notification.Read',
  readNote = 'PersonalData.Note.Read',
  writeNote = 'PersonalData.Note.Write',
  deleteNote = 'PersonalData.Note.Delete',
  readSalary = 'PersonalData.Salary.Read',
  writeSalary = 'PersonalData.Salary.Write',
  deleteSalary = 'PersonalData.Salary.Delete',
  readDeposit = 'PersonalData.Deposit.Read',
  writeDeposit = 'PersonalData.Deposit.Write',
  deleteDeposit = 'PersonalData.Deposit.Delete',
  readLoan = 'PersonalData.Loan.Read',
  writeLoan = 'PersonalData.Loan.Write',
  deleteLoan = 'PersonalData.Loan.Delete',
  readProperty = 'PersonalData.Property.Read',
  writeProperty = 'PersonalData.Property.Write',
  deleteProperty = 'PersonalData.Property.Delete',
  readCompany = 'PersonalData.Company.Read', // todo
  writeCompany = 'PersonalData.Company.Write', // todo
  deleteCompany = 'PersonalData.Company.Delete', // todo
  readCommunicationWithPeople = 'PersonalData.CommunicationWithPeople.Read', // todo
  writeCommunicationWithPeople = 'PersonalData.CommunicationWithPeople.Write', // todo
  deleteCommunicationWithPeople = 'PersonalData.CommunicationWithPeople.Delete', // todo
  readPsychologicalPortrait = 'PersonalData.PsychologicalPortrait.Read', // todo
  writePsychologicalPortrait = 'PersonalData.PsychologicalPortrait.Write', // todo
  deletePsychologicalPortrait = 'PersonalData.PsychologicalPortrait.Delete', // todo
  readPhoto = 'PersonalData.Photo.Read', // todo
  writePhoto = 'PersonalData.Photo.Write', // todo
  deletePhoto = 'PersonalData.Photo.Delete', // todo
  readAssignedMachines = 'PersonalData.AssignedMachines.Read', // todo
  writeAssignedMachines = 'PersonalData.AssignedMachines.Write', // todo
  deleteAssignedMachines = 'PersonalData.AssignedMachines.Delete', // todo
  readOrganization = 'Permissions.Organizations.Read',
  writeOrganization = 'Permissions.Organizations.Update',
  deleteOrganization = 'Permissions.Organizations.Delete',
  organization_create = 'Permissions.Organizations.Create',

  organisationUser_update = 'Permissions.OrganizationUsers.Update',
  permission_read = 'Permissions.Permissions.Read',
  organizationRole_update = 'Permissions.OrganizationRoles.Update',
  organizationRole_read = 'Permissions.OrganizationRoles.Read',
  organizationRole_delete = 'Permissions.OrganizationRoles.Delete',
  user_create = 'Permissions.Users.Create',
  organizationHierarchy_read = 'Permissions.Organizations.HierarchyRead',
  organizationUser_crate = 'Permissions.OrganizationUsers.Create',
  permission_attachToRole = 'Permissions.Permissions.AttachToRole',
  organizationUser_delete = 'Permissions.OrganizationUsers.Delete',
  user_detachRole = 'Permissions.Users.DetachRole',
  role_create = 'Permissions.Roles.Create',
  organizationUser_read = 'Permissions.OrganizationUsers.Read',


  organizationUrlRedirection_read = 'Permissions.OrganizationUrlRedirections.Read',
  organizationUrlRedirection_create = 'Permissions.OrganizationUrlRedirections.Create',
  organizationUrlRedirection_update = 'Permissions.OrganizationUrlRedirections.Update',
  organizationUrlRedirection_delete = 'Permissions.OrganizationUrlRedirections.Delete',

  manageBuilder = 'Permissions.Builder.Manage', //toDo

}
