<p-contextMenu
  #cm
  [appendTo]="'body'"
  [model]="contextMenuItems"/>

<div style="height: 100%; box-shadow: 0 0 12px rgba(0, 0, 0, 0.2); user-select: none;">
  <p-table [style]="{height: '100%'}" #table
           [value]="value"
           [(contextMenuSelection)]="selectedItem"
           (contextMenuSelectionChange)="onContextMenuSelectionChange($event)"
           [contextMenu]="cm"
           [lazy]="lazy"
           (onLazyLoad)="onLazyLoadEvent($event)"
           (onPage)="onPageEvent($event)"
           [paginator]="paginator"
           [paginatorDropdownAppendTo]="'body'"
           [(first)]="first"
           [totalRecords]="totalRecords"
           [rowHover]="true"
           [rows]="rowsLimit"
           [virtualScroll]="virtualScroll"
           [virtualScrollOptions]="virtualScrollOptions"
           [virtualScrollItemSize]="60"
           [rowsPerPageOptions]="rowsPerPageOptions"
           [showCurrentPageReport]="showCurrentPageReport"
           [filterDelay]="filterDelay"
           [globalFilterFields]="globalFilterField"
           [selectionMode]="selectionMode"
           (onRowSelect)="onRowSelectEvent($event)"
           rowGroupMode="subheader"
           currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">

    <ng-container *ngIf="globalFilterField.length">
      <ng-template pTemplate="caption">
        <div style="text-align: left">
          <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
          <input type="text"
                 pInputText
                 size="50"
                 placeholder="{{globalFilterPlaceholder}}"
                 style="width:auto"
                 [(ngModel)]=filterValue
                 (input)="table.filterGlobal(filterValue, globalFilterMatchMode)">
        </div>
      </ng-template>
    </ng-container>

    <ng-template pTemplate="header">
      <tr>
        <ng-container *ngFor="let additionalField of additionalFields">
          <ng-container *ngTemplateOutlet="unClickableHeader; context: { field: additionalField }"></ng-container>
        </ng-container>

        <ng-container *ngFor="let field of fields">
          <ng-container *ngIf="field.sortable || field.searchable; else simpleHeader">
            <ng-container *ngTemplateOutlet="clickableHeader; context: { field: field }"></ng-container>
          </ng-container>

          <ng-template #simpleHeader>
            <ng-container *ngTemplateOutlet="unClickableHeader; context: { field: field }"></ng-container>
          </ng-template>
        </ng-container>

        <ng-template #unClickableHeader let-field="field">
          <th>
            <div class="th-content">
              <div *ngIf="field.displayName" class="left-part">
                {{ field.displayName }}
              </div>
              <div *ngIf="!field.displayName" class="left-part">
                <p-checkbox *ngIf="selectedRowsCount"
                  [(ngModel)]="allSelected"
                  [binary]="true"
                  (onChange)="changeAllRowsData($event)"
                ></p-checkbox>
              </div>
            </div>
          </th>
        </ng-template>

        <ng-template #clickableHeader let-field="field">
          <th [ngClass]="{'narrow-column': (field.flagIcon && !field.concatenate) || field.isCopy}"
              pSortableColumn="{{field.name}}">
            <div class="th-content">
              <div class="left-part">
                {{ field.displayName }}
                <p-sortIcon *ngIf="field.sortable" field="{{field.name}}"></p-sortIcon>
                <p-columnFilter *ngIf="field.searchable" type="text" field="{{ field.name }}"
                                display="menu">
                </p-columnFilter>
              </div>
            </div>
          </th>
        </ng-template>
        <!--        <th class="narrow-column" *ngIf="hasMenuPermission">Actions</th>-->
      </tr>

      <ng-container *ngIf="loading">
        <tr class="tr" *ngFor="let _ of [1, 2, 3, 4, 5]">
          <td class="empty-cell" *ngFor="let _ of fields">
            <ng-container *ngTemplateOutlet="loadingSkeleton"></ng-container>
          </td>
          <td class="empty-cell" *ngFor="let _ of additionalFields">
            <ng-container *ngTemplateOutlet="loadingSkeleton"></ng-container>
          </td>
          <!--          <td class="empty-cell" *ngIf="hasMenuPermission">-->
          <!--            <ng-container *ngTemplateOutlet="loadingSkeleton"></ng-container>-->
          <!--          </td>-->
        </tr>
      </ng-container>
    </ng-template>

    <ng-template pTemplate="groupheader" let-customer>
      <ng-content select="[SubHeader]"></ng-content>
    </ng-template>

    <ng-template pTemplate="body" let-row>
      <tr *ngIf="selectionMode && !loading" [pSelectableRow]="row"
          appLongPress (longPress)="showContextMenu($event)"
          [ngStyle]="{
          'background-color': row['greenBGColor'] ? 'rgba(167, 255, 94, 0.3)' :
          row['redBGColor'] ? 'rgba(255, 0, 0, 0.2)': null,
          'height': row['redBGColor'] ? '42px' : null,
          'user-select': 'none'}"
      >
        <ng-container *ngTemplateOutlet="tableBody; context: { $implicit: row }"></ng-container>
      </tr>
      <tr *ngIf="!selectionMode && !loading && contextMenuItems.length"
          (click)="onRowClick(row)"
          [pContextMenuRow]="row"
          appLongPress (longPress)="showContextMenu($event)"
          [ngStyle]="{
          'background-color': row['greenBGColor'] || (highlightRecentlyCreated && row['createdAtUtc'] && (row['createdAtUtc'] |  timezone:offset :'value' | timeDiff)) ? 'rgba(167, 255, 94, 0.3)' :
          row['redBGColor'] ? 'rgba(255, 0, 0, 0.2)': null,
          'height': row['redBGColor'] ? '42px' : null,
          'user-select': 'none'}"
      >
        <ng-container *ngTemplateOutlet="tableBody; context: { $implicit: row }"></ng-container>
      </tr>
      <tr *ngIf="!selectionMode && !loading && !contextMenuItems.length"
          (click)="onRowClick(row)"
          appLongPress (longPress)="showContextMenu($event)"
          [ngStyle]="{
          'cursor': row.isDirectory ? 'pointer' : null,
          'background-color': row['greenBGColor'] ? 'rgba(167, 255, 94, 0.3)' :
          row['redBGColor'] ? 'rgba(255, 0, 0, 0.2)': null,
          'height': row['redBGColor'] ? '42px' : null,
          'user-select': 'none'}"
      >
        <ng-container *ngTemplateOutlet="tableBody; context: { $implicit: row }"></ng-container>
      </tr>
    </ng-template>

    <ng-template pTemplate="footer">
      <tr class="tr" *ngFor="let _ of loadingRows">
        <td class="empty-cell" *ngFor="let _ of fields">
          <ng-container *ngTemplateOutlet="loadingSkeleton"></ng-container>
        </td>
        <td class="empty-cell" *ngFor="let _ of additionalFields">
          <ng-container *ngTemplateOutlet="loadingSkeleton"></ng-container>
        </td>
        <td class="empty-cell" *ngIf="hasMenuPermission">
          <ng-container *ngTemplateOutlet="loadingSkeleton"></ng-container>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr *ngIf="!loading && !loadingRows.length">
        <td colspan="8">No Data Available</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-tieredMenu #menu
              [popup]="true"
              [model]="menuItems"
></p-tieredMenu>

<ng-template #tableBody let-row>
  <ng-container *ngFor="let additionalField of additionalFields">
    <td *ngIf="additionalField.type == fieldTypesEnum.boolean" class="app__table-cell selectable-row-checkbox">
      <ng-container *ngIf="selectedRowsCount && !additionalField.readonly">
        <p-checkbox
          [(ngModel)]="row['userIsSelected']"
          [disabled]="additionalField.readonly! || row['isDirectory']"
          [binary]="true"
          (onChange)="changeRowData(row, $event)"
        ></p-checkbox>
      </ng-container>
    </td>
  </ng-container>

  <ng-container *ngFor="let field of fields">
    <ng-container *ngIf="field.multiple; else single">
      <td class="app__table-cell">
        <ng-container *ngIf="field.names && row[field.names[0]] !== null && row[field.names[0]] !== undefined ; else emptyDiv">
          {{ row[field.names[0]] || '' }} {{ row[field.names[1]] || '' }} {{ row[field.names[2]] || '' }}
        </ng-container>
      </td>
    </ng-container>
    <ng-template #single>
      <ng-container *ngIf="!field.isCopy && (!field.progress || field.isStatusField)">
        <td *ngIf="!field.type" class="app__table-cell">
          <ng-container *ngIf="!field.flagIcon; else flagContainer">
            <ng-container *ngIf="!field.multiple; else multiple">
              <ng-container *ngIf="row[$any(field).contentConditionField]===true">
                <span style="color: #25a62b;" class="app-table-content" [innerHTML]="content"></span>
              </ng-container>
              <ng-container *ngIf="row[$any(field).contentConditionField]===false">
                <span style="color: #a6860c;" class="app-table-content" [innerHTML]="secondaryContent"></span>
              </ng-container>
              <ng-container *ngIf="!field.secondary || row['secondaryFieldsLoaded']; else loadingSkeleton">
                <ng-container *ngIf="row[field.name] !== null && row[field.name] !== undefined ; else emptyDiv">
                  {{ row[field.name] }}
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-template #multiple>
            <ng-container *ngIf="field.names && row[field.names[0]] !== null && row[field.names[0]] !== undefined ; else emptyDiv">
              {{ row[field.names[0]] || '' }} {{ row[field.names[1]] || '' }} {{ row[field.names[2]] || '' }}
            </ng-container>
          </ng-template>

          <ng-template #flagContainer>
            <ng-container *ngIf="!field.secondary || row['secondaryFieldsLoaded']; else loadingSkeleton">
            <span *ngIf="row[field.name]?.countryName" class="flag" [pTooltip]="row[field.name]?.countryName"
                  [class]="row[field.name]?.flagIcon"></span>
              <div *ngIf="field.name==='countryCode'" class="flex align-items-center	gap-2">
              <span class="flag" [pTooltip]="row[field.name]"
                    [class]="'fi fi-' + (row[field.name] | lowercase)"></span>
                <span>{{ row[field.name] }}</span>
                <ng-container *ngIf="field.concatenate && row[field.concatenate[0]] !== null && row[field.concatenate[0]] !== undefined ; else emptyDiv">
                  {{ row[field.concatenate[0]] || '' }} {{ row[field.concatenate[1]] || '' }} {{ row[field.concatenate[2]] || '' }}
                </ng-container>
              </div>
            </ng-container>
          </ng-template>
        </td>

        <ng-container *ngIf="row['redBGColor']; else mainContent">
          <ng-container *ngTemplateOutlet="emptyCell"></ng-container>
        </ng-container>

        <ng-template #mainContent>
          <ng-container *ngIf="field.type == fieldTypesEnum.date">
            <ng-container *ngIf="!field.secondary || row['secondaryFieldsLoaded']; else loadingCell">
              <ng-container *ngIf="field.applyTimezonePipe">
                <ng-container  *ngIf="field.applyTimeAgoPipe">
                  <ng-container *ngIf="field.showTimeTooltip">
                    <td class="app__table-cell"
                        [pTooltip]="(row[field.name] |  timezone:offset:field.dateProperty)"
                        [autoHide]="false">
                      {{ row[field.name] |  timezone:offset :field.dateProperty | timeAgo }}
                    </td>
                  </ng-container>
                  <ng-container *ngIf="!field.showTimeTooltip">
                    <td class="app__table-cell">
                      {{ row[field.name] |  timezone:offset :field.dateProperty | timeAgo }}
                    </td>
                  </ng-container>
                </ng-container>
                <ng-container  *ngIf="!field.applyTimeAgoPipe">
                  <td *ngIf="!field.showTimeTooltip" class="app__table-cell">
                    {{ row[field.name] |  timezone:offset :field.dateProperty: 'dateOnly' }}
                  </td>
                  <td *ngIf="field.showTimeTooltip" class="app__table-cell"
                      [pTooltip]="(row[field.name] |  timezone:offset:field.dateProperty: 'timeOnly') || undefined"
                      [autoHide]="false"
                      tooltipPosition="left">
                    {{ row[field.name] |  timezone:offset:field.dateProperty: 'dateOnly' }}
                  </td>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!field.applyTimezonePipe">
                <ng-container  *ngIf="field.applyTimeAgoPipe">
                  <td class="app__table-cell">
                    {{ row[field.name] | timeAgo }}
                  </td>
                </ng-container>
                <ng-container *ngIf="!field.applyTimeAgoPipe">
                  <td *ngIf="!field.showTimeTooltip" class="app__table-cell">
                    {{ row[field.name] | date }}
                  </td>
                  <td *ngIf="field.showTimeTooltip" class="app__table-cell"
                      [pTooltip]="(row[field.name] | date:'mediumTime') || undefined" [autoHide]="false"
                      tooltipPosition="left">
                    {{ row[field.name] | date }}
                  </td>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="field.type == fieldTypesEnum.dateTime">
            <ng-container *ngIf="!field.secondary || row['secondaryFieldsLoaded']; else loadingCell">
              <ng-container *ngIf="field.applyTimezonePipe">
                <ng-container *ngIf="field.applyTimeAgoPipe">
                  <ng-container *ngIf="field.showTimeTooltip">
                    <td class="app__table-cell"
                        [pTooltip]="(row[field.name] |  timezone:offset:field.dateProperty)"
                        [autoHide]="false"
                        tooltipPosition="left">
                      {{ row[field.name] |  timezone:offset : field.dateProperty | timeAgo }}
                    </td>
                  </ng-container>
                  <ng-container *ngIf="!field.showTimeTooltip">
                    <td class="app__table-cell">
                      {{ row[field.name] |  timezone:offset : field.dateProperty | timeAgo }}
                    </td>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="!field.applyTimeAgoPipe">
                  <td class="app__table-cell" tooltipPosition="left">
                    {{ row[field.name] |  timezone:offset : field.dateProperty }}
                  </td>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!field.applyTimezonePipe">
                <ng-container *ngIf="field.applyTimeAgoPipe">
                  <td class="app__table-cell" tooltipPosition="left">
                    {{ row[field.name] | date :'medium' | timeAgo }}
                  </td>
                </ng-container>
                <ng-container *ngIf="!field.applyTimeAgoPipe">
                  <td class="app__table-cell" tooltipPosition="left">
                    {{ row[field.name] | date :'medium' }}
                  </td>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="field.type == fieldTypesEnum.object">
            <ng-container *ngIf="row[field.name] && row[field.name]['fieldLoaded']; else loadingCell">
              <td class="app__table-cell" *ngIf="!field.showTimeTooltip">
                <ng-container
                  *ngIf="!field.flagIcon && !field.isStatusField && !field.contentConditionField && !field.isFileName">
                  {{ row[field.name].value }}
                </ng-container>

                <ng-container *ngIf="field.isFileName">
                  <i *ngIf="row.name?.value; else defaultIcon"
                     [ngClass]="utilService.getFileIconClass(row)" class="file-icon"></i>
                  <ng-template #defaultIcon>
                    <i class="file-icon fa {{row.isDirectory ? 'fa-folder' : 'fa-file'}}"></i>
                  </ng-template>
                  {{ row[field.name].value }}
                </ng-container>

                <ng-container *ngIf="field.flagIcon">
                  <ng-container *ngIf="!field.secondary || row['secondaryFieldsLoaded']; else loadingSkeleton">
                    <div class="flex align-items-center	gap-2">
                    <span class="flag" [pTooltip]="row[field.name]?.countryName"
                          [class]="row[field.name]?.flagIcon"></span>
                      <span>{{ row[field.name]?.value }}</span>
                      <ng-container *ngIf="field.concatenate && row[field.concatenate[0]] !== null && row[field.concatenate[0]] !== undefined ; else emptyDiv">
                        {{ row[field.concatenate[0]] || '' }} {{ row[field.concatenate[1]] || '' }} {{ row[field.concatenate[2]] || '' }}
                      </ng-container>
                    </div>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="field.isStatusField">
                  <ng-container *ngIf="!row['isDirectory']">
                    <ng-container
                      *ngTemplateOutlet="dynamicTemplate; context: { value: row[field.name]?.value, field: field, row: row}"></ng-container>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="field.contentConditionField">
                  <ng-container *ngIf="row[$any(field).contentConditionField]===true">
                    <span style="color: #25a62b;" class="app-table-content" [innerHTML]="content"></span>
                    {{ row[field.name].value }}
                  </ng-container>
                  <ng-container *ngIf="row[$any(field).contentConditionField]!==true">
                    {{ row[field.name].value }}
                  </ng-container>
                </ng-container>
              </td>
              <td *ngIf="field.showTimeTooltip" class="app__table-cell"
                  [pTooltip]="(row[field.name].value | date:'mediumTime') || undefined" [autoHide]="false"
                  tooltipPosition="left">
                {{ row[field.name].value | date }}
              </td>
            </ng-container>
          </ng-container>
        </ng-template>

        <td *ngIf="field.type == fieldTypesEnum.boolean" class="app__table-cell">
          <ng-container *ngIf="!field.secondary || row['secondaryFieldsLoaded']; else loadingSkeleton">
            <p-checkbox
              formControlName="{{field.name}}"
              id="field.name"
              [disabled]="true"
              [binary]="true"></p-checkbox>
          </ng-container>
        </td>
      </ng-container>
      <td *ngIf="field.isCopy" class="app__table-cell narrow-column">
        <button
          #copyButton
          pButton
          type="button"
          class="btn copy-btn"
          (click)="utilService.showCopied(copyButton, copied)"
          [cdkCopyToClipboard]="row[field.name]">
          <i class="pi pi-copy copy-icon"></i>
        </button>
        <div
          #copied
          class="copied-btn">
          <i class="pi pi-check"></i>
        </div>
      </td>

      <td *ngIf="field.progress && !field.isStatusField" class="app__table-cell">
        <app-progressBar [value]="row[field.name]">
          <ng-template pTemplate="content" let-value>
            <span>{{ value }}</span>
          </ng-template>
        </app-progressBar>
      </td>
    </ng-template>
  </ng-container>

  <!--  <td *ngIf="hasMenuPermission" class="app__table-cell" style="text-align: right">-->
  <!--    <ng-container *ngIf="menuItems && menuItems.length > 1">-->
  <!--      <button-->
  <!--        pButton-->
  <!--        type="button"-->
  <!--        (click)="toggleMenu(menu, $event, row)"-->
  <!--        icon="pi pi-ellipsis-v table-menu-btn"></button>-->
  <!--    </ng-container>-->
  <!--    <ng-container *ngIf="menuItems && menuItems.length === 1">-->
  <!--      <ng-container *ngIf="menuItems[0].icon; else label">-->
  <!--        <button-->
  <!--          pButton-->
  <!--          type="button"-->
  <!--          (click)="onAction(row)"-->
  <!--          icon="pi {{menuItems[0].icon}}"></button>-->
  <!--      </ng-container>-->
  <!--      <ng-template #label>-->
  <!--        <button-->
  <!--          pButton-->
  <!--          type="button"-->
  <!--          (click)="onAction(row)"-->
  <!--        >{{ menuItems[0].label }}-->
  <!--        </button>-->
  <!--      </ng-template>-->
  <!--    </ng-container>-->
  <!--  </td>-->
</ng-template>

<ng-template #loadingSkeleton>
  <p-skeleton width="6em" styleClass="mb-2"></p-skeleton>
</ng-template>

<ng-template #loadingCell>
  <td class="app__table-cell">
    <ng-container *ngTemplateOutlet="loadingSkeleton"></ng-container>
  </td>
</ng-template>

<ng-template #emptyDiv>
  <div style="height: 20px;"></div>
</ng-template>

<ng-template #emptyCell>
  <td></td>
</ng-template>

<ng-template #dynamicTemplate let-value="value" let-field="field" let-row="row">
  <ng-container *ngIf="(value | fileStateIcon) as status">
    <ng-container *ngIf="value !== SyncStatusEnum.syncing || !field.progress">
      <i [pTooltip]="status.summary" [class]="status.iconClass" [style.color]="status.color"></i>
    </ng-container>
    <ng-container *ngIf="value === SyncStatusEnum.syncing && field.progress">
      <app-progressBar [value]="row[field.progressFieldName].value">
        <ng-template pTemplate="content" let-value>
          <span>{{ row[field.progressFieldName].value }}</span>
        </ng-template>
      </app-progressBar>
    </ng-container>
  </ng-container>
</ng-template>
