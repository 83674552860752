import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {TreeNode} from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class FileEventService {
  private clickTimeout: number[] = [];
  public onFileViewSource = new Subject<TreeNode>();
  private navigateToRoot = new Subject<boolean>();
  private onNodeSelect = new Subject<{ node: any, filter: boolean }>();
  private onFileDelete = new Subject<{ node: any, fromServer: boolean }>();
  private onFileSync = new Subject<{ node: any }>();
  private onDirectoryDelete = new Subject<{ dirPath: string }>();
  private folderOpened = new Subject<string>();
  private onPageRefresh = new Subject<boolean>();
  public tableChanges = new Subject<void>();
  public onHomeNavigate = new Subject<boolean>();
  public onNodeSelect$ = this.onNodeSelect.asObservable();
  public onFileDelete$ = this.onFileDelete.asObservable();
  public onFileSync$ = this.onFileSync.asObservable();
  public onDirectoryDelete$ = this.onDirectoryDelete.asObservable();
  public folderOpened$ = this.folderOpened.asObservable();
  public onPageRefresh$ = this.onPageRefresh.asObservable();
  public navigateToRoot$ = this.navigateToRoot.asObservable();
  public onSelectNodeInPath$ = new Subject<any>();

  public selectedNode: any;
  private previewNode: TreeNode;

  public triggerFileViewEvent(node: TreeNode) {
    this.clickTimeout.push(setTimeout(() => {
      this.previewNode = node;
      this.onFileViewSource.next(node);
    }, 300));
  }

  public triggerPreviewFileViewEvent() {
    this.clickTimeout.push(setTimeout(() => {
      this.onFileViewSource.next(this.previewNode);
    }, 300));
  }

  public nodeSelect(node: any, filter = false) {
    this.clickTimeout.forEach(timeout => clearTimeout(timeout));
    this.selectedNode = node;
    this.onNodeSelect.next({node, filter});
  }

  public deleteFile(node: any, fromServer = true) {
    this.clickTimeout.forEach(timeout => clearTimeout(timeout));
    this.onFileDelete.next({node, fromServer});
  }

  public syncFile(node: any) {
    this.clickTimeout.forEach(timeout => clearTimeout(timeout));
    this.onFileSync.next({node});
  }

  public deleteDirectory(dirPath: string) {
    this.clickTimeout.forEach(timeout => clearTimeout(timeout));
    this.onDirectoryDelete.next({dirPath});
  }

  public openFolder(folderPath: string) {
    this.clickTimeout.forEach(timeout => clearTimeout(timeout));
    this.folderOpened.next(folderPath);
  }

  public getFileExtension(fileName: string): string {
    return fileName.split('.').at(-1) as string;
  }

  public pageRefresh() {
    this.onPageRefresh.next(true);
  }

  public navigateToRootDirectory() {
    this.navigateToRoot.next(true);
  }

  public detectTableChanges() {
    this.tableChanges.next();
  }
}
