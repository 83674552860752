import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit,} from '@angular/core';
import {TableLazyLoadEvent} from 'primeng/table';
import {MenuItem} from 'primeng/api';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TableFieldModel} from '../../../../model/table-field.model';
import {NotifierService} from '../../../../services/notifier.service';
import {AuthService} from '../../../../services/auth.service';
import {DataConversionService} from '../../../../services/data-conversion.service';
import {
  DEFAULT_TABLE_ROWS_OPTIONS,
  LOCAL_STORAGE_REGISTERED_COMPANIES_LIMIT,
  REGISTERED_COMPANIES_LIMIT
} from '../../../../constants';
import {DeletePopup} from '../../../delete-popup/delete-popup.component';
import {RegisteredCompanyModel} from '../../../../model/employee/registered-company.model';
import {REGISTERED_COMPANY_DELETED} from '../../../../../../locale/multilingual-strings-constants';
import {RegisteredCompanyPopupComponent} from '../../../registered-company-popup/registered-company-popup.component';
import {PermissionsEnum} from '../../../../permissions.enum';
import {PersonalDataService} from '../../../../../../../personal-data/src/lib/personal-data.service';
import {NewResponse} from '../../../../../../../personal-data/src/lib/models/response';
import {UtilService} from '../../../../services/util.service';

@Component({
  selector: 'app-registered-companies',
  templateUrl: './registered-companies.component.html',
  styleUrls: ['./registered-companies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisteredCompaniesComponent implements OnInit {
  public registeredCompanies: RegisteredCompanyModel[];
  public items: MenuItem[];
  public ref: DynamicDialogRef | undefined;
  public totalRecords: number;
  public loading = true;
  public REGISTERED_COMPANIES_LIMIT: number;
  public hasWriteRegisteredCompanyPermission = false;
  public hasDeleteRegisteredCompanyPermission = false;
  public selectedRegisteredCompany: RegisteredCompanyModel;

  public fields: TableFieldModel[] = [
    {
      name: 'name',
      displayName: 'Company name',
    },
    {
      name: 'occupation',
      displayName: 'Occupation',
    },
    {
      name: 'annualTurnover',
      displayName: 'Annual turnover',
    },
    {
      name: 'description',
      displayName: 'Description',
    },
  ];

  @Input() public userId: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private notifierService: NotifierService,
    private authService: AuthService,
    private dialogService: DialogService,
    private dataConversionService: DataConversionService,
    private personalDataService: PersonalDataService,
    private utilService: UtilService
  ) {
  }

  public ngOnInit(): void {
    this.hasDeleteRegisteredCompanyPermission = this.authService.hasPermission(PermissionsEnum.deleteCompany);
    this.hasWriteRegisteredCompanyPermission = this.authService.hasPermission(PermissionsEnum.writeCompany);
    const storedLimit = localStorage.getItem(LOCAL_STORAGE_REGISTERED_COMPANIES_LIMIT);
    this.REGISTERED_COMPANIES_LIMIT = storedLimit && DEFAULT_TABLE_ROWS_OPTIONS.includes(+storedLimit) ? +storedLimit : REGISTERED_COMPANIES_LIMIT;
    this.initMenuItems();
    this.initRegisteredCompanies();
  }

  public openAddPopup() {
    this.openPopup('Add a new company', {personalDataId: this.userId});
  }

  private openPopup(header: string, data: { personalDataId?: string, registeredCompany?: RegisteredCompanyModel }) {
    this.ref = this.dialogService.open(RegisteredCompanyPopupComponent, {
      header,
      width: this.utilService.getPopupResponsiveWidth(),
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data,
      maximizable: true,
    });
    this.ref.onClose.subscribe((result: { edited: boolean; added: boolean; registeredCompany: RegisteredCompanyModel }) => {
      this.handlePopupResult(result);
    });
  }

  public handlePopupResult(result: { edited: boolean; added: boolean; registeredCompany: RegisteredCompanyModel }) {
    if (result) {
      if (result.edited) {
        const registeredCompany = this.registeredCompanies.find((registeredCompany: RegisteredCompanyModel) => registeredCompany.id === result.registeredCompany!.id)!;
        registeredCompany.name = result.registeredCompany.name;
        registeredCompany.annualTurnover = result.registeredCompany.annualTurnover;
        registeredCompany.description = result.registeredCompany.description;
        registeredCompany.occupation = result.registeredCompany.occupation;
        this.cdr.detectChanges();
      }
      if (result.added) {
        this.registeredCompanies.push(result.registeredCompany);
        this.totalRecords++;
        this.cdr.detectChanges();
      }
    }
  }

  public refresh() {
    this.initRegisteredCompanies();
  }

  public initRegisteredCompanies(event?: TableLazyLoadEvent) {
    this.loading = true;
    localStorage.setItem(LOCAL_STORAGE_REGISTERED_COMPANIES_LIMIT, event?.rows?.toString() || REGISTERED_COMPANIES_LIMIT.toString());
    this.personalDataService.getAllRegisteredCompanies(this.userId, (event ? event.first! : 1), (event ? event.rows! : this.REGISTERED_COMPANIES_LIMIT)).subscribe({
      next: (res: NewResponse<RegisteredCompanyModel[]>) => {
        this.loading = false;
        if (res.succeed) {
          if (!this.totalRecords && res.value.totalCount) {
            this.totalRecords = res.value.totalCount;
          }
          this.registeredCompanies = res.value.entities;
          this.cdr.detectChanges();
        } else {
          this.notifierService.handleErrors(res.errors);
        }
      },
      error: err => this.notifierService.handleRequestError(err)
    });
  }

  public initMenuItems(registeredCompany?: RegisteredCompanyModel) {
    this.items = [];
    if (this.hasWriteRegisteredCompanyPermission) {
      this.items.push(
        {
          label: 'Edit',
          command: () => {
            registeredCompany && this.openPopup(registeredCompany.name + ': User company',
              {registeredCompany});
          }
        });
    }


    this.items.push({
      label: 'Copy',
      items: [
        {
          label: 'ID',
          command: () => {
            registeredCompany && this.utilService.copyText(registeredCompany.id as string, 'Registered Company ID copied.');
          }
        }
      ]
    })

    if (this.hasDeleteRegisteredCompanyPermission) {
      this.items.push(
        {
          label: 'Delete',
          command: () => {
            registeredCompany && this.openDeleteRegisteredCompanyPopup(registeredCompany);
          }
        });
    }
  }

  public initCmItems() {
    this.initMenuItems(this.selectedRegisteredCompany);
  }

  private openDeleteRegisteredCompanyPopup(registeredCompany: RegisteredCompanyModel) {
    this.ref = this.dialogService.open(DeletePopup, {
      header: 'Are you sure you want to delete this registered company?',
      width: 'fit-content',
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data: {
        id: registeredCompany.id,
        data: this.dataConversionService.convertData(
          [registeredCompany],
          {
            organization: 'Organization',
            occupation: 'Occupation',
            contacts: 'Contacts',
            from: 'From',
            to: 'To',
          }
        )
      },
    });
    this.ref.onClose.subscribe(result => {
      if (result.delete) {
        this.deleteRegisteredCompany(registeredCompany, result.id);
      }
    });
  }

  public deleteRegisteredCompany(registeredCompany: RegisteredCompanyModel, registeredCompanyId: string) {
    this.personalDataService.deleteRegisteredCompany(registeredCompanyId).subscribe({
      next: r => {
        if (r.succeed) {
          this.notifierService.handleSuccessRequest(REGISTERED_COMPANY_DELETED);
          this.registeredCompanies.splice(this.registeredCompanies.indexOf(registeredCompany), 1);
          this.cdr.detectChanges();
        } else {
          this.notifierService.handleErrors(r.errors);
        }
      },
      error: err => {
        this.notifierService.handleRequestError(err);
      },
    });
  }
}
