import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAX_PAGING_LIMIT} from '../../../../shared/constants';
import {HttpIdentityService} from '../../../../../../identity/src/lib/identity-client.service';
import {of, zip} from 'rxjs';
import {
  OrganizationIdentityAggregatorService
} from '../../../../../../organization/src/lib/organization-identity-aggregator.service';
import {TableSelectAllChangeEvent} from 'primeng/table/table.interface';
import {NotifierService} from '../../../../shared/services/notifier.service';
import {ORGANIZATION_UPDATED_SUCCESSFULLY} from '../../../../../locale/multilingual-strings-constants';
import {UtilService} from '../../../../shared/services/util.service';
import {RoleModel} from '../../../../../../identity/src/lib/models/role.model';

@Component({
  selector: 'app-edit-organization-roles',
  templateUrl: './edit-organization-roles.component.html',
  styleUrls: ['./edit-organization-roles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditOrganizationRolesComponent implements OnInit {
  public organizationId: string;
  public roles: RoleModel[] = [];
  public loading = true;
  public selectedRoles: string[] = [];
  public allSelected = false;

  public form: FormGroup;
  public data: any;
  public parent: any;

  public hasPermissionToEditRolePermissions = true;
  public hasPermissionToCreateRolePermissions = true;
  public hasPermissionToDeleteRolePermissions = true;
  public hasPermissionToManagePermissions = true;

  public constructor(
    private httpIdentityService: HttpIdentityService,
    private utilService: UtilService,
    private orgIdentityService: OrganizationIdentityAggregatorService,
    private config: DynamicDialogConfig,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private notifierService: NotifierService,
  ) {
    this.organizationId = config.data.organization;
    this.data = config.data.value;
    this.parent = config.data.value.parent;
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      id: [this.organizationId],
      name: [this.data.label],
    });
    this.orgIdentityService.getOrganizationRoles(this.organizationId, 1, MAX_PAGING_LIMIT).subscribe({
      next: rolesRes => {
        if (rolesRes.succeed) {
          this.selectedRoles = rolesRes.value.entities;
          this.loadRolesData();
        } else {
          this.notifierService.handleErrors(rolesRes.errors);
        }
      },
      error: err => this.notifierService.handleRequestError(err),
    });
  }

  private loadRolesData() {
    if (this.hasPermissionToManagePermissions) {
      const currentOrganizationId = this.utilService.getCurrentOrganizationId();
      zip(
        this.parent || currentOrganizationId ? this.orgIdentityService.getOrganizationRoles(this.parent ? this.parent.data! : currentOrganizationId, 1, MAX_PAGING_LIMIT) : of(null),
        this.httpIdentityService.getAllRoles(1, MAX_PAGING_LIMIT),
      )
        .subscribe({
          next: ([parentRolesRes, allRolesRes]) => {
            if (parentRolesRes) {
              if (parentRolesRes.succeed && allRolesRes.succeed) {
                const parentRoleIds = parentRolesRes.value.entities;
                this.roles = allRolesRes.value.entities.filter(r => parentRoleIds.includes(r.id));
              } else this.notifierService.handleErrors(parentRolesRes.errors.length ? parentRolesRes.errors : allRolesRes.errors);
            } else {
              if (allRolesRes.succeed) {
                this.roles = allRolesRes.value.entities;
              } else this.notifierService.handleErrors(allRolesRes.errors);
            }
            this.loading = false;
            this.updateSelectAllState();
            this.cdr.detectChanges();
          },
          error: err => {
            this.notifierService.handleRequestError(err);
          }
        });
    }
  }

  public onSelectAllChange(event: TableSelectAllChangeEvent) {
    this.allSelected = event.checked;
    const oldSelectedRoles = this.selectedRoles;
    this.selectedRoles = this.allSelected ? this.roles.map(extension => extension.id!) : [];
    this.roles.forEach(role => {
      if ((this.allSelected && !oldSelectedRoles.includes(role.id))
        || ((!this.allSelected && oldSelectedRoles.includes(role.id)))) {
        this.changeRole(role.id, this.allSelected);
      }
    });
    this.cdr.detectChanges();
  }

  public changeRole(roleId: string, checked: boolean) {
    if (this.hasPermissionToCreateRolePermissions || this.hasPermissionToDeleteRolePermissions) {
      this.allSelected = this.selectedRoles.length === this.roles.length;
      if (checked) {
        this.addOrganizationRole(roleId);
      } else {
        if (this.hasPermissionToDeleteRolePermissions) {
          this.deleteOrganizationRole(roleId);
        }
      }
    }
  }

  private addOrganizationRole(roleId: string): void {
    this.orgIdentityService.addOrganizationRole(this.organizationId, roleId).subscribe({
      next: res => {
        if (res.succeed) {
          this.notifierService.handleSuccessRequest(ORGANIZATION_UPDATED_SUCCESSFULLY);
          this.updateSelectAllState();
        } else {
          this.notifierService.handleErrors(res.errors);
        }
        this.cdr.detectChanges();
      },
      error: err => {
        this.notifierService.handleRequestError(err);
        this.cdr.detectChanges();
      },
    });
  }

  private deleteOrganizationRole(roleId: string) {
    this.orgIdentityService.deleteOrganizationRole(this.organizationId, roleId).subscribe({
      next: res => {
        if (res.succeed) {
          this.notifierService.handleSuccessRequest(ORGANIZATION_UPDATED_SUCCESSFULLY);
          this.updateSelectAllState();
        } else {
          this.notifierService.handleErrors(res.errors);
        }
        this.cdr.detectChanges();
      },
      error: err => {
        this.notifierService.handleRequestError(err);
        this.cdr.detectChanges();
      },
    });
  }

  private updateSelectAllState() {
    this.allSelected = this.roles.length === this.selectedRoles.length;
  }
}
