<div style="min-height: 254px" *ngIf="educationForm ">
  <form [formGroup]="educationForm" (ngSubmit)="submit()">
    <div class="form">
      <div class="input-box">
      <span class="p-float-label">
        <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': educationForm.get('educationType')?.touched}"
               id="education-educationType"
               type="text" pInputText formControlName="educationType"/>
        <label htmlFor="education-educationType">Education Type*</label>
      </span>
        <small
          id="education-educationType-help"
          class="p-error"
          *ngIf="educationForm.get('educationType')?.touched && educationForm.get('educationType')?.hasError('required')">
          The education type is required.
        </small>
      </div>
      <div class="input-box">
      <span class="p-float-label">
        <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': educationForm.get('institution')?.touched}"
               id="education-institution"
               type="text" pInputText formControlName="institution"/>
        <label htmlFor="education-institution">Institution*</label>
      </span>
        <small
          id="institution-help"
          class="p-error"
          *ngIf="educationForm.get('institution')?.touched && educationForm.get('institution')?.hasError('required')">
          The institution is required.
        </small>
      </div>
      <div class="input-box">
      <span class="p-float-label">
        <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': educationForm.get('specialty')?.touched}"
               id="specialty"
               type="text" pInputText formControlName="specialty"/>
        <label htmlFor="specialty">Specialty*</label>
      </span>
        <small
          id="specialty-help"
          class="p-error"
          *ngIf="educationForm.get('specialty')?.touched && educationForm.get('specialty')?.hasError('required')">
          The specialty is required.
        </small>
      </div>
      <div class="input-box">
      <span class="p-float-label">
        <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': educationForm.get('degree')?.touched}" id="degree"
               type="text" pInputText formControlName="degree"/>
        <label htmlFor="degree"
               [ngClass]="{'touched-input-label': educationForm.get('degree')?.touched}"
        >Degree*</label>
      </span>
        <small
          id="city-help"
          class="p-error"
          *ngIf="educationForm.get('degree')?.touched && educationForm.get('degree')?.hasError('required')">
          The degree is required.
        </small>
      </div>

      <div class="input-box">
        <span class="p-float-label">
          <div style="min-width: 100%;" [ngClass]="{'ng-dirty': educationForm.get('from')?.touched}">
            <span [ngClass]="{'invalid-label': educationForm.get('from')?.touched && educationForm.get('from')?.invalid}"
                  htmlFor="to"
                  id="fromLabel"
            >From*</span>
            <p-calendar
              [style]="{width: '100%'}"
              id="from"
              [showIcon]="true"
              formControlName="from"
              [appendTo]="'body'"
              placeholder="From"
              ariaLabelledBy="fromLabel"
            ></p-calendar>
          </div>
        </span>
        <small
          id="from-help"
          class="p-error"
          *ngIf="educationForm.get('from')?.touched && educationForm.get('from')?.hasError('required')">
          The field is required.
        </small>
      </div>

      <div class="input-box">
        <span class="p-float-label">
          <div style="min-width: 100%;" [ngClass]="{'ng-dirty': educationForm.get('to')?.touched}">
            <span [ngClass]="{'invalid-label': educationForm.get('to')?.touched && !educationForm.get('to')?.valid}"
                  htmlFor="to"
                  id="toLabel"
            >To*</span>
            <p-calendar
              [style]="{width: '100%'}"
              id="to"
              [showIcon]="true"
              [appendTo]="'body'"
              formControlName="to"
              placeholder="To"
              ariaLabelledBy="toLabel"
            ></p-calendar>
          </div>
        </span>
        <small
          id="to-help"
          class="p-error"
          *ngIf="educationForm.get('to')?.touched">
          <ng-container *ngIf="educationForm.get('to')?.hasError('required')">The field is required.</ng-container>
          <ng-container *ngIf="educationForm.get('to')?.hasError('dateRangeError')">"From" must be less than "To".
          </ng-container>
        </small>
      </div>

      <div class="input-box buttons">
        <p-button [style]="{width: '120px'}" [disabled]="educationForm.invalid || educationForm.disabled" type="submit"
                  label="Save"></p-button>
      </div>
    </div>
  </form>
</div>

