<div style="min-height: 254px" *ngIf="psychologicalPortraitForm ">
  <form [formGroup]="psychologicalPortraitForm" (ngSubmit)="submit()">
    <div class="form">
      <div class="input-box">
      <span class="p-float-label">
        <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': psychologicalPortraitForm.get('summary')?.touched}"
               id="summary"
               type="text" pInputText formControlName="summary"/>
        <label htmlFor="summary">Summary</label>
      </span>
        <small
          id="summary-help"
          class="p-error"
          *ngIf="psychologicalPortraitForm.get('summary')?.touched && psychologicalPortraitForm.get('summary')?.hasError('required')">
          The summary is required.
        </small>
      </div>
      <div class="input-box">
      <span class="p-float-label">

        <textarea [style]="{width: '100%'}"
                  [ngClass]="{'ng-dirty': psychologicalPortraitForm.get('description')?.touched}"
                  [autoResize]="true"
                  id="description"
                  rows="5"
                  cols="30"
                  pInputTextarea
                  formControlName="description"
        ></textarea>
        <label htmlFor="description">Description</label>
      </span>
        <small
          id="description-help"
          class="p-error"
          *ngIf="psychologicalPortraitForm.get('description')?.touched && psychologicalPortraitForm.get('description')?.hasError('required')">
          The description is required.
        </small>
      </div>


      <div class="input-box buttons">
        <p-button [style]="{width: '120px'}"
                  [disabled]="psychologicalPortraitForm.invalid || psychologicalPortraitForm.disabled" type="submit"
                  label="Save"></p-button>
      </div>
    </div>
  </form>
</div>
