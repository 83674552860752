import {Inject, Injectable, InjectionToken} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {first} from 'rxjs/operators';
import {Response} from '../model/response.model';
import {NewResponse} from '../model/new-response';
import {ObinFolderModel} from '../model/obin.model';
import {TokenModel} from '../model/token.model';
import {EditPasswordModel} from '../model/edit-password.model';
import {ForgotPasswordModel} from '../model/forgot-password.model';
import {LoginModel} from '../model/login.model';
import {RegisterModel} from '../model/register.model';
import {ResetPasswordModel} from '../model/reset-password.model';
import {RoleModel} from '../model/role.model';
import {SessionModel} from '../model/session.model';
import {UserResponseModel} from '../model/user-response.model';
import {CreateUserModel} from '../model/create-user.model';
import {AllUsersResponseModel} from '../model/all-users-response.model';
import {PasswordUpdateModel} from '../model/password-update.model';
import {ObinStatisticModel} from '../model/obin-statistic.model';
import {FILE_HOSTING_API_URL} from '../../../shared/constants';
import {ObinFileLogModel} from '../model/obin-file-log.model';

export const API_URL = new InjectionToken<string>('apiUrl');

@Injectable()
export class FileHostingService {
  private static readonly fileHostingApiUrl = FILE_HOSTING_API_URL;
  private readonly headersSkippingInterceptor: HttpHeaders;
  private readonly headers: HttpHeaders;
  private readonly headersSkippingToken: HttpHeaders;
  private readonly optionsSkippingToken: object;
  private readonly optionsSkippingInterceptor: object;
  private readonly fileHostingApiUrl: string;
  private readonly urlAccount: string;
  private readonly urlRoles: string;
  private readonly urlSessions: string;
  private readonly urlUsers: string;
  private readonly urlLogs: string;


  public constructor(private http: HttpClient, @Inject(API_URL) private apiUrl: string) {
    this.headersSkippingToken = new HttpHeaders({
      'skipToken': 'true',
      'skipInterceptor': 'true',
    });
    this.headersSkippingInterceptor = new HttpHeaders({
      'skipInterceptor': 'true',
    });
    this.headers = new HttpHeaders({});


    this.optionsSkippingToken = {
      headers: this.headersSkippingToken,
      withCredentials: true
    };

    this.optionsSkippingInterceptor = {
      headers: this.headersSkippingInterceptor,
      withCredentials: true
    };


    this.fileHostingApiUrl = `${FileHostingService.fileHostingApiUrl}` // `${apiUrl}`;
    this.urlAccount = `${FileHostingService.fileHostingApiUrl}/identity/account`;
    this.urlRoles = `${FileHostingService.fileHostingApiUrl}/identity/roles`;
    this.urlSessions = `${FileHostingService.fileHostingApiUrl}/identity/sessions`;
    this.urlUsers = `${FileHostingService.fileHostingApiUrl}/identity/users`;
    this.urlLogs = `${FileHostingService.fileHostingApiUrl}/logs`;
  }


  // Account
  public confirmEmail(identifier: string, ConfirmationCode: string): Observable<Response<TokenModel>> {
    return this.http.get<Response<TokenModel>>(`${this.urlAccount}/confirmEmail?identifier=${identifier}&ConfirmationCode=${ConfirmationCode}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public editPassword(body: EditPasswordModel): Observable<Response<null>> {
    return this.http.put<Response<null>>(`${this.urlAccount}/editPassword`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public forgotPassword(body: ForgotPasswordModel): Observable<Response<null>> {
    return this.http.post<Response<null>>(`${this.urlAccount}/forgotPassword`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public login(body: LoginModel): Observable<Response<TokenModel>> {
    return this.http.post<Response<TokenModel>>(`${this.urlAccount}/login`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public logout(token: TokenModel): Observable<Response<null>> {
    return this.http.post<Response<null>>(`${this.urlAccount}/logout`, token, this.optionsSkippingInterceptor).pipe(first());
  }

  public refreshToken(token: TokenModel): Observable<Response<TokenModel>> {
    return this.http.post<Response<TokenModel>>(`${this.urlAccount}/refreshToken`, token, this.optionsSkippingInterceptor).pipe(first());
  }

  public register(body: RegisterModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlAccount}/register`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public resetPassword(body: ResetPasswordModel): Observable<Response<TokenModel>> {
    return this.http.post<Response<TokenModel>>(`${this.urlAccount}/resetPassword`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public sendEmailConfirmation(identifier: string): Observable<Response<null>> {
    return this.http.get<Response<null>>(`${this.urlAccount}/sendEmailConfirmation?identifier=${identifier}`, this.optionsSkippingInterceptor).pipe(first());
  }


  // Roles
  public getAllRoles(page: number, size: number): Observable<NewResponse<RoleModel[]>> {
    return this.http.get<NewResponse<RoleModel[]>>(`${this.urlRoles}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public createNewRole(body: { name: string }): Observable<Response<RoleModel>> {
    return this.http.post<Response<RoleModel>>(`${this.urlRoles}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public getRoleById(roleId: string): Observable<Response<RoleModel>> {
    return this.http.get<Response<RoleModel>>(`${this.urlRoles}/${roleId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public updateRole(roleId: string, name: string): Observable<Response<string>> {
    return this.http.put<Response<string>>(`${this.urlRoles}/${roleId}`, {name}, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteRole(roleId: string): Observable<Response<string>> {
    return this.http.delete<Response<string>>(`${this.urlRoles}/${roleId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getUserRoles(userId: string, page: number, size: number): Observable<NewResponse<RoleModel[]>> {
    return this.http.get<NewResponse<RoleModel[]>>(`${this.urlRoles}/GetAllByUserId/${userId}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }


  // Sessions
  public getSessionById(sessionId: string): Observable<Response<SessionModel>> {
    return this.http.get<Response<SessionModel>>(`${this.urlSessions}/${sessionId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteSessionById(sessionId: string): Observable<Response<null>> {
    return this.http.delete<Response<null>>(`${this.urlSessions}/${sessionId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getAllSessionsByUserId(userId: string): Observable<Response<SessionModel[]>> {
    return this.http.get<Response<SessionModel[]>>(`${this.urlSessions}/getAllByUserId?userid=${userId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getCurrentUserSessions(): Observable<Response<SessionModel[]>> {
    return this.http.get<Response<SessionModel[]>>(`${this.urlSessions}/getCurrentUserSessions`, this.optionsSkippingInterceptor).pipe(first());
  }


  // Tokens


  // Users
  public getAllUsers(page: number, size: number): Observable<Response<AllUsersResponseModel[]>> {
    return this.http.get<Response<AllUsersResponseModel[]>>(`${this.urlUsers}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public createUser(body: CreateUserModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlUsers}`, body, this.optionsSkippingInterceptor,).pipe(first());
  }

  public getUserByUserId(userId: string): Observable<Response<UserResponseModel>> {
    return this.http.get<Response<UserResponseModel>>(`${this.urlUsers}/${userId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteUserById(userId: string): Observable<Response<null>> {
    return this.http.delete<Response<null>>(`${this.urlUsers}/${userId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public addUserToRole(userId: string, roleId: string): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlUsers}/${userId}/${roleId}`, {}, this.optionsSkippingInterceptor,).pipe(first())
  }

  public deleteUserFromRole(userId: string, roleId: string): Observable<Response<null>> {
    return this.http.delete<Response<null>>(`${this.urlUsers}/${userId}/${roleId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public setUserPassword(userId: string, body: PasswordUpdateModel): Observable<Response<string>> {
    return this.http.put<Response<string>>(`${this.urlUsers}/${userId}/setPassword`, body, this.optionsSkippingInterceptor).pipe(first());
  }


  // Files
  public uploadFilesToObin(obin: string, body: FormData): any {
    return this.http.post(`${this.fileHostingApiUrl}/files/${obin}`, body, {
      ...this.optionsSkippingInterceptor,
      reportProgress: true,
      observe: 'response',
    }).pipe(first());
  }

  public downloadObinFile(obin: string, fileId: string, obinId: string): Observable<any> {
    const headers = new HttpHeaders({
      obinId: obinId,
      skipInterceptor: 'true',
    });
    return this.http.get(`${this.fileHostingApiUrl}/files/${obin}/${fileId}`, {
      responseType: 'blob',
      observe: 'response',
      withCredentials: true,
      headers: headers,
      params: new HttpParams(),
    }).pipe(first());
  }

  public deleteFileFromBin(obin: string, filename: string): Observable<Response<boolean>> {
    return this.http.delete<Response<boolean>>(`${this.fileHostingApiUrl}/files/${obin}/${filename}`, this.optionsSkippingInterceptor).pipe(first());
  }


  // Logs
  public getLogsByFileId(fileId: string, page: number, size: number): Observable<NewResponse<string[]>> {
    return this.http.get<NewResponse<string[]>>(`${this.urlLogs}/${fileId}/logs?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteFileLogs(fileId: string): Observable<Response<boolean>> {
    return this.http.delete<Response<boolean>>(`${this.urlLogs}/${fileId}/logs/clear`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getLogByLogId(logId: string): Observable<Response<ObinFileLogModel>> {
    return this.http.get<Response<ObinFileLogModel>>(`${this.urlLogs}/${logId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteFileLogById(logId: string): Observable<Response<boolean>> {
    return this.http.delete<Response<boolean>>(`${this.urlLogs}/${logId}`, this.optionsSkippingInterceptor).pipe(first());
  }


  // Obin

  public showObin(obin: string): Observable<Response<ObinFolderModel>> {
    return this.http.get<Response<ObinFolderModel>>(`${this.fileHostingApiUrl}/obin/${obin}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public lockObin(obin: string): Observable<Response<string>> {
    return this.http.put<Response<string>>(`${this.fileHostingApiUrl}/obin/${obin}`, {}, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteObin(obin: string): Observable<Response<boolean>> {
    return this.http.delete<Response<boolean>>(`${this.fileHostingApiUrl}/obin/${obin}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getObinFilesTarArchive(obin: string): Observable<any> {
    return this.http.get(`${this.fileHostingApiUrl}/obin/archive/${obin}/tar`,
      {
        responseType: 'blob',
        observe: 'response',
        withCredentials: true,
        headers: this.headersSkippingInterceptor,
        params: new HttpParams(),
      }).pipe(first());
  }

  public getObinFilesZip(obin: string): Observable<any> {
    return this.http.get(`${this.fileHostingApiUrl}/obin/archive/${obin}/zip`,
      {
        responseType: 'blob',
        observe: 'response',
        withCredentials: true,
        headers: this.headersSkippingInterceptor,
        params: new HttpParams(),
      }).pipe(first());
  }

  public getAbsoluteUrlQrCode(obin: string): Observable<any> {
    return this.http.get(`${this.fileHostingApiUrl}/obin/qr/${obin}`,
      {
        responseType: 'blob',
        observe: 'response',
        withCredentials: true,
        headers: this.headersSkippingInterceptor,
        params: new HttpParams(),
      }
    ).pipe(first());
  }


  // Health
  public getHealthz(): Observable<Response<string>> {
    return this.http.get<Response<string>>(`${this.fileHostingApiUrl}/healthz`, this.optionsSkippingInterceptor).pipe(first());
  }


  // Statistics
  public getFileStatistics(obinId: string, fileId: string): Observable<Response<ObinStatisticModel>> {
    return this.http.get<Response<ObinStatisticModel>>(`${this.fileHostingApiUrl}/statistics/${obinId}/${fileId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getObinStatistics(obinId: string): Observable<Response<ObinStatisticModel>> {
    return this.http.get<Response<ObinStatisticModel>>(`${this.fileHostingApiUrl}/statistics/${obinId}`, this.optionsSkippingToken).pipe(first());
  }
}
