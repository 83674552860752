import {Inject, Injectable, RendererFactory2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {LOCAL_STORAGE_THEME} from '../constants';
import {Subject} from 'rxjs';

@Injectable()
export class ThemeService {
  public theme$: Subject<string> = new Subject<string>();

  public constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.loadThemeFromLocalStorage();
  }

  public getSelectedTheme(): string {
    return localStorage.getItem(LOCAL_STORAGE_THEME) as string;
  }

  public switchTheme(theme: string) {
    const themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = theme + '.css';
      localStorage.setItem(LOCAL_STORAGE_THEME, theme);
      this.theme$.next(theme);
    }
  }

  public loadThemeFromLocalStorage() {
    const savedTheme = localStorage.getItem(LOCAL_STORAGE_THEME) as string;
    const theme = savedTheme || 'light';
    this.switchTheme(theme);
  }
}
