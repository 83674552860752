<div class="image-card">
  <p-card>
    <ng-template pTemplate="header">
      <p-image
        src="{{src}}"
        alt="Image"
        width="250"
        [preview]="true"
      ></p-image>
    </ng-template>
    {{description}}
    <ng-template pTemplate="footer">

    </ng-template>
  </p-card>
</div>
