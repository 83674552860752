import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'timezone'
})
export class DateTimezonePipe implements PipeTransform {
  transform(value: any, offset = 0, dateProperty: string | undefined, format = 'medium', hour12 = false): string {
    if (!value) {
      return '';
    }
    const dateValue = !dateProperty ? value : value[dateProperty];
    if (!dateValue) return '';

    const date = new Date(dateValue);
    const adjustedDate = new Date(date.getTime() + (3600000 * offset));
    const options: Intl.DateTimeFormatOptions = this.getFormatOptions(format, hour12);
    return adjustedDate.toLocaleString('en-US', options);
  }

  private getFormatOptions(format: string, hour12: boolean): Intl.DateTimeFormatOptions {
    switch (format) {
      case 'short':
        return {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: hour12
        };
      case 'medium':
        return {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: hour12
        };
      case 'long':
        return {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          timeZoneName: 'short',
          hour12: hour12
        };
      case 'full':
        return {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          timeZoneName: 'long',
          hour12: hour12
        };
      case 'dateOnly':
        return {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        };
      case 'timeOnly':
        return {
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: hour12
        };
      default:
        return {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: hour12
        };
    }
  }
}
