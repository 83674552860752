import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {MachineUserSocialMediaModel} from '../../../../model/machine/machine.model';
import {MenuItem} from 'primeng/api';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {AuthService} from '../../../../services/auth.service';
import {PermissionsEnum} from '../../../../permissions.enum';
import {TableLazyLoadEvent} from 'primeng/table';
import {DEFAULT_TABLE_ROWS_OPTIONS, LOCAL_STORAGE_SOCIAL_MEDIA_LIMIT, SOCIAL_MEDIA_LIMIT} from '../../../../constants';
import {DeletePopup} from '../../../delete-popup/delete-popup.component';
import {SOCIAL_MEDIA_DELETED} from '../../../../../../locale/multilingual-strings-constants';
import {DataConversionService} from '../../../../services/data-conversion.service';
import {TableFieldModel} from '../../../../model/table-field.model';
import {SocialMediaPopupComponent} from '../../../social-media-popup/social-media-popup.component';
import {NotifierService} from '../../../../services/notifier.service';
import {PersonalDataService} from '../../../../../../../personal-data/src/lib/personal-data.service';
import {UtilService} from '../../../../services/util.service';

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialMediaComponent {
  public hasDeletePermission = false;
  public hasWritePermission = false;
  public socialMedias: MachineUserSocialMediaModel[];
  public items: MenuItem[];
  public ref: DynamicDialogRef | undefined;
  public totalRecords: number;
  public loading = true;
  public socialMediaLimit: number;
  public selectedSocialMedia: MachineUserSocialMediaModel;
  public fields: TableFieldModel[] = [
    {
      name: 'name',
      displayName: 'Social Media',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    },
    {
      name: 'accountId',
      displayName: 'Account Id',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    },
  ]

  @Input() public userId: string;

  constructor(
    private authService: AuthService,
    private notifierService: NotifierService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private dataConversionService: DataConversionService,
    private personalDataService: PersonalDataService,
    private utilService: UtilService
  ) {
  }

  public ngOnInit(): void {
    const storedLimit = localStorage.getItem(LOCAL_STORAGE_SOCIAL_MEDIA_LIMIT);
    this.socialMediaLimit = storedLimit && DEFAULT_TABLE_ROWS_OPTIONS.includes(+storedLimit) ? +storedLimit : SOCIAL_MEDIA_LIMIT;
    this.hasDeletePermission = this.authService.hasPermission(PermissionsEnum.deleteSocialMedia);
    this.hasWritePermission = this.authService.hasPermission(PermissionsEnum.writeSocialMedia, [PermissionsEnum.deleteSocialMedia]);
    this.initSocialMedia();
    this.initMenuItems();
  }

  public openAddPopup() {
    this.openPopup('Add a new social media information', {personalDataId: this.userId});
  }

  private openPopup(header: string, data: { personalDataId?: string, socialMedia?: MachineUserSocialMediaModel }) {
    this.ref = this.dialogService.open(SocialMediaPopupComponent, {
      header,
      width: this.utilService.getPopupResponsiveWidth(),
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data,
      maximizable: true,
    });
    this.ref.onClose.subscribe((result: { edited: boolean; added: boolean; socialMedia: MachineUserSocialMediaModel }) => {
      this.handlePopupResult(result);
    });
  }

  public handlePopupResult(result: { edited: boolean; added: boolean; socialMedia: MachineUserSocialMediaModel }) {
    if (result) {
      if (result.edited) {
        const socialMedia = this.socialMedias.find((socialMedia: MachineUserSocialMediaModel) => socialMedia.id === result.socialMedia!.id)!;
        socialMedia.name = result.socialMedia.name;
        socialMedia.accountId = result.socialMedia.accountId;
        this.cdr.detectChanges();
      }
      if (result.added) {
        this.socialMedias.push(result.socialMedia);
        this.totalRecords++;
        this.cdr.detectChanges();
      }
    }
  }

  public refresh() {
    this.initSocialMedia();
  }

  public initSocialMedia(event?: TableLazyLoadEvent) {
    this.loading = true;
    localStorage.setItem(LOCAL_STORAGE_SOCIAL_MEDIA_LIMIT, event?.rows?.toString() || SOCIAL_MEDIA_LIMIT.toString());
    this.personalDataService.getPersonalDataSocialMedia(this.userId,
      (event ? event.first! / (event ? event.rows! : this.socialMediaLimit) + 1 : 1), (event ? event.rows! : this.socialMediaLimit)).subscribe({
      next: socialMediaResponse => {
        this.loading = false;
        if (socialMediaResponse.succeed) {
          if (!this.totalRecords && socialMediaResponse.value.totalCount) {
            this.totalRecords = socialMediaResponse.value.totalCount;
          }
          // if (socialMediaResponse.value.entities.length) {
          this.socialMedias = socialMediaResponse.value.entities.map(a => ({
            ...a,
          }));
          this.loading = false;
          this.cdr.detectChanges();
          // }
          this.cdr.detectChanges();
        } else {
          this.notifierService.handleErrors(socialMediaResponse.errors);
        }
      },
      error: err => this.notifierService.handleRequestError(err)
    });
  }

  public initMenuItems(socialMedia?: MachineUserSocialMediaModel) {
    this.items = [];

    if (this.hasWritePermission) {
      this.items.push(
        {
          label: 'Edit',
          command: () => {
            socialMedia && this.openPopup(socialMedia.name + ': User social media', {socialMedia});
          }
        });
    }

    this.items.push({
      label: 'Copy',
      items: [
        {
          label: 'ID',
          command: () => {
            socialMedia && this.utilService.copyText(socialMedia.id, 'Social Media ID copied.');
          }
        }
      ]
    })

    if (this.hasDeletePermission) {
      this.items.push(
        {
          label: 'Delete',
          command: () => {
            socialMedia && this.openDeleteSocialMediaPopup(socialMedia);
          }
        });
    }
  }

  public initCmItems() {
    this.initMenuItems(this.selectedSocialMedia);
  }

  private openDeleteSocialMediaPopup(socialMedia: MachineUserSocialMediaModel) {
    this.ref = this.dialogService.open(DeletePopup, {
      header: 'Are you sure you want to delete this social media information?',
      width: 'fit-content',
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data: {
        id: socialMedia.id,
        data: this.dataConversionService.convertData(
          [socialMedia],
          {
            name: 'Name',
            accountUrl: 'Account Url',
          })
      },
    });
    this.ref.onClose.subscribe(result => {
      if (result.delete) {
        this.deleteSocialMedia(socialMedia, result.id);
      }
    });
  }

  public deleteSocialMedia(socialMedia: MachineUserSocialMediaModel, socialMediaId: string) {
    this.personalDataService.deleteSocialMedia(socialMediaId).subscribe({
      next: r => {
        if (r.succeed) {
          this.notifierService.handleSuccessRequest(SOCIAL_MEDIA_DELETED);
          this.socialMedias.splice(this.socialMedias.indexOf(socialMedia), 1);
          this.cdr.detectChanges();
        } else {
          this.notifierService.handleErrors(r.errors);
        }
      },
      error: err => {
        this.notifierService.handleRequestError(err);
      },
    });
  }
}
