<p-tabView *ngIf="isFilter; else employeeForm">
  <p-tabPanel header="Personal Data">
    <ng-container *ngTemplateOutlet="employeeForm"></ng-container>
  </p-tabPanel>

  <ng-container *ngIf="isFilter">
    <p-tabPanel header="Social Media">
      <form class="form"
            style="height: 426px;"
            [formGroup]="socialMediaFilterForm"
            (ngSubmit)="onSubmit(true)">

        <div class="input-fields">
          <div class="input-box">
          <span class="p-float-label">
            <p-dropdown
              [style]="{width: '100%'}"
              [ngClass]="{'ng-dirty': socialMediaFilterForm.get('name')?.touched}"
              id="social-media-account-name"
              [options]="socialMediaNames"
              [filter]="true"
              formControlName="name"
              placeholder="Name"
            ></p-dropdown>
            <label htmlFor="employee-status">Name</label>
          </span>
          </div>

          <div class="input-box">
          <span class="p-float-label">
            <input [style]="{width: '100%'}"
                   [ngClass]="{'ng-dirty': socialMediaFilterForm.get('accountId')?.touched}"
                   id="social-media-account-id"
                   type="text"
                   pInputText
                   formControlName="accountId"/>
            <label htmlFor="employee-status">Account ID</label>
          </span>
          </div>
        </div>

        <div class="input-box buttons">
          <p-button [style]="{width: '120px'}"
                    [disabled]="socialMediaFilterForm.invalid || socialMediaFilterForm.disabled"
                    type="submit"
                    label="{{!isFilter? 'Save' : 'Filter'}}"></p-button>
        </div>
      </form>
    </p-tabPanel>
  </ng-container>
</p-tabView>

<ng-template #employeeForm>
  <div style="min-height: 254px" *ngIf="employeeProfileForm">
    <form [formGroup]="employeeProfileForm" (ngSubmit)="onSubmit()">
      <div class="form">
        <div class="input-box">
          <app-organizations-tree-dropdown
            [id]="'add-employee-organization'"
            [fg]="employeeProfileForm"
            [fcn]="'organizationId'"
            [placeholder]="'Select Organization'"
            [label]="'Select Organization'"
          ></app-organizations-tree-dropdown>
        </div>

        <div class="input-box">
            <span class="p-float-label">
              <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': employeeProfileForm.get('identifier')?.touched}"
                     id="employeeId"
                     type="text" pInputText formControlName="identifier"/>
              <label htmlFor="employeeId">ID</label>
            </span>
        </div>

        <div class="input-box">
            <span class="p-float-label">
              <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': employeeProfileForm.get('firstName')?.touched}"
                     id="employee-firstName"
                     type="text" pInputText formControlName="firstName"/>
              <label htmlFor="employee-firstName">First Name</label>
            </span>
        </div>

        <div class="input-box">
            <span class="p-float-label">
              <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': employeeProfileForm.get('lastName')?.touched}"
                     id="empoyee-lastName"
                     type="text" pInputText formControlName="lastName"/>
              <label htmlFor="employee-lastName">Last Name</label>
            </span>
        </div>

        <div class="input-box">
            <span class="p-float-label">
              <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': employeeProfileForm.get('fatherName')?.touched}"
                     id="employee-fatherName"
                     type="text" pInputText formControlName="fatherName"/>
              <label htmlFor="employee-fatherName">Father Name</label>
            </span>
        </div>

        <div class="input-box">
        <span class="p-float-label">
          <div style="min-width: 100%;" [ngClass]="{'ng-dirty': employeeProfileForm.get('dateOfBirth')?.touched}">
            <span htmlFor="to" id="fromLabel">Date Of Birth</span>
              <p-calendar
                [style]="{width: '100%'}"
                id="from"
                [showIcon]="true"
                formControlName="dateOfBirth"
                [maxDate]="maxBirthDate"
                [appendTo]="'body'"
                placeholder="dateOfBirth"
                ariaLabelledBy="fromLabel"
              ></p-calendar>
          </div>
        </span>
        </div>

        <div class="input-box">
            <span class="p-float-label">
              <input [style]="{width: '100%'}"
                     [ngClass]="{'ng-dirty': employeeProfileForm.get('placeOfBirth')?.touched}"
                     id="employee-placeOfBirth"
                     type="text" pInputText formControlName="placeOfBirth"/>
              <label htmlFor="employee-placeOfBirth">Place Of Birth</label>
            </span>
        </div>

        <div class="input-box">
            <span class="p-float-label">
              <p-dropdown
                [ngClass]="{'ng-dirty': employeeProfileForm.get('gender')?.touched}"
                [style]="{width: '100%'}"
                id="employee-gender"
                formControlName="gender"
                [options]="genders"
                optionLabel="name"
                optionValue="name"
                placeholder="Select Gender"
              ></p-dropdown>
              <label htmlFor="employee-placeOfBirth">Gender</label>
            </span>
        </div>

        <div class="input-box">
            <span class="p-float-label">
              <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': employeeProfileForm.get('status')?.touched}"
                     id="empoyee-status"
                     type="text" pInputText formControlName="status"/>
              <label htmlFor="employee-status">Status</label>
            </span>
        </div>

        <div class="input-box buttons">
          <p-button [style]="{width: '120px'}"
                    [disabled]="employeeProfileForm.invalid || employeeProfileForm.disabled"
                    type="submit"
                    label="{{!isFilter? 'Save' : 'Filter'}}"></p-button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
