import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit, ViewChild,
} from '@angular/core';
import {HttpDataService} from '../../../../services/http/http-data.service';
import {
  ASSIGNED_MACHINES_LIMIT,
  DEFAULT_TABLE_ROWS_OPTIONS,
  LOCAL_STORAGE_ASSIGNED_MACHINES_LIMIT,
  RIGHT_BAR_ACCESS_PERMISSIONS,
  RIGHT_BAR_FILE_SERVICES, RIGHT_BAR_LOGS,
  RIGHT_BAR_REMOTE_DESKTOP
} from '../../../../constants';
import {MachineActivityLogModel, MachineModel} from '../../../../model/machine/machine.model';
import {MenuItem} from 'primeng/api';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TableFieldModel} from '../../../../model/table-field.model';
import {TableLazyLoadEvent} from 'primeng/table';
import {FieldTypesEnum} from '../../../../field-types.enum';
import {NotifierService} from '../../../../services/notifier.service';
import {FieldSortTypesEnum} from '../../../../field-sort-types.enum';
import {PermissionsEnum} from '../../../../permissions.enum';
import {MACHINE_RESTARTED_SUCCESSFULLY} from '../../../../../../locale/multilingual-strings-constants';
import {AuthService} from '../../../../services/auth.service';
import {NavigationService} from '../../../../services/navigation.service';
import {
  SyncConfigurationsComponent
} from '../../../../../modules/home/components/sync-configurations/sync-configurations.component';
import {merge, map, of, throwError} from 'rxjs';
import {TableComponent} from '../../../table/table.component';
import {Response} from '../../../../model/response.model';
import {TerminalEditComponent} from '../../../../../modules/terminal/terminal-edit/terminal-edit.component';
import {UtilService} from '../../../../services/util.service';

@Component({
  selector: 'app-assigned-machines',
  templateUrl: './assigned-machines.component.html',
  styleUrls: ['./assigned-machines.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignedMachinesComponent implements OnInit {
  public machines: MachineModel[];
  public menuItems: MenuItem[] = [];
  public ref: DynamicDialogRef | undefined;
  public totalRecords: number;
  public loading = true;
  public ASSIGNED_MACHINES: number;
  public selectedAssignMachine: MachineModel;
  @ViewChild('assignedMachinesTableComponent', {static: false}) private assignedMachinesTableComponent: TableComponent;

  public fields: TableFieldModel[] = [
    {
      name: 'name',
      displayName: 'Name',
      isCopy: false,
      globalFilter: false,
      sortable: true,
      sortType: FieldSortTypesEnum.string,
      searchable: true,
    },
    {
      name: 'operatingSystem',
      displayName: 'Operating System',
      isCopy: false,
      globalFilter: false,
      sortable: true,
      sortType: FieldSortTypesEnum.string,
      searchable: false,
      secondary: true,
    },
    {
      name: 'version',
      displayName: 'Version',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
      secondary: true,
    },
    {
      name: 'countryCode',
      displayName: 'Country',
      flagIcon: true,
      isCopy: false,
      globalFilter: false,
      searchable: false,
      sortable: true,
      sortType: FieldSortTypesEnum.string,
      secondary: true,
    },
    {
      name: 'ipAddress',
      displayName: 'IP Address',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: true,
      secondary: true,
    },
    {
      name: 'createdAt',
      displayName: 'Created At',
      isCopy: false,
      globalFilter: false,
      searchable: false,
      sortable: true,
      sortType: FieldSortTypesEnum.date,
      type: FieldTypesEnum.date,
      showTimeTooltip: true,
    },
    {
      name: 'updatedAt',
      displayName: 'Updated At',
      isCopy: false,
      globalFilter: false,
      searchable: false,
      sortable: true,
      sortType: FieldSortTypesEnum.date,
      type: FieldTypesEnum.date,
      showTimeTooltip: true,
      secondary: true,
    },
  ]

  @Input() public userId: string;

  constructor(
    private httpDataService: HttpDataService,
    private notifierService: NotifierService,
    private authService: AuthService,
    private dialogService: DialogService,
    private navigationService: NavigationService,
    private cdr: ChangeDetectorRef,
    private utilService: UtilService
  ) {
  }

  public ngOnInit(): void {
    const storedLimit = localStorage.getItem(LOCAL_STORAGE_ASSIGNED_MACHINES_LIMIT);
    this.ASSIGNED_MACHINES = storedLimit && DEFAULT_TABLE_ROWS_OPTIONS.includes(+storedLimit) ? +storedLimit : ASSIGNED_MACHINES_LIMIT;
    this.initData()
  }

  public refresh() {
    this.initData();
  }

  public initData(event?: TableLazyLoadEvent) {
    this.ASSIGNED_MACHINES = event?.rows || ASSIGNED_MACHINES_LIMIT;
    localStorage.setItem(LOCAL_STORAGE_ASSIGNED_MACHINES_LIMIT, this.ASSIGNED_MACHINES.toString());
    // this.httpDataService.getAssignedData(this.userId,
    //   (event ? event.first! : 0), (event ? event.rows! : this.ASSIGNED_MACHINES)).subscribe({
    //   next: assignedMachines => {
    //     this.loading = false;
    //     if (assignedMachines.succeed) {
    //       if (!this.totalRecords && assignedMachines.totalItems) {
    //         this.totalRecords = assignedMachines.totalItems;
    //       }
    //       this.machines = assignedMachines.value;
    //       this.initMachineActivityLogs(this.machines);
    //       this.cdr.detectChanges();
    //     } else {
    //       this.notifierService.handleErrors(assignedMachines.errors);
    //     }
    //   },
    //   error: err => this.notifierService.handleRequestError(err)
    // });
  }

  public initMenuItems(machine: MachineModel) {
    this.menuItems = [];
    if (machine?.isOnline && this.authService.hasPermission(PermissionsEnum.manageMachineDesktop)) {
      this.menuItems.push({
        label: 'VNC',
        command: () => {
          this.navigationService.openRightBarPage(RIGHT_BAR_REMOTE_DESKTOP, {
            machineId: machine?.id,
            machineName: machine?.name,
            opened: true,
          });
        }
      });
    }
    if (machine?.isOnline && this.authService.hasPermission(PermissionsEnum.manageSoftRestartMachine)) {
      this.menuItems.push({
        label: 'Soft Restart',
        command: () => {
          this.httpDataService.getSoftRestartMachine(machine.id).subscribe({
            next: res => {
              if (res.succeed) {
                if (res.value) {
                  this.notifierService.handleSuccessRequest(MACHINE_RESTARTED_SUCCESSFULLY);
                }
              } else this.notifierService.handleErrors(res.errors);
            },
            error: err => {
              this.notifierService.handleRequestError(err);
            }
          });
        }
      });
    }
    if (this.authService.hasPermission(PermissionsEnum.manageMachineFileSystem)) {
      this.menuItems.push({
        label: 'File Services',
        command: () => {
          this.navigationService.openRightBarPage(RIGHT_BAR_FILE_SERVICES,
            {
              machineId: machine?.id,
              opened: true,
              machineName: machine?.name
            });
        }
      })
    }
    this.menuItems.push(
      {separator: true},
      {
        label: 'Rename',
        command: () => {
          this.openMachinePopup({machine});
        }
      }
    );
    if (this.authService.hasPermission(PermissionsEnum.readSyncConfiguration, [PermissionsEnum.writeSyncConfiguration])) {
      this.menuItems.push({
        label: 'Sync Configurations',
        command: () => {
          this.openSyncConfigurationPopup({machine});
        },
      });
    }
    if (this.authService.hasPermission(PermissionsEnum.readMachineAccessPermission, [
      PermissionsEnum.writeMachineAccessPermission,
    ])) {
      this.menuItems.push({
        label: 'Access Permissions',
        command: () => {
          this.navigationService.openRightBarPage(RIGHT_BAR_ACCESS_PERMISSIONS, {
            machineId: machine?.id,
            opened: true,
            machineName: machine?.name
          });
        }
      })
    }
    if (this.authService.hasPermission(PermissionsEnum.readMachineActivityLogs)) {
      this.menuItems.push(
        {separator: true},
        {
          label: 'Activity Logs',
          command: () => {
            this.navigationService.openRightBarPage(RIGHT_BAR_LOGS,
              {
                machineId: machine?.id,
                opened: true,
                machineName: machine?.name
              });
          }
        }
      )
    }

    this.menuItems.push(  {
      label: 'Copy',
      items : [        {
        label: 'Copy ID',
        command: () => {
          machine && this.utilService.copyText(machine.id, 'Machine ID copied.');
        }
      },]
    })
    this.cdr.detectChanges();
  }

  public initCmItems() {
    this.initMenuItems(this.selectedAssignMachine);
  }

  private openMachinePopup(data: { machine: MachineModel }) {
    this.ref = this.dialogService.open(TerminalEditComponent, {
      header: `${data.machine.name}: Edit Machine`,
      width: '400px',
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data,
      maximizable: true,
    });
    this.ref.onClose.subscribe((result: true | undefined) => {
      if (result) {
        this.refresh();
      }
    });
  }

  private openSyncConfigurationPopup(data: { machine: MachineModel }) {
    this.ref = this.dialogService.open(SyncConfigurationsComponent, {
      header: `${data.machine.name} - Sync Configurations`,
      width: '400px',
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data: {machineId: data.machine.id},
      maximizable: true,
    });
    this.ref.onClose.subscribe(result => {
      if (result) {
        this.refresh();
      }
    });
  }

  private setDataForMachinesFromLogs(logResponse: Response<MachineActivityLogModel[]>, machine: MachineModel & {
    greenBGColor?: boolean,
    redBGColor?: boolean,
    secondaryFieldsLoaded?: boolean,
  }) {
    const machineLogData = logResponse.value[0];
    machine.greenBGColor = machine.created;
    machine.redBGColor = !this.isUpdatedLastWeek(machine.updatedAt);
    machine.operatingSystem = machineLogData.operatingSystem;
    machine.version = machineLogData.hostVersion;
    machine.countryCode = {
      countryName: machineLogData?.countryCode || '',
      flagIcon: 'fi fi-' + machineLogData?.countryCode.toLowerCase(),
    };
    machine.ipAddress = machineLogData.ipAddress;
    machine.updatedAt = machine.isOnline ? undefined : machineLogData.createdAt;
    machine.secondaryFieldsLoaded = true;
    this.assignedMachinesTableComponent?.cdr.detectChanges();
  }

  private isUpdatedLastWeek(updatedAt?: string | Date): boolean {
    if (updatedAt) {
      const createdAtDate = typeof updatedAt === 'string' ? new Date(updatedAt) : updatedAt;
      const now = new Date();
      const timeDifference = now.getTime() - createdAtDate.getTime();
      const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
      return daysDifference <= 7;
    }
    return true; // todo false
  }

  private initMachineActivityLogs(value: MachineModel[]) {
    if (value.length && this.authService.hasPermission(PermissionsEnum.readMachineActivityLogs)) {
      merge(...value
        .map(machine => machine.id ? this.httpDataService.getMachineActivityLogs(machine.id, 0, 1).pipe(
            map(logResponse => ({logResponse, machine: machine})),
          ) :
          of(throwError))).subscribe((res: any) => {
        this.setDataForMachinesFromLogs(res.logResponse, res.machine);
        this.cdr.detectChanges();
      });
    } else {
      this.cdr.detectChanges();
    }
  }
}
