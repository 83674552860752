<app-drawer *ngIf="!inNewWindow"
            (visibleChange)="onVisibleChange($event)"
            [visible]="visible"
            [header]="'Shell: ' + terminalName">
  <div HeaderButtonExtras>
    <div class="header-buttons">
      <i (click)="downloadCurrentState()" class="pi pi-download shell-header-button"></i>
      <i (click)="openNewWindows()" class="fa fa-external-link shell-header-button"></i>
    </div>
  </div>
  <div style="padding-bottom: 16px" class="terminal-shell">
    <ng-container [ngTemplateOutlet]="backgroundImageTemplate"></ng-container>
  </div>
</app-drawer>

<ng-container *ngIf="inNewWindow">
  <div class="terminal-shell-full">
    <ng-container [ngTemplateOutlet]="backgroundImageTemplate"></ng-container>
  </div>
</ng-container>

<ng-template #backgroundImageTemplate>
  <div class="container">
      <textarea #textareaElement
                class="textarea"
                pInputTextarea
                appAutoScroll
                [disabled]="disabled"
                [draggable]="false"
                [autoResize]="false"
                [(ngModel)]="terminalValue"
                (keydown)="onKeyDown($event)"
                (keydown.enter)="sendMessage()"></textarea>

    <div *ngIf="contextMenuVisible"
         [ngStyle]="{'top.px': contextMenuPosition.y, 'left.px': contextMenuPosition.x, 'position': 'absolute'}"
         class="context-menu">
      <div class="context-menu-item" (click)="pasteText()">Paste</div>
      <div class="context-menu-item" (click)="cutText()">Cut</div>
      <div class="context-menu-item" (click)="deleteText()">Delete</div>
    </div>

    <button style="display: none"></button>

    <!--    <div>-->
    <!--      <input-->
    <!--        class="input"-->
    <!--        type="text"-->
    <!--        pInputText-->
    <!--        autofocus-->
    <!--        (keydown.enter)="sendMessage()"-->
    <!--        [autofocus]="true"-->
    <!--        [(ngModel)]="inputValue"-->
    <!--        variant="filled"/>-->

    <!--      <p-button-->
    <!--        [style]="{width: '10%'}"-->
    <!--        [disabled]="!inputValue.length"-->
    <!--        type="submit"-->
    <!--        label="Send"-->
    <!--        (onClick)="sendMessage()"-->
    <!--      ></p-button>-->
    <!--    </div>-->
  </div>
</ng-template>
