import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SALARY_SAVED_SUCCESSFULLY} from '../../../../locale/multilingual-strings-constants';
import {MachineUserSalaryModel} from '../../model/machine/machine.model';
import {NotifierService} from '../../services/notifier.service';
import {PersonalDataService} from '../../../../../personal-data/src/lib/personal-data.service';

@Component({
  selector: 'app-edit-salary',
  templateUrl: './salary-popup.component.html',
  styleUrls: ['./salary-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalaryPopupComponent {
  public personalDataId: string;
  public salaryForm: FormGroup;

  constructor(
              private ref: DynamicDialogRef,
              private fb: FormBuilder,
              private notifierService: NotifierService,
              private config: DynamicDialogConfig,
              private cdr: ChangeDetectorRef,
              private personalDataService: PersonalDataService) {

    const salary = config.data.salary;
    this.personalDataId = config.data.personalDataId;
    this.salaryForm = this.fb.group({
      id: [salary?.id],
      personalDataId: [salary?.personalDataId || this.personalDataId],
      company: [salary?.company, [Validators.required,]],
      amount: [salary?.amount, [Validators.required,]],
      percent: [salary?.percent, [Validators.required,]],
      createdAt: [new Date(), [Validators.required,]],
      from: [salary?.from ? new Date(salary?.from) : null ],
      to: [salary?.to ? new Date(salary?.to) : null],
    });
  }

  public submit() {
    if (this.salaryForm.valid) {
      this.salaryForm.disable();
      const body = this.salaryForm.value as MachineUserSalaryModel;
      const submitRequest$ = this.salaryForm.value.id ? this.personalDataService.updateSalary(body) :
        this.personalDataService.addSalary(body);

      submitRequest$.subscribe({
        next: response => {
          if (response.succeed) {
            this.notifierService.handleSuccessRequest(SALARY_SAVED_SUCCESSFULLY);
            if (!this.salaryForm.value.id) {
              this.salaryForm.controls['id'].setValue(response.value);
              this.closeDialog(true);
            } else this.closeDialog(false, true);
          } else {
            this.notifierService.handleErrors(response.errors);
            this.salaryForm.enable();
          }
          this.cdr.detectChanges();
        },
        error: err => {
          this.notifierService.handleRequestError(err);
          this.salaryForm.enable();
          this.cdr.detectChanges();
        }
      })
    }
  }

  public closeDialog(added: boolean, edited?: boolean) {
    this.ref.close({added, edited, salary: this.salaryForm.value});
  }

}
