import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MenuItem, TreeNode} from 'primeng/api';
import {TreeNodeCollapseEvent, TreeNodeContextMenuSelectEvent} from 'primeng/tree/tree.interface';
import {FilesSyncStatusModel} from '../../../../shared/model/machine/file.model';
import {IconSizeModel} from '../../../../shared/model/icon-size.model';
import {TableLazyLoadEvent, TableRowSelectEvent} from 'primeng/table/table.interface';
import {FileEventService} from '../file-event.service';
import {CheckboxChangeEvent} from 'primeng/checkbox';

@Component({
  selector: 'app-table-with-tree',
  templateUrl: './table-with-tree.component.html',
  styleUrls: ['./table-with-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableWithTreeComponent implements OnInit{
  @Input() public isOnline = false;
  @Input() public currentPath: string;
  @Input() public machineId: string;
  @Input() public machineName: string;
  @Input() public selectedFiles: TreeNode[];
  @Input() public selectedIconSize: IconSizeModel;
  @Input() public filesShownInExplorer: TreeNode[] = [];
  @Input() public files: any[] = [];
  @Input() public filesSyncStatus: FilesSyncStatusModel;
  @Input() public directories: TreeNode[] = [];
  @Output() public nodeSelect$: EventEmitter<TreeNode> = new EventEmitter<TreeNode>();
  @Output() public nodeCollapse$: EventEmitter<TreeNodeCollapseEvent> = new EventEmitter<TreeNodeCollapseEvent>();
  @Output() public onRowDataChange$: EventEmitter<{ row: TableRowSelectEvent; event: CheckboxChangeEvent, data: any[] }> = new EventEmitter<{ row: TableRowSelectEvent; event: CheckboxChangeEvent, data: any[] }>();
  @Input() public totalRecords: number;
  @Input() public treeLoading = false;
  @Input() public loading = false;
  @Input() public storedLimit: string;
  @Input() public currentNode: any;
  @Output() public filesLazyLoad: EventEmitter<TableLazyLoadEvent> = new EventEmitter<TableLazyLoadEvent>();
  @Output() public fileLoad: EventEmitter<any> = new EventEmitter<any>();
  @Input() public selectedDirectory: string;
  @Input() public isRegisterEditor: boolean;
  @Input() isPreviewModeOpened: boolean;
  @Output() public deleteRegistryEntry: EventEmitter<any> = new EventEmitter<any>();

  public panelSize: number[];
  public directoryContextMenuItems: MenuItem[] = [
    {
      label: 'Delete', icon: 'pi pi-fw pi-trash', command: () => {
        this.fileEventService.deleteDirectory(this.selectedDirectory);
      }
    },
  ];

  constructor(public fileEventService: FileEventService) {
  }

  public ngOnInit(): void {
    this.panelSize = window.innerWidth > 768 ?
      [18, 82] : window.innerWidth < 500 && window.innerWidth > 320
        ?  [30, 70]
        : window.innerWidth <330 ? [40, 60]
          : [25, 75];
  }

  public getDeletedRegistryEntry(event: any) {
    this.deleteRegistryEntry.emit(event)
  }

  public selectedNodeInPath(node: any): void {
    this.nodeSelect(node);
  }

  public nodeSelect(node: TreeNode): void {
    if (!node.expanded) {
      this.nodeExpand(node);
    } else {
      this.nodeSelect$.emit(node)
    }
  }

  public nodeExpand(node: TreeNode): void {
    node.expanded = true;
    this.nodeSelect$.emit(node)
  }

  public nodeCollapse(node: TreeNodeCollapseEvent): void {
    this.nodeCollapse$.emit(node);
  }

  public emitFilesLazyLoad(event: TableLazyLoadEvent) {
    this.filesLazyLoad.emit(event);
  }

  public emitFileLoad(file: any) {
    this.fileLoad.emit(file);
  }

  onNodeContextMenuSelect(event: TreeNodeContextMenuSelectEvent) {
    this.selectedDirectory = event.node.data;
  }
}
