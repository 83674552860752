<div style="min-height: 254px" *ngIf="registeredCompanyForm">
  <form [formGroup]="registeredCompanyForm" (ngSubmit)="submit()">
    <div class="form">
      <div class="input-box">
      <span class="p-float-label">
        <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': registeredCompanyForm.get('name')?.touched}"
               id="registered-company-name"
               type="text" pInputText formControlName="name"/>
        <label htmlFor="registered-company-name">Company name*</label>
      </span>
        <small
          id="registered-company-name-help"
          class="p-error"
          *ngIf="registeredCompanyForm.get('name')?.touched && registeredCompanyForm.get('name')?.hasError('required')">
          The company name is required.
        </small>
      </div>
      <div class="input-box">
      <span class="p-float-label">
        <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': registeredCompanyForm.get('occupation')?.touched}"
               id="registered-company-occupation"
               type="text" pInputText formControlName="occupation"/>
        <label htmlFor="registered-company-occupation">Occupation*</label>
      </span>
        <small
          id="registered-company-occupation-help"
          class="p-error"
          *ngIf="registeredCompanyForm.get('occupation')?.touched && registeredCompanyForm.get('occupation')?.hasError('required')">
          The occupation is required.
        </small>
      </div>
      <div class="input-box">
      <span class="p-float-label">
        <input [style]="{width: '100%'}"
               [ngClass]="{'ng-dirty': registeredCompanyForm.get('annualTurnover')?.touched}"
               id="registered-company-annualTurnover"
               type="text" pInputText pKeyFilter="int" placeholder="Annual turnover"
               formControlName="annualTurnover"/>
        <label htmlFor="registered-company-annualTurnover">Annual turnover*</label>
      </span>
        <small
          id="registered-company-annualTurnover-help"
          class="p-error"
          *ngIf="registeredCompanyForm.get('annualTurnover')?.touched && registeredCompanyForm.get('annualTurnover')?.hasError('required')">
          The annual turnover is required.
        </small>
      </div>
      <div class="input-box">
      <span class="p-float-label">
        <textarea [style]="{width: '100%'}"
                  [ngClass]="{'ng-dirty': registeredCompanyForm.get('description')?.touched}"
                  [autoResize]="true"
                  id="registered-company-description"
                  rows="5"
                  cols="30"
                  pInputTextarea
                  formControlName="description"
                  placeholder="Description"></textarea>
        <label htmlFor="registered-company-description">Description*</label>
      </span>
        <small
          id="registered-company-description-help"
          class="p-error"
          *ngIf="registeredCompanyForm.get('description')?.touched && registeredCompanyForm.get('description')?.hasError('required')">
          The description is required.
        </small>
      </div>

      <div class="input-box buttons">
        <p-button [style]="{width: '120px'}"
                  [disabled]="registeredCompanyForm.invalid || registeredCompanyForm.disabled" type="submit"
                  label="Save"></p-button>
      </div>
    </div>
  </form>
</div>
