<app-drawer [visible]="visible" [header]="machineName + ' Activity Logs'" (visibleChange)="onVisibleChange($event)">
  <div class="logs-wrapper">
    <div class="logs">
      <div class="flex tools header-buttons-container">
        <div class="flex align-items-center gap-2 table__action-container" (click)="refresh()">
          <i class="pi pi-refresh navigation-img"></i>
          <span class="vertical-align-middle table__action-button">Refresh</span>
        </div>
      </div>
      <app-table
        style="height: 90%"
        [value]="machineActivityLogs"
        [fields]="fields"
        [hasMenuPermission]="false"
        [lazy]="true"
        [totalRecords]="totalRecords"
        [paginator]="true"
        [rowsLimit]="MACHINE_ACTIVITY_LOGS_LIMIT"
        [showCurrentPageReport]="false"
        (onLazyLoad$)="loadActivityLogs($event)"
        [contextMenuItems]="menuItems"
        [(selectedItem)]="selectedItem"
        (selectedItemChange)="initCmItems()"
      ></app-table>
    </div>
  </div>
</app-drawer>
