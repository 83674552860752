<app-drawer class="app-drawer" [visible]="visible" [header]="machineName + ' Scan'"
            (visibleChange)="onVisibleChange($event)">

  <div *ngIf="!isReady"
       style="min-height: 78vh; display: flex; flex-direction: column; align-items: center; justify-content: center">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <div *ngIf="isReady">
    <div class="sync-configurations-wrapper">
      <div class="sync-configurations">
        <ng-container *ngFor="let category of allCategories">
          <div class="sync-configurations__item">
            <p-checkbox
              inputId="syncEnabled + {{category.id}}"
              [ngModel]="selectedCategoryIds.includes(category.id!)"
              [binary]="true"
              (onChange)="changeConfig(category.id!, $event)"
            ></p-checkbox>
            <label for="syncEnabled + {{category.id}}" class="sync-configurations__label">{{ category.name }}</label>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="popup-form__buttons">
      <p-button
        [style]="{width: '120px'}"
        [disabled]="selectedCategoryIds.length < 1 || !isOnline || dataSyncingInProgress"
        (onClick)="startSyncData()"
        type="submit"
        label="Start Scan"
      ></p-button>
    </div>
  </div>
</app-drawer>
