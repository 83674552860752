import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LOAN_SAVED_SUCCESSFULLY} from '../../../../locale/multilingual-strings-constants';
import {MachineUserLoanModel} from '../../model/machine/machine.model';
import {ValidationService} from '../../services/validation.service';
import {NotifierService} from '../../services/notifier.service';
import {PersonalDataService} from '../../../../../personal-data/src/lib/personal-data.service';

@Component({
  selector: 'app-loan-loan',
  templateUrl: './loan-popup.component.html',
  styleUrls: ['./loan-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoanPopupComponent {
  private readonly personalDataId: string;
  public loanForm: FormGroup;

  constructor(
              private ref: DynamicDialogRef,
              private fb: FormBuilder,
              private validationService: ValidationService,
              private notifierService: NotifierService,
              private config: DynamicDialogConfig,
              private cdr: ChangeDetectorRef,
              private personalDataService: PersonalDataService) {

    const loan = config.data.loan;
    this.personalDataId = config.data.personalDataId;
    this.loanForm = this.fb.group({
      id: [loan?.id],
      personalDataId: [loan?.personalDataId || this.personalDataId],
      company: [loan?.company, [Validators.required,]],
      amount: [loan?.amount, [Validators.required,]],
      from: [loan?.from ? new Date(loan?.from) : null, [Validators.required,]],
      to: [loan?.from ? new Date(loan?.to) : null, [Validators.required,]],
      percent: [loan?.percent, [Validators.required, this.validationService.validatePercent()]],
    }, {
      validators: this.validationService.validateDateRange('from', 'to'),
    });
  }

  public submit() {
    if (this.loanForm.valid) {
      this.loanForm.disable();
      const body = this.loanForm.value as MachineUserLoanModel;
      const submitRequest = this.loanForm.value.id ? this.personalDataService.updateLoan(body) :
        this.personalDataService.addLoan(body);

      submitRequest.subscribe({
        next: response => {
          if (response.succeed) {
            this.notifierService.handleSuccessRequest(LOAN_SAVED_SUCCESSFULLY);
            if (!this.loanForm.value.id) {
              this.loanForm.controls['id'].setValue(response.value);
              this.closeDialog(true);
            } else this.closeDialog(false, true);
          } else {
            this.notifierService.handleErrors(response.errors);
            this.loanForm.enable();
          }
          this.cdr.detectChanges();
        },
        error: err => {
          this.notifierService.handleRequestError(err);
          this.loanForm.enable();
          this.cdr.detectChanges();
        }
      })
    }
  }

  public closeDialog(added: boolean, edited?: boolean) {
    this.ref.close({added, edited, loan: this.loanForm.value});
  }
}
