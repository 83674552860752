import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {NotifierService} from '../../../shared/services/notifier.service';
import {TERMINAL_SAVED_SUCCESSFULLY} from '../../../../locale/multilingual-strings-constants';
import {TerminalModel} from '../../../../../organization-url-redirection-aggregator/src/lib/models/terminal.model';
import {TerminalService} from 'organization-url-redirection-aggregator/src/lib/terminal.service';

@Component({
  selector: 'app-terminal-edit',
  templateUrl: './terminal-edit.component.html',
  styleUrls: ['./terminal-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TerminalEditComponent implements OnInit {
  public terminal: TerminalModel;
  public terminalId: string;
  public terminalForm: FormGroup;
  public loading = true;

  public constructor(
    private notifierService: NotifierService,
    private terminalService: TerminalService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
  ) {
    this.terminal = config.data;
    this.terminalId = this.terminal.terminalId;
  }

  public ngOnInit(): void {
    this.setupTerminalForm();
  }

  private setupTerminalForm(): void {
    this.terminalForm = new FormGroup({
      id: new FormControl(this.terminalId),
      name: new FormControl<string>(this.terminal.name || '', [Validators.required]),
      countryCode: new FormControl<string>(this.terminal.countryCode || '', [Validators.required]),
    });
  }

  public saveTerminal() {
    this.terminalService.updateTerminal(this.terminalId, this.terminalForm.value.name, this.terminalForm.value.countryCode).subscribe({
      next: res => {
        if (res.succeed) {
          this.notifierService.handleSuccessRequest(TERMINAL_SAVED_SUCCESSFULLY);
          this.closeDialog();
        } else {
          this.notifierService.handleErrors(res.errors);
        }
      },
      error: err => this.notifierService.handleRequestError(err)
    });
  }

  public closeDialog() {
    this.ref.close({edited: true, name: this.terminalForm.value.name, terminal: this.terminal});
  }

}
