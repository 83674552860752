import {RegisterModel} from './models/register.model';
import {ChangePasswordModel} from './models/change-password.model';
import {ForgotPasswordModel} from './models/forgot-password.model';
import {Response} from './models/response.model';
import {ProfileModel} from './models/profile.model';
import {SessionModel} from './models/session.model';
import {PermissionModel, RoleModel} from './models/role.model';
import {NewResponse} from './models/response';
import {TokenModel} from './models/token.model';
import {UserResponseModel} from './models/user-response.model';
import {Inject, Injectable, InjectionToken} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {first, Observable} from 'rxjs';
import {LoginModel} from '../../../organization/src/lib/models/login.model';

export const API_URL = new InjectionToken<string>('apiUrl');

@Injectable()
export class HttpIdentityService {
  private readonly urlHealthz: string;
  private readonly urlPermissions: string;
  private readonly urlProfile: string;
  private readonly urlSessions: string;
  private readonly urlUsers: string;
  private readonly urlAccount: string;
  private readonly urlAccountManage: string;
  private readonly urlRoles: string;
  private readonly urlIdentityUserRoles: string;

  private readonly headersSkippingInterceptor: HttpHeaders;
  private readonly headers: HttpHeaders;
  private readonly optionsSkippingInterceptor: object;
  private readonly options: object;

  public constructor(private http: HttpClient, @Inject(API_URL) private apiUrl: string) {
    this.headersSkippingInterceptor = new HttpHeaders({
      'Content-Type': 'application/json',
      'skipInterceptor': 'true',
    });
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.optionsSkippingInterceptor = {
      headers: this.headersSkippingInterceptor,
      withCredentials: true
    };
    this.options = {
      headers: this.headers,
      withCredentials: true
    };

    this.urlHealthz = `${apiUrl}/identity-server/healthz`;
    this.urlPermissions = `${apiUrl}/identity/permissions`;
    this.urlProfile = `${apiUrl}/identity/account/profile/`;
    this.urlSessions = `${apiUrl}/identity/sessions`;
    this.urlUsers = `${apiUrl}/identity/users`;
    this.urlAccount = `${apiUrl}/identity/account`;
    this.urlAccountManage = `${apiUrl}/identity/account/manage/`;
    this.urlRoles = `${apiUrl}/identity/roles`;
    this.urlIdentityUserRoles = `${apiUrl}/identity/userRoles`;
  }

  // Health
  public getHealthz(): Observable<Response<string>> {
    return this.http.get<Response<string>>(`${this.urlHealthz}`, this.optionsSkippingInterceptor).pipe(first());
  }

  // Account
  public changePassword(body: ChangePasswordModel): Observable<Response<null>> {
    return this.http.put<Response<null>>(`${this.urlAccount}/editPassword`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public forgotPassword(body: ForgotPasswordModel): Observable<Response<null>> {
    return this.http.post<Response<null>>(`${this.urlAccount}/forgotPassword`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public logout(token?: TokenModel): Observable<Response<null>> {  // todo no optional parameter
    return this.http.post<Response<null>>(`${this.urlAccount}/logout`, token, this.optionsSkippingInterceptor).pipe(first());
  }

  public register(body: RegisterModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlAccount}/register`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public sendEmailConfirmation(identifier: string): Observable<Response<null>> { // todo
    return this.http.get<Response<null>>(`${this.urlAccountManage}/sendEmailConfirmation?identifier=${identifier}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public login(body: LoginModel): Observable<Response<TokenModel>> {
    return this.http.post<Response<TokenModel>>(`${this.urlAccount}/login`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  // Permissions
  public getAllPermissions(): Observable<Response<PermissionModel[]>> {
    return this.http.get<Response<PermissionModel[]>>(`${this.urlPermissions}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public addRolePermission(roleId: string, permissionId: string): Observable<Response<null>> {
    return this.http.post<Response<null>>(`${this.urlPermissions}/${permissionId}/${roleId}`, {}, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteRolePermission(roleId: string, permissionId: string): Observable<Response<string>> {
    return this.http.delete<Response<string>>(`${this.urlPermissions}/${permissionId}/${roleId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getAllPermissionsByRoleId(roleId: string): Observable<Response<PermissionModel[]>> {
    return this.http.get<Response<PermissionModel[]>>(`${this.urlPermissions}/GetAllByRoleId?roleId=${roleId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  // Roles
  public getAllRoles(page: number, size: number): Observable<NewResponse<RoleModel[]>> {
    return this.http.get<NewResponse<RoleModel[]>>(`${this.urlRoles}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public createNewRole(body: { name: string }): Observable<Response<RoleModel>> {
    return this.http.post<Response<RoleModel>>(`${this.urlRoles}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public getRoleById(roleId: string): Observable<Response<RoleModel>> {
    return this.http.get<Response<RoleModel>>(`${this.urlRoles}/${roleId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public updateRole(roleId: string, name: string): Observable<Response<string>> {
    return this.http.put<Response<string>>(`${this.urlRoles}/${roleId}`, {name}, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteRole(roleId: string): Observable<Response<string>> {
    return this.http.delete<Response<string>>(`${this.urlRoles}/${roleId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getUserRoles(userId: string, page: number, size: number): Observable<NewResponse<RoleModel[]>> {
    return this.http.get<NewResponse<RoleModel[]>>(`${this.urlRoles}/GetAllByUserId/${userId}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  // Sessions
  public getSessionById(sessionId: string): Observable<Response<SessionModel>> {
    return this.http.get<Response<SessionModel>>(`${this.urlSessions}/${sessionId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteSessionById(identitySessionId: string): Observable<Response<null>> {
    return this.http.delete<Response<null>>(`${this.urlSessions}/${identitySessionId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getAllSessionsByUserId(userId: string): Observable<Response<SessionModel[]>> {
    return this.http.get<Response<SessionModel[]>>(`${this.urlSessions}/getAllByUserId?userid=${userId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getCurrentUserSessions(): Observable<Response<SessionModel[]>> {
    return this.http.get<Response<SessionModel[]>>(`${this.urlSessions}/getCurrentUserSessions`, this.optionsSkippingInterceptor).pipe(first());
  }

  // Users
  public getUserByUserId(userId: string): Observable<Response<UserResponseModel>> {
    return this.http.get<Response<UserResponseModel>>(`${this.urlUsers}/${userId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteUserById(userId: string): Observable<Response<null>> {
    return this.http.delete<Response<null>>(`${this.urlUsers}/${userId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  ///////////////////////////////////////////////////////////////// todo delete

  public getProfileData(): Observable<Response<ProfileModel>> {
    return this.http.get<Response<ProfileModel>>(`${this.urlProfile}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public saveProfileData(body: ProfileModel): Observable<Response<null>> {
    const options = {
      // timeZone: localStorage.getItem(LOCAL_STORAGE_TIME_ZONE) || '',
    }
    body.dateOfBirth = (body.dateOfBirth as Date).toLocaleString(undefined, options);
    return this.http.put<Response<null>>(`${this.urlProfile}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public getProfileDataByUser(userId: string): Observable<Response<ProfileModel>> {
    return this.http.get<Response<ProfileModel>>(`${this.urlProfile}${userId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public saveProfileDataByUser(userId: string, body: ProfileModel): Observable<Response<null>> {
    return this.http.put<Response<null>>(`${this.urlProfile}${userId}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

}
