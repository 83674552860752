<div>
  <form [formGroup]="organizationForm" (ngSubmit)="addOrganization()">
    <div class="form">
      <div class="input-box">
        <span class="p-float-label">
          <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': organizationForm.get('name')?.touched}"
                 id="role-name"
                 type="text" pInputText formControlName="name"/>
          <label htmlFor="role-name">Name*</label>
        </span>
        <small
          id="role-name-help"
          class="p-error"
          *ngIf="organizationForm.get('name')?.touched && organizationForm.get('name')?.hasError('required')">
          The name is required.
        </small>
      </div>

      <div class="input-box buttons">
        <p-button [style]="{width: '120px'}"
                  [disabled]="saveButtonDisabled || organizationForm.invalid || organizationForm.disabled"
                  type="submit" label="Save"></p-button>
      </div>
    </div>
  </form>
</div>
