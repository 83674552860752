import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  RIGHT_BAR_EMPLOYEE,
  RIGHT_BAR_EMPLOYEE_ADDRESSES,
  RIGHT_BAR_EMPLOYEE_ASSIGNED_MACHINES,
  RIGHT_BAR_EMPLOYEE_EDUCATION,
  RIGHT_BAR_EMPLOYEE_EMAILS,
  RIGHT_BAR_EMPLOYEE_NOTES,
  RIGHT_BAR_EMPLOYEE_PHONE,
  RIGHT_BAR_EMPLOYEE_PHOTOS,
  RIGHT_BAR_EMPLOYEE_RELATIONS,
  RIGHT_BAR_EMPLOYEE_REGISTERED_COMPANIES,
  RIGHT_BAR_EMPLOYEE_REPORT,
  RIGHT_BAR_EMPLOYEE_SOCIAL_MEDIA,
  RIGHT_BAR_EMPLOYEE_WORK_EXPERIENCE,
  RIGHT_BAR_EMPLOYEE_PSYCHOLOGICAL_PORTRAITS,
  RIGHT_BAR_EMPLOYEE_SALARY,
  RIGHT_BAR_EMPLOYEE_DEPOSITS,
  RIGHT_BAR_EMPLOYEE_LOANS,
} from '../../constants';
import {NavigationService} from '../../services/navigation.service';
import {PersonalDataService} from '../../../../../personal-data/src/lib/personal-data.service';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeComponent implements OnInit {
  private _userId: string;
  private _machineId: string;
  private _child: string | null | undefined;

  @Input() set userId(value: string) {
    this._userId = this._userId || value;
  }

  get userId(): string {
    return this._userId;
  }

  @Input() set machineId(value: string) {
    this._machineId = this._machineId || value;
  }

  get machineId(): string {
    return this._machineId;
  }

  @Input() set child(value: string | null | undefined) {
    this._child = !value ? value : (value.startsWith('related') ? this._child : value);
  }

  get child() {
    return this._child;
  }

  @Input() public visible: boolean;
  public userFullName: string | undefined;

  public readonly RIGHT_BAR_EMPLOYEE_REPORT = RIGHT_BAR_EMPLOYEE_REPORT;
  public readonly RIGHT_BAR_EMPLOYEE_ADDRESSES = RIGHT_BAR_EMPLOYEE_ADDRESSES;
  public readonly RIGHT_BAR_EMPLOYEE_EDUCATION = RIGHT_BAR_EMPLOYEE_EDUCATION;
  public readonly RIGHT_BAR_EMPLOYEE_WORK_EXPERIENCE = RIGHT_BAR_EMPLOYEE_WORK_EXPERIENCE;
  public readonly RIGHT_BAR_EMPLOYEE_REGISTERED_COMPANIES = RIGHT_BAR_EMPLOYEE_REGISTERED_COMPANIES;
  public readonly RIGHT_BAR_EMPLOYEE_RELATIONS = RIGHT_BAR_EMPLOYEE_RELATIONS;
  public readonly RIGHT_BAR_EMPLOYEE_NOTES = RIGHT_BAR_EMPLOYEE_NOTES;
  public readonly RIGHT_BAR_EMPLOYEE_PSYCHOLOGICAL_PORTRAITS = RIGHT_BAR_EMPLOYEE_PSYCHOLOGICAL_PORTRAITS;
  public readonly RIGHT_BAR_EMPLOYEE_PHOTOS = RIGHT_BAR_EMPLOYEE_PHOTOS;
  public readonly RIGHT_BAR_EMPLOYEE_ASSIGNED_MACHINES = RIGHT_BAR_EMPLOYEE_ASSIGNED_MACHINES;
  public readonly RIGHT_BAR_EMPLOYEE_PHONE = RIGHT_BAR_EMPLOYEE_PHONE;
  public readonly RIGHT_BAR_EMPLOYEE_SOCIAL_MEDIA = RIGHT_BAR_EMPLOYEE_SOCIAL_MEDIA;
  public readonly RIGHT_BAR_EMPLOYEE_EMAILS = RIGHT_BAR_EMPLOYEE_EMAILS;
  public readonly RIGHT_BAR_EMPLOYEE_SALARY = RIGHT_BAR_EMPLOYEE_SALARY;
  public readonly RIGHT_BAR_EMPLOYEE_DEPOSITS = RIGHT_BAR_EMPLOYEE_DEPOSITS;
  public readonly RIGHT_BAR_EMPLOYEE_LOANS = RIGHT_BAR_EMPLOYEE_LOANS;

  constructor(
    private navigationService: NavigationService,
    private cdr: ChangeDetectorRef,
    private personalDataService: PersonalDataService
  ) {
  }

  public ngOnInit(): void {
    const isNewEmployee = !this.userId;
    if (!isNewEmployee) {
      this.personalDataService.getPersonalDataById(this.userId).subscribe(res => {
        if (res.succeed) {
          const user = res.value;
          const fn = user?.firstName;
          const ln = user?.lastName;
          this.userFullName = (fn && ln ? fn + ' ' + ln : fn ? fn : ln ? ln : '') + ' ';
        }
        this.cdr.detectChanges();
      });
    }
  }

  public onVisibleChange(event: boolean) {
    if (!event) {
      this.navigationService.closeRightBarPage(RIGHT_BAR_EMPLOYEE);
      this.userFullName = undefined;
    }
  }

  public getHeader(): string {
    return this.child ? this.child.split('-').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ') :
      'Personal Data';
  }
}
