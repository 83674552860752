import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'byteNormalizer'
})
export class ByteNormalizerPipe implements PipeTransform {

  transform(bytes: number): string {
    if (!bytes || isNaN(bytes)) return '-';
    if (bytes === 0) return '0 B';

    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return (bytes / Math.pow(k, i)).toFixed(2) + ' ' + sizes[i];
  }
}
