import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appLongPress]'
})
export class LongPressDirective {
  @Output() longPress = new EventEmitter<TouchEvent>();
  private timeout: number;

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent): void {
    this.timeout = setTimeout(() => {
      this.longPress.emit(event);
      event.preventDefault();
    }, 500);
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd(event: TouchEvent): void {
    clearTimeout(this.timeout);
  }

  @HostListener('touchmove')
  onTouchMove(): void {
    clearTimeout(this.timeout);
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeout);
  }
}
