import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Message, MessageService} from 'primeng/api';

@Injectable()
export class ToastService {
  constructor(messageService: MessageService) {
    this.message$.subscribe(m => {
      messageService.add(m);
    })
  }

  public message$ = new Subject<Message>();
}
