<p-sidebar
  [visible]="visible"
  [modal]="false"
  [style]="{top: '50px',
   width: leftBarVisible ? (onlyIcons ? mostWidth : halfWidth) : fullWidth,
   transition: 'width 150ms cubic-bezier(0, 0, 0.2, 1)',}"
  (visibleChange)="onVisibleChange($event)"
  position="right"
>
  <ng-template pTemplate="header">
    <div class="header-content-wrapper">
      <div>
        <ng-content select="[HeaderGraphics]"></ng-content>
      </div>
      <h2 class="sidebar__header-title">{{header}}</h2>
      <div class="sidebar__header-content">
        <ng-content select="[HeaderButtonExtras]"></ng-content>
      </div>
    </div>
  </ng-template>
  <ng-content></ng-content>
</p-sidebar>
