<app-drawer [visible]="visible" [header]="userName + ' User Sessions'" (visibleChange)="onVisibleChange($event)">
  <div class="wrapper">
  <div class="table-wrapper">
    <div class="flex tools header-buttons-container">
      <div class="flex align-items-center gap-2 table__action-container" (click)="refresh()">
        <i class="pi pi-refresh navigation-img"></i>
        <span class="vertical-align-middle table__action-button">Refresh</span>
      </div>
    </div>
    <div class="user-sessions-table">
      <app-table style="height: 100%"
                 [value]="sessions"
                 [fields]="fields"
                 [hasMenuPermission]="hasDeletePermission"
                 [menuItems]="items"
                 [paginator]="true"
                 [rowsLimit]="USER_SESSIONS_LIMIT"
                 [totalRecords]="totalRecords"
                 [loading]="loading"
                 (onToggleMenu$)="initMenuItems($event)"
                 (onPage$)="initLimit($event)"
                 [contextMenuItems]="items"
                 [(selectedItem)]="selectedSession"
                 (selectedItemChange)="initMenuItems(selectedSession)"
      ></app-table>
    </div>
  </div>
  </div>
</app-drawer>
