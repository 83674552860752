<div style="min-height: 254px" *ngIf="workExperienceForm">
  <form [formGroup]="workExperienceForm" (ngSubmit)="submit()">
    <div class="form">
      <div class="input-box">
      <span class="p-float-label">
        <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': workExperienceForm.get('organization')?.touched}"
               id="work-experience-organization"
               type="text" pInputText formControlName="organization"/>
        <label htmlFor="work-experience-organization">Organization*</label>
      </span>
        <small
          id="work-experience-organization-help"
          class="p-error"
          *ngIf="workExperienceForm.get('organization')?.touched && workExperienceForm.get('organization')?.hasError('required')">
          The organization is required.
        </small>
      </div>
      <div class="input-box">
      <span class="p-float-label">
        <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': workExperienceForm.get('occupation')?.touched}"
               id="work-experience-occupation"
               type="text" pInputText formControlName="occupation"/>
        <label htmlFor="work-experience-occupation">Occupation*</label>
      </span>
        <small
          id="work-experience-occupation-help"
          class="p-error"
          *ngIf="workExperienceForm.get('occupation')?.touched && workExperienceForm.get('occupation')?.hasError('required')">
          The occupation is required.
        </small>
      </div>
      <div class="input-box">
      <span class="p-float-label">
        <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': workExperienceForm.get('contacts')?.touched}"
               id="contacts"
               type="text" pInputText formControlName="contacts"/>
        <label htmlFor="contacts">Contacts*</label>
      </span>
        <small
          id="contacts-help"
          class="p-error"
          *ngIf="workExperienceForm.get('contacts')?.touched && workExperienceForm.get('contacts')?.hasError('required')">
          The contacts is required.
        </small>
      </div>
      <div class="input-box">
      <span class="p-float-label">
        <div style="min-width: 100%;" [ngClass]="{'ng-dirty': workExperienceForm.get('from')?.touched}">
          <span [ngClass]="{'invalid-label': workExperienceForm.get('from')?.touched && workExperienceForm.get('from')?.invalid}" htmlFor="to" id="fromLabel">From</span>
            <p-calendar
              [style]="{width: '100%'}"
              id="from"
              [showIcon]="true"
              formControlName="from"
              [appendTo]="'body'"
              placeholder="From"
              ariaLabelledBy="fromLabel"
            ></p-calendar>
        </div>
      </span>
        <small
          id="from-help"
          class="p-error"
          *ngIf="workExperienceForm.get('from')?.touched && workExperienceForm.get('from')?.hasError('required')">
          The field is required.
        </small>
      </div>
      <div class="input-box">
      <span class="p-float-label">
       <div style="min-width: 100%;" [ngClass]="{'ng-dirty': workExperienceForm.get('to')?.touched}">
         <span [ngClass]="{'invalid-label': workExperienceForm.get('to')?.touched && !workExperienceForm.get('to')?.valid}" htmlFor="to" id="toLabel">To</span>
          <p-calendar
            [style]="{width: '100%'}"
            id="to"
            [showIcon]="true"
            [appendTo]="'body'"
            formControlName="to"
            placeholder="To"
            ariaLabelledBy="toLabel"
          ></p-calendar>
       </div>
      </span>
        <small
          id="to-help"
          class="p-error"
          *ngIf="workExperienceForm.get('to')?.touched">
          <ng-container *ngIf="workExperienceForm.get('to')?.hasError('required')">The field is required.</ng-container>
          <ng-container *ngIf="workExperienceForm.get('to')?.hasError('dateRangeError')">"From" must be less than "To".
          </ng-container>
        </small>
      </div>

      <div class="input-box buttons">
        <p-button [style]="{width: '120px'}" [disabled]="workExperienceForm.invalid || workExperienceForm.disabled" type="submit"
                  label="Save"></p-button>
      </div>
    </div>
  </form>
</div>

