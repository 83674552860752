<header class="header">
  <div class="header-content">
    <div
      class="btn"
      (click)="toggleLeftSidebar()">
      <i class="pi pi-bars left-bar-toggle"></i>
    </div>
    <div class="user-info">
      <div class="theme-toggle">
        <label class="theme-option light" [class.selected]="selectedTheme === 'light'" (click)="toggleTheme('light')">
          <i class="pi pi-sun"></i>
        </label>
        <label class="theme-option dark" [class.selected]="selectedTheme === 'dark'" (click)="toggleTheme('dark')">
          <i class="pi pi-moon"></i>
        </label>
      </div>

<!--      <div style="width: 40px">-->
<!--        <p-dropdown [appendTo]="'body'"-->
<!--                    [style]="{borderRadius: '25px', width: '100%', height: '38px', paddingLeft: '15px', display: 'flex', alignItems: 'center',}"-->
<!--                    (onChange)="onLanguageChange($event.value)"-->
<!--                    [(ngModel)]="selectedLanguage"-->
<!--                    [options]="languages"-->
<!--                    id="languageDropdown"-->
<!--                    optionLabel="threeLetterISOLanguageName">-->
<!--          <ng-template pTemplate="selectedItem">-->
<!--            <div style="display: flex; align-items: center; justify-content: center">-->
<!--              <img class="no-user-selection" width="20px" [alt]="selectedLanguage.threeLetterISOLanguageName"-->
<!--                   [src]="'assets/languages/' + selectedLanguage.twoLetterISOLanguageName + '.png'">-->
<!--            </div>-->
<!--          </ng-template>-->

<!--          <ng-template let-language pTemplate="item">-->
<!--            <div class="flex flex-row align-items-center gap-2 header-dropdown-overlay-lang">-->
<!--              <div class="flag-container">-->
<!--                <img class="no-user-selection" width="20px"-->
<!--                     [src]="'assets/languages/' + language.twoLetterISOLanguageName + '.png'">-->
<!--              </div>-->
<!--              <div class="flex align-items-center gap-2">-->
<!--                <div>{{ language.threeLetterISOLanguageName | uppercase }}</div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </ng-template>-->
<!--        </p-dropdown>-->
<!--      </div>-->

      <p-splitButton
        styleClass="p-button-sm"
        [label]="username"
        [model]="items"
        (onClick)="openProfile()"
        [appendTo]="'body'"
      ></p-splitButton>
    </div>
  </div>
</header>
