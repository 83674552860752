<div style="min-height: 254px" *ngIf="noteForm ">
  <form [formGroup]="noteForm" (ngSubmit)="submit()">
    <div class="form">
      <div class="input-box">
      <span class="p-float-label">
        <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': noteForm.get('subject')?.touched}"
               id="subject"
               type="text" pInputText formControlName="subject"/>
        <label htmlFor="subject">Subject</label>
      </span>
        <small
          id="subject-help"
          class="p-error"
          *ngIf="noteForm.get('subject')?.touched && noteForm.get('subject')?.hasError('required')">
          The subject is required.
        </small>
      </div>
      <div class="input-box">
      <span class="p-float-label">

        <textarea [style]="{width: '100%'}"
                  [ngClass]="{'ng-dirty': noteForm.get('value')?.touched}"
                  [autoResize]="true"
                  id="value"
                  rows="5"
                  cols="30"
                  pInputTextarea
                  formControlName="value"
                  ></textarea>
        <label htmlFor="value">Description</label>
      </span>
        <small
          id="value-help"
          class="p-error"
          *ngIf="noteForm.get('value')?.touched && noteForm.get('value')?.hasError('required')">
          The description is required.
        </small>
      </div>


      <div class="input-box buttons">
        <p-button [style]="{width: '120px'}"
                  [disabled]="noteForm.invalid || noteForm.disabled" type="submit"
                  label="Save"></p-button>
      </div>
    </div>
  </form>
</div>
