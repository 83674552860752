import {Injectable} from '@angular/core';
import {DeletePopupDataModel} from '../model/delete-popup-data-model/delete-popup-data.model';

@Injectable({
  providedIn: 'root',
})
export class DataConversionService {
  public convertData(originalData: any[], names: Record<string, string>): DeletePopupDataModel[] {
    const convertedData: DeletePopupDataModel[] = [];

    originalData.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (names[key]) {
          let value = item[key];
          const isValueArray = Array.isArray(value)
          if (isValueArray) {
            value = value.map((obj: { name: string }) => obj.name);
          } else if (typeof value === 'object') {
            value = value.value;
          }
          convertedData.push({
            name: names[key],
            value: value,
            isValueArray: isValueArray,
          });
        }
      });
    });

    return convertedData;
  }
}
