import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {HttpDataService} from '../../../services/http/http-data.service';
import {MAX_PAGING_LIMIT} from '../../../constants';
import {zip} from 'rxjs';
import {MachineUserModel} from '../../../model/machine/machine.model';
import {ToastService} from '../../../services/toast.service';
import {NotifierService} from '../../../services/notifier.service';
import {RelationModel} from '../../../model/employee/relation.model';
import {PersonalDataService} from '../../../../../../personal-data/src/lib/personal-data.service';

@Component({
  selector: 'app-employee-report',
  templateUrl: './employee-report.component.html',
  styleUrls: ['./employee-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeReportComponent implements OnInit {
  public user: MachineUserModel;
  public employeeInfoOptions: Button[] = [
    {key: 'showPI', value: true, name: 'Personal information'},
    {key: 'showAddresses', value: true, name: 'Addresses'},
    {key: 'showContacts', value: true, name: 'Contacts'},
    {key: 'showSocialMedia', value: true, name: 'Social Media'},
    {key: 'showEd', value: true, name: 'Education'},
    {key: 'showWE', value: true, name: 'Work experience'},
    {key: 'showFI', value: true, name: 'Financial information'},
    {key: 'showRC', value: true, name: 'Companies'},
    {key: 'showCWP', value: true, name: 'Relations'},
    {key: 'showPsychP', value: true, name: 'Psychological portraits'},
    {key: 'showNotes', value: true, name: 'Notes'},
  ];
  public showPI = true;
  public showAddresses = true;
  public showEd = true;
  public showContacts = true;
  public showSocialMedia = true;
  public showWE = true;
  public showFI = true;
  public showRC = true;
  public showCWP = true;
  public showNotes = true;
  public showPsychP = true;

  @Input() public userId: string;
  @ViewChild('printableContent') printableContent: ElementRef;

  constructor(
    private httpDataService: HttpDataService,
    private cdr: ChangeDetectorRef,
    private toastService: ToastService,
    private notifierService: NotifierService,
    private personalDataService: PersonalDataService
  ) {
  }

  public ngOnInit(): void {
    this.personalDataService.getMachineUserById(this.userId).subscribe(res => {
      if (res.succeed) {
        this.user = res.value;
        this.initUserData();
      }
    });
  }

  public toggleState(option: Button) {
    option.value = !option.value;
    option.key === 'showPI' && (this.showPI = option.value);
    option.key === 'showAddresses' && (this.showAddresses = option.value);
    option.key === 'showEd' && (this.showEd = option.value);
    option.key === 'showContacts' && (this.showContacts = option.value);
    option.key === 'showSocialMedia' && (this.showSocialMedia = option.value);
    option.key === 'showWE' && (this.showWE = option.value);
    option.key === 'showFI' && (this.showFI = option.value);
    option.key === 'showRC' && (this.showRC = option.value);
    option.key === 'showCWP' && (this.showCWP = option.value);
    option.key === 'showNotes' && (this.showNotes = option.value);
    option.key === 'showPsychP' && (this.showPsychP = option.value);
  }

  public print() {
    const printElement = this.printableContent.nativeElement;
    const rootComponent = document.querySelector('app-root') as HTMLElement;
    const printableSection = printElement.cloneNode(true) as HTMLDivElement;
    printableSection.id = 'section-to-print';

    document.body.append(printableSection);
    rootComponent.style.display = 'none';
    window.print();
    document.getElementById('section-to-print')?.remove();
    rootComponent.style.display = 'inline';
  }

  private initUserData() {
    zip(
      this.personalDataService.getAllAddresses(this.userId, 1, MAX_PAGING_LIMIT),
      this.personalDataService.getAllEducations(this.userId, 1, MAX_PAGING_LIMIT),
      this.personalDataService.getAllEmails(this.userId, 1, MAX_PAGING_LIMIT),
      this.personalDataService.getPersonalDataPhoneNumbers(this.userId, 1, MAX_PAGING_LIMIT),
      this.personalDataService.getPersonalDataSocialMedia(this.userId, 1, MAX_PAGING_LIMIT),
      this.personalDataService.getAllWorks(this.userId, 1, MAX_PAGING_LIMIT),
      this.personalDataService.getAllRegisteredCompanies(this.userId, 1, MAX_PAGING_LIMIT),
      // this.httpDataService.getMachineUserRelations(this.userId, 1, MAX_PAGING_LIMIT),
      this.personalDataService.getPsychologicalPortraits(this.userId, 1, MAX_PAGING_LIMIT),
      this.personalDataService.getMachineUserNotes(this.userId, 1, MAX_PAGING_LIMIT),
      this.personalDataService.getAllSalaries(this.userId, 1, MAX_PAGING_LIMIT),
      this.personalDataService.getAllDeposits(this.userId, 1, MAX_PAGING_LIMIT),
      this.personalDataService.getMachineUserLoans(this.userId, 1, MAX_PAGING_LIMIT),
    ).subscribe(([addressesRes, educationsRes, emailsRes, phoneNumbersRes, socialMediaRes,
                   worksRes, companiesRes, psychPortraitsRes, notesRes, salariesRes,
                   depositsRes, loansRes]) => {
      if (addressesRes.succeed) {
        this.user.addresses = addressesRes.value.entities;
      }
      if (educationsRes.succeed) {
        this.user.educations = educationsRes.value.entities;
      }
      if (emailsRes.succeed) {
        this.user.emails = emailsRes.value.entities;
      }
      if (phoneNumbersRes.succeed) {
        this.user.phoneNumbers = phoneNumbersRes.value.entities;
      }
      if (socialMediaRes.succeed) {
        this.user.socialMedias = socialMediaRes.value.entities;
      }
      if (worksRes.succeed) {
        this.user.works = worksRes.value.entities;
      }
      if (companiesRes.succeed) {
        this.user.registeredCompanies = companiesRes.value.entities;
      }
      // if (relationsRes.succeed) {
      //   this.initRelations(relationsRes.value);
      // }
      if (psychPortraitsRes.succeed) {
        this.user.psychoPortraits = psychPortraitsRes.value.entities;
      }
      if (notesRes.succeed) {
        this.user.notes = notesRes.value.entities;
      }
      if (salariesRes.succeed) {
        this.user.salaries = salariesRes.value.entities;
      }
      if (depositsRes.succeed) {
        this.user.deposits = depositsRes.value.entities;
      }
      if (depositsRes.succeed) {
        this.user.deposits = depositsRes.value.entities;
      }
      if (loansRes.succeed) {
        this.user.loans = loansRes.value.entities;
      }
      this.cdr.detectChanges();
    });
  }

  private initRelations(relations: RelationModel[]) {
    if (relations.length) {
      zip(...relations.map(r => this.personalDataService.getMachineUserById(r.childId || '')))
        .subscribe({
          next: responses => {
            if (!responses.some(res => !res.succeed)) {
              // this.user.relations = responses.map(response => {
              //   const machineUser = response.value;
              //   return {
              //     ...machineUser,
              //     relationType: relations.find(rel => rel.childId === machineUser.id)?.relationType || '',
              //   };
              // });
              this.cdr.detectChanges();
            } else {
              this.toastService.message$.next({severity: 'error', summary: 'Failure', detail: 'An Error occurred.',});
            }
          },
          error: err => this.notifierService.handleRequestError(err),
        });
    } else {
      this.user.relations = [];
      this.cdr.detectChanges();
    }
  }
}

interface Button {
  key: string;
  name: string;
  value: boolean;
}
