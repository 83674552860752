import {ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {inject} from '@angular/core';
import {AuthService} from '../services/auth.service';

export const canActivate: CanActivateFn = () => {
  const authService = inject(AuthService);
  const isAuthenticated = authService.isAuthenticated();
  if (!isAuthenticated) {
    const router = inject(Router);
    router.navigate(['/login']).then();
  }
  return isAuthenticated;
};

export const canActivateChild: CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => canActivate(route, state);
