<div class="assigned-machines">
  <div class="flex gap-4 tools header-buttons-container">
    <div class="flex align-items-center gap-2 table__action-container" (click)="refresh()">
      <i class="pi pi-refresh navigation-img"></i>
      <span class="vertical-align-middle table__action-button">Refresh</span>
    </div>
  </div>

  <div class="users-table">
    <app-table
      #assignedMachinesTableComponent
      [value]="machines"
      [lazy]="true"
      [fields]="fields"
      [paginator]="true"
      [hasMenuPermission]="true"
      [totalRecords]="totalRecords"
      [menuItems]="menuItems"
      [rowsLimit]="ASSIGNED_MACHINES"
      [loading]="loading"
      (onLazyLoad$)="initData($event)"
      (onToggleMenu$)="initMenuItems($event)"
      [contextMenuItems]="menuItems"
      [(selectedItem)]="selectedAssignMachine"
      (selectedItemChange)="initCmItems()"
    ></app-table>
  </div>
</div>
