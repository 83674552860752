<div style="min-height: 254px" *ngIf="addressForm ">
  <form [formGroup]="addressForm" (ngSubmit)="submit()">
    <div class="form">
      <div class="input-box">
      <span class="p-float-label">
        <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': addressForm.get('country')?.touched}"
               id="address-country"
               type="text" pInputText formControlName="country"/>
        <label htmlFor="address-country">Country*</label>
      </span>
        <small
          id="country-help"
          class="p-error"
          *ngIf="addressForm.get('country')?.touched && addressForm.get('country')?.hasError('required')">
          The country is required.
        </small>
      </div>
      <div class="input-box">
      <span class="p-float-label">
        <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': addressForm.get('state')?.touched}" id="address-state"
               type="text" pInputText formControlName="state"/>
        <label htmlFor="address-state">State*</label>
      </span>
        <small
          id="state-help"
          class="p-error"
          *ngIf="addressForm.get('state')?.touched && addressForm.get('state')?.hasError('required')">
          The state is required.
        </small>
      </div>
      <div class="input-box">
      <span class="p-float-label">
        <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': addressForm.get('city')?.touched}" id="address-city"
               type="text" pInputText formControlName="city"/>
        <label htmlFor="address-city">City*</label>
      </span>
        <small
          id="city-help"
          class="p-error"
          *ngIf="addressForm.get('city')?.touched && addressForm.get('city')?.hasError('required')">
          The city is required.
        </small>
      </div>
      <div class="input-box">
      <span class="p-float-label">
        <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': addressForm.get('streetAddress')?.touched}"
               id="address-streetAddress"
               type="text" pInputText formControlName="streetAddress"/>
        <label htmlFor="address-streetAddress">Street Address*</label>
      </span>
        <small
          id="streetAddress-help"
          class="p-error"
          *ngIf="addressForm.get('streetAddress')?.touched && addressForm.get('streetAddress')?.hasError('required')">
          The street address is required.
        </small>
      </div>
      <div class="input-box">
      <span class="p-float-label">
        <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': addressForm.get('postalCode')?.touched}"
               id="address-postalCode"
               type="text" pInputText formControlName="postalCode"/>
        <label htmlFor="address-postalCode">Postal Code*</label>
      </span>
        <small
          id="postalCode-help"
          class="p-error"
          *ngIf="addressForm.get('postalCode')?.touched && addressForm.get('postalCode')?.hasError('required')">
          The postal code is required.
        </small>
      </div>

      <div class="input-box">
        <span class="p-float-label">
          <textarea [style]="{width: '100%'}"
                    [ngClass]="{'ng-dirty': addressForm.get('description')?.touched}"
                    [autoResize]="true"
                    id="address-description"
                    rows="5"
                    cols="30"
                    pInputTextarea
                    formControlName="description"
          ></textarea>
          <label htmlFor="address-description">Description*</label>
        </span>
        <small
          *ngIf="addressForm.get('description')?.touched && addressForm.get('description')?.hasError('required')"
          id="address-description-help"
          class="p-error">
          The description is required.
        </small>
      </div>

      <div class="input-box buttons">
        <p-button [style]="{width: '120px'}" [disabled]="addressForm.invalid || addressForm.disabled" type="submit"
                  label="Save"></p-button>
      </div>
    </div>
  </form>
</div>

