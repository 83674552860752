import {Injectable} from '@angular/core';
import {AbstractControl, ValidatorFn} from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  public validateDateRange(fromControlName: string, toControlName: string): ValidatorFn {
    return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
      const fromControl = formGroup.get(fromControlName);
      const toControl = formGroup.get(toControlName);

      if (fromControl && toControl && fromControl.value && toControl.value) {
        const fromDate = new Date(fromControl.value);
        const toDate = new Date(toControl.value);

        if (toDate < fromDate) {
          toControl.setErrors({dateRangeError: true});
          return {dateRangeError: true};
        } else {
          if (toControl.hasError('dateRangeError')) {
            if (toControl.errors) {
              delete toControl.errors['dateRangeError'];
              if (!Object.keys(toControl.errors).length) {
                toControl.setErrors(null);
              }
            }
          }
        }
      }

      return null;
    };
  }

  public validatePercent(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const percentValue = control.value;

      if (percentValue != null) {
        if (percentValue < 0 || percentValue > 100) {
          return {percentRangeError: true};
        } else {
          if (control.hasError('percentRangeError')) {
            if (control.errors) {
              delete control.errors['percentRangeError'];
              if (!Object.keys(control.errors).length) {
                control.setErrors(null);
              }
            }
          }
        }
      }

      return null;
    };
  }
}
