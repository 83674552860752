<app-drawer [visible]="visible" [header]="userFullName!==undefined ? userFullName + getHeader() : ''"
            (visibleChange)="onVisibleChange($event)">

  <div *ngIf="userFullName===undefined"
       style="height: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <div *ngIf="userFullName!==undefined" class="wrapper">
      <app-employee-report *ngIf="visible && child === RIGHT_BAR_RELATED_EMPLOYEE_REPORT"
                           [userId]="userId"
      ></app-employee-report>

      <app-addresses *ngIf="visible && child === RIGHT_BAR_RELATED_EMPLOYEE_ADDRESSES"
                     [userId]="userId"
      ></app-addresses>

      <app-education *ngIf="visible && child === RIGHT_BAR_RELATED_EMPLOYEE_EDUCATION"
                     [userId]="userId"
      ></app-education>

      <app-phone-number *ngIf="visible && child === RIGHT_BAR_RELATED_EMPLOYEE_PHONE"
                        [userId]="userId"
      ></app-phone-number>

      <app-social-media *ngIf="visible && child === RIGHT_BAR_RELATED_EMPLOYEE_SOCIAL_MEDIA"
                        [userId]="userId"
      ></app-social-media>

      <app-emails *ngIf="visible && child === RIGHT_BAR_RELATED_EMPLOYEE_EMAILS"
                  [userId]="userId"
      ></app-emails>

      <app-work-experience *ngIf="visible && child === RIGHT_BAR_RELATED_EMPLOYEE_WORK_EXPERIENCE"
                           [userId]="userId"
      ></app-work-experience>

      <app-deposits *ngIf="visible && child === RIGHT_BAR_RELATED_EMPLOYEE_DEPOSITS"
                    [userId]="userId"
      ></app-deposits>

      <app-salary *ngIf="visible && child === RIGHT_BAR_RELATED_EMPLOYEE_SALARY"
                  [userId]="userId"
      ></app-salary>

      <app-loans *ngIf="visible && child === RIGHT_BAR_RELATED_EMPLOYEE_LOANS"
                 [userId]="userId"
      ></app-loans>

      <app-registered-companies *ngIf="visible && child === RIGHT_BAR_RELATED_EMPLOYEE_REGISTERED_COMPANIES"
                                [userId]="userId"
      ></app-registered-companies>

      <app-notes *ngIf="visible && child === RIGHT_BAR_RELATED_EMPLOYEE_NOTES"
                 [userId]="userId"
      ></app-notes>

      <app-assigned-machines *ngIf="visible && child === RIGHT_BAR_RELATED_EMPLOYEE_ASSIGNED_MACHINES"
                             [userId]="userId"
      ></app-assigned-machines>

      <app-psychological-portraits
        *ngIf="visible && child === RIGHT_BAR_RELATED_EMPLOYEE_PSYCHOLOGICAL_PORTRAITS"
        [userId]="userId"
      ></app-psychological-portraits>

      <app-employee-photos *ngIf="visible && child === RIGHT_BAR_RELATED_EMPLOYEE_PHOTOS"
      ></app-employee-photos>
  </div>
</app-drawer>
