import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {ShellWebSocketService} from '../../../shared/services/shell-web-socket.service';
import {TreeNode} from 'primeng/api';

@Injectable()
export class RegistryEditorService {
  public onLoaded$: Subject<any> = new Subject();
  public directories$: Subject<any> = new Subject();
  public files$: Subject<any> = new Subject();
  public rootDirectories$: Subject<any> = new Subject();
  public emptyData: Subject<void> = new Subject();

  public machineId: string;
  public rootDirectories = {
    HKCR: 'KEY_CLASSES_ROOT',
    HKCU: 'HKEY_CURRENT_USER',
    HKLM: 'HKEY_LOCAL_MACHINE',
    HKU: 'HKEY_USERS',
    HKCC: 'HKEY_CURRENT_CONFIG'
  }


  constructor(private webSocketService: ShellWebSocketService) {
    this.webSocketService.dataReceived$.subscribe({
      next: (dataView) => this.processIncomingData(dataView),
      error: (err: string) => console.error('WebSocket error:', err)
    });

    this.webSocketService.onLoaded$.subscribe(() => this.loadRootDirectories());
  }

  private processIncomingData(dataView: DataView): void {
    const textDecoder = new TextDecoder('utf-8');
    let decodedString = textDecoder.decode(dataView);

    // Remove the reg query command lines
    decodedString = decodedString.replace(/reg query[^\n]*\n?/g, '');

    const lines = decodedString.split('\n').filter(line => line.trim() !== '');

    for (let i = 0; i < lines.length; i++) {
      if (lines[i].startsWith('HKEY_') && i + 1 < lines.length && lines[i + 1].startsWith('  ')) {
        lines.shift();
      }else  {
        this.emptyData.next()
      }
    }
    this.processRegistryData(lines);
  }

  private processRegistryData(lines: string[]): void {
    const directories = lines.filter(line => line.startsWith('HKEY_'));
    const files = lines.filter(line => /REG_[A-Z_]+/.test(line));

    if (directories.length) {
      this.handleChildDirectories(directories);
    }

    if (files.length) {
      this.handleFiles(files);
    }
  }

  private handleChildDirectories(data: string[]): void {
    const lines = data.filter(line => line.trim() !== '');
    const filteredLines = lines.filter(line => line.startsWith('HKEY_'));
    const directories = filteredLines.map(line => ({
      key: line,
      label: line.split('\\').pop(),
      type: 'child',
      leaf: false,
      children: []
    }));
    this.directories$.next({directories});
  }

  private handleFiles(data: string[]) {
    const lines = data.filter(line => line.trim() !== '');
    const registryTypes = ['REG_SZ', 'REG_DWORD', 'REG_QWORD', 'REG_BINARY', 'REG_MULTI_SZ', 'REG_EXPAND_SZ'];
    const files = lines.reduce((acc: any[], line) => {
      const trimmedLine = line.trim();
      const match = trimmedLine.match(/^(.*?)\s+(REG_[A-Z_]+)\s+(.*)$/);

      if (match && registryTypes.includes(match[2])) {
        acc.push({
          name: {fieldLoaded: true, value: match[1]},
          type: match[2],
          data: match[3]
        });
      }
      return acc;
    }, []);

    this.files$.next({files});
  }

  public connectToMachine(streamId: string): void {
    this.webSocketService.connectWithWebsocket(streamId);
  }

  public closeSocket(): void {
    this.webSocketService.closeSocket();
  }

  public loadRootDirectories(): void {
    this.rootDirectories$.next(this.getRootDirectories());
  }


  private getRootDirectories(): TreeNode[] {
    return (Object.keys(this.rootDirectories) as Array<keyof typeof this.rootDirectories>).map(key => ({
      key,
      label: this.rootDirectories[key],
      type: 'root',
      leaf: false,
      children: []
    }));
  }


  public loadChildDirectories(key: string): void {
    this.webSocketService.sendMessage(`reg query ${key}`);
  }

}
