import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {HttpDataService} from '../../services/http/http-data.service';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {EMPLOYEE_CREATED_SUCCESSFULLY,} from '../../../../locale/multilingual-strings-constants';
import {TreeNode} from 'primeng/api';
import {SOCIAL_MEDIA_NAMES} from '../../constants';
import {PersonalDataModel} from '../../../../../personal-data/src/lib/models/personal-data.model';
import {NotifierService} from '../../services/notifier.service';
import {UtilService} from '../../services/util.service';
import {UserRequestModel} from '../../model/user/user-request.model';
import {SocialMediaSearchModel} from '../../model/machine/machine.model';
import {
  OrganizationPersonalDataAggregatorService
} from '../../../../../organization-personal-data-aggregator/src/lib/organization-personal-data-aggregator.service';

@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEmployeeComponent implements OnInit {
  public machineId = '';
  public genders: { name: string }[];
  public isFilter = false;
  public socialMediaNames = SOCIAL_MEDIA_NAMES;
  public maxBirthDate: Date;

  public employeeProfileForm = this.fb.group({
    id:  [null as string | null],
    identifier: [null],
    machineId: [null],
    firstName: [null],
    lastName: [null],
    fatherName: [null],
    employeeId: [null],
    dateOfBirth: [null],
    placeOfBirth: [null],
    gender: [{name: null}],
    status: [null],
    organizationId: [new FormControl<TreeNode | null>(null), Validators.required],
  });

  public socialMediaFilterForm = this.fb.group({
    name: [null,],
    accountId: [null,],
  });

  constructor(private fb: FormBuilder,
              private httpDataService: HttpDataService,
              private notifierService: NotifierService,
              private config: DynamicDialogConfig,
              private ref: DynamicDialogRef,
              private cdr: ChangeDetectorRef,
              private utilService: UtilService,
              private orgPersonalDataService: OrganizationPersonalDataAggregatorService
  ) {
    this.isFilter = !!config.data.isFilter;
    this.employeeProfileForm.get('machineId')?.setValue(config.data.machineId);
    if (this.isFilter) {
      this.employeeProfileForm.get('organizationId')?.setValidators([]);
    }
    this.machineId = config.data.machineId;
  }

  public ngOnInit(): void {
    this.maxBirthDate = new Date();
    this.genders = this.utilService.getGenders();
    this.employeeProfileForm.controls['organizationId'].setValue(null);
  }

  public onSubmit(filterWithSocialMedia = false) {
    if (!this.isFilter) {
      this.addEmployee();
      return;
    }
    if (filterWithSocialMedia) {
      this.closeDialog({
        formValue: this.socialMediaFilterForm.value as unknown as SocialMediaSearchModel,
        filterWithSocialMedia
      }, false, true);
      return;
    }
    if (this.employeeProfileForm.controls['organizationId'].value) {
      this.employeeProfileForm.controls['organizationId']
        .setValue(this.employeeProfileForm.controls['organizationId'].value?.data);
    }
    this.closeDialog({formValue: this.employeeProfileForm.value as UserRequestModel}, false, true);
  }

  private addEmployee() {
    if (this.employeeProfileForm.valid) {
      this.employeeProfileForm.disable();
      this.employeeProfileForm.value && (this.employeeProfileForm.value.organizationId = this.employeeProfileForm.value.organizationId?.data);
      // this.employeeProfileForm.value && (this.employeeProfileForm.value.gender = this.employeeProfileForm.value.gender!.name);
      const body = this.employeeProfileForm.value as PersonalDataModel;
      const request$ = this.machineId ? this.httpDataService.createMachineUserWithMachineId(body, this.machineId) :
        this.orgPersonalDataService.createPersonalData(this.employeeProfileForm.value.organizationId as string, body);

      request$.subscribe({
        next: response => {
          if (response.succeed) {
            this.notifierService.handleSuccessRequest(EMPLOYEE_CREATED_SUCCESSFULLY);
            this.employeeProfileForm.get('id')?.setValue(response.value);
            this.closeDialog({createdSuccessfully: true, formValue: this.employeeProfileForm.value as UserRequestModel}, false, true);
          } else {
            this.notifierService.handleErrors(response.errors);
            this.employeeProfileForm.enable();
          }
          this.cdr.detectChanges();
        },
        error: err => {
          this.notifierService.handleRequestError(err);
          this.employeeProfileForm.enable();
          this.cdr.detectChanges();
        }
      })
    }
  }

  public closeDialog(data: {
    createdSuccessfully?: boolean,
    formValue?: SocialMediaSearchModel | UserRequestModel,
    filterWithSocialMedia?: boolean
  },  edited: boolean, added: boolean) {
    this.ref.close({ edited, added,  personalInfo: data});
  }

}
