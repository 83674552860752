import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {PERSONAL_INFO_UPDATED_SUCCESSFULLY,} from '../../../../locale/multilingual-strings-constants';
import {NotifierService} from '../../services/notifier.service';
import {UtilService} from '../../services/util.service';
import {MachineUserModel} from '../../model/machine/machine.model';
import {PersonalDataService} from '../../../../../personal-data/src/lib/personal-data.service';

@Component({
  selector: 'app-edit-personal-information',
  templateUrl: './edit-personal-information.component.html',
  styleUrls: ['./edit-personal-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class EditPersonalInformationComponent implements OnInit{
  public personalInformationForm: FormGroup;
  public genders: {name: string}[];
  public maxBirthDate: Date;
  public user: MachineUserModel;

  constructor(
              private ref: DynamicDialogRef,
              private fb: FormBuilder,
              private notifierService: NotifierService,
              private utilService: UtilService,
              private config: DynamicDialogConfig,
              private cdr: ChangeDetectorRef,
              private personalDataService: PersonalDataService) {
    this.maxBirthDate = new Date();
    this.genders = this.utilService.getGenders();
    this.user = config.data.user;
  }


  public editProfileInformation() {
    if (this.personalInformationForm.valid) {
      this.personalInformationForm.disable();
      this.personalDataService.updatePersonalData(this.personalInformationForm.value)
        .subscribe({
          next: response => {
            if (response.succeed) {
              this.notifierService.handleSuccessRequest(PERSONAL_INFO_UPDATED_SUCCESSFULLY);
              this.closeDialog(false, true);
            } else {
              this.notifierService.handleErrors(response.errors);
              this.personalInformationForm.enable();
            }
            this.cdr.detectChanges();
          },
          error: err => {
            this.notifierService.handleRequestError(err);
            this.personalInformationForm.enable();
            this.cdr.detectChanges();
          }
        })
    }
  }

  public ngOnInit(): void {
    this.personalInformationForm = this.fb.group({
      id: new FormControl(this.user?.id),
      identifier: new FormControl(this.user?.identifier),
      firstName: new FormControl(this.user?.firstName, Validators.required),
      fatherName: new FormControl(this.user?.fatherName, Validators.required),
      lastName: new FormControl(this.user?.lastName, Validators.required),
      dateOfBirth: new FormControl(this.user ? new Date(this.user.dateOfBirth) : null, Validators.required),
      placeOfBirth: new FormControl(this.user?.placeOfBirth, Validators.required),
      gender: new FormControl(this.user?.gender, Validators.required),
      status: new FormControl(this.user?.status, Validators.required),
    });
    this.cdr.detectChanges();
  }

  public closeDialog(added: boolean, edited?: boolean) {
    this.ref.close({added, edited, personalInfo: this.personalInformationForm.value});
  }

}
