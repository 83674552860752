import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SOCIAL_MEDIA_SAVED,} from '../../../../locale/multilingual-strings-constants';
import {MachineUserSocialMediaModel} from '../../model/machine/machine.model';
import {SOCIAL_MEDIA_NAMES} from '../../constants';
import {NotifierService} from '../../services/notifier.service';
import {Observable} from 'rxjs';
import {Response} from '../../model/response.model';
import {PersonalDataService} from '../../../../../personal-data/src/lib/personal-data.service';

@Component({
  selector: 'app-social-media-popup',
  templateUrl: './social-media-popup.component.html',
  styleUrls: ['./social-media-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialMediaPopupComponent {
  public personalDataId: string;
  public socialMediaForm: FormGroup;
  public socialMediaNames = SOCIAL_MEDIA_NAMES;

  constructor(
              private ref: DynamicDialogRef,
              private fb: FormBuilder,
              private notifierService: NotifierService,
              private config: DynamicDialogConfig,
              private cdr: ChangeDetectorRef,
              private personalDataService: PersonalDataService) {

    const socialMedia = config.data.socialMedia;
    this.personalDataId = config.data.personalDataId;
    this.socialMediaForm = this.fb.group({
      id: [socialMedia?.id],
      personalDataId: [socialMedia?.personalDataId || this.personalDataId],
      name: [socialMedia?.name, [Validators.required]],
      accountId: [socialMedia?.accountId, [Validators.required,]],
    });
  }

  public submit() {
    if (this.socialMediaForm.valid) {
      this.socialMediaForm.disable();
      const submitRequest$: Observable<Response<string | void>> = this.socialMediaForm.value.id ? this.personalDataService.updateSocialMedia(this.socialMediaForm.value as MachineUserSocialMediaModel) :
        this.personalDataService.addSocialMedia(this.socialMediaForm.value as MachineUserSocialMediaModel);

      submitRequest$.subscribe({
        next: response => {
          if (response.succeed) {
            this.notifierService.handleSuccessRequest(SOCIAL_MEDIA_SAVED);
            if (!this.socialMediaForm.value.id) {
              this.socialMediaForm.controls['id'].setValue(response.value);
              this.closeDialog(true);
            } else this.closeDialog(false, true);
          } else {
            this.notifierService.handleErrors(response.errors);
            this.socialMediaForm.enable();
          }
          this.cdr.detectChanges();
        },
        error: err => {
          this.notifierService.handleRequestError(err);
          this.socialMediaForm.enable();
          this.cdr.detectChanges();
        }
      })
    }
  }

  public closeDialog(added: boolean, edited?: boolean) {
    this.ref.close({added, edited, socialMedia: this.socialMediaForm.value});
  }
}
