import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from '../../services/notifier.service';
import {RELATION_SAVED_SUCCESSFULLY} from '../../../../locale/multilingual-strings-constants';
import {RelationModel} from '../../model/employee/relation.model';
import {Observable} from 'rxjs';
import {Response} from '../../model/response.model';
import {PersonalDataService} from '../../../../../personal-data/src/lib/personal-data.service';

@Component({
  selector: 'app-relation',
  templateUrl: './relation.component.html',
  styleUrls: ['./relation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelationComponent {
  public parentId: string;
  public relationId: string;
  public relationName: string;
  public personalData = [];
  public relationForm: FormGroup;

  constructor(
              private ref: DynamicDialogRef,
              private fb: FormBuilder,
              private notifierService: NotifierService,
              private config: DynamicDialogConfig,
              private cdr: ChangeDetectorRef,
              private personalDataService:PersonalDataService
              ) {

    const relation = config.data.relation;
    this.parentId = config.data.parentId;
    this.relationId = config.data.relationId;
    this.relationName = config.data.relationName;
    this.relationForm = this.fb.group({
      id: [relation?.id],
      parentId: [this.parentId || relation?.parentId],
      childId: [this.relationId || relation?.childId, [Validators.required,]],
      relationType: [relation?.relationType, [Validators.required,]],
    });
  }

  public closeDialog(finished: boolean) {
    this.ref.close(finished);
  }

  public submit() {
    if (this.relationForm.valid) {
      this.relationForm.disable();
      const body = this.relationForm.value as RelationModel;
      const submitRequest$: Observable<Response<string | void>> = this.parentId ? this.personalDataService.updateRelation(body) :
        this.personalDataService.addRelation(body);

      submitRequest$.subscribe({
        next: response => {
          if (response.succeed) {
            this.notifierService.handleSuccessRequest(RELATION_SAVED_SUCCESSFULLY);
            this.closeDialog(true);
          } else {
            this.notifierService.handleErrors(response.errors);
            this.relationForm.enable();
          }
          this.cdr.detectChanges();
        },
        error: err => {
          this.notifierService.handleRequestError(err);
          this.relationForm.enable();
          this.cdr.detectChanges();
        }
      })
    }
  }

}
