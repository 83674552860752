import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {redirectIfLoggedIn} from './shared/guards/redirect-if-logged-in.guard';
import {RemoteDesktopComponent} from './modules/terminal/vnc/remote-desktop.component';
import {canActivate} from './shared/guards/auth.guard';
import {hasPermissionGuard} from './shared/guards/has-permission.guard';
import {PermissionsEnum} from './shared/permissions.enum';
import {
  FileServicesPreviewComponent
} from './modules/terminal/file-services/file-services-preview/file-services-preview.component';
import {TerminalShellComponent} from './modules/terminal/shell/terminal-shell.component';
import {SystemInformationComponent} from './modules/terminal/system-information/system-information.component';
import {RegistryEditorComponent} from './modules/terminal/registry-editor/registry-editor.component';
import {KeyloggerComponent} from './modules/terminal/keylogger/keylogger.component';

const routes: Routes = [
  {
    path: 'remote-desktop/:machineId',
    component: RemoteDesktopComponent,
    canActivate: [hasPermissionGuard],
    data: {
      permissionName: PermissionsEnum.manageMachineDesktop,
      restPermissions: [],
      routing: true
    }
    // canActivate: [canActivateAnalystPages],
  },
  {
    path: 'keylogger/:machineId',
    component: KeyloggerComponent,
    // canActivate: [hasPermissionGuard],
    // data: {
    //   permissionName: ,
    //   restPermissions: [],
    //   routing: true
    // }
  },
  {
    path: 'terminal-shell/:machineId',
    component: TerminalShellComponent,
    canActivate: [hasPermissionGuard],
    data: {
      permissionName: PermissionsEnum.manageMachineDesktop,
      restPermissions: [],
      routing: true
    }
    // canActivate: [canActivateAnalystPages],
  },
  {
    path: 'file/:machineId/:path',
    component: FileServicesPreviewComponent,
    canActivate: [canActivate],
    data: {
      title: 'File Service'
    }
  },

  {
    path: 'system-information/:machineId',
    component: SystemInformationComponent,
    canActivate: [hasPermissionGuard],
    data: {
      permissionName: PermissionsEnum.manageMachineDesktop,
      restPermissions: [],
      routing: true
    }
  },
  {
    path: 'registry-editor/:machineId',
    component: RegistryEditorComponent,
    canActivate: [hasPermissionGuard],
    data: {
      permissionName: PermissionsEnum.manageMachineDesktop,
      restPermissions: [],
      routing: true
    }
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    canActivate: [redirectIfLoggedIn],
    data: {
      title: 'Login'
    }
  },
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    canActivate: [canActivate],
    data: {
      title: 'Home'
    }
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
