import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TerminalService} from '../../../../../organization-url-redirection-aggregator/src/lib/terminal.service';
import {SystemInformationService} from '../file-services/system-information.service';
import {ShellWebSocketService} from '../../../shared/services/shell-web-socket.service';
import {WebSocketService} from '../../../shared/services/web-socket.service';

@Component({
  selector: 'app-system-information',
  templateUrl: './system-information.component.html',
  styleUrls: ['./system-information.component.scss'],
  providers: [ShellWebSocketService, WebSocketService, SystemInformationService],
})
export class SystemInformationComponent implements OnInit, OnDestroy {

  @Input() public terminalId: string;
  @Input() public terminalName: string;
  @Input() public visible: any;
  @Output() public onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  public showProgressSpinner = true;
  public inNewWindow = false;
  public systemInfo: any;
  public arpInfo: any;
  public keys: string[] = [];
  public isMobileView: boolean

  public constructor(
    private cdr: ChangeDetectorRef,
    private systemInformationService: SystemInformationService,
    private terminalService: TerminalService,
  ) {
  }

  public ngOnInit(): void {
    this.isMobileView = window.innerWidth < 768
    this.terminalService.getTerminalShell(this.terminalId).subscribe(res => {
      this.systemInformationService.connectToMachine(res.value);
      this.cdr.detectChanges();
    });

    this.subscribeToInfo();
  }

  private subscribeToInfo(): void {
    this.systemInformationService.receivedString$.subscribe(response => {
      if (response.state === 'LoadingSystemInfo') {
        this.systemInfo = response.data;
      } else if (response.state === 'LoadingArpInfo') {
        this.arpInfo = response.data;
      }
      this.showProgressSpinner = response.loaded;
      this.cdr.detectChanges();
    });
  }

  private parseSystemInfo(info: string): any {
    const lines = info.split('\r\n');
    const systemInfoObject: any = {};
    let currentKey: string | null = null;
    lines.forEach(line => {
      const trimmedLine = line;
      if (trimmedLine.length === 0) {
        return;
      }
      if (/^[^\w\d]|\[\d+\] |^\s/.test(trimmedLine)) {
        if (currentKey && systemInfoObject[currentKey]) {
          systemInfoObject[currentKey] += ' ' + trimmedLine;
        }
      } else {
        const colonIndex = trimmedLine.indexOf(':');
        if (colonIndex !== -1) {
          const key = trimmedLine.substring(0, colonIndex).trim();
          let value = trimmedLine.substring(colonIndex + 1).trim();
          const nextLine = lines[lines.indexOf(line) + 1];
          if (nextLine && /^\s{33}/.test(nextLine)) {
            value += '\r\n' + nextLine.trim();
          }
          if (value === '') {
            value = 'N/A';
          }

          systemInfoObject[key] = value;
          currentKey = key;
        } else {
          console.log()
        }
      }
    });
    return systemInfoObject;
  }

  public onVisibleChange(event: boolean) {
    if (!event) {
      this.onClose.emit(true);
    }
  }

  public ngOnDestroy(): void {
    this.systemInformationService.closeSocket();
  }

}
