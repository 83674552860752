export enum LeftBarItemsEnum {
  dashboard = 1,
  terminals,
  personalData,
  users,
  roles,
  fileCategories,
  fileExtensions,
  builder,
  fileHosting,
  urlRedirections,
  urlShortener,
}
