import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {AuthService} from '../../../../shared/services/auth.service';
import {PermissionsEnum} from '../../../../shared/permissions.enum';
import {Response} from '../../../../shared/model/response.model';
import {Observable} from 'rxjs';
import {
  DEFAULT_TABLE_ROWS_OPTIONS,
  LOCAL_STORAGE_USER_SESSIONS_LIMIT,
  RIGHT_BAR_USER_SESSIONS,
  USER_SESSIONS_LIMIT
} from '../../../../shared/constants';
import {TableLazyLoadEvent} from 'primeng/table';
import {NavigationService} from '../../../../shared/services/navigation.service';
import {TableFieldModel} from '../../../../shared/model/table-field.model';
import {FieldTypesEnum} from '../../../../shared/field-types.enum';
import {DeletePopup} from '../../../../shared/components/delete-popup/delete-popup.component';
import {DataConversionService} from '../../../../shared/services/data-conversion.service';
import {SESSION_DELETED_SUCCESSFULLY} from '../../../../../locale/multilingual-strings-constants';
import {NotifierService} from '../../../../shared/services/notifier.service';
import {HttpIdentityService} from '../../../../../../identity/src/lib/identity-client.service';
import {SessionModel} from '../../../../../../identity/src/lib/models/session.model';
import {FieldSortTypesEnum} from '../../../../shared/field-sort-types.enum';
import {UtilService} from '../../../../shared/services/util.service';

@Component({
  selector: 'app-user-sessions',
  templateUrl: './user-sessions.component.html',
  styleUrls: ['./user-sessions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSessionsComponent implements OnInit {
  @Input() public userName: string;
  @Output() public onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _visible: boolean;
  private ref: DynamicDialogRef | undefined;
  public selectedSession: SessionModel;
  public sessions: SessionModel[];
  public items: MenuItem[] = [];
  public hasDeletePermission = false;
  public totalRecords: number;
  public loading = true;
  public USER_SESSIONS_LIMIT: number;

  public fields: TableFieldModel[] = [
    {
      name: 'ipAddress',
      displayName: 'IP Address',
      isCopy: false,
      globalFilter: false,
      sortable: true,
      sortType: FieldSortTypesEnum.string,
    },
    {
      name: 'device',
      displayName: 'Device',
      isCopy: false,
      globalFilter: false,
    },
    {
      name: 'createdAt',
      displayName: 'Created',
      isCopy: false,
      globalFilter: false,
      searchable: false,
      type: FieldTypesEnum.date,
      showTimeTooltip: true,
      applyTimezonePipe: true,
      sortable: true,
      sortType: FieldSortTypesEnum.string,
    },
    {
      name: 'expiry',
      displayName: 'Refresh Token Expiry Time',
      isCopy: false,
      globalFilter: false,
      searchable: false,
      type: FieldTypesEnum.date,
      showTimeTooltip: true,
      applyTimezonePipe: true,
      sortable: true,
      sortType: FieldSortTypesEnum.string,
    },
    {
      name: 'isExpired',
      displayName: 'Is Expired',
      isCopy: false,
      globalFilter: false,
    },
  ];

  @Input() public userId: string;

  @Input()
  set visible(value: boolean) {
    this._visible = value;
  }

  get visible(): boolean {
    return this._visible;
  }

  public constructor(
    private httpIdentityService: HttpIdentityService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private utilService: UtilService,
    private navigationService: NavigationService,
    private authService: AuthService,
    private dataConversionService: DataConversionService,
    private notifierService: NotifierService,
  ) {
  }

  public ngOnInit() {
    if (!this.userName) {
      this.userName = this.authService.getUserNameFromToken();
    }

    this.initMenuItems();
    this.initSessions();
  }

  public initMenuItems(session?: SessionModel) {
    this.items = [];
    this.items.push({
      label: 'Copy',
      items: [{
        label: 'ID',
        command: () => {
          session && this.utilService.copyText(session.id as string, 'Session ID copied.');
        }
      }]
    })

    if (this.hasDeletePermission) {
      this.items.push({
        separator: true,
      });

      this.items.push({
        label: 'Delete',
        command: () => {
          this.openDeleteSessionPopup(session!);
        }
      });
    }
  }

  private openDeleteSessionPopup(session: SessionModel) {
    this.ref = this.dialogService.open(DeletePopup, {
      header: 'Are you sure you want to delete this session?',
      width: 'fit-content',
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data: {
        id: session.id,
        data: this.dataConversionService.convertData(
          [session],
          {
            id: 'ID',
            device: 'Device',
            ipAddress: 'IP Address',
            active: 'Active',
          }
        ),
      },
    });
    this.ref.onClose.subscribe(result => {
      if (result.delete) {
        this.deleteSession(result.id)
      }
    });
  }

  public onVisibleChange(event: boolean) {
    if (!event) {
      this.navigationService.closeRightBarPage(RIGHT_BAR_USER_SESSIONS);
      this.onClose.emit(true);
    }
  }

  public initLimit(event?: TableLazyLoadEvent) {
    localStorage.setItem(LOCAL_STORAGE_USER_SESSIONS_LIMIT, event?.rows?.toString() || USER_SESSIONS_LIMIT.toString());
  }

  private deleteSession(sessionId: string) {
    this.httpIdentityService.deleteSessionById(sessionId).subscribe({
      next: r => {
        if (r.succeed) {
          this.notifierService.handleSuccessRequest(SESSION_DELETED_SUCCESSFULLY);
          this.refresh();
        } else {
          this.notifierService.handleErrors(r.errors);
        }
      },
      error: err => this.notifierService.handleRequestError(err)
    });
  }

  public refresh() {
    this.initSessions();
  }

  private initSessions() {
    this.loading = true;
    const storedLimit = localStorage.getItem(LOCAL_STORAGE_USER_SESSIONS_LIMIT);
    if (storedLimit && DEFAULT_TABLE_ROWS_OPTIONS.includes(+storedLimit)) {
      this.USER_SESSIONS_LIMIT = +storedLimit;
    } else {
      this.USER_SESSIONS_LIMIT = USER_SESSIONS_LIMIT;
      localStorage.setItem(LOCAL_STORAGE_USER_SESSIONS_LIMIT, USER_SESSIONS_LIMIT.toString());
    }
    let sessions$: Observable<Response<SessionModel[]>>;
    this.hasDeletePermission = this.authService.hasPermission(PermissionsEnum.deleteIdentitySession);
    if (this.userId) {
      sessions$ = this.httpIdentityService.getAllSessionsByUserId(this.userId);
    } else {
      sessions$ = this.httpIdentityService.getCurrentUserSessions();
    }
    sessions$.subscribe(response => {
      if (response.succeed) {
        this.sessions = response.value.map(v => ({
          id: v.id,
          createdAt: v.createdAt,
          updatedAt: v.updatedAt,
          device: v.device,
          ipAddress: v.ipAddress,
          expiry: v.expiry,
          isExpired: v.isExpired,
        } as SessionModel));
        this.totalRecords = this.sessions.length;
        this.loading = false;
        this.cdr.detectChanges();
      }
    });
  }
}
