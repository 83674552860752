<div style="min-height: 254px" *ngIf="emailForm ">
  <form [formGroup]="emailForm" (ngSubmit)="submit()">
    <div class="form">
      <div class="input-box">
      <span class="p-float-label">
        <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': emailForm.get('address')?.touched}" id="address"
               type="text" pInputText formControlName="address"/>
        <label htmlFor="address">Address*</label>
      </span>
        <small
          id="phone-numberType-help"
          class="p-error"
          *ngIf="emailForm.get('address')?.touched && emailForm.get('address')?.hasError('required')">
          The address is required.
        </small>
      </div>

      <div class="input-box buttons">
        <p-button [style]="{width: '120px'}" [disabled]="emailForm.invalid || emailForm.disabled" type="submit"
                  label="Save"></p-button>
      </div>
    </div>
  </form>
</div>

