<div style="min-height: 254px" *ngIf="personalInformationForm ">
  <form [formGroup]="personalInformationForm" (ngSubmit)="editProfileInformation()">
    <div class="form">

      <div class="input-box">
        <span class="p-float-label">
        <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': personalInformationForm.get('firstName')?.touched}"
               id="personal-info-firstName"
               type="text"
               pInputText
               formControlName="firstName"
               [disabled]="personalInformationForm.disabled"/>
          <label htmlFor="personal-info-firstName">Name</label>

        </span>
        <small
          id="personal-info-firstName-err"
          class="p-error personal-info-error"
          *ngIf="personalInformationForm.get('firstName')?.touched && personalInformationForm.get('firstName')?.hasError('required')">
          The field is required.
        </small>
      </div>

      <div class="input-box">
                <span class="p-float-label">

        <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': personalInformationForm.get('lastName')?.touched}"
               id="personal-info-lastName"
               type="text"
               pInputText
               formControlName="lastName"
               [disabled]="personalInformationForm.disabled"/>
                          <label htmlFor="personal-info-lastName">Last Name</label>

                </span>
        <small
          id="personal-info-lastName-err"
          class="p-error personal-info-error"
          *ngIf="personalInformationForm.get('lastName')?.touched && personalInformationForm.get('lastName')?.hasError('required')">
          The field is required.
        </small>
      </div>
      <div class="input-box">
                <span class="p-float-label">

        <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': personalInformationForm.get('fatherName')?.touched}"
               id="personal-info-father-name"
               type="text"
               pInputText
               formControlName="fatherName"
               [disabled]="personalInformationForm.disabled"/>
        <label htmlFor="personal-info-father-name">Father's Name</label>
                </span>

        <small
          id="personal-info-fatherName-err"
          class="p-error personal-info-error"
          *ngIf="personalInformationForm.get('fatherName')?.touched && personalInformationForm.get('fatherName')?.hasError('required')">
          The field is required.
        </small>
      </div>
      <div class="input-box">
                <span class="p-float-label">

        <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': personalInformationForm.get('identifier')?.touched}"
               id="personal-info-employeeId"
               type="text"
               pInputText
               formControlName="identifier"
               [disabled]="personalInformationForm.disabled"/>
        <label htmlFor="personal-info-employeeId">ID</label>
                </span>
        <small
          id="personal-info-identifier-err"
          class="p-error personal-info-error"
          *ngIf="personalInformationForm.get('identifier')?.touched && personalInformationForm.get('identifier')?.hasError('required')">
          The field is required.
        </small>
      </div>
      <div class="input-box">
                <span class="p-float-label">

        <p-calendar id="dateOfBirth"
                    [showIcon]="true"
                    [appendTo]="'body'"
                    [maxDate]="maxBirthDate"
                    formControlName="dateOfBirth"
                    [disabled]="personalInformationForm.disabled"
        ></p-calendar>
                          <label htmlFor="dateOfBirth">Date of Birth</label>

                </span>
      </div>
      <div class="input-box">
                <span class="p-float-label">

        <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': personalInformationForm.get('placeOfBirth')?.touched}"
               id="personal-info-placeOfBirth"
               type="text"
               pInputText
               formControlName="placeOfBirth"
               [disabled]="personalInformationForm.disabled"/>
                          <label htmlFor="personal-info-placeOfBirth">Place of Birth</label>

                </span>
        <small
          id="personal-info-placeOfBirth-err"
          class="p-error personal-info-error"
          *ngIf="personalInformationForm.get('placeOfBirth')?.touched && personalInformationForm.get('placeOfBirth')?.hasError('required')">
          The field is required.
        </small>
      </div>
      <div class="input-box">

        <span class="p-float-label">

          <p-dropdown
            [style]="{width: '100%'}"
            id="gender"
            [options]="genders"
            placeholder="Gender"
            optionLabel="name"
            optionValue="name"
            formControlName="gender"
            [disabled]="personalInformationForm.disabled"

          ></p-dropdown>
          <label htmlFor="personal-info-employeeId">Gender</label>

         </span>
        <small
          id="personal-info-gender-err"
          class="p-error personal-info-error"
          *ngIf="personalInformationForm.get('gender')?.touched && personalInformationForm.get('gender')?.hasError('required')">
          The field is required.
        </small>
      </div>
      <div class="input-box">
                <span class="p-float-label">

        <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': personalInformationForm.get('status')?.touched}"
               id="personal-info-status"
               type="text"
               pInputText
               formControlName="status"
               [disabled]="personalInformationForm.disabled"/>
                          <label htmlFor="personal-info-status">Status</label>

                </span>
        <small
          id="personal-info-status-err"
          class="p-error personal-info-error"
          *ngIf="personalInformationForm.get('status')?.touched && personalInformationForm.get('status')?.hasError('required')">
          The field is required.
        </small>
      </div>


      <div class="input-box buttons">
        <p-button [style]="{width: '120px'}"
                  [disabled]="personalInformationForm.invalid || personalInformationForm.disabled" type="submit"
                  label="Save"></p-button>
      </div>
    </div>
  </form>
</div>

