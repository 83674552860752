import {Inject, Injectable, InjectionToken} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Response} from './models/response.model';
import {first, Observable} from 'rxjs';
import {NewResponse} from './models/response';

export const API_URL = new InjectionToken<string>('apiUrl');

@Injectable()
export class TerminalPersonalDataAdapterService {
  private readonly urlHealthz: string;
  private readonly urlTerminalPersonalData: string;

  private readonly headersSkippingInterceptor: HttpHeaders;
  private readonly headers: HttpHeaders;
  private readonly optionsSkippingInterceptor: object;
  private readonly options: object;


  constructor(private http: HttpClient, @Inject(API_URL) private apiUrl: string) {
    this.headersSkippingInterceptor = new HttpHeaders({
      'Content-Type': 'application/json',
      'skipInterceptor': 'true',
    });
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.optionsSkippingInterceptor = {
      headers: this.headersSkippingInterceptor,
      withCredentials: true
    };
    this.options = {
      headers: this.headers,
      withCredentials: true
    };

    this.urlTerminalPersonalData = `${apiUrl}/terminal-personal-data/terminalPersonalData`;
    this.urlHealthz = `${apiUrl}/terminal-personal-data/healthz`;

  }

  // Health
  public getHealthz(): Observable<Response<string>> {
    return this.http.get<Response<string>>(`${this.urlHealthz}`, this.optionsSkippingInterceptor).pipe(first());
  }

  // Terminal Personal Data
  public getPersonalDataByTerminalId(terminalId: string, page: number, size: number): Observable<NewResponse<string[]>> {
    return this.http.get<NewResponse<string[]>>(`${this.urlTerminalPersonalData}/${terminalId}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public addPersonalDataToTerminal(terminalId: string, personalDataId: string): Observable<Response<boolean>> {
    return this.http.post<Response<boolean>>(`${this.urlTerminalPersonalData}/${terminalId}/${personalDataId}`, {}, this.optionsSkippingInterceptor).pipe(first());
  }

  public removePersonalDataFromTerminal(terminalId: string, personalDataId: string): Observable<Response<boolean>> {
    return this.http.delete<Response<boolean>>(`${this.urlTerminalPersonalData}/${terminalId}/${personalDataId}`, this.optionsSkippingInterceptor).pipe(first());
  }

}
