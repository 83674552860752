import {Inject, Injectable, InjectionToken} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NewResponse} from './models/newResponse.model';
import {Response} from './models/response.model';
import {first, Observable} from 'rxjs';
import {PersonalDataModel} from './models/personal-data.model';

export const API_URL = new InjectionToken<string>('apiUrl');

@Injectable({
  providedIn: 'root'
})
export class OrganizationPersonalDataAggregatorService {
  private readonly urlPersonalDataOrganization: string;
  private readonly urlPersonalDataHealthz: string;
  private readonly headersSkippingInterceptor: HttpHeaders;
  private readonly headers: HttpHeaders;
  private readonly optionsSkippingInterceptor: object;
  private readonly options: object;


  constructor(private http: HttpClient, @Inject(API_URL) private apiUrl: string) {
    this.headersSkippingInterceptor = new HttpHeaders({
      'Content-Type': 'application/json',
      'skipInterceptor': 'true',
    });
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.optionsSkippingInterceptor = {
      headers: this.headersSkippingInterceptor,
      withCredentials: true
    };
    this.options = {
      headers: this.headers,
      withCredentials: true
    };

    this.urlPersonalDataOrganization = `${apiUrl}/personal-data-organization/PersonalDataOrganization`;
    this.urlPersonalDataHealthz = `${apiUrl}/personal-data-organization/healthz`;
  }

  // Health
  public getHealthz(): Observable<Response<string>> {
    return this.http.get<Response<string>>(`${this.urlPersonalDataHealthz}`,this.optionsSkippingInterceptor).pipe(first());
  }

  //PersonalDataOrganization

  public getAllPersonalDataByOrganizationId(organizationId: string, page: number, size: number): Observable<NewResponse<string[]>> {
    return this.http.get<NewResponse<string[]>>(`${this.urlPersonalDataOrganization}/${organizationId}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first())
  }

  public createPersonalData(organizationId: string, body: PersonalDataModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlPersonalDataOrganization}/${organizationId}`, body, this.optionsSkippingInterceptor).pipe(first());
  }
}
