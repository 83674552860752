import {ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, RouterStateSnapshot} from '@angular/router';
import {inject} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {PermissionsEnum} from '../permissions.enum';
import {NavigationService} from '../services/navigation.service';

export const hasPermissionGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
) => {
  const permissionName = route.data['permissionName'];
  const restPermissions = route.data['restPermissions'];
  const routing = route.data['routing'];
  const authService = inject(AuthService);
  const hasAccess = authService.hasPermission(permissionName, restPermissions);
  if (!hasAccess) {
    const navigationService = inject(NavigationService);

    if (routing && !hasAccess) {
      switch (permissionName) {
        case PermissionsEnum.readMachine:
          navigationService.navigateToUsers().then();
          break;
        case PermissionsEnum.writeUser:
          navigationService.navigateToRoles().then();
          break;
        case PermissionsEnum.writeRole:
          navigationService.navigateToHostedServices().then();
          break;
        case PermissionsEnum.manageHostedService:
          navigationService.navigateToEmployees().then();
          break;
        case PermissionsEnum.readMachineUser:
          navigationService.navigateToBlankComponent().then();
          break;
      }
    }
  }
  return true;
};

export const hasPermissionGuardChild: CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => hasPermissionGuard(route, state);
