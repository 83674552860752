import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, tap} from 'rxjs';
import {UtilService} from '../services/util.service';
import {FILE_HOSTING_URL} from '../constants';

@Injectable()
export class UploadProgressInterceptorService implements HttpInterceptor {
  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private utilService: UtilService,
  ) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((res: any) => {
        if (req.url.includes(FILE_HOSTING_URL) && req.method === 'POST') {
          if (res.type === HttpEventType.Response) {
            this.utilService.progress$.next(100);
          }
          if (res.type === HttpEventType.UploadProgress) {
            const percentDone = Math.round(100 * res.loaded / res.total);
            this.utilService.progress$.next(percentDone);
          }
        }
      }),
    );
  }

}
