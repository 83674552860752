<div class="table-wrapper" style="height: 100%">
  <div class="flex gap-4 tools header-buttons-container">
    <ng-container *ngIf="hasWritePermission">
      <div class="flex align-items-center gap-2 table__action-container" (click)="openAddPopup()">
        <i class="pi pi-plus navigation-img"></i>
        <span class="vertical-align-middle table__action-button">Add</span>
      </div>
    </ng-container>
    <div class="flex align-items-center gap-2 table__action-container" (click)="refresh()">
      <i class="pi pi-refresh navigation-img"></i>
      <span class="vertical-align-middle table__action-button">Refresh</span>
    </div>
  </div>

  <div class="social-media-table">
    <app-table
      [fields]="fields"
      [value]="socialMedias"
      [menuItems]="items"
      [lazy]="true"
      [rowsLimit]="socialMediaLimit"
      [totalRecords]="totalRecords"
      [loading]="loading"
      [hasMenuPermission]="hasWritePermission || hasDeletePermission"
      (onLazyLoad$)="initSocialMedia($event)"
      (onToggleMenu$)="initMenuItems($event)"
      [contextMenuItems]="items"
      [(selectedItem)]="selectedSocialMedia"
      (selectedItemChange)="initCmItems()"
    ></app-table>
  </div>

</div>

