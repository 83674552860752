import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {
  constructor(@Inject(LOCALE_ID) public locale: string) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const modified = req.clone({setHeaders: {'Accept-Language': this.locale}});
    return next.handle(modified);
  }

}
