import {Inject, Injectable, InjectionToken} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {first, Observable, of} from 'rxjs';
import {NewResponse} from './models/newResponse.model';
import {Response} from './models/response.model';


export const API_URL = new InjectionToken<string>('apiUrl');

@Injectable({
  providedIn: 'root'
})
export class OrganizationTerminalAggregatorService {


  private readonly urlOrganizationTerminal: string;
  private readonly urlOrganizationTerminalAdapter: string;
  private readonly urlOrganizationTerminalHealthz: string;

  private readonly headersSkippingInterceptor: HttpHeaders;
  private readonly headers: HttpHeaders;
  private readonly optionsSkippingInterceptor: object;
  private readonly options: object;

  constructor(private http: HttpClient, @Inject(API_URL) private apiUrl: string) {

    this.headersSkippingInterceptor = new HttpHeaders({
      'Content-Type': 'application/json',
      'skipInterceptor': 'true',
    });
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.optionsSkippingInterceptor = {
      headers: this.headersSkippingInterceptor,
      withCredentials: true
    };
    this.options = {
      headers: this.headers,
      withCredentials: true
    };

    this.urlOrganizationTerminal = `${apiUrl}/organizationTerminal/OrganizationTerminal`;
    this.urlOrganizationTerminalHealthz = `${apiUrl}/organizationTerminal/healthz`;
    this.urlOrganizationTerminalAdapter = `${apiUrl}/organizationTerminalAdapter/OrganizationTerminal`;

  }

  // Health
  public getHealthz(): Observable<Response<string>> {
    return this.http.get<Response<string>>(`${this.urlOrganizationTerminalHealthz}`,this.optionsSkippingInterceptor).pipe(first());
  }

  //OrganizationTerminal

  public getOrganizationTerminals(page: number, size: number, organizationId?: string): Observable<NewResponse<string[]>> {
    // return of({
    //   succeed: true,
    //   errors: [],
    //   totalItems: 200,
    //   value: {
    //     entities: ['id1', 'id2', 'id3', 'id4', 'id5', 'id6', 'id7', 'id8', 'id9', 'id10', 'id11', 'id12', 'id13', 'id14', 'id15', 'id16', 'id17', 'id18', 'id19', 'id20', 'id21', 'id22', 'id23', 'id24', 'id25', 'id26', 'id27', 'id28', 'id29', 'id30', 'id31', 'id32', 'id33', 'id34', 'id35', 'id36', 'id37', 'id38', 'id39', 'id40', 'id41', 'id42', 'id43', 'id44', 'id45', 'id46', 'id47', 'id48', 'id49', 'id50', 'id51', 'id52', 'id53', 'id54', 'id55', 'id56', 'id57', 'id58', 'id59', 'id60', 'id61', 'id62', 'id63', 'id64', 'id65', 'id66', 'id67', 'id68', 'id69', 'id70', 'id71', 'id72', 'id73', 'id74', 'id75', 'id76', 'id77', 'id78', 'id79', 'id80', 'id81', 'id82', 'id83', 'id84', 'id85', 'id86', 'id87', 'id88', 'id89', 'id90', 'id91', 'id92', 'id93', 'id94', 'id95', 'id96', 'id97', 'id98', 'id99', 'id100', 'id101', 'id102', 'id103', 'id104', 'id105', 'id106', 'id107', 'id108', 'id109', 'id110', 'id111', 'id112', 'id113', 'id114', 'id115', 'id116', 'id117', 'id118', 'id119', 'id120', 'id121', 'id122', 'id123', 'id124', 'id125', 'id126', 'id127', 'id128', 'id129', 'id130', 'id131', 'id132', 'id133', 'id134', 'id135', 'id136', 'id137', 'id138', 'id139', 'id140', 'id141', 'id142', 'id143', 'id144', 'id145', 'id146', 'id147', 'id148', 'id149', 'id150', 'id151', 'id152', 'id153', 'id154', 'id155', 'id156', 'id157', 'id158', 'id159', 'id160', 'id161', 'id162', 'id163', 'id164', 'id165', 'id166', 'id167', 'id168', 'id169', 'id170', 'id171', 'id172', 'id173', 'id174', 'id175', 'id176', 'id177', 'id178', 'id179', 'id180', 'id181', 'id182', 'id183', 'id184', 'id185', 'id186', 'id187', 'id188', 'id189', 'id190', 'id191', 'id192', 'id193', 'id194', 'id195', 'id196', 'id197', 'id198', 'id199', 'id200'],
    //     totalCount: 200,
    //     totalPages: 1,
    //     hasPreviousPage: false,
    //     hasNextPage: false,
    //   }
    // });
    if (!organizationId){
      return this.http.get<NewResponse<string[]>>(`${this.urlOrganizationTerminal}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
    }
    return this.http.get<NewResponse<string[]>>(`${this.urlOrganizationTerminal}?organizationId=${organizationId}&page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getOrganizationTerminalsOnline(page: number, size: number, organizationId?: string): Observable<NewResponse<string[]>> {
    // return of({
    //   succeed: true,
    //   errors: [],
    //   totalItems: 200,
    //   value: {
    //     entities: ['id1', 'id2', 'id3', 'id4', 'id5', 'id6', 'id7', 'id8', 'id9', 'id10', 'id11', 'id12', 'id13', 'id14', 'id15', 'id16', 'id17', 'id18', 'id19', 'id20', 'id21', 'id22', 'id23', 'id24', 'id25', 'id26', 'id27', 'id28', 'id29', 'id30', 'id31', 'id32', 'id33', 'id34', 'id35', 'id36', 'id37', 'id38', 'id39', 'id40', 'id41', 'id42', 'id43', 'id44', 'id45', 'id46', 'id47', 'id48', 'id49', 'id50', 'id51', 'id52', 'id53', 'id54', 'id55', 'id56', 'id57', 'id58', 'id59', 'id60', 'id61', 'id62', 'id63', 'id64', 'id65', 'id66', 'id67', 'id68', 'id69', 'id70', 'id71', 'id72', 'id73', 'id74', 'id75', 'id76', 'id77', 'id78', 'id79', 'id80', 'id81', 'id82', 'id83', 'id84', 'id85', 'id86', 'id87', 'id88', 'id89', 'id90', 'id91', 'id92', 'id93', 'id94', 'id95', 'id96', 'id97', 'id98', 'id99', 'id100', 'id101', 'id102', 'id103', 'id104', 'id105', 'id106', 'id107', 'id108', 'id109', 'id110', 'id111', 'id112', 'id113', 'id114', 'id115', 'id116', 'id117', 'id118', 'id119', 'id120', 'id121', 'id122', 'id123', 'id124', 'id125', 'id126', 'id127', 'id128', 'id129', 'id130', 'id131', 'id132', 'id133', 'id134', 'id135', 'id136', 'id137', 'id138', 'id139', 'id140', 'id141', 'id142', 'id143', 'id144', 'id145', 'id146', 'id147', 'id148', 'id149', 'id150', 'id151', 'id152', 'id153', 'id154', 'id155', 'id156', 'id157', 'id158', 'id159', 'id160', 'id161', 'id162', 'id163', 'id164', 'id165', 'id166', 'id167', 'id168', 'id169', 'id170', 'id171', 'id172', 'id173', 'id174', 'id175', 'id176', 'id177', 'id178', 'id179', 'id180', 'id181', 'id182', 'id183', 'id184', 'id185', 'id186', 'id187', 'id188', 'id189', 'id190', 'id191', 'id192', 'id193', 'id194', 'id195', 'id196', 'id197', 'id198', 'id199', 'id200'],
    //     totalCount: 200,
    //     totalPages: 1,
    //     hasPreviousPage: false,
    //     hasNextPage: false,
    //   }
    // });
    if (!organizationId){
      return this.http.get<NewResponse<string[]>>(`${this.urlOrganizationTerminal}/online?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
    }
    return this.http.get<NewResponse<string[]>>(`${this.urlOrganizationTerminal}/online?organizationId=${organizationId}&page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public addOrganizationTerminal(organizationId: string, terminalId: string): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlOrganizationTerminal}/${organizationId}/${terminalId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteOrganizationTerminal(organizationId: string, terminalId: string): Observable<Response<null>> {
    return this.http.delete<Response<null>>(`${this.urlOrganizationTerminalAdapter}/${organizationId}/${terminalId}`, this.optionsSkippingInterceptor).pipe(first());
  }
}
