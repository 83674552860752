import {Injectable} from '@angular/core';
import {API_URL, WS_URL} from '../constants';
import {Observable, Subject} from 'rxjs';
import {Response} from '../../../../organization/src/lib/models/response.model';
import {first} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class VncWebSocketService {
  private qualityValue = 12;
  public wsRemoteDesktopFrameReceived: Subject<string> = new Subject();
  public framesPerSecond$: Subject<number> = new Subject();
  public lastFrameSize$: Subject<number> = new Subject();
  public connectionDied$: Subject<void> = new Subject();
  private videoBuffer: Uint8Array = new Uint8Array();
  private previousTimestamp = Date.now();
  private currentFPS = 0;

  private readonly headersSkippingInterceptor: HttpHeaders;
  private readonly optionsSkippingInterceptor: object;

  constructor(
    private http: HttpClient,
  ) {
    this.headersSkippingInterceptor = new HttpHeaders({
      'Content-Type': 'application/json',
      'skipInterceptor': 'true',
    });
    this.optionsSkippingInterceptor = {
      headers: this.headersSkippingInterceptor,
      withCredentials: true
    };
  }

  private uint8ArrayToBase64(uint8Array: Uint8Array): string {
    let binary = '';
    const len = uint8Array.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(uint8Array[i]);
    }

    return btoa(binary);
  }

  private get QUALITY(): ArrayBuffer {
    return new Uint8Array([this.qualityValue]).buffer;
  }

  updateQuality(newQuality: number): void {
    this.qualityValue = newQuality;
    console.log('Quality updated to:', this.qualityValue);
  }

  private concatenateUint8Arrays(a: Uint8Array, b: Uint8Array): Uint8Array {
    const result = new Uint8Array(a.length + b.length);
    result.set(a, 0);
    result.set(b, a.length);

    return result;
  }

  private async processBinaryFrame(chunk: Uint8Array): Promise<void> {
    this.videoBuffer = this.concatenateUint8Arrays(this.videoBuffer, chunk);

    // Check for end of JPEG frame
    // const isEndOfFrame = chunk.length < 2 ||
    //   (chunk[chunk.byteLength - 2] === 255 && chunk[chunk.byteLength - 1] === 217);
    //
    // if (isEndOfFrame) {
    this.calculateFramesPerSecond();

    const base64String: string = 'data:image/jpeg;base64,' + this.uint8ArrayToBase64(this.videoBuffer);
    this.videoBuffer = new Uint8Array();
    this.wsRemoteDesktopFrameReceived.next(base64String);
    // }
  }

  public getHealthz(): Observable<Response<string>> {
    return this.http.get<Response<string>>(`${API_URL}/orion-cc-coupler/healthz`, this.optionsSkippingInterceptor).pipe(first());
  }

  public connectWithWebsocket(streamId: string): WebSocket {
    const socket: WebSocket = new WebSocket(`${WS_URL}/orion-cc-coupler/consumer/${streamId}`);
    socket.binaryType = 'arraybuffer';
    this.videoBuffer = new Uint8Array();

    socket.onopen = () => {
      socket.send(this.QUALITY);
    };

    this.setupSocketMessageHandler(socket);
    this.setupSocketCloseHandler(socket);
    this.setupSocketErrorHandler(socket);

    return socket;
  }

  private setupSocketErrorHandler(socket: WebSocket): void {
    socket.onerror = (error: Event) => {
      console.error('[error]', error);
    };
  }

  private setupSocketCloseHandler(socket: WebSocket): void {
    socket.onclose = (event: CloseEvent): void => {
      if (event.wasClean) {
        console.log(
          `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
        );
      } else {
        console.log('[close] Connection died');
        this.connectionDied$.next();
      }
    };
  }

  private setupSocketMessageHandler(socket: WebSocket): void {
    socket.onmessage = async (event: MessageEvent) => {
      if (event.data instanceof ArrayBuffer) {
        const chunk: Uint8Array = new Uint8Array(event.data);
        this.emitFrameSize(chunk.byteLength);
        await this.processBinaryFrame(chunk);
      } else {
        // Handle text frame if needed
        // const json = JSON.parse(event.data);
      }

      socket.send(this.QUALITY);
    };
  }

  private calculateFramesPerSecond(): void {
    const currentTimestamp: number = Date.now();
    const timeDifference: number = currentTimestamp - this.previousTimestamp;
    this.currentFPS++;
    if (timeDifference > 1000) {
      this.previousTimestamp = Date.now();
      this.framesPerSecond$.next(this.currentFPS);
      this.currentFPS = 0;
    }
  }

  private emitFrameSize(data: number): void {
    this.lastFrameSize$.next(data);
  }
}
