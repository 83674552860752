import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {REGISTERED_COMPANY_CREATED_SUCCESSFULLY} from '../../../../locale/multilingual-strings-constants';
import {ValidationService} from '../../services/validation.service';
import {NotifierService} from '../../services/notifier.service';
import {RegisteredCompanyModel} from '../../model/employee/registered-company.model';
import {PersonalDataService} from '../../../../../personal-data/src/lib/personal-data.service';

@Component({
  selector: 'app-registered-company-popup',
  templateUrl: './registered-company-popup.component.html',
  styleUrls: ['./registered-company-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisteredCompanyPopupComponent {
  private readonly personalDataId: string;
  public registeredCompanyForm: FormGroup;

  constructor(
              private ref: DynamicDialogRef,
              private fb: FormBuilder,
              private validationService: ValidationService,
              private notifierService: NotifierService,
              private config: DynamicDialogConfig,
              private cdr: ChangeDetectorRef,
              private personalDataService: PersonalDataService) {
    const registeredCompany = config.data.registeredCompany;
    this.personalDataId = config.data.personalDataId;
    this.registeredCompanyForm = this.fb.group({
      id: [registeredCompany?.id],
      personalDataId: [registeredCompany?.personalDataId || config.data.personalDataId],
      name: [registeredCompany?.name, [Validators.required,]],
      occupation: [registeredCompany?.occupation, [Validators.required,]],
      annualTurnover: [registeredCompany?.annualTurnover, [Validators.required,]],
      description: [registeredCompany?.description, [Validators.required,]],
    });
  }

  public submit() {
    if (this.registeredCompanyForm.valid) {
      this.registeredCompanyForm.disable();
      const body = this.registeredCompanyForm.value as RegisteredCompanyModel;
      const submitRequest$ = this.registeredCompanyForm.value.id ? this.personalDataService.updateRegisteredCompany(body) :
        this.personalDataService.addRegisteredCompany(body);

      submitRequest$.subscribe({
        next: response => {
          if (response.succeed) {
            this.notifierService.handleSuccessRequest(REGISTERED_COMPANY_CREATED_SUCCESSFULLY);
            if (!this.registeredCompanyForm.value.id) {
              this.registeredCompanyForm.controls['id'].setValue(response.value);
              this.closeDialog(true);
            } else this.closeDialog(false, true);
          } else {
            this.notifierService.handleErrors(response.errors);
            this.registeredCompanyForm.enable();
          }
          this.cdr.detectChanges();
        },
        error: err => {
          this.notifierService.handleRequestError(err);
          this.registeredCompanyForm.enable();
          this.cdr.detectChanges();
        }
      })
    }
  }

  public closeDialog(added: boolean, edited?: boolean) {
    this.ref.close({added, edited, registeredCompany: this.registeredCompanyForm.value});
  }
}
