import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit,} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {
  DEFAULT_TABLE_ROWS_OPTIONS,
  LOCAL_STORAGE_PHONE_NUMBERS_LIMIT,
  PHONE_NUMBERS_LIMIT,
} from '../../../../constants';
import {PermissionsEnum} from '../../../../permissions.enum';
import {AuthService} from '../../../../services/auth.service';
import {TableLazyLoadEvent} from 'primeng/table';
import {PhoneNumberPopupComponent} from '../../../phone-number-popup/phone-number-popup.component';
import {DeletePopup} from '../../../delete-popup/delete-popup.component';
import {PHONE_NUMBER_DELETED} from '../../../../../../locale/multilingual-strings-constants';
import {DataConversionService} from '../../../../services/data-conversion.service';
import {TableFieldModel} from '../../../../model/table-field.model';
import {NotifierService} from '../../../../services/notifier.service';
import {PersonalDataService} from '../../../../../../../personal-data/src/lib/personal-data.service';
import {ToastService} from '../../../../services/toast.service';
import {MachineUserPhoneModel} from '../../../../../../../personal-data/src/lib/models/machine.model';
import {UtilService} from '../../../../services/util.service';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneNumberComponent implements OnInit {
  public hasDeletePermission = false;
  public hasWritePermission = false;
  public phones: MachineUserPhoneModel[] = [];
  public items: MenuItem[];
  public ref: DynamicDialogRef | undefined;
  public totalRecords: number;
  public loading = true;
  public PHONE_NUMBERS_LIMIT: number;
  public selectedPhoneNumber: MachineUserPhoneModel;
  public fields: TableFieldModel[] = [
    {
      name: 'numberType',
      displayName: 'Type',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    },
    {
      name: 'number',
      displayName: 'Phone Number',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    },
  ];

  @Input() public userId: string;

  constructor(
    private authService: AuthService,
    private notifierService: NotifierService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private dataConversionService: DataConversionService,
    private personalDataService: PersonalDataService,
    private toastService: ToastService,
    private utilService: UtilService
  ) {
  }

  public ngOnInit(): void {
    const storedLimit = localStorage.getItem(LOCAL_STORAGE_PHONE_NUMBERS_LIMIT);
    this.PHONE_NUMBERS_LIMIT = storedLimit && DEFAULT_TABLE_ROWS_OPTIONS.includes(+storedLimit) ? +storedLimit : PHONE_NUMBERS_LIMIT;
    this.hasDeletePermission = this.authService.hasPermission(PermissionsEnum.deletePhoneNumber);
    this.hasWritePermission = this.authService.hasPermission(PermissionsEnum.writePhoneNumber, [PermissionsEnum.deletePhoneNumber]);
    this.initPhoneNumber();
    this.initMenuItems();
  }


  public openAddPopup() {
    this.openPopup('Add a new phone number', {personalDataId: this.userId});
  }

  private openPopup(header: string, data: { personalDataId?: string, phone?: MachineUserPhoneModel }) {
    this.ref = this.dialogService.open(PhoneNumberPopupComponent, {
      header,
      width: this.utilService.getPopupResponsiveWidth(),
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data,
      maximizable: true,
    });
    this.ref.onClose.subscribe((result: { edited: boolean; added: boolean; phone: MachineUserPhoneModel }) => {
      this.handlePopupResult(result);
    });
  }

  public handlePopupResult(result: { edited: boolean; added: boolean; phone: MachineUserPhoneModel }) {
    if (result) {
      if (result.edited) {
        const phone = this.phones.find((phone: MachineUserPhoneModel) => phone.id === result.phone!.id)!;
        phone.number = result.phone.number;
        phone.numberType = result.phone.numberType;
        this.cdr.detectChanges();
      }
      if (result.added) {
        this.phones.push(result.phone);
        this.totalRecords++;
        this.cdr.detectChanges();
      }
    }
  }

  public refresh() {
    this.initPhoneNumber();
  }

  public initPhoneNumber(event?: TableLazyLoadEvent) {
    this.loading = true;
    localStorage.setItem(LOCAL_STORAGE_PHONE_NUMBERS_LIMIT, event?.rows?.toString() || PHONE_NUMBERS_LIMIT.toString());
    this.personalDataService.getPersonalDataPhoneNumbers(this.userId,
      (event ? event.first! / (event ? event.rows! : this.PHONE_NUMBERS_LIMIT) + 1 : 1), (event ? event.rows! : this.PHONE_NUMBERS_LIMIT)).subscribe({
      next: phoneNumberResponse => {
        this.loading = false;
        if (phoneNumberResponse.succeed) {
          if (!this.totalRecords && phoneNumberResponse.value.totalCount) {
            this.totalRecords = phoneNumberResponse.value.totalCount;
          }
          // if (phoneNumberResponse.value.entities.length) {
          this.phones = phoneNumberResponse.value.entities.map(ph => ({
            ...ph,
          }) as MachineUserPhoneModel);
          this.loading = false;
          this.cdr.detectChanges();
          // zip(...phoneNumberResponse.value.entities.map(phoneId => this.personalDataService.getPhoneNumberById(phoneId)))
          //   .subscribe({
          //     next: results => {
          //       if (!results.some(res => !res.succeed)) {
          //         this.phones.push(...results.map(r => r.value as MachineUserPhoneModel));
          //       } else {
          //         this.toastService.message$.next({severity: 'error', summary: 'Failure', detail: 'An Error occurred.',});
          //       }
          //       this.loading = false;
          //       this.cdr.detectChanges();
          //     },
          //     error: err => this.notifierService.handleRequestError(err),
          //   });
          // }
          this.cdr.detectChanges();
        } else {
          this.notifierService.handleErrors(phoneNumberResponse.errors);
        }
      },
      error: err => this.notifierService.handleRequestError(err)
    });
  }

  public initMenuItems(phone?: MachineUserPhoneModel) {
    this.items = [];

    if (this.hasWritePermission) {
      this.items.push(
        {
          label: 'Edit',
          command: () => {
            phone && this.openPopup(phone.numberType + ': User phone number', {phone});
          }
        });
    }

    this.items.push({
      label: 'Copy',
      items: [
        {
          label: 'ID',
          command: () => {
            phone && this.utilService.copyText(phone.id as string, 'Phone Number ID copied.');
          }
        }
      ]
    })

    if (this.hasDeletePermission) {
      this.items.push(
        {
          label: 'Delete',
          command: () => {
            phone && this.openDeletePhoneNumberPopup(phone);
          }
        });
    }
  }

  public initCmItems() {
    this.initMenuItems(this.selectedPhoneNumber);
  }

  private openDeletePhoneNumberPopup(phone: MachineUserPhoneModel) {
    this.ref = this.dialogService.open(DeletePopup, {
      header: 'Are you sure you want to delete this phone number?',
      width: 'fit-content',
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data: {
        id: phone.id,
        data: this.dataConversionService.convertData(
          [phone],
          {
            numberType: 'Type',
            number: 'Phone Number',
          }
        ),
      },
    });
    this.ref.onClose.subscribe((result?: {
      id: string,
      delete: boolean,
    }) => {
      if (result?.delete) {
        this.deletePhoneNumber(phone, result.id);
      }
    });
  }

  private deletePhoneNumber(phone: MachineUserPhoneModel, phoneId: string) {
    this.personalDataService.deletePhoneNumber(phoneId).subscribe({
      next: r => {
        if (r.succeed) {
          this.notifierService.handleSuccessRequest(PHONE_NUMBER_DELETED);
          this.phones.splice(this.phones.indexOf(phone), 1);
          this.cdr.detectChanges();
        } else {
          this.notifierService.handleErrors(r.errors);
        }
      },
      error: err => {
        this.notifierService.handleRequestError(err);
      },
    });
  }
}
