<!--  [scrollable]="true"-->
<!--  scrollHeight="flex"--> <!-- todo Arayik -->

<p-treeTable
  [rowHover]="true"
  [value]="organizationNodes"
  [selectionMode]="'single'"
  (onNodeSelect)="nodeSelect($event)"
  (onNodeExpand)="nodeExpand($event)"
  [contextMenu]="cm"
>


  <ng-template pTemplate="header">
    <tr>
      <th>
        <div class="header-organizations">
          <span>Organizations</span>
        </div>
      </th>
      <th class="plus-icon">
        <i
          *ngIf="hasPermissionToDeleteOrganizations || hasPermissionToWriteOrganizations"
          (click)="openAddOrganizationPopup()"
          class="pi pi-plus org-add-btn"
        ></i>
      </th>
    </tr>
    <ng-container *ngIf="loading">
      <tr *ngFor="let __ of [1, 2, 3, 4, 5]">
        <td class="empty-cell">
          <p-skeleton width="6em" styleClass="mb-2"></p-skeleton>
        </td>
      </tr>
    </ng-container>
  </ng-template>
  <ng-template pTemplate="body" let-rowNode>
    <tr class="{{rowNode.node.data === selectedOrganizationId ? 'p-highlight' : 'no-highlight'}}"
      [ttRow]="rowNode" [ttSelectableRow]="rowNode" (contextmenu)="onRightClick($event, rowNode)">
      <td>
        <div style="display: flex; align-items: center" class="item">
          <div style="display: flex; align-items: center">
            <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
          </div>
          {{ rowNode.node.label }}
        </div>
      </td>
      <td style="text-align: end; width: 50px; padding-right: 12px" class="burger-menu"
          *ngIf="hasPermissionToWriteOrganizations || hasPermissionToDeleteOrganizations">
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr *ngIf="!loading">
      <td colspan="2" class="no-organization-text">No organizations found.</td>
    </tr>
  </ng-template>
</p-treeTable>
<p-contextMenu #cm [model]="items"></p-contextMenu>
