<div *ngIf="data" class="user-table-wrapper">
  <table class="user-table ">
    <tr *ngFor="let field of data">
      <td>{{field.name}}</td>
      <td *ngIf="!field.isValueArray">{{field.value}}</td>
      <ng-container *ngIf="field.isValueArray">
        <td>
          <div *ngFor="let item  of field.value">{{item}}</div>
        </td>
      </ng-container>
    </tr>
  </table>

  <div class="popup-form__buttons">
    <p-button (click)="onDelete()" type="button" [label]="buttonText"></p-button>
  </div>
</div>
