import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  transform(value: any): string {
    if (!value) {
      return '';
    }
    const now = new Date();
    const targetDate = typeof value === 'string' ? new Date(value) : value;
    const diff = now.getTime() - targetDate.getTime();

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) {
      return years + (years === 1 ? ' year' : ' years') + ' ago';
    } else if (months > 0) {
      return months + (months === 1 ? ' month' : ' months') + ' ago';
    } else if (days > 0) {
      return days + (days === 1 ? ' day' : ' days') + ' ago';
    } else if (hours > 0) {
      return hours + (hours === 1 ? ' hour' : ' hours') + ' ago';
    } else if (minutes > 0) {
      return minutes + (minutes === 1 ? ' minute' : ' minutes') + ' ago';
    } else {
      return seconds + (seconds === 1 ? ' second' : ' seconds') + ' ago';
    }
  }
}
