import {Component, EventEmitter, Input, OnInit, Output, ViewChild,} from '@angular/core';
import {MenuItem, TreeNode} from 'primeng/api';
import {IconSizeModel} from '../../../../shared/model/icon-size.model';
import {FileEventService} from '../file-event.service';
import {ScrollerModule} from 'primeng/scroller';
import {Subscription} from 'rxjs';
import {HttpFileManagerService} from '../http-file-manager.service';
import {ContextMenu} from 'primeng/contextmenu';
import {UtilService} from '../../../../shared/services/util.service';

@Component({
  selector: 'app-files-thumbnail-viewer',
  templateUrl: './files-thumbnail-viewer.component.html',
  styleUrls: ['./files-thumbnail-viewer.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ScrollerModule, HttpFileManagerService]
})
export class FilesThumbnailViewerComponent implements OnInit {
  @Input() public selectedIconSize: IconSizeModel;
  @Input() public currentPath: string;
  @Input() public filesShownInExplorer: any[] = [];
  @Input() public loading: boolean;
  @Output() public onLazyLoad$: EventEmitter<{ first: number, last: number }> = new EventEmitter<{
    first: number,
    last: number
  }>();
  private filesSubscription: Subscription;
  @Input() currentNode: any;
  @Output() public selectedNodeInPath: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('cm') cm: ContextMenu;
  @Input() public selectedFile: TreeNode | null;
  @Output() public selectedFileChange: EventEmitter<TreeNode> = new EventEmitter<TreeNode>();
  @Output() public selectedFileChange$: EventEmitter<TreeNode> = new EventEmitter<TreeNode>();
  @Input() public contextMenuItems: MenuItem[];

  public get folderItemsCount() {
    return this.filesShownInExplorer.filter(f => f.isDirectory).length;
  }

  public get fileItemsCount() {
    return this.filesShownInExplorer.filter(f => !f.isDirectory).length;
  }

  public constructor(
    private fileEventService: FileEventService,
    private fileManagerService: HttpFileManagerService,
    public utilService: UtilService,
  ) {
  }

  public ngOnInit() {
    this.filesSubscription = this.fileManagerService.files$.subscribe(files => {
      this.filesShownInExplorer = files;
    });
  }

  public navigateToRoot() {
    this.fileEventService.navigateToRootDirectory();
  }

  public getPathParts(path: string): string[] {
    const cleanedPath = path.replace(/\/+/g, '/');
    return cleanedPath.replace(/^\/|\/$/g, '').split('/');
  }

  public navigateToPathPart(index: number): void {
    const pathParts = this.getPathParts(this.currentPath);
    const timesToGoUpTheTree = pathParts.length - index - 1;
    let node = this.currentNode;
    for (let i = 0; i < timesToGoUpTheTree && node; i++) {
      node = node.parent;
    }
    if (node) {
      if (this.currentNode.data.includes('\\') || this.currentNode.data.includes(':')) {
        this.fileEventService.openFolder(node.data);
      } else {
        this.selectedNodeInPath.emit(node);
      }
    }
  }

  public onFileView(node: TreeNode, event: MouseEvent): void {
    event.preventDefault();
    if (node.selectable) {
      this.fileEventService.triggerFileViewEvent(node);
    }
  }

  public getFileIconStyle(iconSize: string): { 'font-size': string } {
    switch (iconSize) {
      case 'extraLarge':
        return {'font-size': '8rem'};
      case 'large':
        return {'font-size': '6rem'};
      case 'medium':
        return {'font-size': '4rem'};
      default:
        return {'font-size': '2rem'};
    }
  }

  public getFileSkeletonStyle(iconSize: string): string {
    return iconSize + '-sk'
  }

  public findLastFileWithName(files: any[]): number {
    for (let i = files.length - 1; i >= 0; i--) {
      if (files[i] && files[i].name) {
        return i;
      }
    }
    return -1;
  }

  public onLazyLoadEvent(event: any) {
    this.onLazyLoad$.emit(event);
  }

  public onScrollDown() {
    const start = this.findLastFileWithName(this.filesShownInExplorer) + 1;
    const end = start + 20
    this.onLazyLoad$.emit({first: start, last: end});
  }

  public onContextMenu(event: any, file: any) {
    this.selectedFile = file;
    this.selectedFileChange.emit(this.selectedFile!);
    this.cm.target = event.currentTarget;
    this.cm.show(event);
  }

  public onFileClick(file: any) {
    if (file.isDirectory) {
      this.fileEventService.openFolder(file.data);
    }
  }

  public onHide() {
    this.selectedFile = null;
  }

}
