import {ChangeDetectionStrategy, Component, Input,} from '@angular/core';

@Component({
  selector: 'app-accordion-header',
  templateUrl: './accordion-header.component.html',
  styleUrls: ['./accordion-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class AccordionHeaderComponent {
  @Input() public title = '';
  @Input() public icons = '';

}
