<app-drawer *ngIf="!inNewWindow"
            (visibleChange)="onVisibleChange($event)"
            [visible]="visible"
            [header]="'Registry Editor: ' + terminalName">

  <div class="flex tools header-buttons-container" style="margin-bottom: 20px;">
    <div class="flex align-items-center gap-2 table__action-container"  (click)="refreshCurrentNode()" >
      <i class="pi pi-refresh navigation-img"></i>
      <span class="vertical-align-middle table__action-button" >Refresh</span>
    </div>
  </div>
  <div style="height: 100%">
    <ng-container [ngTemplateOutlet]="backgroundImageTemplate">


      <ng-container>
        <div style="box-shadow: 0 0 12px rgba(0, 0, 0, 0.2)" class="table-with-tree">
          <app-table-with-tree
            [isRegisterEditor]="isRegisterEditor"
            [currentNode]="currentNode"
            [machineId]="terminalId"
            [selectedIconSize]="selectedIconSize"
            [directories]="directories"
            [files]="files"
            [treeLoading]="loading"
            [loading]="loading"
            [selectedDirectory]="selectedDirectory"
            (nodeSelect$)="nodeSelect($event)"
            (deleteRegistryEntry)="getDeletedRegistryEntry($event)"
          ></app-table-with-tree>
        </div>
      </ng-container>


    </ng-container>
  </div>

</app-drawer>

<ng-template #backgroundImageTemplate>
  <div class="container" style=" display: flex;justify-content: center;">
    <div *ngIf="false"
         style="margin: 5em 0 30% 0"
         class="remote-desktop">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>
</ng-template>
