import {NgModule} from '@angular/core';
import {HeaderComponent} from './components/header/header.component';
import {LeftBarComponent} from './components/left-bar/left-bar.component';
import {CommonModule} from '@angular/common';
import {SidebarModule} from 'primeng/sidebar';
import {DrawerComponent} from './components/drawer/drawer.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {TableModule} from 'primeng/table';
import {AccordionModule} from 'primeng/accordion';
import {CalendarModule} from 'primeng/calendar';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {KeyFilterModule} from 'primeng/keyfilter';
import {AccordionHeaderComponent} from './components/employee/accordion-header/accordion-header.component';
import {EmployeeComponent} from './components/employee/employee.component';
import {
  WorkExperienceComponent
} from './components/employee/accordion-contents/work-experience/work-experience.component';
import {
  RegisteredCompaniesComponent
} from './components/employee/accordion-contents/registered-companies/registered-companies.component';
import {
  PsychologicalPortraitsComponent
} from './components/employee/accordion-contents/psychological-portraits/psychological-portraits.component';
import {LogsComponent} from './components/employee/accordion-contents/logs/logs.component';
import {NotesComponent} from './components/employee/accordion-contents/notes/notes.component';
import {SplitButtonModule} from 'primeng/splitbutton';
import {AddressesComponent} from './components/employee/accordion-contents/addresses/addresses.component';
import {EducationComponent} from './components/employee/accordion-contents/education/education.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {RelationsComponent} from './components/employee/accordion-contents/relations/relations.component';
import {EmployeePhotosComponent} from './components/employee/accordion-contents/images/employee-photos.component';
import {ImageModule} from 'primeng/image';
import {ImageCardComponent} from './components/employee/accordion-contents/images/image-card/image-card.component';
import {CardModule} from 'primeng/card';
import {TabViewModule} from 'primeng/tabview';
import {EmployeeReportComponent} from './components/employee/employee-report/employee-report.component';
import {DividerModule} from 'primeng/divider';
import {CheckboxModule} from 'primeng/checkbox';
import {MultiSelectModule} from 'primeng/multiselect';
import {TableComponent} from './components/table/table.component';
import {MenuModule} from 'primeng/menu';
import {TreeSelectModule} from 'primeng/treeselect';
import {FieldsetModule} from 'primeng/fieldset';
import {SkeletonModule} from 'primeng/skeleton';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {PhoneNumberComponent} from './components/employee/accordion-contents/phone-number/phone-number.component';
import {SocialMediaComponent} from './components/employee/accordion-contents/social-media/social-media.component';
import {EmailsComponent} from './components/employee/accordion-contents/emails/emails.component';
import {DeletePopup} from './components/delete-popup/delete-popup.component';
import {DepositsComponent} from './components/employee/accordion-contents/deposits/deposits.component';
import {SalaryComponent} from './components/employee/accordion-contents/salary/salary.component';
import {LoansComponent} from './components/employee/accordion-contents/loans/loans.component';
import {IconSizeDropdownComponent} from './components/icon-size-dropdown/icon-size-dropdown.component';
import {AddressPopupComponent} from './components/address-popup/address-popup.component';
import {EducationPopupComponent} from './components/education/education-popup.component';
import {WorkExperiencePopupComponent} from './components/work-experience-popup/work-experience-popup.component';
import {AddEmployeeComponent} from './components/add-employee/add-employee.component';
import {PhoneNumberPopupComponent} from './components/phone-number-popup/phone-number-popup.component';
import {SocialMediaPopupComponent} from './components/social-media-popup/social-media-popup.component';
import {EmailComponent} from './components/email-popup/email.component';
import {DepositPopupComponent} from './components/deposit-popup/deposit-popup.component';
import {SalaryPopupComponent} from './components/salary-popup/salary-popup.component';
import {LoanPopupComponent} from './components/loan-popup/loan-popup.component';
import {NoteComponent} from './components/note-popup/note.component';
import {
  EditPersonalInformationComponent
} from './components/edit-personal-information/edit-personal-information.component';
import {TooltipModule} from 'primeng/tooltip';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {
  RegisteredCompanyPopupComponent
} from './components/registered-company-popup/registered-company-popup.component';
import {
  AssignedMachinesComponent
} from './components/employee/accordion-contents/assigned-machines/assigned-machines.component';
import {RelationComponent} from './components/relation-popup/relation.component';
import {
  PsychologicalPortraitPopupComponent
} from './components/psychological-portrait-popup/psychological-portrait-popup.component';
import {RelatedEmployeeComponent} from './components/employee/related-employee.component';
import {OrganizationsTableComponent} from './components/organizations-table/organizations-table.component';
import {TreeTableModule} from 'primeng/treetable';
import {
  OrganizationsTreeDropdownComponent
} from './components/organizations-tree-combo-box/organizations-tree-dropdown.component';
import {FileStateIconPipe} from '../file-state.pipe';
import {SplitterModule} from 'primeng/splitter';
import {AutoScrollDirective} from './directives/auto-scroll.directive';
import {PartialDisableDirective} from './directives/partial-disable.directive';
import {ContextMenuModule} from 'primeng/contextmenu';
import {MenubarModule} from 'primeng/menubar';
import {PanelMenuModule} from 'primeng/panelmenu';
import {DateTimezonePipe} from './pipes/date-timezone.pipe';
import {ByteNormalizerPipe} from './pipes/byte-normalizer.pipe';
import {LongPressDirective} from './directives/long-press.directive';
import {CanvasJSAngularChartsModule} from '@canvasjs/angular-charts';
import {ProgressBar} from './components/progress/progress-bar.component';
import {TimeAgoPipe} from './pipes/time-ago.pipe';
import {UserSessionsComponent} from '../modules/identity/users/user-sessions/user-sessions.component';
import {TimeDiffPipe} from './pipes/time-diff.pipe';

@NgModule({
  declarations: [
    ProgressBar,
    HeaderComponent,
    LeftBarComponent,
    DrawerComponent,
    AccordionHeaderComponent,
    EmployeeComponent,
    RelatedEmployeeComponent,
    EmployeeReportComponent,
    WorkExperienceComponent,
    RegisteredCompaniesComponent,
    EmployeePhotosComponent,
    ImageCardComponent,
    PsychologicalPortraitsComponent,
    LogsComponent,
    AddressesComponent,
    EducationComponent,
    NotesComponent,
    ByteNormalizerPipe,
    DateTimezonePipe,
    TimeAgoPipe,
    TimeDiffPipe,
    RelationsComponent,
    TableComponent,
    PhoneNumberComponent,
    SocialMediaComponent,
    AssignedMachinesComponent,
    EmailsComponent,
    DeletePopup,
    DepositsComponent,
    SalaryComponent,
    LoansComponent,
    AddressPopupComponent,
    EducationPopupComponent,
    WorkExperiencePopupComponent,
    RegisteredCompanyPopupComponent,
    AddEmployeeComponent,
    PhoneNumberPopupComponent,
    SocialMediaPopupComponent,
    EmailComponent,
    DepositPopupComponent,
    SalaryPopupComponent,
    LoanPopupComponent,
    RelationComponent,
    NoteComponent,
    PsychologicalPortraitPopupComponent,
    EditPersonalInformationComponent,
    IconSizeDropdownComponent,
    OrganizationsTableComponent,
    OrganizationsTreeDropdownComponent,
    FileStateIconPipe,
    AutoScrollDirective,
    PartialDisableDirective,
    LongPressDirective,
    UserSessionsComponent,
  ],
  imports: [
    ClipboardModule,
    CommonModule,
    SidebarModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule,
    TableModule,
    AccordionModule,
    CalendarModule,
    DropdownModule,
    InputTextareaModule,
    KeyFilterModule,
    SplitButtonModule,
    ImageModule,
    CardModule,
    TabViewModule,
    DividerModule,
    CheckboxModule,
    MultiSelectModule,
    MenuModule,
    TreeSelectModule,
    FieldsetModule,
    SkeletonModule,
    TieredMenuModule,
    TooltipModule,
    ProgressSpinnerModule,
    TreeTableModule,
    SplitterModule,
    ContextMenuModule,
    MenubarModule,
    PanelMenuModule,
    CanvasJSAngularChartsModule
  ],
    exports: [
        HeaderComponent,
        LeftBarComponent,
        DrawerComponent,
        AccordionHeaderComponent,
        EmployeeComponent,
        RelatedEmployeeComponent,
        EmployeeReportComponent,
        WorkExperienceComponent,
        RegisteredCompaniesComponent,
        EmployeePhotosComponent,
        ImageCardComponent,
        PsychologicalPortraitsComponent,
        LogsComponent,
        AddressesComponent,
        EducationComponent,
        NotesComponent,
        ByteNormalizerPipe,
        DateTimezonePipe,
        RelationsComponent,
        TableComponent,
        IconSizeDropdownComponent,
        OrganizationsTableComponent,
        OrganizationsTreeDropdownComponent,
        AutoScrollDirective,
        PartialDisableDirective,
        UserSessionsComponent,
        ProgressBar,
    ],
  providers: []
})
export class SharedModule {
}
