import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {AuthService} from '../../../../shared/services/auth.service';
import {NotifierService} from '../../../../shared/services/notifier.service';
import {NavigationService} from '../../../../shared/services/navigation.service';
import {MAX_PAGING_LIMIT, RIGHT_BAR_SYNC_CONFIGURATIONS} from '../../../../shared/constants';
import {PermissionsEnum} from '../../../../shared/permissions.enum';
import {FileServiceService} from '../../../../../../file-service/src/lib/file-service.service';
import {of, switchMap, zip} from 'rxjs';
import {FileCategoryModel} from '../../../../../../file-service/src/lib/models/file-category.model';
import {CheckboxChangeEvent} from 'primeng/checkbox/checkbox.interface';
import {
  CONFIGURATIONS_SAVED_SUCCESSFULLY,
  SCANNING_STARTED
} from '../../../../../locale/multilingual-strings-constants';
import {TerminalService} from '../../../../../../organization-url-redirection-aggregator/src/lib/terminal.service';

@Component({
  selector: 'app-sync-configurations',
  templateUrl: './sync-configurations.component.html',
  styleUrls: ['./sync-configurations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SyncConfigurationsComponent {
  @Input() public machineName: string;
  @Input() public isOnline: boolean;
  private _visible: boolean;
  private _terminalId: string;
  public configurationsForm: FormGroup;
  public hasManageSyncConfigurationPermission = false;
  public isReady = false;
  public allCategories: FileCategoryModel[] = [];
  public selectedCategoryIds: string[] = [];
  public dataSyncingInProgress = false;

  get terminalId(): string {
    return this._terminalId;
  }

  @Input() set terminalId(value: string) {
    this._terminalId = this._terminalId || value;
  }

  get visible(): boolean {
    return this._visible;
  }

  @Input()
  set visible(value: boolean) {
    this._visible = value;
    this.hasManageSyncConfigurationPermission =
      this.authService.hasPermission(PermissionsEnum.writeSyncConfiguration);
    this.getConfigurationData();
  }

  public constructor(
    private notifierService: NotifierService,
    private terminalService: TerminalService,
    private cdr: ChangeDetectorRef,
    private navigationService: NavigationService,
    private fileServiceService: FileServiceService,
    public authService: AuthService,
  ) {
  }

  public getConfigurationData() {
    this.getFileCategoriesData();
    //   this.httpDataService.getConfigurationsByMachineId(this.machineId).subscribe(
    //     {
    //       next: configurationsResponse => {
    //         if (configurationsResponse.succeed) {
    //           this.setupConfigurationsForm(configurationsResponse.value);
    //           this.isReady = true;
    //         } else {
    //           this.notifierService.handleErrors(configurationsResponse.errors);
    //         }
    //         this.cdr.detectChanges();
    //       },
    //       error: err => this.notifierService.handleRequestError(err)
    //     }
    //   )
  }

  // private getFileCategoriesData(): void {
  //   zip(
  //     this.fileServiceService.getFileCategories(1, MAX_PAGING_LIMIT),
  //     this.fileServiceService.getTerminalFileCategories(this.machineId, 1, MAX_PAGING_LIMIT)
  //   ).subscribe({
  //     next: ([all, terminalCategories]) => {
  //       if (all.succeed && terminalCategories.succeed) {
  //         this.allCategories = all.value.entities;
  //         this.selectedCategories = terminalCategories.value.entities;
  //       } else {
  //         this.notifierService.handleErrors([...all.errors, ...terminalCategories.errors]);
  //       }
  //     },
  //     error: err => this.notifierService.handleRequestError(err),
  //   })
  // }


  private getFileCategoriesData(): void {
    zip(
      this.fileServiceService.getFileCategories(1, MAX_PAGING_LIMIT),
      this.fileServiceService.getTerminalFileCategories(this.terminalId, 1, MAX_PAGING_LIMIT)
    ).pipe(switchMap(([all, terminalCategories]) => {
      if (all.succeed && terminalCategories.succeed) {
        const allCategories = all.value.entities;
        this.selectedCategoryIds = terminalCategories.value.entities;
        const observers = allCategories.map(categoryId => this.fileServiceService.getFileCategory(categoryId));
        return zip(observers)
      } else {
        this.notifierService.handleErrors([...all.errors, ...terminalCategories.errors]);
        return of();
      }
    })).subscribe({
      next: res => {
        if (res) {
          res.forEach(response => {
            if (response.errors.length) {
              this.notifierService.handleErrors(response.errors);
            } else {
              this.allCategories.push(response.value);
            }
          });
          this.isReady = true;
          this.cdr.detectChanges();
        }
      },
      error: err => this.notifierService.handleRequestError(err),
    })
  }

  public changeConfig(categoryId: string, event: CheckboxChangeEvent): void {
    const body = {
      'terminalId': this.terminalId,
      'fileCategoryId': categoryId
    }
    if (event.checked) {
      this.selectedCategoryIds.push(categoryId);
      this.fileServiceService.addTerminalFileCategory(body).subscribe({
        next: res => {
          if (res.succeed) {
            this.notifierService.handleSuccessRequest(CONFIGURATIONS_SAVED_SUCCESSFULLY);
          } else {
            this.notifierService.handleErrors(res.errors);
          }
        },
        error: err => this.notifierService.handleRequestError(err),
      })
    } else {
      this.selectedCategoryIds = this.selectedCategoryIds.filter(id => id !== categoryId);
      this.fileServiceService.deleteTerminalFileCategory(body).subscribe({
        next: res => {
          if (res.succeed) {
            this.notifierService.handleSuccessRequest(CONFIGURATIONS_SAVED_SUCCESSFULLY);
          } else {
            this.notifierService.handleErrors(res.errors);
          }
        },
        error: err => this.notifierService.handleRequestError(err),
      })
    }
  }

  public startSyncData() {
    this.dataSyncingInProgress = true;
    this.cdr.detectChanges();
    this.terminalService.syncTerminal(this.terminalId).subscribe({
      next: res => {
        this.dataSyncingInProgress = false;
        this.cdr.detectChanges();
        if (res.succeed) {
          this.notifierService.handleSuccessRequest(SCANNING_STARTED);
        } else this.notifierService.handleErrors(res.errors);
      },
      error: err => {
        this.dataSyncingInProgress = false;
        this.cdr.detectChanges();
        this.notifierService.handleRequestError(err);
      }
    });
  }

  // private setupConfigurationsForm(value: ConfigurationModel): void {
  //   this.configurationsForm = new FormGroup({
  //     machineId: new FormControl(this.machineId),
  //     syncEnabled: new FormControl<boolean>(value.syncEnabled),
  //     officeFiles: new FormControl<boolean>(value.officeFiles),
  //     textFiles: new FormControl<boolean>(value.textFiles),
  //     imageFiles: new FormControl<boolean>(value.imageFiles),
  //     videoFiles: new FormControl<boolean>(value.videoFiles),
  //   });
  //   this.hasManageSyncConfigurationPermission ?
  //     this.configurationsForm.enable() : this.configurationsForm.disable();
  // }

  // public saveConfigurations() {
  //   this.httpDataService.saveMachineConfigurations(this.configurationsForm.value).subscribe({
  //     next: res => {
  //       if (res.succeed) {
  //         this.notifierService.handleSuccessRequest(CONFIGURATIONS_SAVED_SUCCESSFULLY);
  //       } else {
  //         this.notifierService.handleErrors(res.errors);
  //       }
  //     },
  //     error: err => this.notifierService.handleRequestError(err)
  //   });
  // }

  public onVisibleChange(event: boolean) {
    if (!event) {
      this.navigationService.closeRightBarPage(RIGHT_BAR_SYNC_CONFIGURATIONS);
    }
  }

}
