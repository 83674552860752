import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {REQUEST_ERROR} from '../../../locale/multilingual-strings-constants';
import {DEFAULT_LOCALE} from '../constants';
import {MultilingualStringModel} from '../model/multilingual-string.model';
import {ToastService} from './toast.service';

@Injectable()
export class NotifierService {
  constructor(private toastService: ToastService,) {
  }

  public handleRequestError(error: HttpErrorResponse): void {
    console.error(error);
    this.toastService.message$.next({
      severity: 'error',
      summary: 'Failure',
      detail: REQUEST_ERROR[DEFAULT_LOCALE],
    });
  }

  public handleErrors(errors: string[]): void {
    if (errors && errors.length) {
      console.warn(errors);
      this.toastService.message$.next({
        severity: 'error',
        summary: 'Failure',
        detail: errors.join('\n'),
      });
    }
  }

  public handleSuccessRequest(detail: MultilingualStringModel, severity?: string, summary?: string): void {
    this.toastService.message$.next({
      severity: severity || 'success',
      summary: summary === undefined ? 'Success' : summary,
      detail: detail[DEFAULT_LOCALE],
    });
  }
}
