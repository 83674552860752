import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {MachineUserSalaryModel,} from '../../../../model/machine/machine.model';
import {MenuItem} from 'primeng/api';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {AuthService} from '../../../../services/auth.service';
import {DEFAULT_TABLE_ROWS_OPTIONS, LOCAL_STORAGE_SALARIES_LIMIT, SALARIES_LIMIT} from '../../../../constants';
import {PermissionsEnum} from '../../../../permissions.enum';
import {TableLazyLoadEvent} from 'primeng/table';
import {DeletePopup} from '../../../delete-popup/delete-popup.component';
import {DataConversionService} from '../../../../services/data-conversion.service';
import {SALARY_DELETED} from '../../../../../../locale/multilingual-strings-constants';
import {SalaryPopupComponent} from '../../../salary-popup/salary-popup.component';
import {TableFieldModel} from '../../../../model/table-field.model';
import {NotifierService} from '../../../../services/notifier.service';
import {PersonalDataService} from '../../../../../../../personal-data/src/lib/personal-data.service';
import {FieldTypesEnum} from '../../../../field-types.enum';
import {UtilService} from '../../../../services/util.service';

@Component({
  selector: 'app-salary',
  templateUrl: './salary.component.html',
  styleUrls: ['./salary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalaryComponent {
  public hasDeletePermission = false;
  public hasWritePermission = false;
  public salaries: MachineUserSalaryModel[];
  public items: MenuItem[];
  public ref: DynamicDialogRef | undefined;
  public totalRecords: number;
  public loading = true;
  public SALARIES_LIMIT: number;
  public selectedSalary: MachineUserSalaryModel;
  public fields: TableFieldModel[] = [
    {
      name: 'company',
      displayName: 'Company',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    },
    {
      name: 'amount',
      displayName: 'Amount',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    },

    {
      name: 'from',
      displayName: 'From',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
      type: FieldTypesEnum.date,
    },
    {
      name: 'to',
      displayName: 'To',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
      type: FieldTypesEnum.date,
    },
  ];

  @Input() public userId: string;

  constructor(
    private authService: AuthService,
    private notifierService: NotifierService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private dataConversionService: DataConversionService,
    private personalDataService: PersonalDataService,
    private utilService: UtilService
  ) {
  }

  public ngOnInit(): void {
    const storedLimit = localStorage.getItem(LOCAL_STORAGE_SALARIES_LIMIT);
    this.SALARIES_LIMIT = storedLimit && DEFAULT_TABLE_ROWS_OPTIONS.includes(+storedLimit) ? +storedLimit : SALARIES_LIMIT;
    this.hasDeletePermission = this.authService.hasPermission(PermissionsEnum.deleteSalary);
    this.hasWritePermission = this.authService.hasPermission(PermissionsEnum.writeSalary, [PermissionsEnum.deleteSalary]);
    this.initSalaries();
    this.initMenuItems();
  }


  public openAddPopup() {
    this.openPopup('Add a new salary', {personalDataId: this.userId});
  }

  private openPopup(header: string, data: { personalDataId?: string, salary?: MachineUserSalaryModel }) {
    this.ref = this.dialogService.open(SalaryPopupComponent, {
      header,
      width: this.utilService.getPopupResponsiveWidth(),
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data,
      maximizable: true,
    });
    this.ref.onClose.subscribe((result: { edited: boolean; added: boolean; salary: MachineUserSalaryModel }) => {
      this.handlePopupResult(result);
    });
  }

  public handlePopupResult(result: { edited: boolean; added: boolean; salary: MachineUserSalaryModel }) {
    if (result) {
      if (result.edited) {
        const salary = this.salaries.find((salary: MachineUserSalaryModel) => salary.id === result.salary!.id)!;
        salary.company = result.salary.company;
        salary.from = result.salary.from;
        salary.to = result.salary.to;
        salary.amount = result.salary.amount;
        salary.createdAt = result.salary.createdAt;
        salary.percent = result.salary.percent;
        this.cdr.detectChanges();
      }
      if (result.added) {
        this.salaries.push(result.salary);
        this.totalRecords++;
        this.cdr.detectChanges();
      }
    }
  }

  public refresh() {
    this.initSalaries();
  }

  public initSalaries(event?: TableLazyLoadEvent) {
    this.loading = true;
    this.SALARIES_LIMIT = event?.rows || SALARIES_LIMIT;
    localStorage.setItem(LOCAL_STORAGE_SALARIES_LIMIT, this.SALARIES_LIMIT.toString());
    this.personalDataService.getAllSalaries(this.userId,
      (event ? event.first! / (event ? event.rows! : this.SALARIES_LIMIT) + 1 : 1), (event ? event.rows! : this.SALARIES_LIMIT)).subscribe({
      next: salaryResponse => {
        this.loading = false;
        if (salaryResponse.succeed) {
          if (!this.totalRecords && salaryResponse.value.totalCount) {
            this.totalRecords = salaryResponse.value.totalCount;
          }
          // if (salaryResponse.value.entities.length) {
          this.salaries = salaryResponse.value.entities.map(a => ({
            ...a,
          }));
          this.loading = false;
          this.cdr.detectChanges();
          // }
          this.cdr.detectChanges();
        } else {
          this.notifierService.handleErrors(salaryResponse.errors);
        }
      },
      error: err => this.notifierService.handleRequestError(err)
    });
  }

  public initMenuItems(salary?: MachineUserSalaryModel) {
    this.items = [];

    if (this.hasWritePermission) {
      this.items.push(
        {
          label: 'Edit',
          command: () => {
            salary && this.openPopup(salary.company + ': User salary', {salary: salary});
          }
        });
    }

    this.items.push({
      label: 'Copy',
      items: [
        {
          label: 'ID',
          command: () => {
            salary && this.utilService.copyText(salary.id, 'Salary ID copied.');
          }
        }
      ]
    })

    if (this.hasDeletePermission) {
      this.items.push(
        {
          label: 'Delete',
          command: () => {
            salary && this.openDeleteSalaryPopup(salary);
          }
        });
    }
  }

  public initCmItems() {
    this.initMenuItems(this.selectedSalary);
  }

  private openDeleteSalaryPopup(salary: MachineUserSalaryModel) {
    this.ref = this.dialogService.open(DeletePopup, {
      header: 'Are you sure you want to delete this salary?',
      width: 'fit-content',
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data: {
        id: salary.id,
        data: this.dataConversionService.convertData(
          [salary],
          {
            company: 'Company',
            amount: 'Amount',
          }
        ),
      },
    });
    this.ref.onClose.subscribe((result?: {
      id: string,
      delete: boolean,
    }) => {
      if (result?.delete) {
        this.deleteSalary(salary, result.id);
      }
    });
  }

  private deleteSalary(salary: MachineUserSalaryModel, salaryId: string) {
    this.personalDataService.deleteSalary(salaryId).subscribe({
      next: r => {
        if (r.succeed) {
          this.notifierService.handleSuccessRequest(SALARY_DELETED);
          this.salaries.splice(this.salaries.indexOf(salary), 1);
          this.cdr.detectChanges();
        } else {
          this.notifierService.handleErrors(r.errors);
        }
      },
      error: err => {
        this.notifierService.handleRequestError(err);
      },
    });
  }
}
