import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appAutoScroll]'
})
export class AutoScrollDirective implements OnChanges {

  @Input() appAutoScroll: any;

  constructor(private el: ElementRef) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['appAutoScroll']) {
      this.scrollToBottom();
    }
  }

  private scrollToBottom(): void {
    const textarea = this.el.nativeElement;
    textarea.scrollTop = textarea.scrollHeight;
  }
}
