import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TableFieldModel} from '../../../../model/table-field.model';
import {HttpDataService} from '../../../../services/http/http-data.service';
import {NotifierService} from '../../../../services/notifier.service';
import {
  DEFAULT_TABLE_ROWS_OPTIONS,
  LOCAL_STORAGE_RELATIONS_LIMIT,
  RELATIONS_LIMIT,
  RIGHT_BAR_ADD_PERSONAL_DATA_RELATIONS,
  RIGHT_BAR_RELATED_EMPLOYEE_ADDRESSES,
  RIGHT_BAR_RELATED_EMPLOYEE_ASSIGNED_MACHINES,
  RIGHT_BAR_RELATED_EMPLOYEE_DEPOSITS,
  RIGHT_BAR_RELATED_EMPLOYEE_EDUCATION,
  RIGHT_BAR_RELATED_EMPLOYEE_EMAILS,
  RIGHT_BAR_RELATED_EMPLOYEE_LOANS,
  RIGHT_BAR_RELATED_EMPLOYEE_NOTES,
  RIGHT_BAR_RELATED_EMPLOYEE_PERSONAL_INFORMATION,
  RIGHT_BAR_RELATED_EMPLOYEE_PHONE,
  RIGHT_BAR_RELATED_EMPLOYEE_PHOTOS,
  RIGHT_BAR_RELATED_EMPLOYEE_PSYCHOLOGICAL_PORTRAITS,
  RIGHT_BAR_RELATED_EMPLOYEE_REGISTERED_COMPANIES,
  RIGHT_BAR_RELATED_EMPLOYEE_REPORT,
  RIGHT_BAR_RELATED_EMPLOYEE_SALARY,
  RIGHT_BAR_RELATED_EMPLOYEE_SOCIAL_MEDIA,
  RIGHT_BAR_RELATED_EMPLOYEE_URL_ADMIN,
  RIGHT_BAR_RELATED_EMPLOYEE_WORK_EXPERIENCE,
  RIGHT_BAR_RELATED_EMPLOYEE,
} from '../../../../constants';
import {TableLazyLoadEvent} from 'primeng/table';
import {DataConversionService} from '../../../../services/data-conversion.service';
import {DeletePopup} from '../../../delete-popup/delete-popup.component';
import {RELATION_DELETED} from '../../../../../../locale/multilingual-strings-constants';
import {RelationComponent} from '../../../relation-popup/relation.component';
import {NavigationService} from '../../../../services/navigation.service';
import {zip} from 'rxjs';
import {ToastService} from '../../../../services/toast.service';
import {FieldSortTypesEnum} from '../../../../field-sort-types.enum';
import {MachineUserModel} from '../../../../model/machine/machine.model';
import {EditPersonalInformationComponent} from '../../../edit-personal-information/edit-personal-information.component';
import {RelationModel} from '../../../../model/employee/relation.model';
import {PersonalDataService} from '../../../../../../../personal-data/src/lib/personal-data.service';
import {UtilService} from '../../../../services/util.service';

@Component({
  selector: 'app-relations',
  templateUrl: './relations.component.html',
  styleUrls: ['./relations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelationsComponent {
  private _userId: string;
  public hasDeletePermission = true; // todo
  public hasWritePermission = true; // todo
  public relations: RelationModel[];
  public relatedMachineUsers: (MachineUserModel & RelationModel)[];
  public items: MenuItem[];
  public ref: DynamicDialogRef | undefined;
  public totalRecords: number;
  public loading = true;
  public RELATIONS_LIMIT: number;
  public selectedRelation: MachineUserModel & RelationModel;

  get userId(): string {
    return this._userId;
  }

  @Input() set userId(value: string) {
    this._userId = this._userId || value;
  }

  public fields: TableFieldModel[] = [
    {
      name: 'identifier',
      displayName: 'ID',
      sortable: true,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    },
    {
      name: 'firstName',
      displayName: 'First Name',
      sortable: true,
      sortType: FieldSortTypesEnum.string,
      isCopy: false,
      globalFilter: false,
      searchable: true,
    },
    {
      name: 'lastName',
      displayName: 'Last Name',
      sortable: true,
      sortType: FieldSortTypesEnum.string,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    },
    {
      name: 'relationType',
      displayName: 'Relation Type',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    }
  ];

  constructor(
    private httpDataService: HttpDataService,
    private notifierService: NotifierService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private dataConversionService: DataConversionService,
    private navigationService: NavigationService,
    private toastService: ToastService,
    private personalDataService: PersonalDataService,
    private utilService: UtilService
  ) {
  }

  public ngOnInit(): void {
    const storedLimit = localStorage.getItem(LOCAL_STORAGE_RELATIONS_LIMIT);
    this.RELATIONS_LIMIT = storedLimit && DEFAULT_TABLE_ROWS_OPTIONS.includes(+storedLimit) ? +storedLimit : RELATIONS_LIMIT;
    this.navigationService.openRightBar$.subscribe(res => {
      if (res.value === RIGHT_BAR_RELATED_EMPLOYEE && !res.opened && res.childValue === RIGHT_BAR_RELATED_EMPLOYEE_PERSONAL_INFORMATION) {
        this.refresh();
      }
    });
    this.initData();
  }

  public goToAddPersonalDataRelations() {
    this.navigationService.openRightBarPage(RIGHT_BAR_ADD_PERSONAL_DATA_RELATIONS, {
      cloneFor: this.userId, // todo
      opened: true
    });
  }

  private openPopup(header: string, relatedMachineUser: MachineUserModel & RelationModel) {
    this.ref = this.dialogService.open(RelationComponent, {
      header,
      width: this.utilService.getPopupResponsiveWidth(),
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data: {
        relation: this.relations.find(r => r.childId === relatedMachineUser.id),
        relationName: relatedMachineUser.firstName + ' ' + relatedMachineUser.lastName,
      },
      maximizable: true,
    });
    this.ref.onClose.subscribe((result: true | undefined) => {
      if (result) {
        this.refresh();
      }
    });
  }

  public refresh() {
    this.loading = true;
    this.cdr.detectChanges();
    this.initData();
  }

  public initData(event?: TableLazyLoadEvent) {
    this.RELATIONS_LIMIT = event?.rows || RELATIONS_LIMIT;
    localStorage.setItem(LOCAL_STORAGE_RELATIONS_LIMIT, this.RELATIONS_LIMIT.toString());
    this.personalDataService.getPersonalDataRelations(this.userId,
      (event ? event.first! : 1), (event ? event.rows! : this.RELATIONS_LIMIT)).subscribe({
      next: relationsResponse => {
        this.loading = false;
        if (relationsResponse.succeed) {
          if (!this.totalRecords && relationsResponse.totalItems) {
            this.totalRecords = relationsResponse.totalItems;
          }
          this.relations = relationsResponse.value;
          if (this.relations.length) {
            zip(...this.relations.map(r => this.personalDataService.getMachineUserById(r.childId || '')))
              .subscribe({
                next: responses => {
                  if (!responses.some(res => !res.succeed)) {
                    // this.relatedMachineUsers = responses.map(response => {
                    //   const machineUser = response.value;
                    //   return {
                    //     ...machineUser,
                    //     relationType: this.relations.find(rel => rel.childId === machineUser.id)?.relationType || '',
                    //   };
                    // });
                    this.cdr.detectChanges();
                  } else {
                    this.toastService.message$.next({
                      severity: 'error',
                      summary: 'Failure',
                      detail: 'An Error occurred.',
                    });
                  }
                },
                error: err => this.notifierService.handleRequestError(err),
              });
          } else {
            this.relatedMachineUsers = [];
            this.cdr.detectChanges();
          }
        } else {
          this.notifierService.handleErrors(relationsResponse.errors);
        }
      },
      error: err => this.notifierService.handleRequestError(err)
    });
  }

  public initMenuItems(relatedMachineUser: MachineUserModel & RelationModel) {
    this.items = [];

    if (this.hasWritePermission) {
      this.items.push(
        {
          label: 'Edit',
          command: () => {
            this.openPopup('Edit user relation', relatedMachineUser);
          }
        });
    }

    if (this.hasDeletePermission) {
      this.items.push(
        {
          label: 'Delete',
          command: () => {
            const rel = this.relations.find(r => r.childId === relatedMachineUser.id);
            if (rel) {
              this.openDeleteRelationPopup(rel);
            }
          }
        });
    }

    this.items.push(
      {separator: true},
      {
        label: 'Data Management',
        items: [
          {
            label: 'Data Report',
            command: () => this.showEmployeeReport(relatedMachineUser)
          },
          {
            label: 'Personal Information',
            command: () => {
              this.openEditPersonalInfoPopup(relatedMachineUser,
                relatedMachineUser.firstName + relatedMachineUser.lastName + ': Edit personal information'
              );
            }
          },
        ]
      },
      {
        label: 'Contacts',
        items: [
          {
            label: 'Phone numbers',
            command: () => this.editEmployee(relatedMachineUser, RIGHT_BAR_RELATED_EMPLOYEE_PHONE),
          },
          {
            label: 'Addresses',
            command: () => this.editEmployee(relatedMachineUser, RIGHT_BAR_RELATED_EMPLOYEE_ADDRESSES)
          },
          {
            label: 'Social media',
            command: () => this.editEmployee(relatedMachineUser, RIGHT_BAR_RELATED_EMPLOYEE_SOCIAL_MEDIA),
          },
          {
            label: 'Emails',
            command: () => this.editEmployee(relatedMachineUser, RIGHT_BAR_RELATED_EMPLOYEE_EMAILS),

          },
        ]
      },
      {
        label: 'URL Redirection',
        command: () => this.editEmployee(relatedMachineUser, RIGHT_BAR_RELATED_EMPLOYEE_URL_ADMIN)
      },
      {
        label: 'Professional Information',
        items: [
          {
            label: 'Education',
            command: () => this.editEmployee(relatedMachineUser, RIGHT_BAR_RELATED_EMPLOYEE_EDUCATION)
          },
          {
            label: 'Work experience',
            command: () => this.editEmployee(relatedMachineUser, RIGHT_BAR_RELATED_EMPLOYEE_WORK_EXPERIENCE)
          },
        ]
      },
      {
        label: 'Financial information',
        items: [
          {
            label: 'Salary',
            command: () => this.editEmployee(relatedMachineUser, RIGHT_BAR_RELATED_EMPLOYEE_SALARY),
          },
          {
            label: 'Deposits',
            command: () => this.editEmployee(relatedMachineUser, RIGHT_BAR_RELATED_EMPLOYEE_DEPOSITS),
          },
          {
            label: 'Loans',
            command: () => this.editEmployee(relatedMachineUser, RIGHT_BAR_RELATED_EMPLOYEE_LOANS),
          },
        ]
      },
      {
        label: 'Additional Information',
        items: [
          {
            label: 'Companies',
            command: () => this.editEmployee(relatedMachineUser, RIGHT_BAR_RELATED_EMPLOYEE_REGISTERED_COMPANIES)
          },
          {
            label: 'Notes',
            command: () => this.editEmployee(relatedMachineUser, RIGHT_BAR_RELATED_EMPLOYEE_NOTES)
          },
          {
            label: 'Psychological portraits',
            command: () => this.editEmployee(relatedMachineUser, RIGHT_BAR_RELATED_EMPLOYEE_PSYCHOLOGICAL_PORTRAITS)
          },
          {
            label: 'Photos',
            command: () => this.editEmployee(relatedMachineUser, RIGHT_BAR_RELATED_EMPLOYEE_PHOTOS)
          },
        ]
      },
      {
        label: 'Machines',
        command: () => this.editEmployee(relatedMachineUser, RIGHT_BAR_RELATED_EMPLOYEE_ASSIGNED_MACHINES)
      }
    );
  }

  public initCmItems() {
    this.initMenuItems(this.selectedRelation);
  }

  private openEditPersonalInfoPopup(user: MachineUserModel, header: string) {
    this.ref = this.dialogService.open(EditPersonalInformationComponent, {
      header,
      width: this.utilService.getPopupResponsiveWidth(),
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data: {user},
      maximizable: true,
    });

    this.ref.onClose.subscribe((result?: {
      succeed: boolean,
      value: MachineUserModel,
    }) => {
      if (result) {
        this.refresh();
      }
    });
  }

  public showEmployeeReport(user: MachineUserModel) {
    this.navigationService.openRightBarPage(RIGHT_BAR_RELATED_EMPLOYEE, {
      machineId: '',
      userId: user.id,
      opened: true,
      machineName: user.firstName + ' ' + user.lastName + ' '
    }, undefined, RIGHT_BAR_RELATED_EMPLOYEE_REPORT);
  }

  public editEmployee(user: MachineUserModel, child?: string) {
    this.navigationService.openRightBarPage(RIGHT_BAR_RELATED_EMPLOYEE,
      {
        machineId: '',
        userId: user.id,
        opened: true,
        machineName: user.firstName + ' ' + user.lastName + ' '
      },
      undefined, child);
  }

  private openDeleteRelationPopup(relation: RelationModel) {
    this.ref = this.dialogService.open(DeletePopup, {
      header: 'Are you sure you want to delete this relation?',
      width: 'fit-content',
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data: {
        id: relation.id,
        data: this.dataConversionService.convertData(
          [relation],
          {
            personalData: 'personalData',
            personalDataId: 'personalDataId',
            relationType: 'relationType',
          }
        ),
      },
    });
    this.ref.onClose.subscribe((result?: {
      id: string,
      delete: boolean,
    }) => {
      if (result?.delete) {
        this.deleteRelation(result.id);
      }
    });
  }

  private deleteRelation(relationId: string) {
    this.personalDataService.deleteRelation(relationId).subscribe({
      next: r => {
        if (r.succeed) {
          this.notifierService.handleSuccessRequest(RELATION_DELETED);
          this.refresh();
        } else {
          this.notifierService.handleErrors(r.errors);
        }
      },
      error: err => {
        this.notifierService.handleRequestError(err);
      },
    });
  }
}
