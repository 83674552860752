<div style="min-height: 254px" *ngIf="socialMediaForm ">
  <form [formGroup]="socialMediaForm" (ngSubmit)="submit()">
    <div class="form">
      <div class="input-box">
      <span class="p-float-label">
       <p-dropdown
         [style]="{width: '100%'}"
         [ngClass]="{'ng-dirty': socialMediaForm.get('name')?.touched}"
         id="name"
         [options]="socialMediaNames"
         [filter]="true"
         formControlName="name"
         placeholder="Name"
       ></p-dropdown>
        <label htmlFor="name">Name*</label>
      </span>
        <small
          id="name-help"
          class="p-error"
          *ngIf="socialMediaForm.get('name')?.touched && socialMediaForm.get('name')?.hasError('required')">
          The name is required.
        </small>
      </div>
      <div class="input-box">
      <span class="p-float-label">
        <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': socialMediaForm.get('accountId')?.touched}"
               id="accountId"
               type="text" pInputText formControlName="accountId"/>
        <label htmlFor="accountId">Account Id*</label>
      </span>
        <small
          id="phone-number-help"
          class="p-error"
          *ngIf="socialMediaForm.get('accountId')?.touched && socialMediaForm.get('accountId')?.hasError('required')">
          The account Id is required.
        </small>
      </div>

      <div class="input-box buttons">
        <p-button [style]="{width: '120px'}" [disabled]="socialMediaForm.invalid || socialMediaForm.disabled"
                  type="submit"
                  label="Save"></p-button>
      </div>
    </div>
  </form>
</div>

