import {Inject, Injectable, InjectionToken} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {first, Observable} from 'rxjs';

export const API_URL = new InjectionToken<string>('apiUrl');

@Injectable()
export class DisplayDeviceService {
    private readonly urlDisplayDevice: string;

    private readonly headersSkippingInterceptor: HttpHeaders;
    private readonly headers: HttpHeaders;
    private readonly optionsSkippingInterceptor: object;
    private readonly options: object;

    public constructor(private http: HttpClient, @Inject(API_URL) private apiUrl: string) {
        this.headersSkippingInterceptor = new HttpHeaders({
            'Content-Type': 'application/json',
            'skipInterceptor': 'true',
        });
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        this.optionsSkippingInterceptor = {
            headers: this.headersSkippingInterceptor,
            withCredentials: true
        };
        this.options = {
            headers: this.headers,
            withCredentials: true
        };

        this.urlDisplayDevice = `${apiUrl}/orion-cc-display-device/client`;
    }

    // Display device
    public getDisplay(streamId: string): Observable<any> {
        return this.http.get<any>(`${this.urlDisplayDevice}/${streamId}`, this.optionsSkippingInterceptor).pipe(first());
    }
}
