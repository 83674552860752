<div style="min-height: 254px" *ngIf="phoneNumberForm ">
  <form [formGroup]="phoneNumberForm" (ngSubmit)="submit()">
    <div class="form">
      <div class="input-box">
        <span class="p-float-label">
          <p-dropdown
            [options]="phoneNumberTypes"
            [ngClass]="{'ng-dirty': phoneNumberForm.get('numberType')?.touched}"
            formControlName="numberType"
            optionLabel="label"
            optionValue="value"
            placeholder="Select Type"
          ></p-dropdown>
          <label htmlFor="numberType">Type*</label>
        </span>
        <small
          id="phone-numberType-help"
          class="p-error"
          *ngIf="phoneNumberForm.get('numberType')?.touched && phoneNumberForm.get('numberType')?.hasError('required')">
          The phone number type is required.
        </small>
      </div>

      <div class="input-box">
        <span class="p-float-label">
          <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': phoneNumberForm.get('number')?.touched}"
                 id="phone-number"
                 type="text" pInputText formControlName="number"/>
          <label htmlFor="phone-number">Number*</label>
        </span>
        <small
          id="phone-number-help"
          class="p-error"
          *ngIf="phoneNumberForm.get('number')?.touched && phoneNumberForm.get('number')?.hasError('required')">
          The number is required.
        </small>
      </div>

      <div class="input-box buttons">
        <p-button [style]="{width: '120px'}" [disabled]="phoneNumberForm.invalid || phoneNumberForm.disabled"
                  type="submit"
                  label="Save"></p-button>
      </div>
    </div>
  </form>
</div>

