<p-fieldset>
  <ng-template pTemplate="header">
    <div class="flex align-items-center text-primary">
      <span class="navigation-img pi pi-images"></span>
      <span class="font-bold text-lg">Photos</span>
    </div>
  </ng-template>
  <div class="image-card-wrapper">
    <app-image-card
      [src]="'https://primefaces.org/cdn/primeng/images/galleria/galleria1.jpg'"
      [description]="'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab ad amet velit. Accusantium impedit laborum quas suscipit voluptas. Ab corporis dolores fuga modi nihil obcaecati odio perferendis quae similique tempora.'"
    ></app-image-card>

    <app-image-card
      [src]="'https://www.meydanfz.ae/wp-content/uploads/2021/10/Events.png'"
      [description]="'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi atque debitis incidunt laborum, libero natus nisi optio repellendus temporibus! Accusamus ad, aliquam aperiam incidunt natus obcaecati praesentium quae sequi vitae?'"
    ></app-image-card>
  </div>
</p-fieldset>
