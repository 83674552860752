import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {first} from 'rxjs/operators';
import {OrganizationModel} from './models/organization.model';
import {Response} from './models/response.model';
import {NewResponse} from './models/response';
import {Inject, Injectable, InjectionToken} from '@angular/core';
import {FilterMetadata} from 'primeng/api';
import {CreateUserModel} from './models/create-user.model';
import {PasswordUpdateModel} from './models/password-update.model';
import {TokenModel} from '../../../identity/src/lib/models/token.model';
import {LoginModel} from './models/login.model';
import {ResetPasswordModel} from './models/reset-password.model';

export const API_URL = new InjectionToken<string>('apiUrl');

@Injectable()
export class OrganizationIdentityAggregatorService {
  private readonly urlAggregatorHealthz: string;
  private readonly urlAggregatorAccount: string;
  private readonly urlOrganizations: string;
  private readonly urlOrganizationUsers: string;
  private readonly urlOrganizationRoles: string;

  private readonly headersSkippingInterceptor: HttpHeaders;
  private readonly headers: HttpHeaders;
  private readonly optionsSkippingInterceptor: object;
  private readonly options: object;

  public constructor(private http: HttpClient, @Inject(API_URL) private apiUrl: string) {
    this.headersSkippingInterceptor = new HttpHeaders({
      'Content-Type': 'application/json',
      'skipInterceptor': 'true',
    });
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.optionsSkippingInterceptor = {
      headers: this.headersSkippingInterceptor,
      withCredentials: true
    };
    this.options = {
      headers: this.headers,
      withCredentials: true
    };

    this.urlAggregatorHealthz = `${apiUrl}/organization/healthz`;
    this.urlAggregatorAccount = `${apiUrl}/organization/Account`;
    this.urlOrganizations = `${apiUrl}/organization/organizations`;
    this.urlOrganizationUsers = `${apiUrl}/organization/organizationUsers`;
    this.urlOrganizationRoles = `${apiUrl}/organization/organizationRoles`;

  }

  // Health
  public getHealthz(): Observable<Response<string>> {
    return this.http.get<Response<string>>(`${this.urlAggregatorHealthz}`,this.optionsSkippingInterceptor).pipe(first());
  }


  // Account
  public confirmEmail(identifier: string, ConfirmationCode : string): Observable<Response<TokenModel>> {
    return this.http.get<Response<TokenModel>>(`${this.urlAggregatorAccount}/confirmEmail?identifier=${identifier}&ConfirmationCode=${ConfirmationCode}`,this.optionsSkippingInterceptor).pipe(first());
  }

  public refreshToken(token?: TokenModel): Observable<Response<TokenModel>> {
    return this.http.post<Response<TokenModel>>(`${this.urlAggregatorAccount}/refreshToken`, token, this.optionsSkippingInterceptor).pipe(first());
  }

  public resetPassword(body: ResetPasswordModel): Observable<Response<TokenModel>> { // todo
    return this.http.post<Response<TokenModel>>(`${this.urlAggregatorAccount}/resetPassword`, body, this.optionsSkippingInterceptor).pipe(first());
  }


  // OrganizationRoles

  public getOrganizationRoles(organizationId: string, page: number, size: number,): Observable<NewResponse<string[]>> {
    return this.http.get<NewResponse<string []>>(`${this.urlOrganizationRoles}/${organizationId}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public addOrganizationRole(organizationId: string, roleId: string): Observable<NewResponse<null>> {
    return this.http.post<NewResponse<null>>(`${this.urlOrganizationRoles}/${organizationId}/${roleId}`, {}, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteOrganizationRole(organizationId: string, roleId: string): Observable<NewResponse<null>> {
    return this.http.delete<NewResponse<null>>(`${this.urlOrganizationRoles}/${organizationId}/${roleId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  // Organizations
  public getOrganization(organizationId: string): Observable<Response<OrganizationModel>> {
    return this.http.get<Response<OrganizationModel>>(`${this.urlOrganizations}/${organizationId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public addOrganization(parentId: string | null, body: OrganizationModel): Observable<Response<string>> {
    if (parentId) {
      return this.http.post<Response<string>>(`${this.urlOrganizations}?organizationId=${parentId}`, body, this.optionsSkippingInterceptor).pipe(first());
    }
    return this.http.post<Response<string>>(`${this.urlOrganizations}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public updateOrganization(organizationId: string, body: OrganizationModel): Observable<Response<string>> {
    return this.http.put<Response<string>>(`${this.urlOrganizations}/${organizationId}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteOrganization(organizationId: string): Observable<Response<null>> {
    return this.http.delete<Response<null>>(`${this.urlOrganizations}/${organizationId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getChildOrganizations(organizationId: string,page: number, size: number): Observable<NewResponse<OrganizationModel[]>> {
    if (organizationId) {
      return this.http.get<NewResponse<OrganizationModel[]>>(`${this.urlOrganizations}?page=${page}&size=${size}&organizationId=${organizationId}`, this.optionsSkippingInterceptor).pipe(first())
    } else {
      return this.http.get<NewResponse<OrganizationModel[]>>(`${this.urlOrganizations}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first())
    }
  }

  // Organization users
  public getAllUsersByOrganizationId(
    organizationId: string,
    page: number,
    size: number,
    filter?: {
      [s: string]: FilterMetadata | FilterMetadata[] | undefined;
    },
  ): Observable<NewResponse<string[]>> {
    return this.http.get<NewResponse<string[]>>(`${this.urlOrganizationUsers}/${organizationId}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public createOrganizationUser(organizationId: string, body: CreateUserModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlOrganizationUsers}/${organizationId}`, body, this.optionsSkippingInterceptor,).pipe(first());
  }

  public deleteUserFromRole(userId: string, organizationId: string, roleId: string): Observable<Response<null>> {
    return this.http.delete<Response<null>>(`${this.urlOrganizationUsers}/${organizationId}/${userId}/${roleId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public addUserToRole(organizationId: string, userId: string, roleId: string): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlOrganizationUsers}/${organizationId}/${userId}/${roleId}`, {}, this.optionsSkippingInterceptor,).pipe(first())
  }

  public setUserPassword(userId: string, organizationId: string, body: PasswordUpdateModel): Observable<Response<string>> {
    return this.http.put<Response<string>>(`${this.urlOrganizationUsers}/${organizationId}/${userId}/SetPassword`, body, this.optionsSkippingInterceptor).pipe(first());
  }
}
