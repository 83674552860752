import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PHONE_NUMBER_SAVED,} from '../../../../locale/multilingual-strings-constants';
import {NotifierService} from '../../services/notifier.service';
import {Observable} from 'rxjs';
import {Response} from '../../model/response.model';
import {PersonalDataService} from '../../../../../personal-data/src/lib/personal-data.service';
import {MachineUserPhoneModel} from '../../../../../personal-data/src/lib/models/machine.model';

@Component({
  selector: 'app-phone-number-popup',
  templateUrl: './phone-number-popup.component.html',
  styleUrls: ['./phone-number-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneNumberPopupComponent {
  public personalDataId: string;
  public phoneNumberForm: FormGroup;
  public phoneNumberTypes: { label: string, value: string }[] = [
    {label: 'Mobile', value: 'mobile'},
    {label: 'Home', value: 'home'},
    {label: 'Work', value: 'work'},
  ];

  constructor(
              private ref: DynamicDialogRef,
              private fb: FormBuilder,
              private notifierService: NotifierService,
              private config: DynamicDialogConfig,
              private cdr: ChangeDetectorRef,
              private personalDataService: PersonalDataService) {

    const phone = config.data.phone;
    this.personalDataId = config.data.personalDataId;
    this.phoneNumberForm = this.fb.group({
      id: [phone?.id],
      personalDataId: [phone?.personalDataId || this.personalDataId],
      number: [phone?.number, [Validators.required]],
      numberType: [phone?.numberType, [Validators.required,]],
    });
  }

  public submit() {
    if (this.phoneNumberForm.valid) {
      this.phoneNumberForm.disable();
      const body = this.phoneNumberForm.value as MachineUserPhoneModel;
      console.log(this.personalDataId);
      const submitRequest$: Observable<Response<string | void>> = this.phoneNumberForm.value.id ? this.personalDataService.updatePhoneNumber(body) :
        this.personalDataService.addPhoneNumber(body);

      submitRequest$.subscribe({
        next: response => {
          if (response.succeed) {
            this.notifierService.handleSuccessRequest(PHONE_NUMBER_SAVED);
            if (!this.phoneNumberForm.value.id) {
              this.phoneNumberForm.controls['id'].setValue(response.value);
              this.closeDialog(true);
            } else this.closeDialog(false, true);
          } else {
            this.notifierService.handleErrors(response.errors);
            this.phoneNumberForm.enable();
          }
          this.cdr.detectChanges();
        },
        error: err => {
          this.notifierService.handleRequestError(err);
          this.phoneNumberForm.enable();
          this.cdr.detectChanges();
        }
      })
    }
  }

  public closeDialog(added: boolean, edited?: boolean) {
    this.ref.close({added, edited, phone: this.phoneNumberForm.value});
  }

}
