<p-dropdown [options]="iconSizes"
            [(ngModel)]="selectedSize"
            (onChange)="onSizeChange($event.value)"
            [style]="{width: '125px'}"
            [appendTo]="'body'"
            optionLabel="name"
            placeholder="Select a Country">
  <ng-template pTemplate="selectedItem">
    <div *ngIf="selectedSize" class="flex align-items-center gap-2">
      <i class="{{selectedSize.icon}}"
         [style]="{'font-size': selectedSize.iconSize}"></i>
      <div>View</div>
    </div>
  </ng-template>

  <ng-template let-type pTemplate="item">
    <div class="flex align-items-center gap-2">
      <i class="{{type.icon}}"
         [style]="{'font-size': type.iconSize}"></i>
      <div>{{ type.name }}</div>
    </div>
  </ng-template>
</p-dropdown>
