import {Injectable} from '@angular/core';
import {LOCAL_STORAGE_LANGUAGE} from '../constants';
import {LanguageModel} from '../model/language.model';

@Injectable()
export class LanguageService {
  public getSupportedLanguages(): any[] {
    return [
      {
        id: 'en',
        name: 'russian',
        threeLetterISOLanguageName: 'eng',
        twoLetterISOLanguageName: 'en',
      },
      {
        id: 'ru',
        name: 'english',
        threeLetterISOLanguageName: 'rus',
        twoLetterISOLanguageName: 'ru',
      }
    ];
  }

  public updateSelectedLanguage(selectedLanguage: LanguageModel): void {
    localStorage.setItem(LOCAL_STORAGE_LANGUAGE, selectedLanguage.id);
    window.location.href = window.location.href.replace(/\/\w+\/#/, `/${selectedLanguage.twoLetterISOLanguageName}/#`);
  }

  public getSelectedLanguage(): string {
    return localStorage.getItem(LOCAL_STORAGE_LANGUAGE) || this.getSupportedLanguages()[0].id;
  }

}
