<app-drawer *ngIf="!inNewWindow"
            (visibleChange)="onVisibleChange($event)"
            [visible]="visible"
            [header]="'VNC: ' + machineName + (fps ? (' fps: '+ fps) : '')">
  <div HeaderGraphics>
    <div class="canvas-container">
      <div (click)="openFPSChartPopup()"  style="cursor: pointer">
        <canvasjs-chart [options]="FPSChartOptions" (chartInstance)="setFpsChart($event)"
                        [styles]="{width: '200px', height: '80px',}"></canvasjs-chart>
      </div>

      <div (click)="openBytesLengthChartPopup()" style="cursor: pointer">
        <canvasjs-chart [options]="bytesLengthOptions" (chartInstance)="setBytesSizeChart($event)"
                        [styles]="{width: '200px', height: '80px',}"></canvasjs-chart>
      </div>
    </div>
  </div>
  <div HeaderButtonExtras>
    <div class="header-container">
      <div class="quality-buttons-group">
        <div class="select-quality">
          <p-dropdown
            [style]="{width: '100%'}"
            [(ngModel)]="selectedQuality"
            (onChange)="changeQuality($event.value)"
            [options]="options"
            [appendTo]="'body'"
            id="quality"
            placeholder="Quality"
            optionLabel="name"
            optionValue="value"
          >
            <ng-template pTemplate="selectedItem" let-selectedOption>
              <div class="flex align-items-center">
                <img
                  style="width: 20px"
                  [src]="'assets/icons/quality/' + selectedQuality + 'p.svg'"
                  [alt]="selectedQuality">
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <i (click)="openNewWindows()" class="fa fa-external-link new-window-btn shell-header-button"></i>
      </div>
    </div>
  </div>

  <div style="padding-bottom: 16px" class="remote-desktop">
    <ng-container [ngTemplateOutlet]="backgroundImageTemplate"></ng-container>
  </div>
</app-drawer>

<ng-container *ngIf="inNewWindow">
  <div class="remote-desktop-full">
    <ng-container [ngTemplateOutlet]="backgroundImageTemplate"></ng-container>
  </div>
</ng-container>

<ng-template #backgroundImageTemplate>
  <div class="container">
    <div *ngIf="disconnected" class="disconnected-container">
      <p class="disconnected">Disconnected...</p>
    </div>

    <canvas #remoteDesktop id="remoteDesktop" width="800" height="600"></canvas>

    <div *ngIf="showProgressSpinner"
         style="position: absolute ;display: flex; flex-direction: column; align-items: center; justify-content: center"
         class="remote-desktop">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>
</ng-template>
