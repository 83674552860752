import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {MachineUserDepositModel,} from '../../../../model/machine/machine.model';
import {MenuItem} from 'primeng/api';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {AuthService} from '../../../../services/auth.service';
import {DEFAULT_TABLE_ROWS_OPTIONS, DEPOSITS_LIMIT, LOCAL_STORAGE_DEPOSITS_LIMIT} from '../../../../constants';
import {PermissionsEnum} from '../../../../permissions.enum';
import {TableLazyLoadEvent} from 'primeng/table';
import {DeletePopup} from '../../../delete-popup/delete-popup.component';
import {DEPOSIT_DELETED} from '../../../../../../locale/multilingual-strings-constants';
import {DataConversionService} from '../../../../services/data-conversion.service';
import {DepositPopupComponent} from '../../../deposit-popup/deposit-popup.component';
import {TableFieldModel} from '../../../../model/table-field.model';
import {NotifierService} from '../../../../services/notifier.service';
import {FieldTypesEnum} from '../../../../field-types.enum';
import {PersonalDataService} from '../../../../../../../personal-data/src/lib/personal-data.service';
import {UtilService} from '../../../../services/util.service';

@Component({
  selector: 'app-deposits',
  templateUrl: './deposits.component.html',
  styleUrls: ['./deposits.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepositsComponent {
  public hasDeletePermission = false;
  public hasWritePermission = false;
  public deposits: MachineUserDepositModel[];
  public items: MenuItem[];
  public ref: DynamicDialogRef | undefined;
  public totalRecords: number;
  public loading = true;
  public DEPOSITS_LIMIT: number;
  public selectedDeposit: MachineUserDepositModel;
  public fields: TableFieldModel[] = [
    {
      name: 'company',
      displayName: 'Company',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    },
    {
      name: 'amount',
      displayName: 'Amount',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    },
    {
      name: 'from',
      displayName: 'From',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
      type: FieldTypesEnum.date
    },
    {
      name: 'to',
      displayName: 'To',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
      type: FieldTypesEnum.date
    },
    {
      name: 'percent',
      displayName: 'Percent',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    },
  ];

  @Input() public userId: string;

  constructor(
    private authService: AuthService,
    private notifierService: NotifierService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private dataConversionService: DataConversionService,
    private personalDataService: PersonalDataService,
    private utilService: UtilService
  ) {
  }

  public ngOnInit(): void {
    const storedLimit = localStorage.getItem(LOCAL_STORAGE_DEPOSITS_LIMIT);
    this.DEPOSITS_LIMIT = storedLimit && DEFAULT_TABLE_ROWS_OPTIONS.includes(+storedLimit) ? +storedLimit : DEPOSITS_LIMIT;
    this.hasDeletePermission = this.authService.hasPermission(PermissionsEnum.deleteDeposit);
    this.hasWritePermission = this.authService.hasPermission(PermissionsEnum.writeDeposit, [PermissionsEnum.deleteDeposit]);
    this.initDeposit();
    this.initMenuItems();
  }

  public openAddPopup() {
    this.openPopup('Add a new deposit', {personalDataId: this.userId});
  }

  private openPopup(header: string, data: { personalDataId?: string, deposit?: MachineUserDepositModel }) {
    this.ref = this.dialogService.open(DepositPopupComponent, {
      header,
      width: this.utilService.getPopupResponsiveWidth(),
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data,
      maximizable: true,
    });
    this.ref.onClose.subscribe((result: { edited: boolean; added: boolean; deposit: MachineUserDepositModel }) => {
      this.handlePopupResult(result);
    });
  }

  public handlePopupResult(result: { edited: boolean; added: boolean; deposit: MachineUserDepositModel }) {
    if (result) {
      if (result.edited) {
        const deposit = this.deposits.find((deposit: MachineUserDepositModel) => deposit.id === result.deposit!.id)!;
        deposit.company = result.deposit.company;
        deposit.from = result.deposit.from;
        deposit.to = result.deposit.to;
        deposit.amount = result.deposit.amount;
        deposit.percent = result.deposit.percent;
        this.cdr.detectChanges();
      }
      if (result.added) {
        this.deposits.push(result.deposit);
        this.totalRecords++;
        this.cdr.detectChanges();
      }
    }
  }

  public refresh() {
    this.initDeposit();
  }

  public initDeposit(event?: TableLazyLoadEvent) {
    this.loading = true;
    this.DEPOSITS_LIMIT = event?.rows || DEPOSITS_LIMIT;
    localStorage.setItem(LOCAL_STORAGE_DEPOSITS_LIMIT, this.DEPOSITS_LIMIT.toString());
    this.personalDataService.getAllDeposits(this.userId,
      (event ? event.first! / (event ? event.rows! : this.DEPOSITS_LIMIT) + 1 : 1), (event ? event.rows! : this.DEPOSITS_LIMIT)).subscribe({
      next: depositResponse => {
        this.loading = false;
        if (depositResponse.succeed) {
          if (!this.totalRecords && depositResponse.value.totalCount) {
            this.totalRecords = depositResponse.value.totalCount;
          }
          // if (depositResponse.value.entities.length) {
          this.deposits = depositResponse.value.entities.map(a => ({
            ...a,
          }));
          this.loading = false;
          this.cdr.detectChanges();
          // }
          this.cdr.detectChanges();
        } else {
          this.notifierService.handleErrors(depositResponse.errors);
        }
      },
      error: err => this.notifierService.handleRequestError(err)
    });
  }

  public initMenuItems(deposit?: MachineUserDepositModel) {
    this.items = [];

    if (this.hasWritePermission) {
      this.items.push(
        {
          label: 'Edit',
          command: () => {
            deposit && this.openPopup(deposit.company + ': User deposits', {deposit});
          }
        });
    }


    this.items.push(  {
      label: 'Copy',
      items: [
        {
          label: 'ID',
          command: () => {
            deposit && this.utilService.copyText(deposit.id as string, 'Deposit ID copied.');
          }
        }
      ]
    })

    if (this.hasDeletePermission) {
      this.items.push(
        {
          label: 'Delete',
          command: () => {
            deposit && this.openDeleteDepositPopup(deposit);
          }
        });
    }
  }
  public initCmItems() {
    this.initMenuItems(this.selectedDeposit);
  }

  private openDeleteDepositPopup(deposit: MachineUserDepositModel) {
    this.ref = this.dialogService.open(DeletePopup, {
      header: 'Are you sure you want to delete this deposit?',
      width: 'fit-content',
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data: {
        id: deposit.id,
        data: this.dataConversionService.convertData(
          [deposit],
          {
            company: 'Company',
            amount: 'Amount',
            from: 'From',
            to: 'To',
            percent: 'Percent'
          }
        ),
      },
    });
    this.ref.onClose.subscribe((result?: {
      id: string,
      delete: boolean,
    }) => {
      if (result?.delete) {
        this.deleteDeposit(deposit, result.id);
      }
    });
  }

  private deleteDeposit(deposit: MachineUserDepositModel, depositId: string) {
    this.personalDataService.deleteDeposit(depositId).subscribe({
      next: r => {
        if (r.succeed) {
          this.notifierService.handleSuccessRequest(DEPOSIT_DELETED);
          this.deposits.splice(this.deposits.indexOf(deposit), 1);
          this.cdr.detectChanges();
        } else {
          this.notifierService.handleErrors(r.errors);
        }
      },
      error: err => {
        this.notifierService.handleRequestError(err);
      },
    });
  }

}
