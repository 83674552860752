<p-splitter [panelSizes]="panelSize" styleClass="mb-5">
  <ng-template pTemplate>
    <div class="left-panel__content"
         [ngClass]="{'disabled-tree': treeLoading}">
      <ng-container *ngIf="directories">
        <p-tree
          class="file-tree"
          [style]="{padding: '3px'}"
          [value]="directories"
          [loading]="treeLoading"
          (onNodeCollapse)="nodeCollapse($event)"
          (onNodeSelect)="nodeSelect($event.node)"
          (onNodeExpand)="nodeExpand($event.node)"
          selectionMode="single"
        ></p-tree>
      </ng-container>
    </div>
  </ng-template>

  <ng-template pTemplate>
    <div style="width: 100%" class="files-table">
      <app-file-services-table
        [isOnline]="isOnline"
        [isRegisterEditor]="isRegisterEditor"
        [currentNode]="currentNode"
        [currentPath]="currentPath"
        [machineId]="machineId"
        [machineName]="machineName"
        [filesShownInExplorer]="filesShownInExplorer"
        [files]="files"
        [loading]="loading"
        [totalRecords]="totalRecords"
        [storedLimit]="storedLimit"
        [selectedFiles]="selectedFiles"
        [selectedIconSize]="selectedIconSize"
        (onRowDataChange$)="onRowDataChange$.emit($event)"
        (filesLazyLoad)="emitFilesLazyLoad($event)"
        (fileLoad)="emitFileLoad($event)"
        (selectedNodeInPath)="selectedNodeInPath($event)"
        [selectedDirectory]="selectedDirectory"
        (deleteRegistryEntry)="getDeletedRegistryEntry($event)"
        [isPreviewModeOpened]="isPreviewModeOpened"
      ></app-file-services-table>
    </div>
  </ng-template>
</p-splitter>

<p-contextMenu
  #cm
  [appendTo]="'body'"
  [model]="directoryContextMenuItems" />
