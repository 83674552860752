<div *ngIf="loading"
     style="display: flex; flex-direction: column; align-items: center; justify-content: center">
  <p-progressSpinner></p-progressSpinner>
</div>
<div *ngIf="!loading">
  <form *ngIf="form" [formGroup]="form">
    <div class="form">
      <div class="input-box">
        <span class="p-float-label">
        <input
          [style]="{width: '100%'}"
          type="text"
          [disabled]="true"
          pInputText
          readonly
          formControlName="name"
          placeholder="Username"/>
          <label>Organization Name</label>
        </span>
      </div>

      <div *ngIf="!loading">
        <div class="popup-form__body">
          <div class="input-box">
            <label style="padding-left: 8px;" htmlFor="add-role-ms-permissions">Select Roles</label>
            <div style="margin-top: 10px" id="add-role-ms-permissions" class="card">
              <p-table
                #dt1
                [value]="roles"
                [(selection)]="selectedRoles"
                [selectAll]="allSelected"
                (selectAllChange)="onSelectAllChange($event)"
                (onRowSelect)="changeRole($event.data, true )"
                (onRowUnselect)="changeRole($event.data, false)"
                [scrollHeight]="'300px'"
                [globalFilterFields]="['name',]">

                <ng-template pTemplate="caption">
                  <div class="flex">
              <span style="width: 100%" class="p-input-icon-left mr-auto">
                <i class="pi pi-search"></i>
                <input
                  #filterInput
                  style="width: 100%;"
                  pInputText type="text"
                  placeholder="Search permissions"
                  (input)="dt1.filterGlobal(filterInput.value, 'contains')"
                />
              </span>
                  </div>
                </ng-template>

                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 4rem">
                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th></th>
                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-permission>
                  <tr>
                    <td>
                      <p-tableCheckbox [disabled]="!hasPermissionToEditRolePermissions"
                                       [value]="permission.id"></p-tableCheckbox>
                    </td>
                    <td>{{permission.name}}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
