<div style="min-height: 254px" *ngIf="relationForm ">
  <form [formGroup]="relationForm" (ngSubmit)="submit()">
    <div class="form">
      <div *ngIf="relationName" class="input-box">
        <span class="p-float-label">
        <input
          [value]="relationName"
          [style]="{width: '100%'}"
          disabled
          type="text"
          pInputText
          readonly
          placeholder="Relation"/>
          <label>Relation*</label>
        </span>
      </div>
      <div class="input-box">
        <span class="p-float-label">
          <input [style]="{width: '100%'}" [ngClass]="{'ng-dirty': relationForm.get('relationType')?.touched}"
                 id="relationType"
                 type="text" pInputText formControlName="relationType"/>
          <label htmlFor="relationType">Relation Type</label>
        </span>
        <small
          id="relationType-help"
          class="p-error"
          *ngIf="relationForm.get('relationType')?.touched && relationForm.get('relationType')?.hasError('required')">
          The Relation Type is required.
        </small>
      </div>

      <div class="input-box buttons">
        <p-button [style]="{width: '120px'}"
                  [disabled]="relationForm.invalid || relationForm.disabled" type="submit"
                  label="Save"></p-button>
      </div>
    </div>
  </form>
</div>

