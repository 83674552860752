import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';

@Directive({
  selector: '[partialDisable]'
})
export class PartialDisableDirective implements OnInit {

  @Input('partialDisable') disabledCharacters = 0;

  public constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  public ngOnInit() {
    this.renderer.setProperty(this.el.nativeElement, 'disabled', false);
    const textarea = this.el.nativeElement as HTMLTextAreaElement;

    textarea.addEventListener('keydown', (event: KeyboardEvent) => {
      const caretPosition = textarea.selectionStart || 0;
      if (caretPosition < this.disabledCharacters) {
        event.preventDefault();
      }
    });

    textarea.addEventListener('mouseup', (event) => {
      const caretPosition = textarea.selectionStart || 0;
      console.log(this.disabledCharacters);
      console.log(caretPosition);
      if (caretPosition < this.disabledCharacters) {
        textarea.blur();
      }
    });

    // textarea.addEventListener('selectstart', (event) => {
    //   const caretPosition = textarea.selectionStart || 0;
    //   if (caretPosition < this.disabledCharacters) {
    //     event.preventDefault();
    //   }
    // });
  }

  // @HostListener('select', ['$event'])
  // onSelect(event: Event) {
  //   event.preventDefault();
  // }
}
