import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild,} from '@angular/core';
import {MenuItem, TreeNode, TreeTableNode} from 'primeng/api';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {PermissionsEnum} from '../../permissions.enum';
import {DeletePopup} from '../delete-popup/delete-popup.component';
import {
  SaveOrganizationComponent
} from '../../../modules/administration/components/save-organization/save-organization.component';
import {ORGANIZATION_DELETED} from '../../../../locale/multilingual-strings-constants';
import {NotifierService} from '../../services/notifier.service';
import {UtilService} from '../../services/util.service';
import {AuthService} from '../../services/auth.service';
import {DataConversionService} from '../../services/data-conversion.service';
import {Platform} from '@angular/cdk/platform';
import {PopupResultModel} from './popup-result.model';
import {OrganizationModel} from '../../../../../organization/src/lib/models/organization.model';
import {
  OrganizationIdentityAggregatorService
} from '../../../../../organization/src/lib/organization-identity-aggregator.service';
import {
  EditOrganizationRolesComponent
} from '../../../modules/administration/components/edit-organization-roles/edit-organization-roles.component';
import {ContextMenu, ContextMenuModule} from 'primeng/contextmenu';
import {ToastModule} from 'primeng/toast';
import {MAX_PAGING_LIMIT} from '../../constants';
import {OrganizationEventService} from '../../services/organization-event.service';

@Component({
  selector: 'app-organizations-table',
  templateUrl: './organizations-table.component.html',
  styleUrls: ['./organizations-table.component.scss'],
  providers: [ContextMenuModule, ToastModule]
})
export class OrganizationsTableComponent implements OnInit {
  public hasPermissionToWriteOrganizations = false;
  public hasPermissionToDeleteOrganizations = false;
  @Input() public organizationNodes: TreeNode[] = [];
  @Input() public additionalItems: MenuItem[];
  public  items!: MenuItem[];
  private ref: DynamicDialogRef;
  private isMobileView: boolean;
  @Input() public selectedOrganizationId: string;
  @Input() public currentOrganizationId: string;
  @Input() public loading = true;
  @Output() public organizationNodeSelect$: EventEmitter<string> = new EventEmitter<string>();
  @Output() public organizationNodesChange: EventEmitter<TreeNode[]> = new EventEmitter<TreeNode[]>();
  @Output() public additionalItemEvent: EventEmitter<{ organizationId: string; key: number }> = new EventEmitter<{
    organizationId: string;
    key: number
  }>();
  @ViewChild('cm') cm: ContextMenu;


  public constructor(
    private organizationService: OrganizationIdentityAggregatorService,
    private cdr: ChangeDetectorRef,
    private notifierService: NotifierService,
    private utilService: UtilService,
    private authService: AuthService,
    private dialogService: DialogService,
    private dataConversionService: DataConversionService,
    private platform: Platform,
    private organizationEventService: OrganizationEventService,
  ) {
  }

  public ngOnInit(): void {
    this.initializeOrganizationData();
    this.utilService.onPageRefresh$.subscribe(_ => this.initializeOrganizationData());
    this.isMobileView = this.platform.IOS || this.platform.ANDROID;
    this.hasPermissionToWriteOrganizations = this.authService.hasPermission(PermissionsEnum.writeOrganization, [
      PermissionsEnum.deleteOrganization]);
    this.hasPermissionToDeleteOrganizations = this.authService.hasPermission(PermissionsEnum.deleteOrganization);
    this.organizationEventService.onOrgNodeSelect$.subscribe({
      next: value => {
        this.organizationNodeSelect$.emit(value);
      },
    });
  }

  private initializeOrganizationData(): void {
    this.organizationNodes = [];
    this.loading = true;
    const observer = this.organizationService.getChildOrganizations(this.currentOrganizationId, 1, MAX_PAGING_LIMIT);
    observer.subscribe((res: any) => {
      if (res && res.value) {
        this.organizationNodes = this.utilService.buildOrganizationTree(res.value.entities);
        const organizations = res.value.entities;
        organizations.forEach((org: any, index: number) => {
          this.organizationService.getChildOrganizations(org.organizationId, 1, MAX_PAGING_LIMIT).subscribe({
            next: childRes => {
              const childNodes = this.utilService.buildOrganizationTree(childRes.value.entities, org.organizationId);
              this.organizationNodes[index].children = childNodes.length ? childNodes : undefined;
              this.organizationNodes[index].leaf = childNodes.length === 0;
              this.organizationNodesChange.emit(this.organizationNodes);
              this.cdr.detectChanges();
            }
          });
        });
        if (!res.value.length) {
          this.organizationNodesChange.emit(this.organizationNodes);
          this.loading = false;
          this.cdr.detectChanges();
        }
      }
    });
  }

  public onRightClick(event: any, rowNode: TreeTableNode) {
    event.preventDefault();
    const node = rowNode.node as TreeNode & { name: string, parentId: string, blocked: boolean }
    if (rowNode.node as TreeNode & { name: string, parentId: string, blocked: boolean }) {
      this.items = [
        {
          label: 'Add', icon: 'pi pi-plus', command: () => {
            this.openOrgPopup('Add organization for ' + node?.label, {parentId: node?.data});
          }
        },
        {
          label: 'Rename', icon: 'pi pi-pencil',command: () => {
            const popupData = {organization: node, parentId: node?.parent?.data, currentOrgId: this.currentOrganizationId};
            this.openOrgPopup( 'Edit: ' + node?.label, popupData, node);
          }
        }
        ,
        {
          label: 'Edit roles', icon: 'pi pi-user-edit', command: () => {
            const popupHeader = this.organizationNodes.find(node => node.data === rowNode.node?.data)?.label
            const popupData = {organization : node?.data, value: node};
            this.openOrganizationRolesPopup(popupHeader! + ': Edit roles', popupData);
          }
        },
        {
          label: 'Copy', icon: 'pi pi-copy',
          items : [        {
            label: 'Copy ID',
            command: () => {
              console.log(node)
              node && this.utilService.copyText(node.data, 'Organization ID copied.');
            }
          },]
        },
        {
          label: 'Delete',
          icon:'pi pi-trash',
          command: () => {
            this.openDeleteOrganizationPopup(node.data, node);
          }
        },
      ];
      if (this.additionalItems) {
        this.additionalItems = this.additionalItems.map(item => {
          return item.separator ? item : {
            ...item,
            command: () => {
              this.emitEvent({
                organizationId: node.data,
                key: item['key'],
              });
            }
          }
        });
        this.items.push(...this.additionalItems);
      }
      this.cm.show(event);
    }
  }

  public nodeSelect(event: TreeTableNode) {
    if (!(event.originalEvent?.target as HTMLElement).matches('span.p-button-icon.pi.pi-bars')) {
      this.organizationNodeSelect$.emit(event.node?.data);
    }
  }

  public nodeExpand(event: any): void {
    const node = event.node;

    if (!node) {
      return;
    }

    const orgId = node.id;
    if (node.children && node.children.length) {
      node.children.forEach((chNode: TreeNode) => {
        this.organizationService.getChildOrganizations(chNode.data, 1, MAX_PAGING_LIMIT).subscribe({
          next: childRes => {
            if (childRes.succeed) {
              const childNodes = this.utilService.buildOrganizationTree(childRes.value.entities, orgId);
              chNode.children = childNodes.length ? childNodes : undefined;
              chNode.leaf = childNodes.length === 0;
              this.cdr.detectChanges();
            } else {
              this.notifierService.handleErrors(childRes.errors);
            }
          },

          error: err => {
            this.notifierService.handleRequestError(err);
          }
        });
      });
    }
  }

  // public toggleOrgMenu(orgMenu: Menu, event: MouseEvent, rowNode: {
  //   node: TreeNode & { name: string, parentId: string, blocked: boolean, organizationType: number }
  // }) {
  //   const node = rowNode.node;
  //   const organization: OrganizationModel = node.data;
  //   orgMenu.toggle(event);
  // }

  public openAddOrganizationPopup(): void {
    this.openOrgPopup('Add organization', {currentOrgId: this.selectedOrganizationId});
  }

  private openOrgPopup(header: string, data: { organization?: TreeNode, parentId?: string, currentOrgId?: string }, node?: TreeNode & {
    parentId: string,
    blocked: boolean,
  },) {
    this.ref = this.dialogService.open(SaveOrganizationComponent, {
      header,
      width: this.utilService.getPopupResponsiveWidth(),
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data,
      maximizable: true,
    });
    this.ref.onClose.subscribe((result: PopupResultModel) => {
      if (result) {
        if (result.created) {
          this.handleCreatedOrganization(result);
          this.cdr.detectChanges();
        }
        if (result.updated && node) {
          this.handleUpdatedOrganization(result, node);
          this.cdr.detectChanges();
        }
      }
    });
  }


  public openOrganizationRolesPopup(header: string, data: any) {
    this.ref = this.dialogService.open(EditOrganizationRolesComponent, {
      header,
      width: this.utilService.getPopupResponsiveWidth(),
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data,
      maximizable: true,
    });

  }

  private handleUpdatedOrganization(result: PopupResultModel, node: TreeNode & {
    parentId: string,
    blocked: boolean
  }): void {
    node.label = result.value.name;
    const oldParentId = node.parentId;
    const newParentId = result.value.parentId;
    if (oldParentId !== newParentId) {
      const oldParentNode = this.utilService.findOrganizationNode(oldParentId, this.organizationNodes);
      const newParentNode = this.utilService.findOrganizationNode(newParentId!, this.organizationNodes);
      if (oldParentNode?.children) {
        oldParentNode.children.splice(oldParentNode.children.indexOf(node), 1);
        if (!oldParentNode.children.length) {
          oldParentNode.leaf = true;
        }
      }
      if (newParentNode) {
        newParentNode.leaf = false;
        if (!newParentNode.children) {
          newParentNode.children = [];
        }
        newParentNode.children.push(node);
      }
      node.parentId = result.value.parentId!;
    }
    this.organizationNodes = [...this.organizationNodes];
    this.organizationNodesChange.emit(this.organizationNodes);
  }

  private handleCreatedOrganization(result: PopupResultModel): void {
    const createdOrganization = result.value;
    let parentNode = null;
    if (createdOrganization.parentId) {
      parentNode = this.utilService.findOrganizationNode(createdOrganization.parentId, this.organizationNodes);
      if (parentNode) {
        parentNode.leaf = false;
        if (!parentNode.children) {
          parentNode.children = [];
        }
        parentNode.children.push({
          label: createdOrganization.name,
          data: createdOrganization.organizationId,
          selectable: true,
          leaf: true,
          parentId: createdOrganization.parentId,
        } as TreeNode);
      }
    } else {
      this.organizationNodes.push({
        label: createdOrganization.name,
        data: createdOrganization.organizationId,
        selectable: true,
        leaf: true,
        parentId: createdOrganization.parentId,
      } as TreeNode);
    }
    this.organizationNodes = [...this.organizationNodes];
    this.organizationNodesChange.emit(this.organizationNodes);
  }

  private openDeleteOrganizationPopup(organization: OrganizationModel, node: TreeNode & {
    parentId: string,
    blocked: boolean
  }) {
    this.ref = this.dialogService.open(DeletePopup, {
      header: 'Are you sure you want to delete this organization?',
      width: 'fit-content',
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data: {
        id: organization,
        data: this.dataConversionService.convertData(
          [node],
          {
            label: 'name'
          })
      },
    });
    this.ref.onClose.subscribe(result => {
      if (result?.delete) {
        this.deleteOrganization(result.id, node);
      }
    });
  }

  private deleteOrganization(organizationId: string, node: TreeNode & { parentId: string, blocked: boolean }) {
    this.organizationService.deleteOrganization(organizationId).subscribe({
      next: r => {
        if (r.succeed) {
          if (!node.parent) {
            this.organizationNodes.splice(this.organizationNodes.findIndex(node => node.data === organizationId), 1);
          } else if (node.parent?.children) {
            node.parent.children.splice(node.parent.children.indexOf(node), 1);
            if (!node.parent.children.length) {
              node.parent.leaf = true;
            }
          }
          this.organizationNodes = [...this.organizationNodes];
          this.organizationNodesChange.emit(this.organizationNodes);
          this.cdr.detectChanges();
          this.notifierService.handleSuccessRequest(ORGANIZATION_DELETED);
        } else {
          this.notifierService.handleErrors(r.errors);
        }
      },
      error: err => {
        this.notifierService.handleRequestError(err);
      },
    });
  }

  private emitEvent(event: {organizationId: string; key: number}) {
    this.additionalItemEvent.emit(event);
  }
}
