import {ShellWebSocketService} from '../../../shared/services/shell-web-socket.service';
import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class SystemInformationService {
  public receivedString$: Subject<{ data: string, state: string, loaded: boolean }> = new Subject();
  public onDirectoriesReceived$: Subject<any> = new Subject();
  public onFilesReceived$: Subject<any> = new Subject();
  public onLoaded$: Subject<any> = new Subject();
  private state: SystemInformationState = SystemInformationState.LoadingSystemInfo;
  private flag = false;
  public loaded = true;

  public constructor(private webSocketService: ShellWebSocketService) {

    this.webSocketService.dataReceived$.subscribe({
      next: (dataView) => {
        const textDecoder = new TextDecoder('utf-8');
        const decodedString = textDecoder.decode(dataView);
        const filteredData = this.filterCommandPrompt(decodedString);
        this.receivedString$.next({ data: filteredData, state: this.state, loaded: this.loaded });

        if (this.state == SystemInformationState.LoadingSystemInfo) {
          if(!this.flag){
            this.flag = true;
            this.webSocketService.sendMessage('arp -a');
            this.state = SystemInformationState.LoadingArpInfo;
            this.loaded = false
          }
        } else if (this.state == SystemInformationState.LoadingArpInfo) {
          this.state = SystemInformationState.Completed;
        }

      },
      error: (err: string) => {
        console.error(err);
      },
    });
    this.webSocketService.onLoaded$.subscribe(() =>{
      this.getSystemInfo()
    })

  }
  public getSystemInfo(): void {
    this.webSocketService.sendMessage('systeminfo');
  }


  public connectToMachine(streamId: string): void {
    this.webSocketService.connectWithWebsocket(streamId);
  }


  private filterCommandPrompt(response: string): string {
    const lines = response.split('\n');
    const filteredLines = lines.filter(line => !line.startsWith('C:\\') && !line.startsWith('>') && !line.startsWith('arp -a'));
    return filteredLines.join('\n').trim();
  }

  public closeSocket(): void {
    this.webSocketService.closeSocket();
  }

}
enum SystemInformationState {
  LoadingSystemInfo = 'LoadingSystemInfo',
  LoadingArpInfo = 'LoadingArpInfo',
  Completed = 'Completed',
}
