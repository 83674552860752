<div *ngIf="terminalForm">
  <form [formGroup]="terminalForm"
        (ngSubmit)="saveTerminal()">

    <div class="form">
      <div class="input-box">
        <span class="p-float-label">
          <input
            [style]="{width: '100%'}"
            type="text"
            pInputText
            id="terminal-name"
            formControlName="name"
            [ngClass]="{'ng-dirty': terminalForm.get('name')?.touched}"/>
          <label htmlFor="terminal-name">Name*</label>
        </span>
        <small id="terminal-name-help" class="p-error"
          *ngIf="terminalForm.get('name')?.touched && terminalForm.get('name')?.hasError('required')">
          The name is required.
        </small>
      </div>

      <div class="popup-form__buttons">
        <p-button
          [style]="{width: '120px'}"
          [disabled]="terminalForm.invalid || terminalForm.disabled"
          type="submit"
          label="Rename"
        ></p-button>
      </div>
    </div>
  </form>
</div>
