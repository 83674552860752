import {ChangeDetectionStrategy, Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {RIGHT_BAR_MAILBOX, RIGHT_BAR_PROFILE, RIGHT_BAR_USER_SESSIONS} from '../../constants';
import {MenuItem} from 'primeng/api';
import {PermissionsEnum} from '../../permissions.enum';
import {NavigationService} from '../../services/navigation.service';
import {DOCUMENT} from '@angular/common';
import {ThemeService} from '../../services/theme.service';
import {LanguageService} from '../../services/language.service';
import {LanguageModel} from '../../model/language.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  public username: string;
  public unreadNotificationsCount: number;
  public items: MenuItem[];
  public selectedTheme: string;
  public selectedLanguage: LanguageModel;
  public languages: LanguageModel[];
  public notificationCount: number;
  private domObserver: MutationObserver;

  public constructor(
    private authService: AuthService,
    private navigationService: NavigationService,
    private themeService: ThemeService,
    private languageService: LanguageService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) public locale: string,
  ) {
    this.languages = this.languageService.getSupportedLanguages();
    this.selectedLanguage = this.languages.find(language => language.id === this.languageService.getSelectedLanguage())!;
    this.notificationCount = this.unreadNotificationsCount ?? '';
  }

  public openMailbox() {
    if (this.authService.hasPermission(PermissionsEnum.readOwnNotification, [PermissionsEnum.deleteOwnNotification])) {
      this.navigationService.openRightBarPage(RIGHT_BAR_MAILBOX);
      this.unreadNotificationsCount = 0; // todo
    }
  }

  public logout() {
    this.authService.logout();
  }

  public toggleLeftSidebar() {
    this.navigationService.toggleOnlyIcons();
  }

  public ngOnInit(): void {
    this.initMenuItems();
    this.selectedTheme = this.themeService.getSelectedTheme();
    this.username = this.authService.getUserNameFromToken();
    this.fixMenuPosition();
  }

  public onLanguageChange(language: LanguageModel): void {
    if (language.twoLetterISOLanguageName === this.locale) {
      return;
    }

    this.selectedLanguage = language;
    this.languageService.updateSelectedLanguage(language);
  }

  private fixMenuPosition() {
    this.domObserver = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          const addedElement = mutation.addedNodes[0] as HTMLElement;
          const isMenu = addedElement.classList.contains('p-tieredmenu-overlay');
          if (isMenu) {
            setTimeout(() => {
              const headerHeight = 60;
              addedElement.style.position = 'fixed';
              addedElement.style.top = headerHeight + 'px';
            }, 1);
          }
        }
      }
    });
    const bodyElem = document.querySelector('body');
    if (bodyElem) {
      this.domObserver.observe(bodyElem, {
        childList: true,
      });
    }
  }

  public openProfile() {
    if (this.authService.hasPermission(PermissionsEnum.readIdentitySession) ||
      this.authService.hasPermission(PermissionsEnum.writeProfile) ||
      this.authService.hasPermission(PermissionsEnum.manageOwnAccount)) {
      this.navigationService.openRightBarPage(RIGHT_BAR_PROFILE);
    }
  }

  public openSessions() {
    this.navigationService.openRightBarPage(RIGHT_BAR_USER_SESSIONS);
  }

  public toggleTheme(theme: string) {
    this.selectedTheme = theme;
    this.themeService.switchTheme(theme);
  }

  private initMenuItems() {
    this.items = [];
    if (this.authService.hasPermission(PermissionsEnum.readOwnNotification, [PermissionsEnum.deleteOwnNotification])) {
      this.items.push(
        {
          label: `Notifications ${this.notificationCount}`,
          command: () => {
            this.openMailbox();
          }
        }
      )
    }

    if (this.authService.hasPermission(PermissionsEnum.readProfile, [PermissionsEnum.deleteIdentitySession, PermissionsEnum.deleteIdentitySession])) {
      this.items.push(
        {
          label: 'Sessions',
          command: () => {
            this.openSessions();
          }
        },
      )
    }

    this.items.push(
      {
        label: 'Permissions',
        command: () => {
          this.navigationService.navigateToUserPermissions().then();
          this.navigationService.closeRightBarPage();
        }
      },
      {
        label: 'Mode',
        items: [
          {
            label: 'Dark',
            icon: 'pi pi-moon',
            command: () => {
              this.toggleTheme('dark');
            }
          },
          {
            label: 'Light',
            icon: 'pi pi-sun',
            command: () => {
              this.toggleTheme('light');
            }
          }
        ]
      },
      {separator: true},
      {
        label: 'Logout',
        command: () => {
          this.logout();
        }
      }
    )
  }
}
