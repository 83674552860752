import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ToastModule} from 'primeng/toast';
import {AuthService} from './shared/services/auth.service';
import {HttpDataService} from './shared/services/http/http-data.service';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ToastService} from './shared/services/toast.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {UtilService} from './shared/services/util.service';
import {DialogService} from 'primeng/dynamicdialog';
import {LoadingComponent} from './shared/components/loading/loading.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {LoadingInterceptor} from './shared/interceptors/loading.interceptor';
import {LocaleInterceptor} from './shared/interceptors/locale.interceptor';
import {JWT_OPTIONS, JwtHelperService} from '@auth0/angular-jwt';
import {AuthInterceptor} from './shared/interceptors/auth-interceptor.service';
import {MessageService} from 'primeng/api';
import {SharedModule} from './shared/shared.module';
import {NavigationService} from './shared/services/navigation.service';
import {NotifierService} from './shared/services/notifier.service';
import {ThemeService} from './shared/services/theme.service';
import {API_URL as IDENTITY_API_URL, HttpIdentityService} from '../../identity/src/lib/identity-client.service';
import {
  API_URL as ORGANIZATION_API_URL,
  OrganizationIdentityAggregatorService
} from '../../organization/src/lib/organization-identity-aggregator.service';
import {API_URL as PERSON_DATA_API_URL, PersonalDataService} from '../../personal-data/src/lib/personal-data.service';
import {
  API_URL as ORGANIZATION_PERSON_DATA_API_URL,
  OrganizationPersonalDataAggregatorService
} from '../../organization-personal-data-aggregator/src/lib/organization-personal-data-aggregator.service';
import {
  API_URL as ORGANIZATION_TERMINAL_API_URL,
  OrganizationTerminalAggregatorService
} from '../../organization-terminal-aggregator/src/lib/organization-terminal-aggregator.service';
import {
  API_URL as TERMINAL_API_URL,
  TerminalService
} from '../../organization-url-redirection-aggregator/src/lib/terminal.service';
import {
  API_URL as DISPLAY_DEVICE_API_URL,
  DisplayDeviceService
} from '../../organization-url-redirection-aggregator/src/lib/display-device.service';
import {API_URL as FILE_SERVICE_API_URL, FileServiceService} from '../../file-service/src/lib/file-service.service';
import {API_URL} from './shared/constants';
import {
  API_URL as TERMINAL_PERSONAL_DATA_API_URL,
  TerminalPersonalDataAdapterService
} from '../../personal-data/src/lib/terminal-personal-data-adapter.service';
import {
  API_URL as URL_REDIRECTION_API_URL,
  UrlRedirectionService
} from '../../organization-url-redirection-aggregator/src/lib/url-redirection.service';
import {
  API_URL as URL_SHORTENER_API_URL,
  UrlShortenerService
} from '../../organization-url-redirection-aggregator/src/lib/url-shortener.service';
import {
  API_URL as FILE_HOSTING_API_URL,
  FileHostingService
} from './modules/file-hosting/services/file-hosting.service';
import {UploadProgressInterceptorService} from './shared/interceptors/upload-progress.interceptor.service';
import {LanguageService} from './shared/services/language.service';
import {KeyloggerService} from './shared/services/keylogger.service';

@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastModule,
    HttpClientModule,
    ProgressSpinnerModule,
    SharedModule,
  ],
  providers: [
    HttpIdentityService,
    {provide: IDENTITY_API_URL, useValue: API_URL},
    OrganizationIdentityAggregatorService,
    {provide: ORGANIZATION_API_URL, useValue: API_URL},
    PersonalDataService,
    {provide: PERSON_DATA_API_URL, useValue: API_URL},
    TerminalPersonalDataAdapterService,
    {provide: TERMINAL_PERSONAL_DATA_API_URL, useValue: API_URL},
    OrganizationPersonalDataAggregatorService,
    {provide: ORGANIZATION_PERSON_DATA_API_URL, useValue: API_URL},
    OrganizationTerminalAggregatorService,
    {provide: ORGANIZATION_TERMINAL_API_URL, useValue: API_URL},
    TerminalService,
    {provide: TERMINAL_API_URL, useValue: API_URL},
    DisplayDeviceService,
    {provide: DISPLAY_DEVICE_API_URL, useValue: API_URL},
    FileServiceService,
    {provide: FILE_SERVICE_API_URL, useValue: API_URL},
    UrlRedirectionService,
    {provide: URL_REDIRECTION_API_URL, useValue: API_URL},
    UrlShortenerService,
    {provide: URL_SHORTENER_API_URL, useValue: API_URL},
    FileHostingService,
    {provide: FILE_HOSTING_API_URL, useValue: API_URL},
    AuthService,
    DialogService,
    HttpDataService,
    ToastService,
    UtilService,
    KeyloggerService,
    NavigationService,
    NotifierService,
    JwtHelperService,
    ThemeService,
    MessageService,
    LanguageService,
    {provide: JWT_OPTIONS, useValue: JWT_OPTIONS},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LocaleInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UploadProgressInterceptorService,
      multi: true
    },
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
