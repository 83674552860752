import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'timeDiff'
})
export class TimeDiffPipe implements PipeTransform {

  transform(value: any): boolean {
    if (!value) {
      return false;
    }
    const now = new Date();
    const targetDate = typeof value === 'string' ? new Date(value) : value;
    const diff = now.getTime() - targetDate.getTime();
    const hours = Math.floor(diff / 3600000);

    return hours <= 0;

  }
}
