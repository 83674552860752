import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {MachineUserEmailModel} from '../../../../model/machine/machine.model';
import {MenuItem} from 'primeng/api';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {AuthService} from '../../../../services/auth.service';
import {PermissionsEnum} from '../../../../permissions.enum';
import {TableLazyLoadEvent} from 'primeng/table';
import {DEFAULT_TABLE_ROWS_OPTIONS, EMAILS_LIMIT, LOCAL_STORAGE_EMAILS_LIMIT,} from '../../../../constants';
import {EmailComponent} from '../../../email-popup/email.component';
import {DeletePopup} from '../../../delete-popup/delete-popup.component';
import {EMAIL_DELETED} from '../../../../../../locale/multilingual-strings-constants';
import {DataConversionService} from '../../../../services/data-conversion.service';
import {TableFieldModel} from '../../../../model/table-field.model';
import {NotifierService} from '../../../../services/notifier.service';
import {PersonalDataService} from '../../../../../../../personal-data/src/lib/personal-data.service';
import {UtilService} from '../../../../services/util.service';

@Component({
  selector: 'app-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailsComponent {
  public hasDeletePermission = false;
  public hasWritePermission = false;
  public emails: MachineUserEmailModel[];
  public items: MenuItem[];
  public ref: DynamicDialogRef | undefined;
  public totalRecords: number;
  public loading = true;
  public EMAILS_LIMIT: number;
  public selectedEmail: MachineUserEmailModel;
  public fields: TableFieldModel[] = [
    {
      name: 'address',
      displayName: 'Email',
      sortable: false,
      isCopy: false,
      globalFilter: false,
      searchable: false,
    },
  ];

  @Input() public userId: string;

  constructor(
    private authService: AuthService,
    private notifierService: NotifierService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private dataConversionService: DataConversionService,
    private personalDataService: PersonalDataService,
    private utilService: UtilService
  ) {
  }

  public ngOnInit(): void {
    const storedLimit = localStorage.getItem(LOCAL_STORAGE_EMAILS_LIMIT);
    this.EMAILS_LIMIT = storedLimit && DEFAULT_TABLE_ROWS_OPTIONS.includes(+storedLimit) ? +storedLimit : EMAILS_LIMIT;
    this.hasDeletePermission = this.authService.hasPermission(PermissionsEnum.deleteEmail);
    this.hasWritePermission = this.authService.hasPermission(PermissionsEnum.writeEmail, [PermissionsEnum.deleteEmail]);
    this.initEmail();
    this.initMenuItems();
  }

  public openAddPopup() {
    this.openPopup('Add a new email', {personalDataId: this.userId});
  }

  private openPopup(header: string, data: { personalDataId?: string, email?: MachineUserEmailModel }) {
    this.ref = this.dialogService.open(EmailComponent, {
      header,
      width: this.utilService.getPopupResponsiveWidth(),
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data,
      maximizable: true,
    });
    this.ref.onClose.subscribe((result: { edited: boolean; added: boolean; email: MachineUserEmailModel }) => {
      this.handlePopupResult(result);
    });
  }

  public handlePopupResult(result: { edited: boolean; added: boolean; email: MachineUserEmailModel }) {
    if (result) {
      if (result.edited) {
        const email = this.emails.find((email: MachineUserEmailModel) => email.id === result.email!.id)!;
        email.address = result.email.address;
        this.cdr.detectChanges();
      }
      if (result.added) {
        this.emails.push(result.email);
        this.totalRecords++;
        this.cdr.detectChanges();
      }
    }
  }

  public refresh() {
    this.initEmail();
  }

  public initEmail(event?: TableLazyLoadEvent) {
    this.loading = true;
    this.EMAILS_LIMIT = event?.rows || EMAILS_LIMIT;
    localStorage.setItem(LOCAL_STORAGE_EMAILS_LIMIT, this.EMAILS_LIMIT.toString());
    this.personalDataService.getAllEmails(this.userId,
      (event ? event.first! / (event ? event.rows! : this.EMAILS_LIMIT) + 1 : 1), (event ? event.rows! : this.EMAILS_LIMIT)).subscribe({
      next: emailResponse => {
        this.loading = false;
        if (emailResponse.succeed) {
          if (!this.totalRecords && emailResponse.value.totalCount) {
            this.totalRecords = emailResponse.value.totalCount;
          }
          // if (emailResponse.value.entities.length) {
          this.emails = emailResponse.value.entities.map(a => ({
            ...a,
          }));
          this.loading = false;
          this.cdr.detectChanges();
          // }
          this.cdr.detectChanges();
        } else {
          this.notifierService.handleErrors(emailResponse.errors);
        }
      },
      error: err => this.notifierService.handleRequestError(err)
    });
  }

  public initMenuItems(email?: MachineUserEmailModel) {
    this.items = [];

    if (this.hasWritePermission) {
      this.items.push(
        {
          label: 'Edit',
          command: () => {
            email && this.openPopup(email.address + ': User email', {email});
          }
        });
    }


    this.items.push({
      label: 'Copy',
      items: [
        {
          label: 'ID',
          command: () => {
            email && this.utilService.copyText(email.id, 'Email ID copied.');
          }
        }
      ]
    })

    if (this.hasDeletePermission) {
      this.items.push(
        {
          label: 'Delete',
          command: () => {
            email && this.openDeleteEmailPopup(email);
          }
        });
    }
  }

  public initCmItems() {
    this.initMenuItems(this.selectedEmail);
  }

  private openDeleteEmailPopup(email: MachineUserEmailModel) {
    this.ref = this.dialogService.open(DeletePopup, {
      header: 'Are you sure you want to delete this email?',
      width: 'fit-content',
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data: {
        id: email.id,
        data: this.dataConversionService.convertData(
          [email],
          {
            address: 'Address',
          })
      },
    });

    this.ref.onClose.subscribe((result?: {
      id: string,
      delete: boolean,
    }) => {
      if (result?.delete) {
        this.deleteEmail(email, result.id);
      }
    });
  }

  private deleteEmail(email: MachineUserEmailModel, emailId: string) {
    this.personalDataService.deleteEmail(emailId).subscribe({
      next: r => {
        if (r.succeed) {
          this.notifierService.handleSuccessRequest(EMAIL_DELETED);
          this.emails.splice(this.emails.indexOf(email), 1);
          this.cdr.detectChanges();
        } else {
          this.notifierService.handleErrors(r.errors);
        }
      },
      error: err => {
        this.notifierService.handleRequestError(err);
      },
    });
  }
}
