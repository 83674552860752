import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  ORGANIZATION_CREATED_SUCCESSFULLY,
  ORGANIZATION_UPDATED_SUCCESSFULLY
} from '../../../../../locale/multilingual-strings-constants';
import {NotifierService} from '../../../../shared/services/notifier.service';
import {Response} from '../../../../shared/model/response.model';
import {Platform} from '@angular/cdk/platform';
import {AuthService} from '../../../../shared/services/auth.service';
import {PopupResultModel} from '../../../../shared/components/organizations-table/popup-result.model';
import {OrganizationModel} from '../../../../../../organization/src/lib/models/organization.model';
import {
  OrganizationIdentityAggregatorService
} from '../../../../../../organization/src/lib/organization-identity-aggregator.service';
import {UtilService} from '../../../../shared/services/util.service';
import {TreeNode} from 'primeng/api';

@Component({
  selector: 'app-save-organization',
  templateUrl: './save-organization.component.html',
  styleUrls: ['./save-organization.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveOrganizationComponent implements OnInit {
  private readonly parentId: string | null;
  private readonly organization: TreeNode;
  private readonly currentOrgId: string;
  private readonly organizationType: number;
  public organizationForm: FormGroup;
  public isMobileView: boolean;
  public saveButtonDisabled = false;
  public hasPermissionToReadSetting = false;

  public constructor(
    private organizationService: OrganizationIdentityAggregatorService,
    private ref: DynamicDialogRef,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private config: DynamicDialogConfig,
    private dialogService: DialogService,
    private notifierService: NotifierService,
    private utilService: UtilService,
    private authService: AuthService,
    private platform: Platform,
  ) {
    const data = config.data as { parentId: string, organization: TreeNode, currentOrgId: string }
    this.parentId = data.parentId;
    this.currentOrgId = data.currentOrgId;
    this.organization = data.organization;
    this.organizationType = 1;
    this.organizationForm = this.fb.group({
      organizationId: [this.organization?.data || null],
      name: [this.organization?.label || null, [Validators.required,]],
      parentId: [null],
      organizationType: 1
    });
  }

  public ngOnInit(): void {
    this.initializePermissions();
    this.isMobileView = this.platform.IOS || this.platform.ANDROID;
  }

  public closeDialog(result: PopupResultModel) {
    this.ref.close(result);
  }

  public addOrganization() {
    if (this.organizationForm.valid) {
      this.organizationForm.disable();
      const body = this.buildOrganizationRequestBody();
      this.createOrUpdateOrganization(body);
    }
  }

  private initializePermissions(): void {
    // this.hasPermissionToReadOrganizationSetting = this.authService.hasPermission(PermissionsEnum.organizationSetting_read,
    //   [PermissionsEnum.organizationSetting_create, PermissionsEnum.organizationSetting_edit, PermissionsEnum.organizationSetting_delete]);
    // this.hasPermissionToCreateOrganizationSetting = this.authService.hasPermission(PermissionsEnum.organizationSetting_create,
    //   [PermissionsEnum.organizationSetting_delete]);
    // this.hasPermissionToEditOrganizationSetting = this.authService.hasPermission(PermissionsEnum.organizationSetting_edit,
    //   [PermissionsEnum.organizationSetting_delete]);
    // this.hasPermissionToDeleteOrganizationSetting = this.authService.hasPermission(PermissionsEnum.organizationSetting_delete);
    // this.hasPermissionToCreateSetting = this.authService.hasPermission(PermissionsEnum.setting_create, [PermissionsEnum.setting_delete]);
    // this.hasPermissionToEditSetting = this.authService.hasPermission(PermissionsEnum.setting_edit, [PermissionsEnum.setting_delete]);
    // this.hasPermissionToReadSetting = this.authService.hasPermission(PermissionsEnum.setting_read,
    //   [PermissionsEnum.setting_create, PermissionsEnum.setting_edit, PermissionsEnum.setting_delete]);
  }

  private buildOrganizationRequestBody(): OrganizationModel {
    return {
      blocked: false, // todo
      ...this.organizationForm.value,
    };
  }

  private createOrUpdateOrganization(body: OrganizationModel): void {
    if (this.organization) {
      this.updateOrganization(body);
    } else {
      this.createOrganization(body);
    }
  }

  private updateOrganization(organizationData: OrganizationModel): void {
    this.organizationService.updateOrganization(organizationData.organizationId!, organizationData).subscribe({
      next: (response) => {
        if (response.succeed) {
          this.handleSuccessResponse(response, organizationData);
        } else {
          this.notifierService.handleErrors(response.errors);
          this.organizationForm.enable();
        }
      },
      error: (err) => {
        this.notifierService.handleRequestError(err);
        this.organizationForm.enable();
      },
    });
  }

  private createOrganization( organizationData: OrganizationModel): void {
    const parentIdValue = this.parentId || (this.organizationForm.get('parentId')?.value?.data ?? null) || this.utilService.getCurrentOrganizationId();
    this.organizationService.addOrganization(parentIdValue, organizationData).subscribe({
      next: (response) => {
        if (response.succeed) {
          this.handleSuccessResponse(response, organizationData);
        } else {
          this.notifierService.handleErrors(response.errors);
          this.organizationForm.enable();
        }
        this.cdr.detectChanges();
      },
      error: (err) => {
        this.notifierService.handleRequestError(err);
        this.organizationForm.enable();
      },
    });
  }

  private handleSuccessResponse(response: Response<string>, body: OrganizationModel): void {
    if (response.value) {
      this.notifierService.handleSuccessRequest(ORGANIZATION_CREATED_SUCCESSFULLY);
      this.closeDialog({
        created: true,
        value: {...body, organizationId: response.value, parentId: this.parentId} as OrganizationModel,
      });
    } else {
      this.notifierService.handleSuccessRequest(ORGANIZATION_UPDATED_SUCCESSFULLY);
      this.closeDialog({
        updated: true,
        value: body,
      });
    }
  }
}
